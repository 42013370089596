import urls from '@constants/urls';
import { getItemFromStorage } from '@utils/localStorageHandler';

declare const IS_STANDALONE_MODE: boolean;

export type PageLinkType = {
  path: string;
  icon: string;
  name: string;
  activePrefix?: string;
  isShown?: () => boolean;
  beta?: boolean;
};

export const pagesLinks = (workspaceId: number): PageLinkType[] => [
  ...(IS_STANDALONE_MODE
    ? [
        {
          path: urls.DASHBOARD,
          name: 'Dashboard',
          icon: 'dashboard',
          activePrefix: '/dashboard',
        },
      ]
    : []),
  {
    path: urls.ALL_CAMPAIGNS(workspaceId),
    name: 'Campaigns',
    icon: 'campaigns',
    activePrefix: `/workspaces/\\d+/campaigns`,
  },
  {
    path: `/workspaces/${workspaceId}/relationships/websites`,
    name: 'Relationships',
    icon: 'relationships',
    activePrefix: '/workspaces/\\d+/relationships',
  },
  {
    path: `/workspaces/${workspaceId}/insights/campaign-reports`,
    name: 'Insights',
    icon: 'insights',
    activePrefix: '/workspaces/\\d+/insights',
  },
  {
    path: `/workspaces/${workspaceId}/inbox/all`,
    name: 'Inbox',
    icon: 'inbox',
    activePrefix: '/workspaces/\\d+/inbox',
  },
  ...(getItemFromStorage('ENABLE_PRESS_INQUIRIES', false) === 'true'
    ? [
        {
          path: '/inquiries/all-inquiries',
          name: 'Press Inquiries',
          icon: 'inquiries',
          activePrefix: '/inquiries',
        },
      ]
    : []),
  {
    path: '/toolbox/email-finder',
    name: 'Toolbox',
    icon: 'toolbox',
    activePrefix: '/toolbox',
  },
];
