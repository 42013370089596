import React, { useMemo } from 'react';
import { Link, useHistory } from 'react-router-dom';
import cn from 'class-names';

import { RenderConfigurableSidebarLink } from '@uikit/ConfigurableSidebar/_components/ConfigurableSidebarLinks';
import { redirectUserTo } from '@utils/historyHandler';
import useCurrentWorkspaceId from '@hooks/useCurrentWorkspaceId';

function GroupLink({ title, pathKey, pathname, campaignId }) {
  const history = useHistory();
  const currentWorkspaceId = useCurrentWorkspaceId();
  const cnGroupLink = cn('campaign-analytics-sidebar-group__item', {
    'campaign-analytics-sidebar-group__item--active':
      pathname === `/campaign-analytics/${campaignId}/${pathKey}`,
  });

  const url = useMemo(() => {
    return `/workspaces/${currentWorkspaceId}/campaign-analytics/${campaignId}/${pathKey}`;
  }, [campaignId, pathKey]);

  return (
    <Link
      to={url}
      className={cnGroupLink}
      onClick={(event) => {
        redirectUserTo(history, url);
      }}
    >
      {title}
    </Link>
  );
}

export default [
  {
    pageKey: 'peoplePage',
    RenderLink: ({ pathname, campaignId, workspaceId }) => {
      return (
        <RenderConfigurableSidebarLink
          title="People"
          icon="allMembers"
          url={`/workspaces/${workspaceId}/campaign-analytics/${campaignId}/people`}
          isActive={new RegExp(`/workspaces/\\d+/campaign-analytics/${campaignId}/people`).test(
            pathname
          )}
        />
      );
    },
  },
  {
    pageKey: 'opportunitiesPage',
    RenderLink: ({ pathname, campaignId, workspaceId }) => {
      return (
        <RenderConfigurableSidebarLink
          title="Opportunities"
          icon="opportunities"
          url={`/workspaces/${workspaceId}/campaign-analytics/${campaignId}/opportunities`}
          isActive={new RegExp(
            `/workspaces/\\d+/campaign-analytics/${campaignId}/opportunities`
          ).test(pathname)}
        />
      );
    },
  },
  {
    pageKey: 'sequencePage',
    RenderLink: ({ pathname, campaignId, workspaceId }) => {
      return (
        <RenderConfigurableSidebarLink
          title="Sequence"
          icon="sequence"
          url={`/workspaces/${workspaceId}/campaign-analytics/${campaignId}/sequence`}
          isActive={new RegExp(`/workspaces/\\d+/campaign-analytics/${campaignId}/sequence`).test(
            pathname
          )}
        />
      );
    },
  },
  {
    pageKey: 'insightsPage',
    RenderLink: ({ pathname, campaignId, workspaceId }) => {
      return (
        <RenderConfigurableSidebarLink
          title="Insights"
          icon="insights"
          url={`/workspaces/${workspaceId}/campaign-analytics/${campaignId}/insights`}
          isActive={new RegExp(`/workspaces/\\d+/campaign-analytics/${campaignId}/insights`).test(
            pathname
          )}
        />
      );
    },
  },
  {
    pageKey: 'inboxPage',
    RenderLink: ({ pathname, campaignId, workspaceId }) => (
      <RenderConfigurableSidebarLink
        title="Inbox"
        icon="inbox"
        url={`/workspaces/${workspaceId}/campaign-analytics/${campaignId}/inbox`}
        isActive={new RegExp(`/workspaces/\\d+/campaign-analytics/${campaignId}/inbox`).test(
          pathname
        )}
      />
    ),
  },
  {
    pageKey: 'sent',
    RenderLink: ({ pathname, campaignId, workspaceId }) => {
      return (
        <RenderConfigurableSidebarLink
          title="Sent"
          icon="sent"
          url={`/workspaces/${workspaceId}/campaign-analytics/${campaignId}/sent`}
          isActive={new RegExp(`/workspaces/\\d+/campaign-analytics/${campaignId}/sent`).test(
            pathname
          )}
        />
      );
    },
  },
  {
    pageKey: 'outbox',
    RenderLink: ({ pathname, campaignId, workspaceId }) => {
      return (
        <RenderConfigurableSidebarLink
          title="Outbox"
          icon="outbox"
          url={`/workspaces/${workspaceId}/campaign-analytics/${campaignId}/outbox`}
          isActive={new RegExp(`/workspaces/\\d+/campaign-analytics/${campaignId}/outbox`).test(
            pathname
          )}
        />
      );
    },
  },
  {
    pageKey: 'bounced',
    RenderLink: ({ pathname, campaignId, workspaceId }) => {
      return (
        <RenderConfigurableSidebarLink
          title="Bounced"
          icon="needAttention"
          url={`/workspaces/${workspaceId}/campaign-analytics/${campaignId}/bounced`}
          isActive={new RegExp(`/workspaces/\\d+/campaign-analytics/${campaignId}/bounced`).test(
            pathname
          )}
        />
      );
    },
  },
  {
    pageKey: 'settingsPage',
    RenderLink: ({ pathname, campaignId, workspaceId }) => {
      return (
        <RenderConfigurableSidebarLink
          title="Settings"
          icon="gear"
          url={`/workspaces/${workspaceId}/campaign-analytics/${campaignId}/settings`}
          isActive={new RegExp(`/workspaces/\\d+/campaign-analytics/${campaignId}/settings`).test(
            pathname
          )}
        />
      );
    },
  },
];
