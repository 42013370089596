import React from 'react';

import stripHtml from '@utils/stripHtml';
import spamWordsList from '@constants/spamWordsList';

import './SpamWords.scss';

function SpamWords({ template }: { template: string }) {
  const foundedSpamWords = stripHtml(template)
    .trim()
    .split(' ')
    .filter((word) => spamWordsList[word]);

  if (!foundedSpamWords.length) {
    return null;
  }

  return (
    <div className="spam-words">
      {Array.from(new Set(foundedSpamWords)).map((word) => {
        return (
          <span key={word} className="spam-words__word">
            "{word}"
          </span>
        );
      })}
    </div>
  );
}

export default SpamWords;
