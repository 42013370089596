import React, { useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import cn from 'class-names';

import { redirectUserTo } from '@utils/historyHandler';
import SidebarIcon from '../../../uikit/SidebarIcon/SidebarIcon';
import { PageLinkType } from '../pagesLinks';

type SidebarPagesLinksPropsType = {
  pages: PageLinkType[];
  pathname: string;
};

export default function ({ pages, pathname }: SidebarPagesLinksPropsType): JSX.Element {
  const history = useHistory();
  const getSidebarLinkClassName = (isActive) =>
    cn('sidebar__link sidebar-link', {
      'sidebar-link--active': isActive,
    });

  const filteredPages = pages.filter(({ isShown }) => isShown === undefined || isShown());

  const checkIsActive = useCallback(
    (activePrefix): boolean => {
      const pattern = new RegExp(activePrefix);
      return pattern.test(pathname);
    },
    [pathname]
  );

  return (
    <>
      {filteredPages.map(({ path, name, icon, activePrefix, beta }: PageLinkType) => {
        const isActive = checkIsActive(activePrefix);
        return (
          <React.Fragment key={path}>
            <Link
              className={getSidebarLinkClassName(isActive)}
              to={path}
              data-for={`sidebar-link-${path}`}
              data-tip=""
              onClick={(event) => {
                if (isActive) {
                  event.preventDefault();
                } else {
                  redirectUserTo(history, path);
                }
              }}
            >
              <span className="sidebar-link__icon">
                <SidebarIcon isActive={isActive} type={icon} />
              </span>
              {name}
            </Link>
          </React.Fragment>
        );
      })}
    </>
  );
}
