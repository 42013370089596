import React, { ReactNode, RefObject } from 'react';
import { Link, useHistory } from 'react-router-dom';
import cn from 'class-names';

import { Spinner } from '@uikit/Spinner/Spinner';
import { SVGIcon, SvgIconType } from '@uikit/Icon/Icon';

import withConfirmText from '@hocs/withConfirmText';

import './Button.scss';
import { redirectUserTo } from '@utils/historyHandler';

const loaderColorsByType = {
  primary: '#fff',
  secondary: 'black',
  alert: '#fff',
  'bordered-grey': '#8F9199',
};

type TypesUnion =
  | 'primary'
  | 'secondary'
  | 'white'
  | 'black'
  | 'grey'
  | 'bordered'
  | 'bordered-grey'
  | 'ghost'
  | 'additional'
  | 'alert'
  | 'graph'
  | 'show-more'
  | 'confirm';

type ButtonPropsType = {
  children?: ReactNode;
  href?: string;
  type?: TypesUnion;
  onClick?: (any) => void;
  disabled?: boolean;
  onClickDisabled?: (any) => void;
  isSubmit?: boolean;
  className?: string;
  submitText?: string;
  innerRef?: RefObject<HTMLButtonElement>;
  size?: 'xs' | 's' | 'm' | 'l' | 'xl';
  isInline?: boolean;
  isAdditional?: boolean;
  isLoading?: boolean;
  loadingText?: string;
  leftIcon?: string;
  confirmationText?: string;
  [key: string]: any;
  iconSize?: number;
};

export function Button(props: ButtonPropsType): JSX.Element {
  const {
    children,
    type = 'primary',
    className,
    disabled,
    onClickDisabled,
    href,
    onClick,
    size = 'm',
    isSubmit,
    submitText = '',
    innerRef,
    isInline,
    isAdditional,
    isLoading,
    loadingText,
    leftIcon,
    colorIcon,
    confirmationText,
    iconSize,
    ...rest
  } = props;
  const history = useHistory();
  const cnButton = cn('button', {
    'button--disabled': disabled,
    'button--additional': isAdditional,
    'button--inline': isInline,
    [`button--size-${size}`]: size,
    [`button--${type}`]: type.length > 0,
    'button--link': href && href.length > 0,
    [className]: className && className.length > 0,
  });

  const handleClick = (event) => {
    if (disabled && onClickDisabled) {
      onClickDisabled(event);
      return;
    }

    if (!disabled && onClick) {
      onClick(event);
    }
  };

  const renderChildren = () => {
    if (isLoading) {
      return loadingText ? (
        <>
          <Spinner style={{ marginRight: '8px' }} color={loaderColorsByType[type]} />
          <span>{loadingText}</span>
        </>
      ) : (
        <Spinner color={loaderColorsByType[type]} />
      );
    }

    if (leftIcon) {
      return (
        <>
          <SVGIcon icon={leftIcon as SvgIconType} color={colorIcon || 'white'} size={iconSize} />
          {children ? <span>{children}</span> : null}
        </>
      );
    }

    return children;
  };

  if (href) {
    return (
      <Link
        {...rest}
        ref={innerRef}
        onClick={(event) => {
          handleClick(event);
          redirectUserTo(history, href);
        }}
        to={href}
        className={cnButton}
      >
        {renderChildren()}
      </Link>
    );
  }

  if (isSubmit) {
    return (
      <button {...rest} ref={innerRef} className={cnButton} type="submit" disabled={disabled}>
        {renderChildren()}
      </button>
    );
  }

  return (
    <button
      {...rest}
      ref={innerRef}
      onClick={handleClick}
      type="button"
      className={cnButton}
      disabled={disabled && onClickDisabled ? !disabled : disabled}
    >
      {renderChildren()}
    </button>
  );
}

export default withConfirmText<ButtonPropsType>(Button);
