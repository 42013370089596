import {
  changePasswordByTokenApi,
  checkAccountByEmailApi,
  loginByEmailApi,
  loginForUser,
  logoutApi,
  registerApi,
  sendVerificationCodeApi,
} from '@api/auth.api';

import { AuthStep as AUTH_STEP } from 'respona_api/generated/common_pb';
import { LoginResponseType, VerificationEmailResponseType } from '@ts/auth.types';
import { AuthHandlerTypeMap, VerificationCodeTypeMap } from 'respona_api/generated/auth-shared_pb';

import { shutdown as shutdownIntercom } from 'src/libs/intercom';
import urls from '@constants/urls';
import { calendlyStatusTypes } from '@constants/onboardingPopupStatuses';
import gtm from 'src/libs/gtm';
import { redirectUserTo } from '@utils/historyHandler';
import fetchMetadata from '@api/base/fetchMetadata';
import { removeItemFromStorage } from '@utils/localStorageHandler';

declare const IS_STANDALONE_MODE: boolean;

class AuthService {
  loginCallback(history: History, response: LoginResponseType): LoginResponseType {
    const { sessionToken } = response;

    if (IS_STANDALONE_MODE) {
      localStorage.setItem('token', sessionToken);
    }

    if (response.authStep === AUTH_STEP.CAMPAIGN) {
      redirectUserTo(history, urls.CREATE_NEW_CAMPAIGN(response.workspaceId));
    } else if (response.authStep === AUTH_STEP.DASHBOARD) {
      if (IS_STANDALONE_MODE) redirectUserTo(history, urls.DASHBOARD);
      else redirectUserTo(history, urls.ALL_CAMPAIGNS(response.workspaceId));
    } else {
      redirectUserTo(history, '/');
    }

    if (IS_STANDALONE_MODE) {
      if (
        response.firstLogin &&
        localStorage.getItem('showCalendly') !== calendlyStatusTypes.COMPLETE
      ) {
        localStorage.setItem('showCalendly', calendlyStatusTypes.AWAITING);
      }
    }

    return response;
  }

  async checkAccountByEmail(email: string): Promise<VerificationEmailResponseType> {
    return checkAccountByEmailApi(email);
  }

  async sendVerificationCode(
    email: string,
    type: VerificationCodeTypeMap[keyof VerificationCodeTypeMap]
  ): Promise<void> {
    return sendVerificationCodeApi(email, type);
  }

  async login(
    history: History,
    email: string,
    password: string,
    captcha: string
  ): Promise<LoginResponseType> {
    const promise = loginByEmailApi(email, password, captcha);
    promise.then((loginResponse) => {
      gtm.signIn(loginResponse.userId);
      this.loginCallback(history, loginResponse);
    });
    return promise;
  }

  async loginWithToken(
    history: History,
    token: string,
    type?: AuthHandlerTypeMap[keyof AuthHandlerTypeMap]
  ): Promise<LoginResponseType> {
    if (IS_STANDALONE_MODE) {
      const existingToken = localStorage.getItem('token');
      if (existingToken?.length > 0) {
        await logoutApi(existingToken);
        localStorage.removeItem('token');
      }
    }

    return loginForUser(token, type).then((response) => this.loginCallback(history, response));
  }

  async changePasswordByToken(
    history: History,
    token: string,
    email: string,
    password: string
  ): Promise<LoginResponseType> {
    return changePasswordByTokenApi(token, email, password).then((response) =>
      this.loginCallback(history, response)
    );
  }

  async register(
    email: string,
    password: string,
    fullName: string,
    captcha: string,
    code: string,
    ref?: string,
    utmTagPairs?: [string, string][],
    source?: string
  ): Promise<LoginResponseType> {
    return registerApi(email, password, fullName, captcha, code, ref, utmTagPairs, source);
  }

  async logout(history?: History): Promise<void> {
    return fetchMetadata()
      .then((metadata) => {
        return logoutApi(metadata.Authorization).finally(() => {
          if (IS_STANDALONE_MODE) {
            shutdownIntercom();
            redirectUserTo(history, '/welcome');
          } else {
            redirectUserTo(history, '/');
          }
        });
      })
      .finally(() => {
        removeItemFromStorage('token');
        removeItemFromStorage('showCalendly');
        removeItemFromStorage('showEwebinar');
      });
  }
}

export const authService = new AuthService();
