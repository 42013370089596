import React, { useEffect, useMemo, useState } from 'react';

import {
  CustomVariableValueRelatedTo,
  CustomVariableValueRelatedToMap,
} from 'respona_api/generated/common_pb';

import { DispatchType } from 'src/store';

import { checkIsAnyLoading } from '@utils/loadingChecks';

import useAllVariablesInEditor from '@hooks/useAllVariablesInEditor';
import useCurrentWorkspaceId from '@hooks/useCurrentWorkspaceId';

import ConfigurableTable from '@uikit/ConfigurableTable/ConfigurableTable';
import SectionSelect from '@uikit/SectionSelect/SectionSelect';
import Loader from '@uikit/Loader/Loader';

import { customFieldsTableConfig } from '@components/Settings/CustomFields/customFieldsTableConfig';

import './CustomFields.scss';
import { VariableType } from '@constants/variables';
import { getDealsMockVariables } from '@constants/dealsMockVariables';

declare const IS_STANDALONE_MODE: boolean;

const sectionOptions = [
  {
    title: 'Contacts',
    key: CustomVariableValueRelatedTo.PERSON,
    visible: true,
  },
  {
    title: 'Websites',
    key: CustomVariableValueRelatedTo.WEBSITE,
    visible: true,
  },
  {
    title: 'Campaign Opportunities',
    key: CustomVariableValueRelatedTo.OPPORTUNITY,
    visible: true,
  },
  {
    title: 'Deals',
    key: CustomVariableValueRelatedTo.RELATIONSHIP_OPPORTUNITY,
    visible: IS_STANDALONE_MODE,
  },
];

function CustomFields({
  customFieldsRef,
  onRowClick,
}: {
  customFieldsRef?: {
    current?: {
      setRelation: (
        value: CustomVariableValueRelatedToMap[keyof CustomVariableValueRelatedToMap]
      ) => void;
      relation: CustomVariableValueRelatedToMap[keyof CustomVariableValueRelatedToMap];
    };
  };
  onRowClick: (row: VariableType) => void;
}) {
  const [relation, setRelation] = useState<
    CustomVariableValueRelatedToMap[keyof CustomVariableValueRelatedToMap]
  >(CustomVariableValueRelatedTo.PERSON);

  const { variables, isCustomVariablesLoading, removeCustomVariable } =
    useAllVariablesInEditor(null);

  const handleEditFieldSidebarOpen = (value) => onRowClick(value);

  useEffect(() => {
    if (!customFieldsRef) {
      return;
    }

    // eslint-disable-next-line no-param-reassign
    customFieldsRef.current = { setRelation, relation };
  }, [customFieldsRef, setRelation, relation]);

  const preparedVariable = useMemo(() => {
    let values = variables
      .filter((variable) => variable.relatedTo === relation)
      .sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1));
    if (relation === CustomVariableValueRelatedTo.RELATIONSHIP_OPPORTUNITY) {
      values = [...values, ...getDealsMockVariables()];
    }
    return values;
  }, [variables, relation]);

  return (
    <div className="custom-fields">
      <SectionSelect
        selectedOption={relation}
        onChange={setRelation}
        options={sectionOptions.filter((it) => it.visible)}
      />

      <div className="custom-fields__content">
        <Loader isLoading={isCustomVariablesLoading} withTopMargin>
          <ConfigurableTable
            tableClassName="custom-fields__table"
            rowClassName="custom-fields__table__row"
            config={customFieldsTableConfig}
            onRowClick={onRowClick}
            renderProps={{
              onRemove: removeCustomVariable,
              onEdit: handleEditFieldSidebarOpen,
            }}
            data={preparedVariable}
          />
        </Loader>
      </div>
    </div>
  );
}

export default CustomFields;
