import React from 'react';
import { useHistory } from 'react-router-dom';

import { SVGIcon } from '@uikit/Icon/Icon';
import { authService } from 'src/services/authService';

function LogoutButton({ color }: { color?: string }): JSX.Element {
  const history = useHistory();
  const handleLogout = (e) => {
    e.stopPropagation();

    authService.logout(history);
  };

  return (
    <div
      className="sidebar-footer__profile-logout-button"
      onClick={handleLogout}
      role="button"
      tabIndex={0}
    >
      <SVGIcon icon="logout" color={color} />
    </div>
  );
}

export default LogoutButton;
