import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';

import useCurrentWorkspaceId from '@hooks/useCurrentWorkspaceId';
import useCustomVariables from '@hooks/useCustomVariables';
import useWorkspaceMembers from '@hooks/useWorkspaceMembers';
import useRelationshipPipelineStages, {
  PIPELINE_COLUMN_QUERY_KEY,
} from '@hooks/useRelationshipPipelineStages';
import { DEALS_WITHOUT_FILTERS_QUERY_KEY } from '@hooks/useRelationshipOpportunities';
import { DEALS_WITH_FILTERS_QUERY_KEY } from '@hooks/useRelationshipOpportunitiesWithFilters';

import { SVGIcon } from '@uikit/Icon/Icon';
import Loader from '@uikit/Loader/Loader';

import SidebarCRMSection from '@components/SidebarCRM/sections/SidebarCRMSection/SidebarCRMSection';
import DealsSidebar from '@components/Relationships/WebsitesSidebar/_components/DealsSidebar/DealsSidebar';
import CustomVariable from '@components/SidebarCRM/sections/SidebarCRMSectionVariables/_components/CustomVariable/CustomVariable';
import SidebarCRMSectionSelectionField from '@components/SidebarCRM/sections/SidebarCRMSection/_components/SidebarCRMSectionSelectionField/SidebarCRMSectionSelectionField';

import {
  CustomVariablePlaceholderType,
  CustomVariableSelectItemValueType,
  CustomVariableSelectType,
  CustomVariableValueType,
} from '@ts/customVariales.types';
import { RelationshipPipelineOpportunityType } from '@ts/people.types';
import { PipelineOpportunityType } from '@ts/relationshipPipeline.types';

import { getDateShort } from '@utils/date';
import { CustomVariableValueRelatedTo } from 'respona_api/generated/common_pb';

import './SidebarCRMSectionOpportunities.scss';
import useCustomVariablesValues from '@hooks/useCustomVariablesValues';

const resetCache = (queryClient) => {
  queryClient.removeQueries([PIPELINE_COLUMN_QUERY_KEY]);
  queryClient.removeQueries([DEALS_WITHOUT_FILTERS_QUERY_KEY]);
  queryClient.removeQueries([DEALS_WITH_FILTERS_QUERY_KEY]);
};

function Deal({
  itemId,
  opportunity,
  moveOpportunity,
  assignUser,
  createOrUpdateCustomVariableValue,
}) {
  const [stageOptions, setStageOptions] = useState([]);
  const { members, composeMemberOptions } = useWorkspaceMembers();
  const queryClient = useQueryClient();
  const workspaceId = useCurrentWorkspaceId();
  const [showVariablesForPipelineId, setShowVariablesForPipelineId] = useState<number>(-1);
  const { customVariables } = useCustomVariables(
    CustomVariableValueRelatedTo.RELATIONSHIP_OPPORTUNITY
  );
  const customVariablesByType = useMemo(
    () =>
      customVariables?.filter(
        (it) => it.relatedTo === CustomVariableValueRelatedTo.RELATIONSHIP_OPPORTUNITY
      ) || [],
    [customVariables]
  );

  const variableNames = useMemo(
    () => customVariablesByType.map((it) => it.name),
    [customVariablesByType]
  );

  const {
    items: valuesData,
    refetch: refetchCustomVariableValues,
    isLoading: isLoadingCustomVariablesValues,
    addItem: addVariableValueToCache,
  } = useCustomVariablesValues(variableNames, [opportunity.id], false);

  const { items: stages } = useRelationshipPipelineStages(opportunity.pipelineId, workspaceId);

  useEffect(() => {
    if (stages.length > 0) {
      setStageOptions(stages.map((stage) => ({ label: stage.title, value: stage.id })));
    }
  }, [stages.length]);

  const handleSaveVariable = useCallback(
    (newVariable: CustomVariableValueType) => {
      createOrUpdateCustomVariableValue(newVariable).then(() => {
        addVariableValueToCache(newVariable);
      });
    },
    [queryClient]
  );

  const handleSaveStringVariable = useCallback(
    (newInput: string, variable: CustomVariablePlaceholderType, value: CustomVariableValueType) => {
      const newVariable = value
        ? { ...value, stringValue: newInput }
        : ({
            variableId: variable.id,
            variableName: variable.name,
            type: variable.type,
            relatedTo: variable.relatedTo,
            relatedId: opportunity.id,
            stringValue: newInput,
          } as CustomVariableValueType);
      handleSaveVariable(newVariable);
    },
    [handleSaveVariable, workspaceId]
  );

  const handleSaveSelectVariable = (
    newInput: { label: string; value: string }[],
    variable: CustomVariablePlaceholderType,
    value: CustomVariableValueType
  ) => {
    const select = {
      valuesList: newInput.map((it) => {
        return { uid: it.value, value: it.label } as CustomVariableSelectItemValueType;
      }),
    } as CustomVariableSelectType;
    const newVariable = value
      ? ({ ...value, selectValue: select } as CustomVariableValueType)
      : ({
          variableId: variable.id,
          variableName: variable.name,
          type: variable.type,
          relatedTo: variable.relatedTo,
          relatedId: opportunity.id,
          stringValue: null,
          selectValue: select,
        } as CustomVariableValueType);
    handleSaveVariable(newVariable);
  };

  const onClickShowDetails = (opportunityId: number) => {
    if (opportunityId === showVariablesForPipelineId) {
      setShowVariablesForPipelineId(-1);
    } else {
      setShowVariablesForPipelineId(opportunityId);
      refetchCustomVariableValues();
    }
  };

  return (
    <div key={opportunity.id} className="contacts-sidebar-section-opportunities__opportunity">
      <div
        className="contacts-sidebar-section-opportunities__opportunity-title"
        onClick={() =>
          window.open(
            `/workspaces/${workspaceId}/relationships/pipelines?id=${opportunity.pipelineId}`,
            '_blank'
          )
        }
      >
        <span>{opportunity.pipelineTitle}</span>
        <SVGIcon icon="externalLink" color="#221CB6" size={12} />
      </div>
      <div className="contacts-sidebar-section-opportunities__opportunity-status">
        <SidebarCRMSectionSelectionField
          initialValue={opportunity.stageId}
          options={stageOptions}
          icon="flag"
          onChange={(_, newValue: number, label) => {
            moveOpportunity(newValue, opportunity.id, opportunity.pipelineId, label).then((res) => {
              resetCache(queryClient);
            });
          }}
        />
      </div>
      <div className="contacts-sidebar-section-opportunities__opportunity-status">
        <SidebarCRMSectionSelectionField
          initialValue={opportunity.assignedToUserId}
          options={composeMemberOptions(false)}
          icon="user"
          onChange={(_, newValue: number) => {
            assignUser(newValue, opportunity.id).then(() => resetCache(queryClient));
          }}
        />
      </div>
      <div className="contacts-sidebar-section-opportunities__opportunity-date">
        <SVGIcon icon="calendar" color="#C4C6CD" size={16} />
        {opportunity.createdAt > 0 ? getDateShort(new Date(opportunity.createdAt)) : '-'}
      </div>
      {customVariablesByType.length && showVariablesForPipelineId === opportunity.id ? (
        <Loader isLoading={isLoadingCustomVariablesValues}>
          {customVariablesByType.map((customVariableByType) => (
            <CustomVariable
              key={customVariableByType.id}
              item={customVariableByType}
              variableValues={valuesData}
              members={members}
              handleSaveStringVariable={handleSaveStringVariable}
              handleSaveSelectVariable={handleSaveSelectVariable}
            />
          ))}
        </Loader>
      ) : null}
      {customVariablesByType.length > 0 && (
        <div
          className={`contact-sidebar-crm__show-more-btn ${
            showVariablesForPipelineId === opportunity.id ? 'show-less' : ''
          }`}
          onClick={() => onClickShowDetails(opportunity.id)}
        >
          {showVariablesForPipelineId === opportunity.id ? 'Show Less' : 'Show More'}
          <SVGIcon icon="chevron" size={8} />
        </div>
      )}
    </div>
  );
}

function SidebarCRMSectionOpportunities({
  queryKey,
  title = 'Opportunities',
  itemId,
  getOpportunities,
  createOrUpdateCustomVariableValue,
  assignUser,
  moveOpportunity,
  index,
  isDisabledDomain = false,
  isDisabledWebsiteName = false,
}: {
  queryKey: string;
  title?: string;
  itemId: number;
  getOpportunities: (itemId, workspaceId) => Promise<RelationshipPipelineOpportunityType[]>;
  createOrUpdateCustomVariableValue: (
    variables: CustomVariableValueType
  ) => Promise<RelationshipPipelineOpportunityType[]>;
  assignUser: (userId: number, opportunityId: number) => void;
  moveOpportunity: (
    stageId: number,
    opportunityId: number,
    pipelineId: number
  ) => Promise<PipelineOpportunityType>;
  index: number;
  isDisabledDomain?: boolean;
  isDisabledWebsiteName?: boolean;
}) {
  const ref = useRef(null);
  const queryClient = useQueryClient();
  const workspaceId = useCurrentWorkspaceId();

  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [isOpenCreateNewSideBar, setOpenCreateNewSideBar] = useState<boolean>(false);

  const { data, isLoading } = useQuery<RelationshipPipelineOpportunityType[]>(
    [queryKey, itemId],
    () => getOpportunities(itemId, workspaceId),
    {
      enabled: workspaceId > 0 && itemId > 0,
      refetchOnWindowFocus: false,
      staleTime: 15 * (60 * 1000),
      onSettled: (result) => !!result?.length && ref.current.setOpen(true),
    }
  );

  const handleSaveOpportunity = useCallback(
    (
      opportunity: PipelineOpportunityType,
      additionalData: { pipelineId: number; pipelineTitle: string }
    ) => {
      const formattedOpportunity = {
        pipelineId: additionalData.pipelineId,
        pipelineTitle: additionalData.pipelineTitle,
        stageId: opportunity.stageId,
        // TODO: add title
        stageTitle: '',
        assignedToUserId: opportunity.assignedToUserId,
        createdByUserId: opportunity.createdByUserId,
        createdAt: opportunity.createdAt,
        id: opportunity.id,
      };
      // TODO incorrect key - looks like correct :-)
      queryClient.setQueryData([queryKey, itemId], (cachedData: PipelineOpportunityType[]) => {
        if (!cachedData) {
          return [formattedOpportunity];
        }
        return [formattedOpportunity, ...cachedData];
      });

      resetCache(queryClient);
    },
    [queryClient]
  );

  const onOpenCallback = useCallback(() => {
    // let's try to how it works without it
    // queryClient.invalidateQueries([queryKey, itemId]);
  }, [queryClient, itemId]);

  const handleMoveOpportunity = (
    stageId: number,
    opportunityId: number,
    pipelineId: number,
    label: string
  ) => {
    return moveOpportunity(stageId, opportunityId, pipelineId).then((result) => {
      queryClient.setQueryData(
        [queryKey, itemId],
        (cachedData: PipelineOpportunityType[]): PipelineOpportunityType[] => {
          return cachedData.map((opportunity: PipelineOpportunityType) => {
            if (opportunity.id === opportunityId) {
              return {
                ...opportunity,
                stageId,
                stageTitle: label,
                assignedToUserId: result.assignedToUserId,
              };
            }

            return opportunity;
          });
        }
      );
      return result;
    });
  };

  const handleAssignUser = (userId, opportunityId) => {
    queryClient.setQueryData(
      [queryKey, itemId],
      (cachedData: PipelineOpportunityType[]): PipelineOpportunityType[] => {
        return cachedData.map((opportunity: PipelineOpportunityType) => {
          if (opportunity.id === opportunityId) {
            return {
              ...opportunity,
              assignedToUserId: userId,
            };
          }

          return opportunity;
        });
      }
    );

    return assignUser(userId, opportunityId);
  };

  return (
    <SidebarCRMSection
      ref={ref}
      index={index}
      id="opportunities"
      title={title}
      onOpen={onOpenCallback}
      rightComponent={
        <div
          className="contact-sidebar-crm__content__add-btn"
          onClick={() => {
            setOpenCreateNewSideBar(true);
          }}
        >
          <SVGIcon icon="plusIcon" size={10} color="#221CB6" />
          Add
        </div>
      }
    >
      {isOpenCreateNewSideBar ? (
        <DealsSidebar
          extraClass="contacts-sidebar"
          isOpen={isOpenCreateNewSideBar}
          itemId={itemId}
          onSave={handleSaveOpportunity}
          onClose={() => {
            setOpenCreateNewSideBar(false);
          }}
          isDisabledDomain={isDisabledDomain}
          isDisabledWebsiteName={isDisabledWebsiteName}
        />
      ) : null}
      <div className="contacts-sidebar-section-opportunities">
        <Loader isLoading={isLoading} withTopMargin>
          {data?.length > 0 ? (
            data
              .slice(0, isExpanded ? undefined : 3)
              .map((opportunity: RelationshipPipelineOpportunityType) => (
                <Deal
                  key={opportunity.id}
                  itemId={itemId}
                  opportunity={opportunity}
                  moveOpportunity={handleMoveOpportunity}
                  assignUser={handleAssignUser}
                  createOrUpdateCustomVariableValue={createOrUpdateCustomVariableValue}
                />
              ))
          ) : (
            <div className="contacts-sidebar-section-opportunities--empty">Empty</div>
          )}

          {!isExpanded && data?.length > 3 && (
            <div className="contact-sidebar-crm__show-more-btn" onClick={() => setIsExpanded(true)}>
              Show More
              <SVGIcon icon="chevron" size={8} />
            </div>
          )}
        </Loader>
      </div>
    </SidebarCRMSection>
  );
}

export default SidebarCRMSectionOpportunities;
