import React, { useCallback, useRef, useState } from 'react';
import { QueryKey, useQuery } from 'react-query';

import { PeopleRelationshipStatus } from 'respona_api/generated/common_pb';

import { WebsiteType } from '@ts/company.types';
import { PersonBaseType } from '@ts/people.types';

import useCurrentWorkspaceId from '@hooks/useCurrentWorkspaceId';

import { SVGIcon } from '@uikit/Icon/Icon';
import RelationshipsCreatePersonSidebar from '@components/Relationships/RelationshipsCreatePersonSidebar/RelationshipsCreatePersonSidebar';
import SidebarCRMSection from '@components/SidebarCRM/sections/SidebarCRMSection/SidebarCRMSection';

import './SidebarCRMSectionContact.scss';
import SectionPerson from '@components/SidebarCRM/components/SectionPerson/SectionPerson';

export const personStatusOptions = [
  {
    value: PeopleRelationshipStatus.RELATIONSHIP_STATUS_NOT_CONTACTED,
    label: 'Not contacted',
  },
  {
    value: PeopleRelationshipStatus.RELATIONSHIP_STATUS_ATTEMPTING_TO_REACH,
    label: 'Scheduled',
  },
  {
    value: PeopleRelationshipStatus.RELATIONSHIP_STATUS_CONTACTED,
    label: 'Contacted',
  },
  {
    value: PeopleRelationshipStatus.RELATIONSHIP_STATUS_REPLIED,
    label: 'Replied',
  },
  {
    value: PeopleRelationshipStatus.RELATIONSHIP_STATUS_BOUNCED,
    label: 'Bounced',
  },
  {
    value: PeopleRelationshipStatus.RELATIONSHIP_STATUS_UNSUBSCRIBED,
    label: 'Unsubscribed',
  },
  {
    value: PeopleRelationshipStatus.RELATIONSHIP_STATUS_CANCELED_MANUALLY,
    label: 'Stopped',
  },
  {
    value: PeopleRelationshipStatus.RELATIONSHIP_STATUS_INTERESTED,
    label: 'Interested',
  },
  {
    value: PeopleRelationshipStatus.RELATIONSHIP_STATUS_NOT_INTERESTED,
    label: 'Not interested',
  },
  {
    value: PeopleRelationshipStatus.RELATIONSHIP_STATUS_WON,
    label: 'Won',
  },
  {
    value: PeopleRelationshipStatus.RELATIONSHIP_STATUS_LOST,
    label: 'Lost',
  },
];

function SidebarCRMSectionContact({
  queryKeyWebsite,
  index,
  itemId,
  title = 'Contacts',
  getWebsite,
  updateContact,
  onCreatedContact,
  removeContact,
  saveEmail,
  removeEmail,
}: {
  queryKeyWebsite: QueryKey;
  index: number;
  itemId: number;
  title: string;
  getWebsite: (itemId: number, workspaceId: number) => Promise<WebsiteType>;
  updateContact?: (
    key: keyof PersonBaseType,
    value: string,
    contact: PersonBaseType
  ) => Promise<void>;
  onCreatedContact: (PersonBaseType) => Promise<void>;
  removeContact: (id: number) => Promise<void>;
  saveEmail?: (contactId: number, initialEmail: string, email: string) => Promise<void>;
  removeEmail?: (personId: number, email: string) => Promise<void>;
}) {
  const ref = useRef(null);
  const workspaceId = useCurrentWorkspaceId();
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [queryEnabled, setQueryEnabled] = useState<boolean>(true);
  const [isOpenedCreatePersonSideBar, setOpenedCreatePersonSideBar] = useState(false);

  const onOpenCallback = useCallback(() => {
    setQueryEnabled(true);
  }, []);

  if (!itemId) {
    return null;
  }

  const preparedQuery = [queryKeyWebsite, itemId, workspaceId];
  const { data: website } = useQuery<WebsiteType>(
    preparedQuery,
    () => getWebsite(itemId, workspaceId),
    {
      enabled: queryEnabled && !!itemId,
      refetchOnWindowFocus: false,
    }
  );

  const forceOpen = website?.contactsList?.length > 0;

  return (
    <SidebarCRMSection
      extraClass="inbox-contact-section"
      ref={ref}
      index={index}
      id="contacts"
      title={title}
      onOpen={onOpenCallback}
      forceOpen={forceOpen}
      rightComponent={
        <div
          className="contact-sidebar-crm__content__add-btn"
          onClick={() => setOpenedCreatePersonSideBar(true)}
        >
          <SVGIcon icon="plusIcon" size={10} color="#221CB6" />
          Add
        </div>
      }
    >
      <div>
        {website?.contactsList.length === 0 ? (
          <div className="contacts-sidebar-section-metrics--empty">Empty</div>
        ) : (
          website?.contactsList
            ?.slice(0, isExpanded ? undefined : 3)
            ?.map((contact) => (
              <SectionPerson
                key={contact.id}
                contact={contact as any}
                updateContact={(key, value, con) => updateContact(key, value, con as any)}
                removeContact={removeContact}
                saveEmail={saveEmail}
                removeEmail={removeEmail}
                isRemovable={website?.contactsList?.length > 1}
              />
            ))
        )}
        {!isExpanded && website?.contactsList?.length > 3 && (
          <div className="contact-sidebar-crm__show-more-btn" onClick={() => setIsExpanded(true)}>
            Show More
            <SVGIcon icon="chevron" size={8} />
          </div>
        )}
      </div>
      {!isOpenedCreatePersonSideBar ? null : (
        <div className="relationships-organizations__person-info-sidebar">
          <RelationshipsCreatePersonSidebar
            website={website}
            onUpdateContactInWebsite={(contact) => {
              onCreatedContact(contact);
              setOpenedCreatePersonSideBar(false);
            }}
            onClose={() => setOpenedCreatePersonSideBar(false)}
          />
        </div>
      )}
    </SidebarCRMSection>
  );
}

export default SidebarCRMSectionContact;
