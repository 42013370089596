import React, { Dispatch, SetStateAction } from 'react';
import { useHistory } from 'react-router-dom';

import BaseModal from '@uikit/BaseModal/BaseModal';

import './PersonalizationPipelineSetupModal.scss';
import { SVGIcon } from '@uikit/Icon/Icon';
import { OpenAIModelType } from 'respona_api/generated/common_pb';
import { useSelector } from 'react-redux';
import { personalizationAutomationSelector } from '@redux/selectors/automationPersonalization.selectors';
import { billingDetailsSelector } from '@redux/selectors/billings.selectors';
import { Button } from '@uikit/Button/Button';
import PersonalizationAutomationSetup from '@components/CampaignSettings/PersonalizationAutomationSetup/PersonalizationAutomationSetup';
import { redirectUserTo } from '@utils/historyHandler';

function PersonalizationPipelineSetupModal({
  pipelineId,
  isOpenModal,
  setOpenModal,
  setPaymentSidebarOpen,
  setUpsellCreditsSidebarOpened,
  setUpsellCreditsNotEnough,
}: {
  pipelineId: number;
  isOpenModal: boolean;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  setPaymentSidebarOpen: (boolean) => void;
  setUpsellCreditsSidebarOpened: (boolean) => void;
  setUpsellCreditsNotEnough: (number) => void;
}): JSX.Element {
  const { data: automationPersonalization } = useSelector(personalizationAutomationSelector);
  const { data: billingDetails } = useSelector(billingDetailsSelector);
  const isStarterPlan = () => billingDetails.type === 0;
  const history = useHistory();
  return (
    <BaseModal
      classNames={{ modal: 'personalization-campaign-modal' }}
      isOpen={isOpenModal}
      onClose={() => setOpenModal(false)}
      closeOnBackgroundClick={false}
    >
      <div className="personalization-campaign-builder">
        <div className="personalization-campaign-builder__header">
          <div className="personalization-campaign-builder__header-title">AI Personalization</div>
          <div className="personalization-campaign-builder__header-info">
            <SVGIcon size={10} icon="wallet" />
            <span>
              {automationPersonalization.model === OpenAIModelType.GPT_EXPENSIVE
                ? '5 credits'
                : '1 credit'}{' '}
              per email
            </span>
          </div>
        </div>
        <div className="personalization-campaign-builder__content">
          {isStarterPlan() ? (
            <div className="accordion-content-body__started-plan">
              <h2 className="accordion-content-body__started-plan-title">Unlock AI Personalizer</h2>
              <p className="accordion-content-body__started-plan-text">
                2X your reply rates with AI-powered variables. Available on Pro and Unlimited plans.
              </p>
              <Button
                className="accordion-content-body__started-plan-button"
                type="additional"
                onClick={() => redirectUserTo(history, '/settings/billing/change-subscription')}
              >
                Upgrade my plan
              </Button>
            </div>
          ) : (
            <PersonalizationAutomationSetup
              pipelineId={pipelineId}
              setOpenModal={setOpenModal}
              setUpsellCreditsSidebarOpened={setUpsellCreditsSidebarOpened}
              setUpsellCreditsNotEnough={setUpsellCreditsNotEnough}
              setPaymentSidebarOpen={setPaymentSidebarOpen}
            />
          )}
        </div>
      </div>
    </BaseModal>
  );
}

export default PersonalizationPipelineSetupModal;
