import React, { useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import { DispatchType } from 'src/store';

import { SVGIcon } from '@uikit/Icon/Icon';
import OpportunityContactSidebarSection from '@components/CampaignSettings/OpportunityContactSidebar/_components/OpportunityContactSidebarSection/OpportunityContactSidebarSection';
import ManualConfirmContactSideBar from '@components/CampaignSettings/ManualConfirmContactSideBar/ManualConfirmContactSideBar';
import SubscriptionActivationSidebar from '@components/SubscriptionActivationSidebars/SubscriptionActivationSidebar';
import SectionPerson from '@components/SidebarCRM/components/SectionPerson/SectionPerson';
import UpsellCreditsSidebar from '@components/UpsellSidebars/UpsellCreditsSidebar/UpsellCreditsSidebar';

import { addNotification } from '@redux/actions/notifications.actions';
import { getActiveCampaignInfo } from '@redux/selectors/campaignSettings.selectors';
import { fetchBillingCredits, fetchBillingDetails } from '@redux/thunks/billings.thunks';
import {
  currentContactAutomationStateSelector,
  currentContactAutomationTypeSelector,
} from '@redux/selectors/automationContact.selectors';

import { OpportunityDetailsType, OpportunitySidebarProps } from '@ts/opportunity.types';
import { OpportunityContactContainerType } from '@ts/contact.types';
import { ContactAutomationSearchModes } from '@ts/automationContacts.types';

import { getOpportunitiesStatsApi, removeOpportunityContactApi } from '@api/campaign.api';
import { ContactSearchSource as CONTACT_SEARCH_SOURCE } from 'respona_api/generated/common_pb';

import './SidebarCRMSectionContactOpportunity.scss';

function SidebarCRMSectionContactOpportunity({
  opportunity,
  index,
  actions,
}: OpportunitySidebarProps) {
  const { contactsList: contacts } = opportunity;
  const dispatch = useDispatch<DispatchType>();
  const queryClient = useQueryClient();
  const {
    info: { id: campaignId, workspaceId },
  } = useSelector(getActiveCampaignInfo);
  const [openContactSidebar, setOpenContactSidebar] = useState<boolean>(false);
  const [isOpen, setOpen] = useState(contacts.length > 0);
  const [isPaymentSidebarOpen, setPaymentSidebarOpen] = useState<boolean>(false);
  const [upsellCreditsSidebarOpened, setUpsellCreditsSidebarOpened] = useState<boolean>(false);
  const [isExpanded, setIsExpanded] = useState<boolean>(contacts.length > 0);
  const [upsellCreditsNotEnough, setUpsellCreditsNotEnough] = useState<number>(0);

  useEffect(() => {
    if ((isOpen && contacts == null) || contacts.length <= 0) {
      setOpen(false);
      setIsExpanded(false);
    }
  }, [contacts]);

  const removeContact = (contactId: number) => {
    return removeOpportunityContactApi(opportunity.id, workspaceId, contactId).then(() => {
      dispatch(addNotification({ title: 'Contact removed', type: 'success' }));
      queryClient.setQueryData(
        ['active-opportunity', opportunity.id],
        (cachedOpp: OpportunityDetailsType) => ({
          ...cachedOpp,
          contactsList: cachedOpp.contactsList.filter((it) => it.contactId !== contactId),
        })
      );
      queryClient.setQueryData(
        ['opportunities-with-metrics', campaignId, workspaceId, []],
        (cachedData) => {
          if (cachedData == null) {
            return cachedData;
          }
          // @ts-ignore
          const { pages, pageParams } = cachedData;
          const newPages = pages[0].map((item) => {
            if (item.id !== opportunity.id) {
              return item;
            }

            return {
              ...item,
              contactsList: item.contactsList.filter((it) => it.contactId !== contactId),
            };
          });

          return {
            pageParams,
            pages: [newPages],
          };
        }
      );
    });
  };

  const automationContact = useSelector(currentContactAutomationStateSelector);
  const automationType = useSelector(currentContactAutomationTypeSelector);
  const { info: campaignInfo } = useSelector(getActiveCampaignInfo);

  const { data: dataOpportunitiesStats, isLoading: isLoadingOpportunitiesStats } = useQuery(
    [
      'opportunities-stats-counter',
      campaignInfo.id,
      campaignInfo.workspaceId,
      campaignInfo.lastBatch,
    ],
    () =>
      getOpportunitiesStatsApi(campaignInfo.id, campaignInfo.workspaceId, campaignInfo.lastBatch),
    {
      enabled: !!campaignInfo.id,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (isLoadingOpportunitiesStats === false) {
      setUpsellCreditsNotEnough(getCardsInfo().secondValue);
    }
  }, [isLoadingOpportunitiesStats]);

  const unassignedOpportunitiesCount = () => {
    if (isLoadingOpportunitiesStats) {
      return 0;
    }
    return dataOpportunitiesStats.withoutContacts;
  };

  const getCardsInfo = () => {
    const opportunitiesWithoutContactsNumber = unassignedOpportunitiesCount();
    if (opportunitiesWithoutContactsNumber == null) {
      return {
        secondValue: 0,
      };
    }
    const assignNumber =
      automationType === ContactAutomationSearchModes.BLOG_POST ||
      automationType === ContactAutomationSearchModes.DOMAINS
        ? automationContact.assignNumber || 2
        : 1;

    const contactsPerOpportunityNumber = opportunitiesWithoutContactsNumber * assignNumber;

    return {
      firstTitle: 'Unassigned opportunities',
      firstValue: opportunitiesWithoutContactsNumber,
      secondTitle:
        automationContact.source !== CONTACT_SEARCH_SOURCE.RESPONA_SEARCH_SOURCE
          ? 'Estimated searches'
          : 'Reserved credits',
      secondValue:
        automationType === ContactAutomationSearchModes.EXPERIMENTAL
          ? opportunitiesWithoutContactsNumber + contactsPerOpportunityNumber * 50
          : automationContact.source !== CONTACT_SEARCH_SOURCE.RESPONA_SEARCH_SOURCE
            ? opportunitiesWithoutContactsNumber + contactsPerOpportunityNumber
            : opportunitiesWithoutContactsNumber + contactsPerOpportunityNumber * 5,
      thirdTitle: 'Time (mins)',
      thirdValue: Math.ceil(contactsPerOpportunityNumber * 1.1 + Math.random() * 0.2),
    };
  };

  return (
    <OpportunityContactSidebarSection
      index={index}
      id="opportunity-contacts"
      title="Contacts"
      showArrow={contacts?.length > 0}
      onOpen={() => setOpen(!isOpen)}
      isInitialOpen={isOpen}
      rightComponent={
        <div
          className="opportunity-contacts-sidebar-crm__content__add-btn"
          onClick={() => setOpenContactSidebar(true)}
        >
          <SVGIcon icon="plusIcon" size={10} color="#221CB6" />
          Add
        </div>
      }
    >
      {isPaymentSidebarOpen && (
        <SubscriptionActivationSidebar
          title="Activate your subscription"
          onClose={() => {
            setPaymentSidebarOpen(false);
          }}
          onActionAfterPay={() => {
            setPaymentSidebarOpen(false);
            dispatch(fetchBillingDetails());
            dispatch(fetchBillingCredits());
          }}
        />
      )}

      {openContactSidebar && (
        <ManualConfirmContactSideBar
          activeOpportunity={opportunity as any}
          onClose={() => {
            setOpenContactSidebar(false);
          }}
          setPaymentSidebarOpen={setPaymentSidebarOpen}
          setUpsellCreditsSidebarOpened={setUpsellCreditsSidebarOpened}
          refetchActiveOpportunity={actions.refetchActiveOpportunity}
        />
      )}

      {upsellCreditsSidebarOpened && (
        <UpsellCreditsSidebar
          title="You don&rsquo;t have enough credits for this action. How would you like to proceed?"
          onClose={() => setUpsellCreditsSidebarOpened(false)}
          needForAutomation={upsellCreditsNotEnough}
        />
      )}

      {contacts?.length > 0 ? (
        <div className="opportunity-contacts-sidebar-section-contacts__contacts">
          {contacts?.slice(0, isExpanded ? undefined : 3)?.map((contact) => (
            <SectionPerson
              contact={{
                name: contact.name,
                status: contact.status,
                emailsList: [contact.contactEmail],
                jobPosition: contact.jobPosition,
                socialLinksList: contact.socialLinksList,
                contactId: contact.contactId,
                id: contact.personId,
              }}
              removeContact={removeContact}
              isRemovable={contacts.length > 1}
            />
          ))}

          {!isExpanded && contacts?.length > 3 && (
            <div
              className="opportunity-contacts-sidebar-crm__show-more-btn"
              onClick={() => setIsExpanded(true)}
            >
              Show More
              <SVGIcon icon="chevron" size={8} />
            </div>
          )}
        </div>
      ) : (
        <div className="opportunity-contacts-sidebar-section-tags--empty">Empty</div>
      )}
    </OpportunityContactSidebarSection>
  );
}

export default SidebarCRMSectionContactOpportunity;
