import React, { useState } from 'react';

import { useHistory, useLocation } from 'react-router-dom';

import urls from '@constants/urls';

import Button from '@uikit/Button/Button';

import Display from '@components/Display/Display';
import PageHeader from '@components/PageHeader/PageHeader';
import CampaignsSearch from '@components/CampainsHeader/CampaignsSearch/CampaignsSearch';

import './CampaignsHeader.scss';
import MoveToFolderModal from '@components/Campaigns/MoveToFolderModal/MoveToFolderModal';
import { CampaignType } from '@ts/campaigns.types';
import { CampaignBatchOperations } from 'respona_api/generated/campaign_pb';
import CampaignBatchAction = CampaignBatchOperations.CampaignBatchAction;
import ChangeOwnerModal from '@components/Campaigns/ChangeOwnerModal/ChangeOwnerModal';
import useWorkspaceMembers from '@hooks/useWorkspaceMembers';
import { redirectUserTo } from '@utils/historyHandler';
import useCurrentWorkspaceId from '@hooks/useCurrentWorkspaceId';

function CampaignsHeaderActions({
  selectedCampaigns,
  handleCampaignBatchAction,
  refetchCampaigns,
}: {
  selectedCampaigns: CampaignType[];
  handleCampaignBatchAction: (
    action: CampaignBatchOperations.CampaignBatchActionMap[keyof CampaignBatchOperations.CampaignBatchActionMap],
    optDestinationId?: number
  ) => void;
  refetchCampaigns: () => void;
}) {
  const { members: workspaceMembers } = useWorkspaceMembers();
  const location = useLocation();

  const [isCreatingCampaign, setIsCreatingCampaign] = useState<boolean>();
  const [isOpenMoveToFolderModal, setOpenMoveToFolderModal] = useState<boolean>(false);
  const [isOpenChangeOwnerModal, setOpenChangeOwnerModal] = useState<boolean>(false);
  const currentWorkspaceId = useCurrentWorkspaceId();

  const history = useHistory();

  const isDeleted = location.pathname === urls.DELETED(currentWorkspaceId);

  const startNewCampaign = () => {
    const query = new URLSearchParams(location.search);
    const folderId = query.get('folderId');
    if (folderId?.length > 0) {
      redirectUserTo(
        history,
        `/workspaces/${currentWorkspaceId}/campaign-templates?folderId=${folderId}`
      );
    } else {
      redirectUserTo(history, `/workspaces/${currentWorkspaceId}/campaign-templates`);
    }
  };

  const closeMoveModal = () => {
    setOpenMoveToFolderModal(false);
    refetchCampaigns();
  };

  const closeChangeOwnerModal = () => {
    setOpenChangeOwnerModal(false);
    refetchCampaigns();
  };

  return (
    <div className="campaigns-header__actions-wrapper">
      <Display isVisible={!isDeleted}>
        <>
          <CampaignsSearch
            selectedCampaigns={selectedCampaigns}
            handleCampaignBatchAction={handleCampaignBatchAction}
            setOpenMoveToFolderModal={setOpenMoveToFolderModal}
            setOpenChangeOwnerModal={setOpenChangeOwnerModal}
          />
          <Button
            isLoading={isCreatingCampaign}
            loadingText="Starting campaign..."
            className="campaigns-header__button"
            onClick={startNewCampaign}
          >
            Start new campaign
          </Button>
        </>
      </Display>

      <MoveToFolderModal
        campaigns={selectedCampaigns}
        isOpen={isOpenMoveToFolderModal}
        onClose={closeMoveModal}
      />
      <ChangeOwnerModal
        ownedItems={selectedCampaigns.map(({ id, ownerId, title }) => ({ id, ownerId, title }))}
        onClose={closeChangeOwnerModal}
        isOpen={isOpenChangeOwnerModal}
        onConfirmChangeOwner={(ownerId) =>
          handleCampaignBatchAction(CampaignBatchAction.CHANGE_OWNER, ownerId)
        }
        workspaceMembers={workspaceMembers}
      />
    </div>
  );
}

function CampaignsHeader({
  title,
  selectedCampaigns,
  handleCampaignBatchAction,
  refetchCampaigns,
}: {
  title: string;
  selectedCampaigns: CampaignType[];
  handleCampaignBatchAction: (
    action: CampaignBatchOperations.CampaignBatchActionMap[keyof CampaignBatchOperations.CampaignBatchActionMap],
    optDestinationId?: number
  ) => void;
  refetchCampaigns: () => void;
}): JSX.Element {
  return (
    <PageHeader
      title={title}
      howToLink="https://help.respona.com/en/articles/6332415-how-to-use-campaigns"
      renderHeaderActions={() => (
        <CampaignsHeaderActions
          selectedCampaigns={selectedCampaigns}
          handleCampaignBatchAction={handleCampaignBatchAction}
          refetchCampaigns={refetchCampaigns}
        />
      )}
    />
  );
}

export default CampaignsHeader;
