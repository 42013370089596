import React, { useEffect } from 'react';
import { requestMainBillingPopup } from '@api/semrush/integration.api';

function IntegrationBillingFailedPage(): JSX.Element {
  useEffect(() => {
    requestMainBillingPopup();
  }, []);

  return <div />;
}

export default IntegrationBillingFailedPage;
