import request from '@api/base/request';

import automationContentService from '@api/services/automationContentService';
import { createRange } from '@helpers/apiHelpers';

import {
  BacklinksSearch,
  ContentAutomationRequest,
  KeywordSearch,
  LaunchContentAutomationRequest,
  PodcastSearch,
} from 'respona_api/generated/automation-content_pb';

import {
  AhrefMetricsType,
  BacklinksSearchType,
  ContentAutomationPresetContainerType,
  KeywordsSearchType,
  ListennotesMetricsType,
  MetricsTypeType,
  MozMetricsType,
  PodcastSearchType,
  SearchModeType,
  SemrushMetricsType,
} from '@ts/automationContent.types';
import * as common_pb from 'respona_api/generated/common_pb';
import {
  AhrefMetrics,
  BacklinksSearchSource,
  BacklinkStatus,
  ContentMetricsType,
  ContentMetricsTypeMap,
  DateOfLastEpisode,
  ListennotesMetrics,
  MozMetrics,
  SemrushMetrics,
  TimestampRange,
} from 'respona_api/generated/common_pb';

declare const IS_STANDALONE_MODE: boolean;

function composeRealMetricsType(
  metricsType: MetricsTypeType,
  ahrefMetrics: AhrefMetricsType,
  semrushMetrics: SemrushMetricsType
): ContentMetricsTypeMap[keyof ContentMetricsTypeMap] {
  if (metricsType === ContentMetricsType.LISTENNOTES_METRICS) {
    return ContentMetricsType.LISTENNOTES_METRICS;
  }
  if (metricsType === ContentMetricsType.AHREFS_METRICS && ahrefMetrics?.includePaidMetrics) {
    return ContentMetricsType.AHREFS_METRICS;
  }
  if (metricsType === ContentMetricsType.SEMRUSH_METRICS && semrushMetrics?.includePaidMetrics) {
    return ContentMetricsType.SEMRUSH_METRICS;
  }
  if (metricsType === ContentMetricsType.MOZ_METRICS && IS_STANDALONE_MODE) {
    return ContentMetricsType.MOZ_METRICS;
  }
  return ContentMetricsType.EMPTY_METRICS;
}

export const buildLaunchCampaignContentAutomationApiRequest = (
  enableContactsSearch: boolean,
  campaignId: number,
  workspaceId: number,
  batch: number,
  pipelineId: number,
  searchMode: SearchModeType,
  metricsType: MetricsTypeType,
  keywordsConfig?: KeywordsSearchType,
  backlinksConfig?: BacklinksSearchType,
  podcastConfig?: PodcastSearchType,
  ahrefMetrics?: AhrefMetricsType,
  listennotesMetrics?: ListennotesMetricsType,
  semrushMetrics?: SemrushMetricsType,
  mozMetrics?: MozMetricsType
): LaunchContentAutomationRequest => {
  const realMetricsType = composeRealMetricsType(metricsType, ahrefMetrics, semrushMetrics);
  const launchRequest = new LaunchContentAutomationRequest();

  launchRequest.setCampaignPipelineId(pipelineId);
  launchRequest.setCampaignId(campaignId);
  launchRequest.setWorkspaceId(workspaceId);
  launchRequest.setBatch(batch);
  launchRequest.setSearchMode(searchMode);
  launchRequest.setMetricsType(realMetricsType);

  if (keywordsConfig) {
    const keywordsModel = new KeywordSearch();

    keywordsModel.setSource(keywordsConfig.source);
    keywordsModel.setQueriesList(keywordsConfig.queriesList);
    keywordsModel.setAnalyzeResultsNumber(keywordsConfig.analyzeResultsNumber);
    keywordsModel.setLocation(keywordsConfig.location);
    keywordsModel.setLanguage(keywordsConfig.language);
    keywordsModel.setOneResultPerDomain(keywordsConfig.oneResultPerDomain);
    keywordsModel.setIgnoreUnsubscribed(keywordsConfig.ignoreUnsubscribed);
    keywordsModel.setIgnoreAlreadyContacted(keywordsConfig.ignoreAlreadyContacted);
    keywordsModel.setSortBy(keywordsConfig.sortBy);
    keywordsModel.setIgnoreActive(keywordsConfig.ignoreActive);
    keywordsModel.setOptionalUnlinkedDomain(keywordsConfig.optionalUnlinkedDomain);

    if (keywordsConfig.optionalFreshnessValue) {
      const timestampRange = new common_pb.TimestampRange();

      timestampRange.setFrom(keywordsConfig.optionalFreshnessValue.from);
      timestampRange.setTo(keywordsConfig.optionalFreshnessValue.to);

      keywordsModel.setOptionalFreshnessValue(timestampRange);
    }

    if (keywordsConfig.ignoreAlreadyContacted) {
      keywordsModel.setOptionalIgnoreAlreadyContactedDays(
        keywordsConfig.optionalIgnoreAlreadyContactedDays
      );
    }

    launchRequest.setKeywords(keywordsModel);
  }

  if (backlinksConfig) {
    const backlinksSearchModel = new BacklinksSearch();

    backlinksSearchModel.setSource(backlinksConfig.source);

    backlinksSearchModel.setUrlsList(backlinksConfig.urlsList);
    backlinksSearchModel.setAnalyzeResultsNumber(backlinksConfig.analyzeResultsNumber);
    backlinksSearchModel.setRel(backlinksConfig.rel);
    backlinksSearchModel.setOptionalAnchor(backlinksConfig.optionalAnchor);
    backlinksSearchModel.setOneResultPerDomain(backlinksConfig.oneResultPerDomain);
    backlinksSearchModel.setIgnoreUnsubscribed(backlinksConfig.ignoreUnsubscribed);
    backlinksSearchModel.setIgnoreAlreadyContacted(backlinksConfig.ignoreAlreadyContacted);
    backlinksSearchModel.setIgnoreHomepages(backlinksConfig.ignoreHomepages);
    backlinksSearchModel.setIgnoreActive(backlinksConfig.ignoreActive);
    backlinksSearchModel.setOptionalSourceUrl(backlinksConfig.optionalSourceUrl);
    backlinksSearchModel.setOptionalTargetUrl(backlinksConfig.optionalTargetUrl);
    backlinksSearchModel.setOptionalUnlinkedDomain(backlinksConfig.optionalUnlinkedDomain);
    backlinksSearchModel.setSortBy(backlinksConfig.sortBy);
    if (
      backlinksConfig.source === BacklinksSearchSource.RESPONA_BACKLINKS_SEARCH ||
      backlinksConfig.source === BacklinksSearchSource.MOZ_BACKLINKS_SEARCH
    ) {
      backlinksSearchModel.setLinkStatus(BacklinkStatus.ALL_BACKLINKS);
    } else {
      backlinksSearchModel.setLinkStatus(backlinksConfig.linkStatus);
    }

    if (backlinksConfig.ignoreAlreadyContacted) {
      backlinksSearchModel.setOptionalIgnoreAlreadyContactedDays(
        backlinksConfig.optionalIgnoreAlreadyContactedDays
      );
    }

    launchRequest.setBacklinks(backlinksSearchModel);
  }

  if (podcastConfig) {
    const podcastSearchModel = new PodcastSearch();

    podcastSearchModel.setSource(podcastConfig.source);
    podcastSearchModel.setKeywordsList(podcastConfig.keywordsList);
    podcastSearchModel.setAnalyzeResultsNumber(podcastConfig.analyzeResultsNumber);
    podcastSearchModel.setLocation(podcastConfig.location);
    podcastSearchModel.setLanguage(podcastConfig.language);
    podcastSearchModel.setOneResultPerDomain(podcastConfig.oneResultPerDomain);
    podcastSearchModel.setIgnoreUnsubscribed(podcastConfig.ignoreUnsubscribed);
    podcastSearchModel.setIgnoreAlreadyContacted(podcastConfig.ignoreAlreadyContacted);
    podcastSearchModel.setIgnoreActive(podcastConfig.ignoreActive);
    podcastSearchModel.setHasGuestInterviews(podcastConfig.hasGuestInterviews);
    podcastSearchModel.setCreateContacts(enableContactsSearch);

    if (podcastConfig.ignoreAlreadyContacted) {
      podcastSearchModel.setOptionalIgnoreAlreadyContactedDays(
        podcastConfig.optionalIgnoreAlreadyContactedDays
      );
    }

    launchRequest.setPodcast(podcastSearchModel);
  }

  if (realMetricsType === ContentMetricsType.AHREFS_METRICS) {
    const ahrefMetricsModel = new AhrefMetrics();

    ahrefMetricsModel.setIncludePaidMetrics(ahrefMetrics.includePaidMetrics);
    ahrefMetricsModel.setDomainRating(
      createRange(ahrefMetrics.domainRating.min, ahrefMetrics.domainRating.max)
    );
    ahrefMetricsModel.setDomainMonthlyTraffic(
      createRange(ahrefMetrics.domainMonthlyTraffic.min, ahrefMetrics.domainMonthlyTraffic.max)
    );
    // ahrefMetricsModel.setWebsiteTraffic(
    //   createRange(ahrefMetrics.websiteTraffic.min, ahrefMetrics.websiteTraffic.max)
    // );
    ahrefMetricsModel.setDomainOrganicKeywords(
      createRange(ahrefMetrics.domainOrganicKeywords.min, ahrefMetrics.domainOrganicKeywords.max)
    );

    launchRequest.setAhrefs(ahrefMetricsModel);
  }

  if (realMetricsType === ContentMetricsType.SEMRUSH_METRICS) {
    const semrushMetricsModel = new SemrushMetrics();

    semrushMetricsModel.setIncludePaidMetrics(semrushMetrics.includePaidMetrics);
    semrushMetricsModel.setDomainAuthorityScore(
      createRange(semrushMetrics.domainAuthorityScore.min, semrushMetrics.domainAuthorityScore.max)
    );
    semrushMetricsModel.setDomainMonthlyTraffic(
      createRange(semrushMetrics.domainMonthlyTraffic.min, semrushMetrics.domainMonthlyTraffic.max)
    );
    semrushMetricsModel.setDomainOrganicKeywords(
      createRange(
        semrushMetrics.domainOrganicKeywords.min,
        semrushMetrics.domainOrganicKeywords.max
      )
    );

    launchRequest.setSemrush(semrushMetricsModel);
  }

  if (realMetricsType === ContentMetricsType.MOZ_METRICS) {
    const mozMetricsModel = new MozMetrics();

    mozMetricsModel.setIncludePaidMetrics(mozMetrics.includePaidMetrics);
    mozMetricsModel.setDomainAuthority(
      createRange(mozMetrics.domainAuthority.min, mozMetrics.domainAuthority.max)
    );
    mozMetricsModel.setDomainSpamScore(
      createRange(mozMetrics.domainSpamScore.min, mozMetrics.domainSpamScore.max)
    );
    mozMetricsModel.setPageAuthority(
      createRange(mozMetrics.pageAuthority.min, mozMetrics.pageAuthority.max)
    );

    launchRequest.setMoz(mozMetricsModel);
  }

  if (realMetricsType === ContentMetricsType.LISTENNOTES_METRICS) {
    const listennotesMetricsModel = new ListennotesMetrics();

    listennotesMetricsModel.setTotalEpisodes(
      createRange(listennotesMetrics.totalEpisodes.min, listennotesMetrics.totalEpisodes.max)
    );
    listennotesMetricsModel.setDateoflastepisode(listennotesMetrics.dateoflastepisode);
    if (listennotesMetrics.dateoflastepisode === DateOfLastEpisode.CUSTOM) {
      const customDateRange = new TimestampRange();
      customDateRange.setFrom(listennotesMetrics.customDateValue.from);
      customDateRange.setTo(listennotesMetrics.customDateValue.to);
      listennotesMetricsModel.setCustomDateValue(customDateRange);
    }
    listennotesMetricsModel.setSortBy(listennotesMetrics.sortBy);
    listennotesMetricsModel.setListenScore(
      createRange(listennotesMetrics.listenScore.min, listennotesMetrics.listenScore.max)
    );

    launchRequest.setListennotes(listennotesMetricsModel);
  }

  return launchRequest;
};

export const getCampaignContentAutomationPresetApi = (
  campaignId: number,
  workspaceId: number,
  pipelineId: number
): Promise<ContentAutomationPresetContainerType> => {
  const service = automationContentService.getInstance();
  const presetRequest = new ContentAutomationRequest();

  presetRequest.setCampaignId(campaignId);
  presetRequest.setWorkspaceId(workspaceId);
  presetRequest.setPipelineId(pipelineId);

  return request<ContentAutomationPresetContainerType>(
    service,
    service.getCampaignContentAutomationPreset,
    presetRequest
  );
};
