import React, { useEffect, useState } from 'react';

import { useQuery, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import ConfigurableTable from '@uikit/ConfigurableTable/ConfigurableTable';
import Loader from '@uikit/Loader/Loader';
import NoAccessMessage from '@uikit/NoAccessMessage/NoAccessMessage';

import { setStatusForFilterCampaign } from '@redux/actions/campaigns.actions';
import { getCurrentWorkspaceId } from '@redux/selectors/workspaces.selectors';
import {
  campaignsFiltersSelector,
  campaignsFoldersSelector,
} from '@redux/selectors/campaigns.selectors';

import queryCacheKeys from '@constants/queryCacheKeys.constants';
import { getAllCampaignsQuery } from '@queries/campaigns.queries';
import {
  changeCampaignStatusApi,
  changeOwnerOfCampaignApi,
  deleteCampaignApi,
  duplicateCampaignApi,
  getOpenCampaignApi,
  restoreCampaignApi,
  updateCampaignApi,
} from '@api/campaign.api';
import { addNotification } from '@redux/actions/notifications.actions';

import { CampaignDuplicateResultType, CampaignType } from '@ts/campaigns.types';
import RenameModal from '@components/Campaigns/RenameModal/RenameModal';
import { createUpdaterCacheItemById } from '@helpers/cacheUpdateHelper';
import urls from '@constants/urls';
import Pagination from '@uikit/Pagination/Pagination';
import usePagination from '@hooks/usePagination';
import MoveToFolderModal from '@components/Campaigns/MoveToFolderModal/MoveToFolderModal';
import useNonInitialEffect from '@hooks/useNonInitialEffect';
import ChangeOwnerModal from '@components/Campaigns/ChangeOwnerModal/ChangeOwnerModal';
import StubIllustration from '@uikit/StubIllustration/StubIllustration';
import useWorkspaceMembers from '@hooks/useWorkspaceMembers';
import { DispatchType } from 'src/store';

import tableConfig, { RenderRow } from './tableConfig';

import './CampaignsTable.scss';
import { redirectUserTo } from '@utils/historyHandler';

function CampaignsTable({
  folderId,
  isDeletedType = false,
  checkboxes,
  setCheckboxes,
  getRefetchCampaigns,
}: {
  folderId: number;
  isDeletedType: boolean;
  checkboxes: CampaignType[];
  setCheckboxes: (ids: CampaignType[]) => void;
  getRefetchCampaigns: any;
}): JSX.Element {
  const dispatch = useDispatch<DispatchType>();
  const queryClient = useQueryClient();
  const history = useHistory();
  const workspaceId = useSelector(getCurrentWorkspaceId);
  const filters = useSelector(campaignsFiltersSelector);

  const { members: workspaceMembers } = useWorkspaceMembers();

  const [isCreatingCampaign, setIsCreatingCampaign] = useState<boolean>(false);
  const [isBlockingLoader, setIsBlockingLoader] = useState<boolean>(false);
  const [moveCampaignInfo, changeMoveCampaignInfo] = useState(null);
  const [newOwnerCampaignInfo, changeNewOwnerCampaignInfo] = useState<null | CampaignType>(null);
  const [renameCampaignInfo, changeRenameCampaignInfo] = useState<null | CampaignType>(null);
  const handleCampaignRenameClose = () => changeRenameCampaignInfo(null);
  const handleCampaignRenameOpen = (campaign) => changeRenameCampaignInfo(campaign);
  const { page, changePage, limit, changeLimit, changeToDefault } = usePagination();

  const campaignsQueryKey = [
    queryCacheKeys.campaignsList,
    isDeletedType,
    page,
    limit,
    folderId,
    filters,
  ];

  const {
    isFetching: isCampaignsFetching,
    data: campaigns = [],
    error: campaignsError,
    refetch: refetchCampaigns,
  } = useQuery(
    campaignsQueryKey,
    () => getAllCampaignsQuery(workspaceId, isDeletedType, page - 1, limit, folderId, filters),
    {
      enabled: false,
      retry: false,
    }
  );

  useEffect(() => {
    getRefetchCampaigns(refetchCampaigns);
  }, []);

  useEffect(() => {
    if (workspaceId) {
      refetchCampaigns();
      changeToDefault();
    }
  }, [workspaceId, folderId, isDeletedType, filters]);

  useNonInitialEffect(() => {
    if (workspaceId) {
      refetchCampaigns();
    }
  }, [page, limit]);

  const { items: campaignFolders } = useSelector(campaignsFoldersSelector);

  const handleCampaignMoveClose = () => changeMoveCampaignInfo(null);
  const handleCampaignMoveOpen = (campaign) => changeMoveCampaignInfo(campaign);
  const handleCampaignDuplicate = (campaign) => {
    setIsBlockingLoader(true);
    duplicateCampaignApi(campaign.id, workspaceId, campaign.folderId)
      .then((response: CampaignDuplicateResultType[]) => {
        const successCampaigns = response.find((it) => it.success);
        if (successCampaigns) {
          return queryClient.setQueryData(campaignsQueryKey, (cache: CampaignType[]) => [
            response[response.length - 1].campaign,
            ...cache,
          ]);
        }

        dispatch(addNotification({ title: 'Duplicate campaign failed', type: 'error' }));
      })
      .finally(() => setIsBlockingLoader(false));
  };

  const handleCampaignClick = (campaignId, status) => {
    if (status === 0) {
      redirectUserTo(history, `/workspaces/${workspaceId}/campaign-builder/${campaignId}/sequence`);
    } else {
      redirectUserTo(history, `/workspaces/${workspaceId}/campaign-analytics/${campaignId}/people`);
    }
  };

  const handleChangeCampaignStatus = (campaignId, newStatus) => {
    return changeCampaignStatusApi(campaignId, workspaceId, newStatus).then(() => {
      return queryClient.setQueryData(
        campaignsQueryKey,
        createUpdaterCacheItemById(campaignId, 'status', newStatus)
      );
    });
  };

  const handleRenameCampaign = async (campaign, newTitle) => {
    handleCampaignRenameClose();
    const openCampaign = await getOpenCampaignApi(campaign.id, workspaceId);
    /*
      Fields priority and senderEmail are
     */
    const updatedCampaignInfo = {
      ...openCampaign,
      title: newTitle,
    };

    updateCampaignApi(updatedCampaignInfo)
      .then(() => {
        queryClient.setQueryData(
          campaignsQueryKey,
          createUpdaterCacheItemById(campaign.id, 'title', newTitle)
        );
      })
      .catch((err) => dispatch(addNotification({ title: err.message, type: 'error' })));
  };

  const handleRemoveCampaign = (
    campaignId: number,
    campaignWorkspaceId: number,
    hardDelete: true
  ) => deleteCampaignApi(campaignId, workspaceId, hardDelete).then(() => refetchCampaigns());

  const handleRestoreCampaign = (campaignId) =>
    restoreCampaignApi(campaignId, workspaceId).then(() => refetchCampaigns());

  /*
   New Owner Modal
   */
  const handleOpenChangeOwnerModal = (campaign: CampaignType) =>
    changeNewOwnerCampaignInfo(campaign);

  const handleCloseChangeOwnerModal = () => changeNewOwnerCampaignInfo(null);

  const handleChangeOwner = (newOwnerId: number) => {
    handleCloseChangeOwnerModal();
    changeOwnerOfCampaignApi(newOwnerCampaignInfo.id, newOwnerId, workspaceId)
      .then(() => {
        queryClient.setQueryData(campaignsQueryKey, (cache: any) => {
          return cache.map((item) => {
            if (item.id === newOwnerCampaignInfo.id) {
              const { logoUrl, fullName } = workspaceMembers.find(
                ({ userId }) => userId === newOwnerId
              );
              return {
                ...item,
                ownerId: newOwnerId,
                ownerAvatar: logoUrl,
                ownerName: fullName,
              };
            }

            return item;
          });
        });
      })
      .catch((err) => dispatch(addNotification({ title: err.message, type: 'error' })));
  };

  const handleChangeStatusFilter = (filter: number) => {
    dispatch(setStatusForFilterCampaign(filters?.status === filter ? 0 : filter));
  };

  if (isCampaignsFetching) {
    return <Loader isLoading withTopMargin />;
  }

  // @ts-ignore
  const errorMessage = campaignsError && campaignsError.message;

  if (errorMessage && errorMessage.indexOf('User is not a member of workspace') !== -1) {
    return <NoAccessMessage message="Oops! You don't have access to this workspace." showButton />;
  }

  if ((!campaigns || campaigns.length === 0) && !isCampaignsFetching) {
    if (isDeletedType) {
      const redirectToCampaigns = () => redirectUserTo(history, urls.ALL_CAMPAIGNS(workspaceId));
      return (
        <StubIllustration
          text="You don’t have any deleted campaigns"
          buttonText="Return to campaigns"
          onClickButton={redirectToCampaigns}
        />
      );
    }

    const redirectToCampaignCreation = async () => {
      if (folderId > 0) {
        redirectUserTo(
          history,
          `/workspaces/${workspaceId}/campaign-templates?folderId=${folderId}`
        );
      } else {
        redirectUserTo(history, `/workspaces/${workspaceId}/campaign-templates`);
      }
    };

    return (
      <StubIllustration
        text="Let’s create your first campaign :)"
        buttonText="Start new campaign"
        onClickButton={redirectToCampaignCreation}
        isLoading={isCreatingCampaign}
      />
    );
  }

  const handlerSelectAll = () => {
    if (campaigns.length === checkboxes.length) {
      setCheckboxes([]);
    } else {
      setCheckboxes(campaigns);
    }
  };

  const handlerSelectOne = (row: CampaignType) => {
    if (checkboxes.find((campaign) => campaign.id === row.id)) {
      setCheckboxes(checkboxes.filter((campaign) => campaign.id !== row.id));
    } else {
      setCheckboxes([row, ...checkboxes]);
    }
  };

  const isSelectedAll = () => {
    if (campaigns.length > checkboxes.length) {
      return false;
    }

    return campaigns.every(({ id }) => checkboxes.find(({ id: campaignId }) => id === campaignId));
  };

  return (
    <Loader isLoading={isBlockingLoader}>
      <div className="campaigns-table-wrapper">
        <ConfigurableTable
          config={tableConfig}
          data={campaigns}
          tableClassName="campaigns-table"
          renderProps={{
            onRowClick: isDeletedType ? () => null : handleCampaignClick,
            onMoveCampaign: handleCampaignMoveOpen,
            onDuplicateCampaign: handleCampaignDuplicate,
            campaignFolders,
            onRename: handleCampaignRenameOpen,
            onRemove: handleRemoveCampaign,
            onRestore: handleRestoreCampaign,
            onUpdateStatus: handleChangeCampaignStatus,
            onChangeOwner: handleOpenChangeOwnerModal,
            isDeletedType,
            status: filters?.status,
            handleChangeStatusFilter,
            handlerSelectAll,
            handlerSelectOne,
            isSelectedAll: isSelectedAll(),
            checkboxes,
          }}
          RenderRow={RenderRow}
        />
      </div>
      <Pagination
        curPage={page}
        onChangePage={changePage}
        pageLimit={limit}
        onChangePageLimit={changeLimit}
        totalItems={campaigns[0]?.totalItems}
      />
      <RenameModal
        isOpen={!!renameCampaignInfo}
        campaign={renameCampaignInfo}
        onClose={handleCampaignRenameClose}
        onRename={handleRenameCampaign}
      />
      <MoveToFolderModal
        campaigns={[moveCampaignInfo]}
        isOpen={moveCampaignInfo !== null}
        onClose={handleCampaignMoveClose}
        folderId={folderId ? Number(folderId) : undefined}
        queryKey={campaignsQueryKey}
      />
      <ChangeOwnerModal
        ownedItems={newOwnerCampaignInfo ? [newOwnerCampaignInfo] : []}
        onClose={handleCloseChangeOwnerModal}
        isOpen={!!newOwnerCampaignInfo}
        onConfirmChangeOwner={handleChangeOwner}
        workspaceMembers={workspaceMembers}
      />
    </Loader>
  );
}

export default CampaignsTable;
