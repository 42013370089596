import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import CampaignTemplate from '@components/CampaignTemplates/CampaignTemplate/CampaignTemplate';
import CampaignTemplatesList from '@components/CampaignTemplates/CampaignTemplatesList/CampaignTemplatesList';

import FirstCampaignModal from '@components/Campaigns/FirstCampaignModal/FirstCampaignModal';
import { getItemFromStorage } from '@utils/localStorageHandler';

import useCurrentWorkspaceId from '@hooks/useCurrentWorkspaceId';

import './CampaignTemplatesPage.scss';

function CampaignTemplatesPage() {
  const firstCampaign = getItemFromStorage('firstCampaign', false);
  const workspaceId = useCurrentWorkspaceId();

  return (
    <div className="campaign-templates">
      {firstCampaign != null && firstCampaign === 'true' && <FirstCampaignModal />}
      <Switch>
        <Route exact path="/workspaces/:workspaceId?/campaign-templates">
          <CampaignTemplatesList />
        </Route>

        <Route
          exact
          path="/workspaces/:workspaceId?/campaign-templates/:templateId"
          component={CampaignTemplate}
        />

        <Redirect to={`/workspaces/${workspaceId}/campaign-templates`} />
      </Switch>
    </div>
  );
}

export default CampaignTemplatesPage;
