import { pushIFrameUrl } from '@api/semrush/integration.api';
import { History } from 'react-router-dom';

declare const IS_STANDALONE_MODE: boolean;

export const redirectUserTo = (history: History, url: string): void => {
  if (history != null) {
    history.push(url);
  } else {
    window.location.pathname = url;
  }
  if (!IS_STANDALONE_MODE) {
    pushIFrameUrl(url);
  }
};

export const redirectUserToSearchParams = (
  history: History,
  payload: {
    search: string;
    pathname?: string;
  }
): void => {
  history.push({ search: payload.search, pathname: payload.pathname });
  if (!IS_STANDALONE_MODE && payload.search?.length > 0 && payload.pathname?.length > 0) {
    pushIFrameUrl(payload.pathname);
  }
};
