import { getAccessToken } from '@api/semrush/integration.api';
import { loginByIntegration } from '@api/auth.api';
import { authService } from 'src/services/authService';
import { isInternalPage } from '@utils/redirections';
import { getBillingShortDetailsApi } from '@api/billing.api';

declare const INTEGRATION_MODE: string;
declare const IS_LOCAL: boolean;

export const checkLoggedIn = async (history: History): Promise<boolean> => {
  if (INTEGRATION_MODE === 'semrush') {
    try {
      const jwtToken = await getAccessToken();
      const password = IS_LOCAL ? 'hardcoded_password' : undefined;

      const result = await loginByIntegration(jwtToken, password);

      if (!isInternalPage(window.location.pathname)) {
        authService.loginCallback(history, result);
      }

      return true;
    } catch (error) {
      console.error('Error:', error);
      return false;
    }
  } else {
    const token = localStorage.getItem('token');

    if (token == null) {
      return false;
    }

    const response = await getBillingShortDetailsApi(false);

    return response != null;
  }
};
