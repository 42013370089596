import React, { ErrorInfo, ReactNode } from 'react';

import NoAccessMessage from '@uikit/NoAccessMessage/NoAccessMessage';

type PropsType = {
  children: ReactNode;
};
type StateType = {
  hasError: boolean;
};

class ErrorBoundaryNoAccess extends React.Component<PropsType, StateType> {
  constructor(props: PropsType) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error): { hasError: boolean } {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    // You can also log the error to an error reporting service
    // console.log(error, errorInfo);
  }

  render(): JSX.Element | ReactNode {
    // eslint-disable-next-line react/destructuring-assignment
    if (this.state.hasError) {
      return <NoAccessMessage showButton />;
    }

    // eslint-disable-next-line react/destructuring-assignment
    return this.props.children;
  }
}

export default ErrorBoundaryNoAccess;
