// source: search-content.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var common_pb = require('./common_pb.js');
goog.object.extend(proto, common_pb);
goog.exportSymbol('proto.search.content.ArticleItemDetails', null, global);
goog.exportSymbol('proto.search.content.ArticleSearchFilters', null, global);
goog.exportSymbol('proto.search.content.ArticleSearchFilters.MetricsCase', null, global);
goog.exportSymbol('proto.search.content.AudioDetailsRequest', null, global);
goog.exportSymbol('proto.search.content.AudioGenre', null, global);
goog.exportSymbol('proto.search.content.AudioSearchSource', null, global);
goog.exportSymbol('proto.search.content.BacklinksItemDetails', null, global);
goog.exportSymbol('proto.search.content.BacklinksSearchFilters', null, global);
goog.exportSymbol('proto.search.content.BacklinksSearchFilters.MetricsCase', null, global);
goog.exportSymbol('proto.search.content.DateFilter', null, global);
goog.exportSymbol('proto.search.content.EpisodeItemDetails', null, global);
goog.exportSymbol('proto.search.content.EpisodeSearchFilters', null, global);
goog.exportSymbol('proto.search.content.FreshnessType', null, global);
goog.exportSymbol('proto.search.content.PodcastItemDetails', null, global);
goog.exportSymbol('proto.search.content.PodcastSearchFilters', null, global);
goog.exportSymbol('proto.search.content.SearchItem', null, global);
goog.exportSymbol('proto.search.content.SearchItem.DetailsCase', null, global);
goog.exportSymbol('proto.search.content.SearchRequest', null, global);
goog.exportSymbol('proto.search.content.SearchRequest.FiltersCase', null, global);
goog.exportSymbol('proto.search.content.SharedLinkSearchItem', null, global);
goog.exportSymbol('proto.search.content.SharedLinkSearchItem.Domain', null, global);
goog.exportSymbol('proto.search.content.SharedLinkSearchItem.Link', null, global);
goog.exportSymbol('proto.search.content.TrendingContentItemDetails', null, global);
goog.exportSymbol('proto.search.content.TrendingContentSearchFilters', null, global);
goog.exportSymbol('proto.search.content.UidRequest', null, global);
goog.exportSymbol('proto.search.content.UrlItem', null, global);
goog.exportSymbol('proto.search.content.UrlRequest', null, global);
goog.exportSymbol('proto.search.content.UrlSearchType', null, global);
goog.exportSymbol('proto.search.content.UserActor', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.search.content.UserActor = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.search.content.UserActor, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.search.content.UserActor.displayName = 'proto.search.content.UserActor';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.search.content.ArticleSearchFilters = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.search.content.ArticleSearchFilters.oneofGroups_);
};
goog.inherits(proto.search.content.ArticleSearchFilters, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.search.content.ArticleSearchFilters.displayName = 'proto.search.content.ArticleSearchFilters';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.search.content.BacklinksSearchFilters = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.search.content.BacklinksSearchFilters.oneofGroups_);
};
goog.inherits(proto.search.content.BacklinksSearchFilters, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.search.content.BacklinksSearchFilters.displayName = 'proto.search.content.BacklinksSearchFilters';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.search.content.DateFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.search.content.DateFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.search.content.DateFilter.displayName = 'proto.search.content.DateFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.search.content.PodcastSearchFilters = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.search.content.PodcastSearchFilters.repeatedFields_, null);
};
goog.inherits(proto.search.content.PodcastSearchFilters, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.search.content.PodcastSearchFilters.displayName = 'proto.search.content.PodcastSearchFilters';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.search.content.EpisodeSearchFilters = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.search.content.EpisodeSearchFilters, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.search.content.EpisodeSearchFilters.displayName = 'proto.search.content.EpisodeSearchFilters';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.search.content.TrendingContentSearchFilters = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.search.content.TrendingContentSearchFilters, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.search.content.TrendingContentSearchFilters.displayName = 'proto.search.content.TrendingContentSearchFilters';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.search.content.UrlRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.search.content.UrlRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.search.content.UrlRequest.displayName = 'proto.search.content.UrlRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.search.content.SearchRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.search.content.SearchRequest.oneofGroups_);
};
goog.inherits(proto.search.content.SearchRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.search.content.SearchRequest.displayName = 'proto.search.content.SearchRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.search.content.ArticleItemDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.search.content.ArticleItemDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.search.content.ArticleItemDetails.displayName = 'proto.search.content.ArticleItemDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.search.content.BacklinksItemDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.search.content.BacklinksItemDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.search.content.BacklinksItemDetails.displayName = 'proto.search.content.BacklinksItemDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.search.content.PodcastItemDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.search.content.PodcastItemDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.search.content.PodcastItemDetails.displayName = 'proto.search.content.PodcastItemDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.search.content.EpisodeItemDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.search.content.EpisodeItemDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.search.content.EpisodeItemDetails.displayName = 'proto.search.content.EpisodeItemDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.search.content.TrendingContentItemDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.search.content.TrendingContentItemDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.search.content.TrendingContentItemDetails.displayName = 'proto.search.content.TrendingContentItemDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.search.content.UrlItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.search.content.UrlItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.search.content.UrlItem.displayName = 'proto.search.content.UrlItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.search.content.SearchItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.search.content.SearchItem.oneofGroups_);
};
goog.inherits(proto.search.content.SearchItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.search.content.SearchItem.displayName = 'proto.search.content.SearchItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.search.content.UidRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.search.content.UidRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.search.content.UidRequest.displayName = 'proto.search.content.UidRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.search.content.AudioDetailsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.search.content.AudioDetailsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.search.content.AudioDetailsRequest.displayName = 'proto.search.content.AudioDetailsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.search.content.SharedLinkSearchItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.search.content.SharedLinkSearchItem.repeatedFields_, null);
};
goog.inherits(proto.search.content.SharedLinkSearchItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.search.content.SharedLinkSearchItem.displayName = 'proto.search.content.SharedLinkSearchItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.search.content.SharedLinkSearchItem.Link = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.search.content.SharedLinkSearchItem.Link, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.search.content.SharedLinkSearchItem.Link.displayName = 'proto.search.content.SharedLinkSearchItem.Link';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.search.content.SharedLinkSearchItem.Domain = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.search.content.SharedLinkSearchItem.Domain, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.search.content.SharedLinkSearchItem.Domain.displayName = 'proto.search.content.SharedLinkSearchItem.Domain';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.search.content.AudioGenre = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.search.content.AudioGenre, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.search.content.AudioGenre.displayName = 'proto.search.content.AudioGenre';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.search.content.UserActor.prototype.toObject = function(opt_includeInstance) {
  return proto.search.content.UserActor.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.search.content.UserActor} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.search.content.UserActor.toObject = function(includeInstance, msg) {
  var f, obj = {
    active: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    userUid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    timestamp: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.search.content.UserActor}
 */
proto.search.content.UserActor.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.search.content.UserActor;
  return proto.search.content.UserActor.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.search.content.UserActor} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.search.content.UserActor}
 */
proto.search.content.UserActor.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setActive(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserUid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimestamp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.search.content.UserActor.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.search.content.UserActor.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.search.content.UserActor} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.search.content.UserActor.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActive();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getUserUid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional bool active = 1;
 * @return {boolean}
 */
proto.search.content.UserActor.prototype.getActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.search.content.UserActor} returns this
 */
proto.search.content.UserActor.prototype.setActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string user_uid = 2;
 * @return {string}
 */
proto.search.content.UserActor.prototype.getUserUid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.search.content.UserActor} returns this
 */
proto.search.content.UserActor.prototype.setUserUid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 timestamp = 3;
 * @return {number}
 */
proto.search.content.UserActor.prototype.getTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.search.content.UserActor} returns this
 */
proto.search.content.UserActor.prototype.setTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.search.content.ArticleSearchFilters.oneofGroups_ = [[10,11,12]];

/**
 * @enum {number}
 */
proto.search.content.ArticleSearchFilters.MetricsCase = {
  METRICS_NOT_SET: 0,
  AHREF: 10,
  MOZ: 11,
  SEMRUSH: 12
};

/**
 * @return {proto.search.content.ArticleSearchFilters.MetricsCase}
 */
proto.search.content.ArticleSearchFilters.prototype.getMetricsCase = function() {
  return /** @type {proto.search.content.ArticleSearchFilters.MetricsCase} */(jspb.Message.computeOneofCase(this, proto.search.content.ArticleSearchFilters.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.search.content.ArticleSearchFilters.prototype.toObject = function(opt_includeInstance) {
  return proto.search.content.ArticleSearchFilters.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.search.content.ArticleSearchFilters} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.search.content.ArticleSearchFilters.toObject = function(includeInstance, msg) {
  var f, obj = {
    location: jspb.Message.getFieldWithDefault(msg, 1, ""),
    language: jspb.Message.getFieldWithDefault(msg, 2, ""),
    freshness: (f = msg.getFreshness()) && proto.search.content.DateFilter.toObject(includeInstance, f),
    includePaidMetrics: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    limit: jspb.Message.getFieldWithDefault(msg, 5, 0),
    metricsType: jspb.Message.getFieldWithDefault(msg, 6, 0),
    sortBy: jspb.Message.getFieldWithDefault(msg, 7, 0),
    ignoreHomepages: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    optionalUnlinkedDomain: jspb.Message.getFieldWithDefault(msg, 9, ""),
    ahref: (f = msg.getAhref()) && common_pb.AhrefMetrics.toObject(includeInstance, f),
    moz: (f = msg.getMoz()) && common_pb.MozMetrics.toObject(includeInstance, f),
    semrush: (f = msg.getSemrush()) && common_pb.SemrushMetrics.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.search.content.ArticleSearchFilters}
 */
proto.search.content.ArticleSearchFilters.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.search.content.ArticleSearchFilters;
  return proto.search.content.ArticleSearchFilters.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.search.content.ArticleSearchFilters} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.search.content.ArticleSearchFilters}
 */
proto.search.content.ArticleSearchFilters.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocation(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLanguage(value);
      break;
    case 3:
      var value = new proto.search.content.DateFilter;
      reader.readMessage(value,proto.search.content.DateFilter.deserializeBinaryFromReader);
      msg.setFreshness(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludePaidMetrics(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLimit(value);
      break;
    case 6:
      var value = /** @type {!proto.common.ContentMetricsType} */ (reader.readEnum());
      msg.setMetricsType(value);
      break;
    case 7:
      var value = /** @type {!proto.common.ContentSearchOrderBy} */ (reader.readEnum());
      msg.setSortBy(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIgnoreHomepages(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setOptionalUnlinkedDomain(value);
      break;
    case 10:
      var value = new common_pb.AhrefMetrics;
      reader.readMessage(value,common_pb.AhrefMetrics.deserializeBinaryFromReader);
      msg.setAhref(value);
      break;
    case 11:
      var value = new common_pb.MozMetrics;
      reader.readMessage(value,common_pb.MozMetrics.deserializeBinaryFromReader);
      msg.setMoz(value);
      break;
    case 12:
      var value = new common_pb.SemrushMetrics;
      reader.readMessage(value,common_pb.SemrushMetrics.deserializeBinaryFromReader);
      msg.setSemrush(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.search.content.ArticleSearchFilters.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.search.content.ArticleSearchFilters.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.search.content.ArticleSearchFilters} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.search.content.ArticleSearchFilters.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLocation();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLanguage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFreshness();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.search.content.DateFilter.serializeBinaryToWriter
    );
  }
  f = message.getIncludePaidMetrics();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getMetricsType();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getSortBy();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getIgnoreHomepages();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getOptionalUnlinkedDomain();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getAhref();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      common_pb.AhrefMetrics.serializeBinaryToWriter
    );
  }
  f = message.getMoz();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      common_pb.MozMetrics.serializeBinaryToWriter
    );
  }
  f = message.getSemrush();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      common_pb.SemrushMetrics.serializeBinaryToWriter
    );
  }
};


/**
 * optional string location = 1;
 * @return {string}
 */
proto.search.content.ArticleSearchFilters.prototype.getLocation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.search.content.ArticleSearchFilters} returns this
 */
proto.search.content.ArticleSearchFilters.prototype.setLocation = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string language = 2;
 * @return {string}
 */
proto.search.content.ArticleSearchFilters.prototype.getLanguage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.search.content.ArticleSearchFilters} returns this
 */
proto.search.content.ArticleSearchFilters.prototype.setLanguage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional DateFilter freshness = 3;
 * @return {?proto.search.content.DateFilter}
 */
proto.search.content.ArticleSearchFilters.prototype.getFreshness = function() {
  return /** @type{?proto.search.content.DateFilter} */ (
    jspb.Message.getWrapperField(this, proto.search.content.DateFilter, 3));
};


/**
 * @param {?proto.search.content.DateFilter|undefined} value
 * @return {!proto.search.content.ArticleSearchFilters} returns this
*/
proto.search.content.ArticleSearchFilters.prototype.setFreshness = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.search.content.ArticleSearchFilters} returns this
 */
proto.search.content.ArticleSearchFilters.prototype.clearFreshness = function() {
  return this.setFreshness(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.search.content.ArticleSearchFilters.prototype.hasFreshness = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool include_paid_metrics = 4;
 * @return {boolean}
 */
proto.search.content.ArticleSearchFilters.prototype.getIncludePaidMetrics = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.search.content.ArticleSearchFilters} returns this
 */
proto.search.content.ArticleSearchFilters.prototype.setIncludePaidMetrics = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional int32 limit = 5;
 * @return {number}
 */
proto.search.content.ArticleSearchFilters.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.search.content.ArticleSearchFilters} returns this
 */
proto.search.content.ArticleSearchFilters.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional common.ContentMetricsType metrics_type = 6;
 * @return {!proto.common.ContentMetricsType}
 */
proto.search.content.ArticleSearchFilters.prototype.getMetricsType = function() {
  return /** @type {!proto.common.ContentMetricsType} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.common.ContentMetricsType} value
 * @return {!proto.search.content.ArticleSearchFilters} returns this
 */
proto.search.content.ArticleSearchFilters.prototype.setMetricsType = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional common.ContentSearchOrderBy sort_by = 7;
 * @return {!proto.common.ContentSearchOrderBy}
 */
proto.search.content.ArticleSearchFilters.prototype.getSortBy = function() {
  return /** @type {!proto.common.ContentSearchOrderBy} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.common.ContentSearchOrderBy} value
 * @return {!proto.search.content.ArticleSearchFilters} returns this
 */
proto.search.content.ArticleSearchFilters.prototype.setSortBy = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional bool ignore_homepages = 8;
 * @return {boolean}
 */
proto.search.content.ArticleSearchFilters.prototype.getIgnoreHomepages = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.search.content.ArticleSearchFilters} returns this
 */
proto.search.content.ArticleSearchFilters.prototype.setIgnoreHomepages = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional string optional_unlinked_domain = 9;
 * @return {string}
 */
proto.search.content.ArticleSearchFilters.prototype.getOptionalUnlinkedDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.search.content.ArticleSearchFilters} returns this
 */
proto.search.content.ArticleSearchFilters.prototype.setOptionalUnlinkedDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional common.AhrefMetrics ahref = 10;
 * @return {?proto.common.AhrefMetrics}
 */
proto.search.content.ArticleSearchFilters.prototype.getAhref = function() {
  return /** @type{?proto.common.AhrefMetrics} */ (
    jspb.Message.getWrapperField(this, common_pb.AhrefMetrics, 10));
};


/**
 * @param {?proto.common.AhrefMetrics|undefined} value
 * @return {!proto.search.content.ArticleSearchFilters} returns this
*/
proto.search.content.ArticleSearchFilters.prototype.setAhref = function(value) {
  return jspb.Message.setOneofWrapperField(this, 10, proto.search.content.ArticleSearchFilters.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.search.content.ArticleSearchFilters} returns this
 */
proto.search.content.ArticleSearchFilters.prototype.clearAhref = function() {
  return this.setAhref(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.search.content.ArticleSearchFilters.prototype.hasAhref = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional common.MozMetrics moz = 11;
 * @return {?proto.common.MozMetrics}
 */
proto.search.content.ArticleSearchFilters.prototype.getMoz = function() {
  return /** @type{?proto.common.MozMetrics} */ (
    jspb.Message.getWrapperField(this, common_pb.MozMetrics, 11));
};


/**
 * @param {?proto.common.MozMetrics|undefined} value
 * @return {!proto.search.content.ArticleSearchFilters} returns this
*/
proto.search.content.ArticleSearchFilters.prototype.setMoz = function(value) {
  return jspb.Message.setOneofWrapperField(this, 11, proto.search.content.ArticleSearchFilters.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.search.content.ArticleSearchFilters} returns this
 */
proto.search.content.ArticleSearchFilters.prototype.clearMoz = function() {
  return this.setMoz(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.search.content.ArticleSearchFilters.prototype.hasMoz = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional common.SemrushMetrics semrush = 12;
 * @return {?proto.common.SemrushMetrics}
 */
proto.search.content.ArticleSearchFilters.prototype.getSemrush = function() {
  return /** @type{?proto.common.SemrushMetrics} */ (
    jspb.Message.getWrapperField(this, common_pb.SemrushMetrics, 12));
};


/**
 * @param {?proto.common.SemrushMetrics|undefined} value
 * @return {!proto.search.content.ArticleSearchFilters} returns this
*/
proto.search.content.ArticleSearchFilters.prototype.setSemrush = function(value) {
  return jspb.Message.setOneofWrapperField(this, 12, proto.search.content.ArticleSearchFilters.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.search.content.ArticleSearchFilters} returns this
 */
proto.search.content.ArticleSearchFilters.prototype.clearSemrush = function() {
  return this.setSemrush(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.search.content.ArticleSearchFilters.prototype.hasSemrush = function() {
  return jspb.Message.getField(this, 12) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.search.content.BacklinksSearchFilters.oneofGroups_ = [[13,14,15]];

/**
 * @enum {number}
 */
proto.search.content.BacklinksSearchFilters.MetricsCase = {
  METRICS_NOT_SET: 0,
  AHREF: 13,
  MOZ: 14,
  SEMRUSH: 15
};

/**
 * @return {proto.search.content.BacklinksSearchFilters.MetricsCase}
 */
proto.search.content.BacklinksSearchFilters.prototype.getMetricsCase = function() {
  return /** @type {proto.search.content.BacklinksSearchFilters.MetricsCase} */(jspb.Message.computeOneofCase(this, proto.search.content.BacklinksSearchFilters.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.search.content.BacklinksSearchFilters.prototype.toObject = function(opt_includeInstance) {
  return proto.search.content.BacklinksSearchFilters.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.search.content.BacklinksSearchFilters} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.search.content.BacklinksSearchFilters.toObject = function(includeInstance, msg) {
  var f, obj = {
    rel: jspb.Message.getFieldWithDefault(msg, 1, 0),
    optionalAnchor: jspb.Message.getFieldWithDefault(msg, 2, ""),
    optionalTargetUrl: jspb.Message.getFieldWithDefault(msg, 3, ""),
    optionalSourceUrl: jspb.Message.getFieldWithDefault(msg, 4, ""),
    optionalUnlinkedDomain: jspb.Message.getFieldWithDefault(msg, 5, ""),
    onePerDomain: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    limit: jspb.Message.getFieldWithDefault(msg, 7, 0),
    source: jspb.Message.getFieldWithDefault(msg, 8, 0),
    metricsType: jspb.Message.getFieldWithDefault(msg, 9, 0),
    sortBy: jspb.Message.getFieldWithDefault(msg, 10, 0),
    linkStatus: jspb.Message.getFieldWithDefault(msg, 11, 0),
    ignoreHomepages: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    ahref: (f = msg.getAhref()) && common_pb.AhrefMetrics.toObject(includeInstance, f),
    moz: (f = msg.getMoz()) && common_pb.MozMetrics.toObject(includeInstance, f),
    semrush: (f = msg.getSemrush()) && common_pb.SemrushMetrics.toObject(includeInstance, f),
    integrationSource: jspb.Message.getFieldWithDefault(msg, 16, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.search.content.BacklinksSearchFilters}
 */
proto.search.content.BacklinksSearchFilters.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.search.content.BacklinksSearchFilters;
  return proto.search.content.BacklinksSearchFilters.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.search.content.BacklinksSearchFilters} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.search.content.BacklinksSearchFilters}
 */
proto.search.content.BacklinksSearchFilters.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.common.BacklinksRel} */ (reader.readEnum());
      msg.setRel(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOptionalAnchor(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOptionalTargetUrl(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setOptionalSourceUrl(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setOptionalUnlinkedDomain(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOnePerDomain(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLimit(value);
      break;
    case 8:
      var value = /** @type {!proto.common.BacklinksSearchSource} */ (reader.readEnum());
      msg.setSource(value);
      break;
    case 9:
      var value = /** @type {!proto.common.ContentMetricsType} */ (reader.readEnum());
      msg.setMetricsType(value);
      break;
    case 10:
      var value = /** @type {!proto.common.ContentSearchOrderBy} */ (reader.readEnum());
      msg.setSortBy(value);
      break;
    case 11:
      var value = /** @type {!proto.common.BacklinkStatus} */ (reader.readEnum());
      msg.setLinkStatus(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIgnoreHomepages(value);
      break;
    case 13:
      var value = new common_pb.AhrefMetrics;
      reader.readMessage(value,common_pb.AhrefMetrics.deserializeBinaryFromReader);
      msg.setAhref(value);
      break;
    case 14:
      var value = new common_pb.MozMetrics;
      reader.readMessage(value,common_pb.MozMetrics.deserializeBinaryFromReader);
      msg.setMoz(value);
      break;
    case 15:
      var value = new common_pb.SemrushMetrics;
      reader.readMessage(value,common_pb.SemrushMetrics.deserializeBinaryFromReader);
      msg.setSemrush(value);
      break;
    case 16:
      var value = /** @type {!proto.common.IntegrationSource} */ (reader.readEnum());
      msg.setIntegrationSource(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.search.content.BacklinksSearchFilters.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.search.content.BacklinksSearchFilters.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.search.content.BacklinksSearchFilters} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.search.content.BacklinksSearchFilters.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRel();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getOptionalAnchor();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOptionalTargetUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOptionalSourceUrl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getOptionalUnlinkedDomain();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getOnePerDomain();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getSource();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getMetricsType();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getSortBy();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
  f = message.getLinkStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      11,
      f
    );
  }
  f = message.getIgnoreHomepages();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getAhref();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      common_pb.AhrefMetrics.serializeBinaryToWriter
    );
  }
  f = message.getMoz();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      common_pb.MozMetrics.serializeBinaryToWriter
    );
  }
  f = message.getSemrush();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      common_pb.SemrushMetrics.serializeBinaryToWriter
    );
  }
  f = message.getIntegrationSource();
  if (f !== 0.0) {
    writer.writeEnum(
      16,
      f
    );
  }
};


/**
 * optional common.BacklinksRel rel = 1;
 * @return {!proto.common.BacklinksRel}
 */
proto.search.content.BacklinksSearchFilters.prototype.getRel = function() {
  return /** @type {!proto.common.BacklinksRel} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.common.BacklinksRel} value
 * @return {!proto.search.content.BacklinksSearchFilters} returns this
 */
proto.search.content.BacklinksSearchFilters.prototype.setRel = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string optional_anchor = 2;
 * @return {string}
 */
proto.search.content.BacklinksSearchFilters.prototype.getOptionalAnchor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.search.content.BacklinksSearchFilters} returns this
 */
proto.search.content.BacklinksSearchFilters.prototype.setOptionalAnchor = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string optional_target_url = 3;
 * @return {string}
 */
proto.search.content.BacklinksSearchFilters.prototype.getOptionalTargetUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.search.content.BacklinksSearchFilters} returns this
 */
proto.search.content.BacklinksSearchFilters.prototype.setOptionalTargetUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string optional_source_url = 4;
 * @return {string}
 */
proto.search.content.BacklinksSearchFilters.prototype.getOptionalSourceUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.search.content.BacklinksSearchFilters} returns this
 */
proto.search.content.BacklinksSearchFilters.prototype.setOptionalSourceUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string optional_unlinked_domain = 5;
 * @return {string}
 */
proto.search.content.BacklinksSearchFilters.prototype.getOptionalUnlinkedDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.search.content.BacklinksSearchFilters} returns this
 */
proto.search.content.BacklinksSearchFilters.prototype.setOptionalUnlinkedDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional bool one_per_domain = 6;
 * @return {boolean}
 */
proto.search.content.BacklinksSearchFilters.prototype.getOnePerDomain = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.search.content.BacklinksSearchFilters} returns this
 */
proto.search.content.BacklinksSearchFilters.prototype.setOnePerDomain = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional int32 limit = 7;
 * @return {number}
 */
proto.search.content.BacklinksSearchFilters.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.search.content.BacklinksSearchFilters} returns this
 */
proto.search.content.BacklinksSearchFilters.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional common.BacklinksSearchSource source = 8;
 * @return {!proto.common.BacklinksSearchSource}
 */
proto.search.content.BacklinksSearchFilters.prototype.getSource = function() {
  return /** @type {!proto.common.BacklinksSearchSource} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.common.BacklinksSearchSource} value
 * @return {!proto.search.content.BacklinksSearchFilters} returns this
 */
proto.search.content.BacklinksSearchFilters.prototype.setSource = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional common.ContentMetricsType metrics_type = 9;
 * @return {!proto.common.ContentMetricsType}
 */
proto.search.content.BacklinksSearchFilters.prototype.getMetricsType = function() {
  return /** @type {!proto.common.ContentMetricsType} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.common.ContentMetricsType} value
 * @return {!proto.search.content.BacklinksSearchFilters} returns this
 */
proto.search.content.BacklinksSearchFilters.prototype.setMetricsType = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional common.ContentSearchOrderBy sort_by = 10;
 * @return {!proto.common.ContentSearchOrderBy}
 */
proto.search.content.BacklinksSearchFilters.prototype.getSortBy = function() {
  return /** @type {!proto.common.ContentSearchOrderBy} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.common.ContentSearchOrderBy} value
 * @return {!proto.search.content.BacklinksSearchFilters} returns this
 */
proto.search.content.BacklinksSearchFilters.prototype.setSortBy = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};


/**
 * optional common.BacklinkStatus link_status = 11;
 * @return {!proto.common.BacklinkStatus}
 */
proto.search.content.BacklinksSearchFilters.prototype.getLinkStatus = function() {
  return /** @type {!proto.common.BacklinkStatus} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {!proto.common.BacklinkStatus} value
 * @return {!proto.search.content.BacklinksSearchFilters} returns this
 */
proto.search.content.BacklinksSearchFilters.prototype.setLinkStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 11, value);
};


/**
 * optional bool ignore_homepages = 12;
 * @return {boolean}
 */
proto.search.content.BacklinksSearchFilters.prototype.getIgnoreHomepages = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.search.content.BacklinksSearchFilters} returns this
 */
proto.search.content.BacklinksSearchFilters.prototype.setIgnoreHomepages = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional common.AhrefMetrics ahref = 13;
 * @return {?proto.common.AhrefMetrics}
 */
proto.search.content.BacklinksSearchFilters.prototype.getAhref = function() {
  return /** @type{?proto.common.AhrefMetrics} */ (
    jspb.Message.getWrapperField(this, common_pb.AhrefMetrics, 13));
};


/**
 * @param {?proto.common.AhrefMetrics|undefined} value
 * @return {!proto.search.content.BacklinksSearchFilters} returns this
*/
proto.search.content.BacklinksSearchFilters.prototype.setAhref = function(value) {
  return jspb.Message.setOneofWrapperField(this, 13, proto.search.content.BacklinksSearchFilters.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.search.content.BacklinksSearchFilters} returns this
 */
proto.search.content.BacklinksSearchFilters.prototype.clearAhref = function() {
  return this.setAhref(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.search.content.BacklinksSearchFilters.prototype.hasAhref = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional common.MozMetrics moz = 14;
 * @return {?proto.common.MozMetrics}
 */
proto.search.content.BacklinksSearchFilters.prototype.getMoz = function() {
  return /** @type{?proto.common.MozMetrics} */ (
    jspb.Message.getWrapperField(this, common_pb.MozMetrics, 14));
};


/**
 * @param {?proto.common.MozMetrics|undefined} value
 * @return {!proto.search.content.BacklinksSearchFilters} returns this
*/
proto.search.content.BacklinksSearchFilters.prototype.setMoz = function(value) {
  return jspb.Message.setOneofWrapperField(this, 14, proto.search.content.BacklinksSearchFilters.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.search.content.BacklinksSearchFilters} returns this
 */
proto.search.content.BacklinksSearchFilters.prototype.clearMoz = function() {
  return this.setMoz(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.search.content.BacklinksSearchFilters.prototype.hasMoz = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional common.SemrushMetrics semrush = 15;
 * @return {?proto.common.SemrushMetrics}
 */
proto.search.content.BacklinksSearchFilters.prototype.getSemrush = function() {
  return /** @type{?proto.common.SemrushMetrics} */ (
    jspb.Message.getWrapperField(this, common_pb.SemrushMetrics, 15));
};


/**
 * @param {?proto.common.SemrushMetrics|undefined} value
 * @return {!proto.search.content.BacklinksSearchFilters} returns this
*/
proto.search.content.BacklinksSearchFilters.prototype.setSemrush = function(value) {
  return jspb.Message.setOneofWrapperField(this, 15, proto.search.content.BacklinksSearchFilters.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.search.content.BacklinksSearchFilters} returns this
 */
proto.search.content.BacklinksSearchFilters.prototype.clearSemrush = function() {
  return this.setSemrush(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.search.content.BacklinksSearchFilters.prototype.hasSemrush = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional common.IntegrationSource integration_source = 16;
 * @return {!proto.common.IntegrationSource}
 */
proto.search.content.BacklinksSearchFilters.prototype.getIntegrationSource = function() {
  return /** @type {!proto.common.IntegrationSource} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {!proto.common.IntegrationSource} value
 * @return {!proto.search.content.BacklinksSearchFilters} returns this
 */
proto.search.content.BacklinksSearchFilters.prototype.setIntegrationSource = function(value) {
  return jspb.Message.setProto3EnumField(this, 16, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.search.content.DateFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.search.content.DateFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.search.content.DateFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.search.content.DateFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    freshness: jspb.Message.getFieldWithDefault(msg, 1, 0),
    customFreshnessValue: (f = msg.getCustomFreshnessValue()) && common_pb.TimestampRange.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.search.content.DateFilter}
 */
proto.search.content.DateFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.search.content.DateFilter;
  return proto.search.content.DateFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.search.content.DateFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.search.content.DateFilter}
 */
proto.search.content.DateFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.search.content.FreshnessType} */ (reader.readEnum());
      msg.setFreshness(value);
      break;
    case 2:
      var value = new common_pb.TimestampRange;
      reader.readMessage(value,common_pb.TimestampRange.deserializeBinaryFromReader);
      msg.setCustomFreshnessValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.search.content.DateFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.search.content.DateFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.search.content.DateFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.search.content.DateFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFreshness();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getCustomFreshnessValue();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_pb.TimestampRange.serializeBinaryToWriter
    );
  }
};


/**
 * optional FreshnessType freshness = 1;
 * @return {!proto.search.content.FreshnessType}
 */
proto.search.content.DateFilter.prototype.getFreshness = function() {
  return /** @type {!proto.search.content.FreshnessType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.search.content.FreshnessType} value
 * @return {!proto.search.content.DateFilter} returns this
 */
proto.search.content.DateFilter.prototype.setFreshness = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional common.TimestampRange custom_freshness_value = 2;
 * @return {?proto.common.TimestampRange}
 */
proto.search.content.DateFilter.prototype.getCustomFreshnessValue = function() {
  return /** @type{?proto.common.TimestampRange} */ (
    jspb.Message.getWrapperField(this, common_pb.TimestampRange, 2));
};


/**
 * @param {?proto.common.TimestampRange|undefined} value
 * @return {!proto.search.content.DateFilter} returns this
*/
proto.search.content.DateFilter.prototype.setCustomFreshnessValue = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.search.content.DateFilter} returns this
 */
proto.search.content.DateFilter.prototype.clearCustomFreshnessValue = function() {
  return this.setCustomFreshnessValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.search.content.DateFilter.prototype.hasCustomFreshnessValue = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.search.content.PodcastSearchFilters.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.search.content.PodcastSearchFilters.prototype.toObject = function(opt_includeInstance) {
  return proto.search.content.PodcastSearchFilters.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.search.content.PodcastSearchFilters} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.search.content.PodcastSearchFilters.toObject = function(includeInstance, msg) {
  var f, obj = {
    genreUidList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    freshness: jspb.Message.getFieldWithDefault(msg, 2, 0),
    customFreshnessValue: (f = msg.getCustomFreshnessValue()) && common_pb.TimestampRange.toObject(includeInstance, f),
    location: jspb.Message.getFieldWithDefault(msg, 4, ""),
    language: jspb.Message.getFieldWithDefault(msg, 5, ""),
    totalEpisodesNumber: (f = msg.getTotalEpisodesNumber()) && common_pb.Range.toObject(includeInstance, f),
    updateFrequencyHoursNumber: (f = msg.getUpdateFrequencyHoursNumber()) && common_pb.Range.toObject(includeInstance, f),
    listenScoreNumber: (f = msg.getListenScoreNumber()) && common_pb.Range.toObject(includeInstance, f),
    sortBy: jspb.Message.getFieldWithDefault(msg, 9, 0),
    hasGuestInterviews: jspb.Message.getBooleanFieldWithDefault(msg, 10, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.search.content.PodcastSearchFilters}
 */
proto.search.content.PodcastSearchFilters.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.search.content.PodcastSearchFilters;
  return proto.search.content.PodcastSearchFilters.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.search.content.PodcastSearchFilters} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.search.content.PodcastSearchFilters}
 */
proto.search.content.PodcastSearchFilters.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt32());
      msg.setGenreUidList(value);
      break;
    case 2:
      var value = /** @type {!proto.search.content.FreshnessType} */ (reader.readEnum());
      msg.setFreshness(value);
      break;
    case 3:
      var value = new common_pb.TimestampRange;
      reader.readMessage(value,common_pb.TimestampRange.deserializeBinaryFromReader);
      msg.setCustomFreshnessValue(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocation(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setLanguage(value);
      break;
    case 6:
      var value = new common_pb.Range;
      reader.readMessage(value,common_pb.Range.deserializeBinaryFromReader);
      msg.setTotalEpisodesNumber(value);
      break;
    case 7:
      var value = new common_pb.Range;
      reader.readMessage(value,common_pb.Range.deserializeBinaryFromReader);
      msg.setUpdateFrequencyHoursNumber(value);
      break;
    case 8:
      var value = new common_pb.Range;
      reader.readMessage(value,common_pb.Range.deserializeBinaryFromReader);
      msg.setListenScoreNumber(value);
      break;
    case 9:
      var value = /** @type {!proto.common.ContentSearchOrderBy} */ (reader.readEnum());
      msg.setSortBy(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasGuestInterviews(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.search.content.PodcastSearchFilters.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.search.content.PodcastSearchFilters.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.search.content.PodcastSearchFilters} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.search.content.PodcastSearchFilters.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGenreUidList();
  if (f.length > 0) {
    writer.writePackedInt32(
      1,
      f
    );
  }
  f = message.getFreshness();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getCustomFreshnessValue();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      common_pb.TimestampRange.serializeBinaryToWriter
    );
  }
  f = message.getLocation();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getLanguage();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getTotalEpisodesNumber();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      common_pb.Range.serializeBinaryToWriter
    );
  }
  f = message.getUpdateFrequencyHoursNumber();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      common_pb.Range.serializeBinaryToWriter
    );
  }
  f = message.getListenScoreNumber();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      common_pb.Range.serializeBinaryToWriter
    );
  }
  f = message.getSortBy();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getHasGuestInterviews();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
};


/**
 * repeated int32 genre_uid = 1;
 * @return {!Array<number>}
 */
proto.search.content.PodcastSearchFilters.prototype.getGenreUidList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.search.content.PodcastSearchFilters} returns this
 */
proto.search.content.PodcastSearchFilters.prototype.setGenreUidList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.search.content.PodcastSearchFilters} returns this
 */
proto.search.content.PodcastSearchFilters.prototype.addGenreUid = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.search.content.PodcastSearchFilters} returns this
 */
proto.search.content.PodcastSearchFilters.prototype.clearGenreUidList = function() {
  return this.setGenreUidList([]);
};


/**
 * optional FreshnessType freshness = 2;
 * @return {!proto.search.content.FreshnessType}
 */
proto.search.content.PodcastSearchFilters.prototype.getFreshness = function() {
  return /** @type {!proto.search.content.FreshnessType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.search.content.FreshnessType} value
 * @return {!proto.search.content.PodcastSearchFilters} returns this
 */
proto.search.content.PodcastSearchFilters.prototype.setFreshness = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional common.TimestampRange custom_freshness_value = 3;
 * @return {?proto.common.TimestampRange}
 */
proto.search.content.PodcastSearchFilters.prototype.getCustomFreshnessValue = function() {
  return /** @type{?proto.common.TimestampRange} */ (
    jspb.Message.getWrapperField(this, common_pb.TimestampRange, 3));
};


/**
 * @param {?proto.common.TimestampRange|undefined} value
 * @return {!proto.search.content.PodcastSearchFilters} returns this
*/
proto.search.content.PodcastSearchFilters.prototype.setCustomFreshnessValue = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.search.content.PodcastSearchFilters} returns this
 */
proto.search.content.PodcastSearchFilters.prototype.clearCustomFreshnessValue = function() {
  return this.setCustomFreshnessValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.search.content.PodcastSearchFilters.prototype.hasCustomFreshnessValue = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string location = 4;
 * @return {string}
 */
proto.search.content.PodcastSearchFilters.prototype.getLocation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.search.content.PodcastSearchFilters} returns this
 */
proto.search.content.PodcastSearchFilters.prototype.setLocation = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string language = 5;
 * @return {string}
 */
proto.search.content.PodcastSearchFilters.prototype.getLanguage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.search.content.PodcastSearchFilters} returns this
 */
proto.search.content.PodcastSearchFilters.prototype.setLanguage = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional common.Range total_episodes_number = 6;
 * @return {?proto.common.Range}
 */
proto.search.content.PodcastSearchFilters.prototype.getTotalEpisodesNumber = function() {
  return /** @type{?proto.common.Range} */ (
    jspb.Message.getWrapperField(this, common_pb.Range, 6));
};


/**
 * @param {?proto.common.Range|undefined} value
 * @return {!proto.search.content.PodcastSearchFilters} returns this
*/
proto.search.content.PodcastSearchFilters.prototype.setTotalEpisodesNumber = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.search.content.PodcastSearchFilters} returns this
 */
proto.search.content.PodcastSearchFilters.prototype.clearTotalEpisodesNumber = function() {
  return this.setTotalEpisodesNumber(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.search.content.PodcastSearchFilters.prototype.hasTotalEpisodesNumber = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional common.Range update_frequency_hours_number = 7;
 * @return {?proto.common.Range}
 */
proto.search.content.PodcastSearchFilters.prototype.getUpdateFrequencyHoursNumber = function() {
  return /** @type{?proto.common.Range} */ (
    jspb.Message.getWrapperField(this, common_pb.Range, 7));
};


/**
 * @param {?proto.common.Range|undefined} value
 * @return {!proto.search.content.PodcastSearchFilters} returns this
*/
proto.search.content.PodcastSearchFilters.prototype.setUpdateFrequencyHoursNumber = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.search.content.PodcastSearchFilters} returns this
 */
proto.search.content.PodcastSearchFilters.prototype.clearUpdateFrequencyHoursNumber = function() {
  return this.setUpdateFrequencyHoursNumber(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.search.content.PodcastSearchFilters.prototype.hasUpdateFrequencyHoursNumber = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional common.Range listen_score_number = 8;
 * @return {?proto.common.Range}
 */
proto.search.content.PodcastSearchFilters.prototype.getListenScoreNumber = function() {
  return /** @type{?proto.common.Range} */ (
    jspb.Message.getWrapperField(this, common_pb.Range, 8));
};


/**
 * @param {?proto.common.Range|undefined} value
 * @return {!proto.search.content.PodcastSearchFilters} returns this
*/
proto.search.content.PodcastSearchFilters.prototype.setListenScoreNumber = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.search.content.PodcastSearchFilters} returns this
 */
proto.search.content.PodcastSearchFilters.prototype.clearListenScoreNumber = function() {
  return this.setListenScoreNumber(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.search.content.PodcastSearchFilters.prototype.hasListenScoreNumber = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional common.ContentSearchOrderBy sort_by = 9;
 * @return {!proto.common.ContentSearchOrderBy}
 */
proto.search.content.PodcastSearchFilters.prototype.getSortBy = function() {
  return /** @type {!proto.common.ContentSearchOrderBy} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.common.ContentSearchOrderBy} value
 * @return {!proto.search.content.PodcastSearchFilters} returns this
 */
proto.search.content.PodcastSearchFilters.prototype.setSortBy = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional bool has_guest_interviews = 10;
 * @return {boolean}
 */
proto.search.content.PodcastSearchFilters.prototype.getHasGuestInterviews = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.search.content.PodcastSearchFilters} returns this
 */
proto.search.content.PodcastSearchFilters.prototype.setHasGuestInterviews = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.search.content.EpisodeSearchFilters.prototype.toObject = function(opt_includeInstance) {
  return proto.search.content.EpisodeSearchFilters.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.search.content.EpisodeSearchFilters} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.search.content.EpisodeSearchFilters.toObject = function(includeInstance, msg) {
  var f, obj = {
    durationMinutes: (f = msg.getDurationMinutes()) && common_pb.Range.toObject(includeInstance, f),
    freshness: jspb.Message.getFieldWithDefault(msg, 2, 0),
    customFreshnessValue: (f = msg.getCustomFreshnessValue()) && common_pb.TimestampRange.toObject(includeInstance, f),
    location: jspb.Message.getFieldWithDefault(msg, 4, ""),
    language: jspb.Message.getFieldWithDefault(msg, 5, ""),
    listenScoreNumber: (f = msg.getListenScoreNumber()) && common_pb.Range.toObject(includeInstance, f),
    sortBy: jspb.Message.getFieldWithDefault(msg, 7, 0),
    onePerPodcast: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    withPodcastDetails: jspb.Message.getBooleanFieldWithDefault(msg, 9, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.search.content.EpisodeSearchFilters}
 */
proto.search.content.EpisodeSearchFilters.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.search.content.EpisodeSearchFilters;
  return proto.search.content.EpisodeSearchFilters.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.search.content.EpisodeSearchFilters} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.search.content.EpisodeSearchFilters}
 */
proto.search.content.EpisodeSearchFilters.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_pb.Range;
      reader.readMessage(value,common_pb.Range.deserializeBinaryFromReader);
      msg.setDurationMinutes(value);
      break;
    case 2:
      var value = /** @type {!proto.search.content.FreshnessType} */ (reader.readEnum());
      msg.setFreshness(value);
      break;
    case 3:
      var value = new common_pb.TimestampRange;
      reader.readMessage(value,common_pb.TimestampRange.deserializeBinaryFromReader);
      msg.setCustomFreshnessValue(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocation(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setLanguage(value);
      break;
    case 6:
      var value = new common_pb.Range;
      reader.readMessage(value,common_pb.Range.deserializeBinaryFromReader);
      msg.setListenScoreNumber(value);
      break;
    case 7:
      var value = /** @type {!proto.common.ContentSearchOrderBy} */ (reader.readEnum());
      msg.setSortBy(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOnePerPodcast(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setWithPodcastDetails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.search.content.EpisodeSearchFilters.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.search.content.EpisodeSearchFilters.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.search.content.EpisodeSearchFilters} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.search.content.EpisodeSearchFilters.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDurationMinutes();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_pb.Range.serializeBinaryToWriter
    );
  }
  f = message.getFreshness();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getCustomFreshnessValue();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      common_pb.TimestampRange.serializeBinaryToWriter
    );
  }
  f = message.getLocation();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getLanguage();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getListenScoreNumber();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      common_pb.Range.serializeBinaryToWriter
    );
  }
  f = message.getSortBy();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getOnePerPodcast();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getWithPodcastDetails();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
};


/**
 * optional common.Range duration_minutes = 1;
 * @return {?proto.common.Range}
 */
proto.search.content.EpisodeSearchFilters.prototype.getDurationMinutes = function() {
  return /** @type{?proto.common.Range} */ (
    jspb.Message.getWrapperField(this, common_pb.Range, 1));
};


/**
 * @param {?proto.common.Range|undefined} value
 * @return {!proto.search.content.EpisodeSearchFilters} returns this
*/
proto.search.content.EpisodeSearchFilters.prototype.setDurationMinutes = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.search.content.EpisodeSearchFilters} returns this
 */
proto.search.content.EpisodeSearchFilters.prototype.clearDurationMinutes = function() {
  return this.setDurationMinutes(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.search.content.EpisodeSearchFilters.prototype.hasDurationMinutes = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional FreshnessType freshness = 2;
 * @return {!proto.search.content.FreshnessType}
 */
proto.search.content.EpisodeSearchFilters.prototype.getFreshness = function() {
  return /** @type {!proto.search.content.FreshnessType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.search.content.FreshnessType} value
 * @return {!proto.search.content.EpisodeSearchFilters} returns this
 */
proto.search.content.EpisodeSearchFilters.prototype.setFreshness = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional common.TimestampRange custom_freshness_value = 3;
 * @return {?proto.common.TimestampRange}
 */
proto.search.content.EpisodeSearchFilters.prototype.getCustomFreshnessValue = function() {
  return /** @type{?proto.common.TimestampRange} */ (
    jspb.Message.getWrapperField(this, common_pb.TimestampRange, 3));
};


/**
 * @param {?proto.common.TimestampRange|undefined} value
 * @return {!proto.search.content.EpisodeSearchFilters} returns this
*/
proto.search.content.EpisodeSearchFilters.prototype.setCustomFreshnessValue = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.search.content.EpisodeSearchFilters} returns this
 */
proto.search.content.EpisodeSearchFilters.prototype.clearCustomFreshnessValue = function() {
  return this.setCustomFreshnessValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.search.content.EpisodeSearchFilters.prototype.hasCustomFreshnessValue = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string location = 4;
 * @return {string}
 */
proto.search.content.EpisodeSearchFilters.prototype.getLocation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.search.content.EpisodeSearchFilters} returns this
 */
proto.search.content.EpisodeSearchFilters.prototype.setLocation = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string language = 5;
 * @return {string}
 */
proto.search.content.EpisodeSearchFilters.prototype.getLanguage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.search.content.EpisodeSearchFilters} returns this
 */
proto.search.content.EpisodeSearchFilters.prototype.setLanguage = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional common.Range listen_score_number = 6;
 * @return {?proto.common.Range}
 */
proto.search.content.EpisodeSearchFilters.prototype.getListenScoreNumber = function() {
  return /** @type{?proto.common.Range} */ (
    jspb.Message.getWrapperField(this, common_pb.Range, 6));
};


/**
 * @param {?proto.common.Range|undefined} value
 * @return {!proto.search.content.EpisodeSearchFilters} returns this
*/
proto.search.content.EpisodeSearchFilters.prototype.setListenScoreNumber = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.search.content.EpisodeSearchFilters} returns this
 */
proto.search.content.EpisodeSearchFilters.prototype.clearListenScoreNumber = function() {
  return this.setListenScoreNumber(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.search.content.EpisodeSearchFilters.prototype.hasListenScoreNumber = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional common.ContentSearchOrderBy sort_by = 7;
 * @return {!proto.common.ContentSearchOrderBy}
 */
proto.search.content.EpisodeSearchFilters.prototype.getSortBy = function() {
  return /** @type {!proto.common.ContentSearchOrderBy} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.common.ContentSearchOrderBy} value
 * @return {!proto.search.content.EpisodeSearchFilters} returns this
 */
proto.search.content.EpisodeSearchFilters.prototype.setSortBy = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional bool one_per_podcast = 8;
 * @return {boolean}
 */
proto.search.content.EpisodeSearchFilters.prototype.getOnePerPodcast = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.search.content.EpisodeSearchFilters} returns this
 */
proto.search.content.EpisodeSearchFilters.prototype.setOnePerPodcast = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional bool with_podcast_details = 9;
 * @return {boolean}
 */
proto.search.content.EpisodeSearchFilters.prototype.getWithPodcastDetails = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.search.content.EpisodeSearchFilters} returns this
 */
proto.search.content.EpisodeSearchFilters.prototype.setWithPodcastDetails = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.search.content.TrendingContentSearchFilters.prototype.toObject = function(opt_includeInstance) {
  return proto.search.content.TrendingContentSearchFilters.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.search.content.TrendingContentSearchFilters} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.search.content.TrendingContentSearchFilters.toObject = function(includeInstance, msg) {
  var f, obj = {
    language: jspb.Message.getFieldWithDefault(msg, 1, ""),
    freshness: jspb.Message.getFieldWithDefault(msg, 2, 0),
    customFreshnessValue: (f = msg.getCustomFreshnessValue()) && common_pb.TimestampRange.toObject(includeInstance, f),
    minWordsNumber: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.search.content.TrendingContentSearchFilters}
 */
proto.search.content.TrendingContentSearchFilters.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.search.content.TrendingContentSearchFilters;
  return proto.search.content.TrendingContentSearchFilters.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.search.content.TrendingContentSearchFilters} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.search.content.TrendingContentSearchFilters}
 */
proto.search.content.TrendingContentSearchFilters.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLanguage(value);
      break;
    case 2:
      var value = /** @type {!proto.search.content.FreshnessType} */ (reader.readEnum());
      msg.setFreshness(value);
      break;
    case 3:
      var value = new common_pb.TimestampRange;
      reader.readMessage(value,common_pb.TimestampRange.deserializeBinaryFromReader);
      msg.setCustomFreshnessValue(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMinWordsNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.search.content.TrendingContentSearchFilters.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.search.content.TrendingContentSearchFilters.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.search.content.TrendingContentSearchFilters} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.search.content.TrendingContentSearchFilters.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLanguage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFreshness();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getCustomFreshnessValue();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      common_pb.TimestampRange.serializeBinaryToWriter
    );
  }
  f = message.getMinWordsNumber();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional string language = 1;
 * @return {string}
 */
proto.search.content.TrendingContentSearchFilters.prototype.getLanguage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.search.content.TrendingContentSearchFilters} returns this
 */
proto.search.content.TrendingContentSearchFilters.prototype.setLanguage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional FreshnessType freshness = 2;
 * @return {!proto.search.content.FreshnessType}
 */
proto.search.content.TrendingContentSearchFilters.prototype.getFreshness = function() {
  return /** @type {!proto.search.content.FreshnessType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.search.content.FreshnessType} value
 * @return {!proto.search.content.TrendingContentSearchFilters} returns this
 */
proto.search.content.TrendingContentSearchFilters.prototype.setFreshness = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional common.TimestampRange custom_freshness_value = 3;
 * @return {?proto.common.TimestampRange}
 */
proto.search.content.TrendingContentSearchFilters.prototype.getCustomFreshnessValue = function() {
  return /** @type{?proto.common.TimestampRange} */ (
    jspb.Message.getWrapperField(this, common_pb.TimestampRange, 3));
};


/**
 * @param {?proto.common.TimestampRange|undefined} value
 * @return {!proto.search.content.TrendingContentSearchFilters} returns this
*/
proto.search.content.TrendingContentSearchFilters.prototype.setCustomFreshnessValue = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.search.content.TrendingContentSearchFilters} returns this
 */
proto.search.content.TrendingContentSearchFilters.prototype.clearCustomFreshnessValue = function() {
  return this.setCustomFreshnessValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.search.content.TrendingContentSearchFilters.prototype.hasCustomFreshnessValue = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int32 min_words_number = 4;
 * @return {number}
 */
proto.search.content.TrendingContentSearchFilters.prototype.getMinWordsNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.search.content.TrendingContentSearchFilters} returns this
 */
proto.search.content.TrendingContentSearchFilters.prototype.setMinWordsNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.search.content.UrlRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.search.content.UrlRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.search.content.UrlRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.search.content.UrlRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    query: jspb.Message.getFieldWithDefault(msg, 1, ""),
    limit: jspb.Message.getFieldWithDefault(msg, 2, 0),
    type: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.search.content.UrlRequest}
 */
proto.search.content.UrlRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.search.content.UrlRequest;
  return proto.search.content.UrlRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.search.content.UrlRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.search.content.UrlRequest}
 */
proto.search.content.UrlRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuery(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLimit(value);
      break;
    case 3:
      var value = /** @type {!proto.search.content.UrlSearchType} */ (reader.readEnum());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.search.content.UrlRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.search.content.UrlRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.search.content.UrlRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.search.content.UrlRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuery();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional string query = 1;
 * @return {string}
 */
proto.search.content.UrlRequest.prototype.getQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.search.content.UrlRequest} returns this
 */
proto.search.content.UrlRequest.prototype.setQuery = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 limit = 2;
 * @return {number}
 */
proto.search.content.UrlRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.search.content.UrlRequest} returns this
 */
proto.search.content.UrlRequest.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional UrlSearchType type = 3;
 * @return {!proto.search.content.UrlSearchType}
 */
proto.search.content.UrlRequest.prototype.getType = function() {
  return /** @type {!proto.search.content.UrlSearchType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.search.content.UrlSearchType} value
 * @return {!proto.search.content.UrlRequest} returns this
 */
proto.search.content.UrlRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.search.content.SearchRequest.oneofGroups_ = [[5,6,7,8,9,10,11,12,13,14]];

/**
 * @enum {number}
 */
proto.search.content.SearchRequest.FiltersCase = {
  FILTERS_NOT_SET: 0,
  GOOGLE_SEARCH: 5,
  BING_NEWS: 6,
  PODCAST: 7,
  EPISODE: 8,
  TRENDING_CONTENT: 9,
  BING_SEARCH: 10,
  AHREF_BACKLINKS: 11,
  SEMRUSH_BACKLINKS: 12,
  MOZ_BACKLINKS: 13,
  GOOGLE_NEWS: 14
};

/**
 * @return {proto.search.content.SearchRequest.FiltersCase}
 */
proto.search.content.SearchRequest.prototype.getFiltersCase = function() {
  return /** @type {proto.search.content.SearchRequest.FiltersCase} */(jspb.Message.computeOneofCase(this, proto.search.content.SearchRequest.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.search.content.SearchRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.search.content.SearchRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.search.content.SearchRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.search.content.SearchRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    query: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0),
    campaignId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    page: jspb.Message.getFieldWithDefault(msg, 4, 0),
    googleSearch: (f = msg.getGoogleSearch()) && proto.search.content.ArticleSearchFilters.toObject(includeInstance, f),
    bingNews: (f = msg.getBingNews()) && proto.search.content.ArticleSearchFilters.toObject(includeInstance, f),
    podcast: (f = msg.getPodcast()) && proto.search.content.PodcastSearchFilters.toObject(includeInstance, f),
    episode: (f = msg.getEpisode()) && proto.search.content.EpisodeSearchFilters.toObject(includeInstance, f),
    trendingContent: (f = msg.getTrendingContent()) && proto.search.content.TrendingContentSearchFilters.toObject(includeInstance, f),
    bingSearch: (f = msg.getBingSearch()) && proto.search.content.ArticleSearchFilters.toObject(includeInstance, f),
    ahrefBacklinks: (f = msg.getAhrefBacklinks()) && proto.search.content.BacklinksSearchFilters.toObject(includeInstance, f),
    semrushBacklinks: (f = msg.getSemrushBacklinks()) && proto.search.content.BacklinksSearchFilters.toObject(includeInstance, f),
    mozBacklinks: (f = msg.getMozBacklinks()) && proto.search.content.BacklinksSearchFilters.toObject(includeInstance, f),
    googleNews: (f = msg.getGoogleNews()) && proto.search.content.ArticleSearchFilters.toObject(includeInstance, f),
    organizationId: jspb.Message.getFieldWithDefault(msg, 15, 0),
    workspaceId: jspb.Message.getFieldWithDefault(msg, 16, 0),
    batch: jspb.Message.getFieldWithDefault(msg, 17, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.search.content.SearchRequest}
 */
proto.search.content.SearchRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.search.content.SearchRequest;
  return proto.search.content.SearchRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.search.content.SearchRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.search.content.SearchRequest}
 */
proto.search.content.SearchRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuery(value);
      break;
    case 2:
      var value = /** @type {!proto.common.ContentSearchType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCampaignId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 5:
      var value = new proto.search.content.ArticleSearchFilters;
      reader.readMessage(value,proto.search.content.ArticleSearchFilters.deserializeBinaryFromReader);
      msg.setGoogleSearch(value);
      break;
    case 6:
      var value = new proto.search.content.ArticleSearchFilters;
      reader.readMessage(value,proto.search.content.ArticleSearchFilters.deserializeBinaryFromReader);
      msg.setBingNews(value);
      break;
    case 7:
      var value = new proto.search.content.PodcastSearchFilters;
      reader.readMessage(value,proto.search.content.PodcastSearchFilters.deserializeBinaryFromReader);
      msg.setPodcast(value);
      break;
    case 8:
      var value = new proto.search.content.EpisodeSearchFilters;
      reader.readMessage(value,proto.search.content.EpisodeSearchFilters.deserializeBinaryFromReader);
      msg.setEpisode(value);
      break;
    case 9:
      var value = new proto.search.content.TrendingContentSearchFilters;
      reader.readMessage(value,proto.search.content.TrendingContentSearchFilters.deserializeBinaryFromReader);
      msg.setTrendingContent(value);
      break;
    case 10:
      var value = new proto.search.content.ArticleSearchFilters;
      reader.readMessage(value,proto.search.content.ArticleSearchFilters.deserializeBinaryFromReader);
      msg.setBingSearch(value);
      break;
    case 11:
      var value = new proto.search.content.BacklinksSearchFilters;
      reader.readMessage(value,proto.search.content.BacklinksSearchFilters.deserializeBinaryFromReader);
      msg.setAhrefBacklinks(value);
      break;
    case 12:
      var value = new proto.search.content.BacklinksSearchFilters;
      reader.readMessage(value,proto.search.content.BacklinksSearchFilters.deserializeBinaryFromReader);
      msg.setSemrushBacklinks(value);
      break;
    case 13:
      var value = new proto.search.content.BacklinksSearchFilters;
      reader.readMessage(value,proto.search.content.BacklinksSearchFilters.deserializeBinaryFromReader);
      msg.setMozBacklinks(value);
      break;
    case 14:
      var value = new proto.search.content.ArticleSearchFilters;
      reader.readMessage(value,proto.search.content.ArticleSearchFilters.deserializeBinaryFromReader);
      msg.setGoogleNews(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOrganizationId(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWorkspaceId(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBatch(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.search.content.SearchRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.search.content.SearchRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.search.content.SearchRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.search.content.SearchRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuery();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getCampaignId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getGoogleSearch();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.search.content.ArticleSearchFilters.serializeBinaryToWriter
    );
  }
  f = message.getBingNews();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.search.content.ArticleSearchFilters.serializeBinaryToWriter
    );
  }
  f = message.getPodcast();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.search.content.PodcastSearchFilters.serializeBinaryToWriter
    );
  }
  f = message.getEpisode();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.search.content.EpisodeSearchFilters.serializeBinaryToWriter
    );
  }
  f = message.getTrendingContent();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.search.content.TrendingContentSearchFilters.serializeBinaryToWriter
    );
  }
  f = message.getBingSearch();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.search.content.ArticleSearchFilters.serializeBinaryToWriter
    );
  }
  f = message.getAhrefBacklinks();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.search.content.BacklinksSearchFilters.serializeBinaryToWriter
    );
  }
  f = message.getSemrushBacklinks();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.search.content.BacklinksSearchFilters.serializeBinaryToWriter
    );
  }
  f = message.getMozBacklinks();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.search.content.BacklinksSearchFilters.serializeBinaryToWriter
    );
  }
  f = message.getGoogleNews();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.search.content.ArticleSearchFilters.serializeBinaryToWriter
    );
  }
  f = message.getOrganizationId();
  if (f !== 0) {
    writer.writeInt64(
      15,
      f
    );
  }
  f = message.getWorkspaceId();
  if (f !== 0) {
    writer.writeInt64(
      16,
      f
    );
  }
  f = message.getBatch();
  if (f !== 0) {
    writer.writeInt32(
      17,
      f
    );
  }
};


/**
 * optional string query = 1;
 * @return {string}
 */
proto.search.content.SearchRequest.prototype.getQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.search.content.SearchRequest} returns this
 */
proto.search.content.SearchRequest.prototype.setQuery = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional common.ContentSearchType type = 2;
 * @return {!proto.common.ContentSearchType}
 */
proto.search.content.SearchRequest.prototype.getType = function() {
  return /** @type {!proto.common.ContentSearchType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.common.ContentSearchType} value
 * @return {!proto.search.content.SearchRequest} returns this
 */
proto.search.content.SearchRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional int64 campaign_id = 3;
 * @return {number}
 */
proto.search.content.SearchRequest.prototype.getCampaignId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.search.content.SearchRequest} returns this
 */
proto.search.content.SearchRequest.prototype.setCampaignId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 page = 4;
 * @return {number}
 */
proto.search.content.SearchRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.search.content.SearchRequest} returns this
 */
proto.search.content.SearchRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional ArticleSearchFilters google_search = 5;
 * @return {?proto.search.content.ArticleSearchFilters}
 */
proto.search.content.SearchRequest.prototype.getGoogleSearch = function() {
  return /** @type{?proto.search.content.ArticleSearchFilters} */ (
    jspb.Message.getWrapperField(this, proto.search.content.ArticleSearchFilters, 5));
};


/**
 * @param {?proto.search.content.ArticleSearchFilters|undefined} value
 * @return {!proto.search.content.SearchRequest} returns this
*/
proto.search.content.SearchRequest.prototype.setGoogleSearch = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.search.content.SearchRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.search.content.SearchRequest} returns this
 */
proto.search.content.SearchRequest.prototype.clearGoogleSearch = function() {
  return this.setGoogleSearch(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.search.content.SearchRequest.prototype.hasGoogleSearch = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional ArticleSearchFilters bing_news = 6;
 * @return {?proto.search.content.ArticleSearchFilters}
 */
proto.search.content.SearchRequest.prototype.getBingNews = function() {
  return /** @type{?proto.search.content.ArticleSearchFilters} */ (
    jspb.Message.getWrapperField(this, proto.search.content.ArticleSearchFilters, 6));
};


/**
 * @param {?proto.search.content.ArticleSearchFilters|undefined} value
 * @return {!proto.search.content.SearchRequest} returns this
*/
proto.search.content.SearchRequest.prototype.setBingNews = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.search.content.SearchRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.search.content.SearchRequest} returns this
 */
proto.search.content.SearchRequest.prototype.clearBingNews = function() {
  return this.setBingNews(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.search.content.SearchRequest.prototype.hasBingNews = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional PodcastSearchFilters podcast = 7;
 * @return {?proto.search.content.PodcastSearchFilters}
 */
proto.search.content.SearchRequest.prototype.getPodcast = function() {
  return /** @type{?proto.search.content.PodcastSearchFilters} */ (
    jspb.Message.getWrapperField(this, proto.search.content.PodcastSearchFilters, 7));
};


/**
 * @param {?proto.search.content.PodcastSearchFilters|undefined} value
 * @return {!proto.search.content.SearchRequest} returns this
*/
proto.search.content.SearchRequest.prototype.setPodcast = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.search.content.SearchRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.search.content.SearchRequest} returns this
 */
proto.search.content.SearchRequest.prototype.clearPodcast = function() {
  return this.setPodcast(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.search.content.SearchRequest.prototype.hasPodcast = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional EpisodeSearchFilters episode = 8;
 * @return {?proto.search.content.EpisodeSearchFilters}
 */
proto.search.content.SearchRequest.prototype.getEpisode = function() {
  return /** @type{?proto.search.content.EpisodeSearchFilters} */ (
    jspb.Message.getWrapperField(this, proto.search.content.EpisodeSearchFilters, 8));
};


/**
 * @param {?proto.search.content.EpisodeSearchFilters|undefined} value
 * @return {!proto.search.content.SearchRequest} returns this
*/
proto.search.content.SearchRequest.prototype.setEpisode = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.search.content.SearchRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.search.content.SearchRequest} returns this
 */
proto.search.content.SearchRequest.prototype.clearEpisode = function() {
  return this.setEpisode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.search.content.SearchRequest.prototype.hasEpisode = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional TrendingContentSearchFilters trending_content = 9;
 * @return {?proto.search.content.TrendingContentSearchFilters}
 */
proto.search.content.SearchRequest.prototype.getTrendingContent = function() {
  return /** @type{?proto.search.content.TrendingContentSearchFilters} */ (
    jspb.Message.getWrapperField(this, proto.search.content.TrendingContentSearchFilters, 9));
};


/**
 * @param {?proto.search.content.TrendingContentSearchFilters|undefined} value
 * @return {!proto.search.content.SearchRequest} returns this
*/
proto.search.content.SearchRequest.prototype.setTrendingContent = function(value) {
  return jspb.Message.setOneofWrapperField(this, 9, proto.search.content.SearchRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.search.content.SearchRequest} returns this
 */
proto.search.content.SearchRequest.prototype.clearTrendingContent = function() {
  return this.setTrendingContent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.search.content.SearchRequest.prototype.hasTrendingContent = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional ArticleSearchFilters bing_search = 10;
 * @return {?proto.search.content.ArticleSearchFilters}
 */
proto.search.content.SearchRequest.prototype.getBingSearch = function() {
  return /** @type{?proto.search.content.ArticleSearchFilters} */ (
    jspb.Message.getWrapperField(this, proto.search.content.ArticleSearchFilters, 10));
};


/**
 * @param {?proto.search.content.ArticleSearchFilters|undefined} value
 * @return {!proto.search.content.SearchRequest} returns this
*/
proto.search.content.SearchRequest.prototype.setBingSearch = function(value) {
  return jspb.Message.setOneofWrapperField(this, 10, proto.search.content.SearchRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.search.content.SearchRequest} returns this
 */
proto.search.content.SearchRequest.prototype.clearBingSearch = function() {
  return this.setBingSearch(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.search.content.SearchRequest.prototype.hasBingSearch = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional BacklinksSearchFilters ahref_backlinks = 11;
 * @return {?proto.search.content.BacklinksSearchFilters}
 */
proto.search.content.SearchRequest.prototype.getAhrefBacklinks = function() {
  return /** @type{?proto.search.content.BacklinksSearchFilters} */ (
    jspb.Message.getWrapperField(this, proto.search.content.BacklinksSearchFilters, 11));
};


/**
 * @param {?proto.search.content.BacklinksSearchFilters|undefined} value
 * @return {!proto.search.content.SearchRequest} returns this
*/
proto.search.content.SearchRequest.prototype.setAhrefBacklinks = function(value) {
  return jspb.Message.setOneofWrapperField(this, 11, proto.search.content.SearchRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.search.content.SearchRequest} returns this
 */
proto.search.content.SearchRequest.prototype.clearAhrefBacklinks = function() {
  return this.setAhrefBacklinks(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.search.content.SearchRequest.prototype.hasAhrefBacklinks = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional BacklinksSearchFilters semrush_backlinks = 12;
 * @return {?proto.search.content.BacklinksSearchFilters}
 */
proto.search.content.SearchRequest.prototype.getSemrushBacklinks = function() {
  return /** @type{?proto.search.content.BacklinksSearchFilters} */ (
    jspb.Message.getWrapperField(this, proto.search.content.BacklinksSearchFilters, 12));
};


/**
 * @param {?proto.search.content.BacklinksSearchFilters|undefined} value
 * @return {!proto.search.content.SearchRequest} returns this
*/
proto.search.content.SearchRequest.prototype.setSemrushBacklinks = function(value) {
  return jspb.Message.setOneofWrapperField(this, 12, proto.search.content.SearchRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.search.content.SearchRequest} returns this
 */
proto.search.content.SearchRequest.prototype.clearSemrushBacklinks = function() {
  return this.setSemrushBacklinks(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.search.content.SearchRequest.prototype.hasSemrushBacklinks = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional BacklinksSearchFilters moz_backlinks = 13;
 * @return {?proto.search.content.BacklinksSearchFilters}
 */
proto.search.content.SearchRequest.prototype.getMozBacklinks = function() {
  return /** @type{?proto.search.content.BacklinksSearchFilters} */ (
    jspb.Message.getWrapperField(this, proto.search.content.BacklinksSearchFilters, 13));
};


/**
 * @param {?proto.search.content.BacklinksSearchFilters|undefined} value
 * @return {!proto.search.content.SearchRequest} returns this
*/
proto.search.content.SearchRequest.prototype.setMozBacklinks = function(value) {
  return jspb.Message.setOneofWrapperField(this, 13, proto.search.content.SearchRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.search.content.SearchRequest} returns this
 */
proto.search.content.SearchRequest.prototype.clearMozBacklinks = function() {
  return this.setMozBacklinks(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.search.content.SearchRequest.prototype.hasMozBacklinks = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional ArticleSearchFilters google_news = 14;
 * @return {?proto.search.content.ArticleSearchFilters}
 */
proto.search.content.SearchRequest.prototype.getGoogleNews = function() {
  return /** @type{?proto.search.content.ArticleSearchFilters} */ (
    jspb.Message.getWrapperField(this, proto.search.content.ArticleSearchFilters, 14));
};


/**
 * @param {?proto.search.content.ArticleSearchFilters|undefined} value
 * @return {!proto.search.content.SearchRequest} returns this
*/
proto.search.content.SearchRequest.prototype.setGoogleNews = function(value) {
  return jspb.Message.setOneofWrapperField(this, 14, proto.search.content.SearchRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.search.content.SearchRequest} returns this
 */
proto.search.content.SearchRequest.prototype.clearGoogleNews = function() {
  return this.setGoogleNews(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.search.content.SearchRequest.prototype.hasGoogleNews = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional int64 organization_id = 15;
 * @return {number}
 */
proto.search.content.SearchRequest.prototype.getOrganizationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.search.content.SearchRequest} returns this
 */
proto.search.content.SearchRequest.prototype.setOrganizationId = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional int64 workspace_id = 16;
 * @return {number}
 */
proto.search.content.SearchRequest.prototype.getWorkspaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.search.content.SearchRequest} returns this
 */
proto.search.content.SearchRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional int32 batch = 17;
 * @return {number}
 */
proto.search.content.SearchRequest.prototype.getBatch = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.search.content.SearchRequest} returns this
 */
proto.search.content.SearchRequest.prototype.setBatch = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.search.content.ArticleItemDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.search.content.ArticleItemDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.search.content.ArticleItemDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.search.content.ArticleItemDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    rank: jspb.Message.getFieldWithDefault(msg, 1, 0),
    domainRating: jspb.Message.getFieldWithDefault(msg, 2, 0),
    domainTraffic: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    domainTrafficValue: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    domainTrafficKeywordsNumber: jspb.Message.getFieldWithDefault(msg, 5, 0),
    referringDomains: jspb.Message.getFieldWithDefault(msg, 6, 0),
    backlinksTotal: jspb.Message.getFieldWithDefault(msg, 7, 0),
    backlinksNofollow: jspb.Message.getFieldWithDefault(msg, 8, 0),
    backlinksDofollow: jspb.Message.getFieldWithDefault(msg, 9, 0),
    backlinksOther: jspb.Message.getFieldWithDefault(msg, 10, 0),
    domainSpamScore: jspb.Message.getFieldWithDefault(msg, 11, 0),
    pageAuthority: jspb.Message.getFieldWithDefault(msg, 12, 0),
    publishingDate: jspb.Message.getFieldWithDefault(msg, 13, 0),
    includePaidMetrics: jspb.Message.getBooleanFieldWithDefault(msg, 14, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.search.content.ArticleItemDetails}
 */
proto.search.content.ArticleItemDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.search.content.ArticleItemDetails;
  return proto.search.content.ArticleItemDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.search.content.ArticleItemDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.search.content.ArticleItemDetails}
 */
proto.search.content.ArticleItemDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRank(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDomainRating(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDomainTraffic(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDomainTrafficValue(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDomainTrafficKeywordsNumber(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setReferringDomains(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBacklinksTotal(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBacklinksNofollow(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBacklinksDofollow(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBacklinksOther(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDomainSpamScore(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageAuthority(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPublishingDate(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludePaidMetrics(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.search.content.ArticleItemDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.search.content.ArticleItemDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.search.content.ArticleItemDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.search.content.ArticleItemDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRank();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getDomainRating();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getDomainTraffic();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getDomainTrafficValue();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getDomainTrafficKeywordsNumber();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getReferringDomains();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getBacklinksTotal();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getBacklinksNofollow();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getBacklinksDofollow();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getBacklinksOther();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getDomainSpamScore();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getPageAuthority();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
  f = message.getPublishingDate();
  if (f !== 0) {
    writer.writeInt64(
      13,
      f
    );
  }
  f = message.getIncludePaidMetrics();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
};


/**
 * optional int64 rank = 1;
 * @return {number}
 */
proto.search.content.ArticleItemDetails.prototype.getRank = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.search.content.ArticleItemDetails} returns this
 */
proto.search.content.ArticleItemDetails.prototype.setRank = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 domain_rating = 2;
 * @return {number}
 */
proto.search.content.ArticleItemDetails.prototype.getDomainRating = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.search.content.ArticleItemDetails} returns this
 */
proto.search.content.ArticleItemDetails.prototype.setDomainRating = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional double domain_traffic = 3;
 * @return {number}
 */
proto.search.content.ArticleItemDetails.prototype.getDomainTraffic = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.search.content.ArticleItemDetails} returns this
 */
proto.search.content.ArticleItemDetails.prototype.setDomainTraffic = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double domain_traffic_value = 4;
 * @return {number}
 */
proto.search.content.ArticleItemDetails.prototype.getDomainTrafficValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.search.content.ArticleItemDetails} returns this
 */
proto.search.content.ArticleItemDetails.prototype.setDomainTrafficValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional int64 domain_traffic_keywords_number = 5;
 * @return {number}
 */
proto.search.content.ArticleItemDetails.prototype.getDomainTrafficKeywordsNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.search.content.ArticleItemDetails} returns this
 */
proto.search.content.ArticleItemDetails.prototype.setDomainTrafficKeywordsNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 referring_domains = 6;
 * @return {number}
 */
proto.search.content.ArticleItemDetails.prototype.getReferringDomains = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.search.content.ArticleItemDetails} returns this
 */
proto.search.content.ArticleItemDetails.prototype.setReferringDomains = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 backlinks_total = 7;
 * @return {number}
 */
proto.search.content.ArticleItemDetails.prototype.getBacklinksTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.search.content.ArticleItemDetails} returns this
 */
proto.search.content.ArticleItemDetails.prototype.setBacklinksTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int64 backlinks_nofollow = 8;
 * @return {number}
 */
proto.search.content.ArticleItemDetails.prototype.getBacklinksNofollow = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.search.content.ArticleItemDetails} returns this
 */
proto.search.content.ArticleItemDetails.prototype.setBacklinksNofollow = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int64 backlinks_dofollow = 9;
 * @return {number}
 */
proto.search.content.ArticleItemDetails.prototype.getBacklinksDofollow = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.search.content.ArticleItemDetails} returns this
 */
proto.search.content.ArticleItemDetails.prototype.setBacklinksDofollow = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int64 backlinks_other = 10;
 * @return {number}
 */
proto.search.content.ArticleItemDetails.prototype.getBacklinksOther = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.search.content.ArticleItemDetails} returns this
 */
proto.search.content.ArticleItemDetails.prototype.setBacklinksOther = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int32 domain_spam_score = 11;
 * @return {number}
 */
proto.search.content.ArticleItemDetails.prototype.getDomainSpamScore = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.search.content.ArticleItemDetails} returns this
 */
proto.search.content.ArticleItemDetails.prototype.setDomainSpamScore = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int32 page_authority = 12;
 * @return {number}
 */
proto.search.content.ArticleItemDetails.prototype.getPageAuthority = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.search.content.ArticleItemDetails} returns this
 */
proto.search.content.ArticleItemDetails.prototype.setPageAuthority = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional int64 publishing_date = 13;
 * @return {number}
 */
proto.search.content.ArticleItemDetails.prototype.getPublishingDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.search.content.ArticleItemDetails} returns this
 */
proto.search.content.ArticleItemDetails.prototype.setPublishingDate = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional bool include_paid_metrics = 14;
 * @return {boolean}
 */
proto.search.content.ArticleItemDetails.prototype.getIncludePaidMetrics = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.search.content.ArticleItemDetails} returns this
 */
proto.search.content.ArticleItemDetails.prototype.setIncludePaidMetrics = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.search.content.BacklinksItemDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.search.content.BacklinksItemDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.search.content.BacklinksItemDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.search.content.BacklinksItemDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    domainRating: jspb.Message.getFieldWithDefault(msg, 1, 0),
    firstSeenDate: jspb.Message.getFieldWithDefault(msg, 2, 0),
    urlTo: jspb.Message.getFieldWithDefault(msg, 3, ""),
    textPre: jspb.Message.getFieldWithDefault(msg, 4, ""),
    anchor: jspb.Message.getFieldWithDefault(msg, 5, ""),
    textPost: jspb.Message.getFieldWithDefault(msg, 6, ""),
    noFollow: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    domainTraffic: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    domainTrafficValue: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    domainTrafficKeywordsNumber: jspb.Message.getFieldWithDefault(msg, 10, 0),
    referringDomains: jspb.Message.getFieldWithDefault(msg, 11, 0),
    backlinksTotal: jspb.Message.getFieldWithDefault(msg, 12, 0),
    backlinksNofollow: jspb.Message.getFieldWithDefault(msg, 13, 0),
    backlinksDofollow: jspb.Message.getFieldWithDefault(msg, 14, 0),
    domainSpamScore: jspb.Message.getFieldWithDefault(msg, 15, 0),
    pageAuthority: jspb.Message.getFieldWithDefault(msg, 16, 0),
    backlinksOther: jspb.Message.getFieldWithDefault(msg, 17, 0),
    includePaidMetrics: jspb.Message.getBooleanFieldWithDefault(msg, 18, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.search.content.BacklinksItemDetails}
 */
proto.search.content.BacklinksItemDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.search.content.BacklinksItemDetails;
  return proto.search.content.BacklinksItemDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.search.content.BacklinksItemDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.search.content.BacklinksItemDetails}
 */
proto.search.content.BacklinksItemDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDomainRating(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFirstSeenDate(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrlTo(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTextPre(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAnchor(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setTextPost(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNoFollow(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDomainTraffic(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDomainTrafficValue(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDomainTrafficKeywordsNumber(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setReferringDomains(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBacklinksTotal(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBacklinksNofollow(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBacklinksDofollow(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDomainSpamScore(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageAuthority(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBacklinksOther(value);
      break;
    case 18:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludePaidMetrics(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.search.content.BacklinksItemDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.search.content.BacklinksItemDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.search.content.BacklinksItemDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.search.content.BacklinksItemDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDomainRating();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getFirstSeenDate();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getUrlTo();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTextPre();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAnchor();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getTextPost();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getNoFollow();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getDomainTraffic();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getDomainTrafficValue();
  if (f !== 0.0) {
    writer.writeDouble(
      9,
      f
    );
  }
  f = message.getDomainTrafficKeywordsNumber();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getReferringDomains();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getBacklinksTotal();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
  f = message.getBacklinksNofollow();
  if (f !== 0) {
    writer.writeInt64(
      13,
      f
    );
  }
  f = message.getBacklinksDofollow();
  if (f !== 0) {
    writer.writeInt64(
      14,
      f
    );
  }
  f = message.getDomainSpamScore();
  if (f !== 0) {
    writer.writeInt32(
      15,
      f
    );
  }
  f = message.getPageAuthority();
  if (f !== 0) {
    writer.writeInt32(
      16,
      f
    );
  }
  f = message.getBacklinksOther();
  if (f !== 0) {
    writer.writeInt64(
      17,
      f
    );
  }
  f = message.getIncludePaidMetrics();
  if (f) {
    writer.writeBool(
      18,
      f
    );
  }
};


/**
 * optional int64 domain_rating = 1;
 * @return {number}
 */
proto.search.content.BacklinksItemDetails.prototype.getDomainRating = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.search.content.BacklinksItemDetails} returns this
 */
proto.search.content.BacklinksItemDetails.prototype.setDomainRating = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 first_seen_date = 2;
 * @return {number}
 */
proto.search.content.BacklinksItemDetails.prototype.getFirstSeenDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.search.content.BacklinksItemDetails} returns this
 */
proto.search.content.BacklinksItemDetails.prototype.setFirstSeenDate = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string url_to = 3;
 * @return {string}
 */
proto.search.content.BacklinksItemDetails.prototype.getUrlTo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.search.content.BacklinksItemDetails} returns this
 */
proto.search.content.BacklinksItemDetails.prototype.setUrlTo = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string text_pre = 4;
 * @return {string}
 */
proto.search.content.BacklinksItemDetails.prototype.getTextPre = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.search.content.BacklinksItemDetails} returns this
 */
proto.search.content.BacklinksItemDetails.prototype.setTextPre = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string anchor = 5;
 * @return {string}
 */
proto.search.content.BacklinksItemDetails.prototype.getAnchor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.search.content.BacklinksItemDetails} returns this
 */
proto.search.content.BacklinksItemDetails.prototype.setAnchor = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string text_post = 6;
 * @return {string}
 */
proto.search.content.BacklinksItemDetails.prototype.getTextPost = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.search.content.BacklinksItemDetails} returns this
 */
proto.search.content.BacklinksItemDetails.prototype.setTextPost = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional bool no_follow = 7;
 * @return {boolean}
 */
proto.search.content.BacklinksItemDetails.prototype.getNoFollow = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.search.content.BacklinksItemDetails} returns this
 */
proto.search.content.BacklinksItemDetails.prototype.setNoFollow = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional double domain_traffic = 8;
 * @return {number}
 */
proto.search.content.BacklinksItemDetails.prototype.getDomainTraffic = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.search.content.BacklinksItemDetails} returns this
 */
proto.search.content.BacklinksItemDetails.prototype.setDomainTraffic = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional double domain_traffic_value = 9;
 * @return {number}
 */
proto.search.content.BacklinksItemDetails.prototype.getDomainTrafficValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.search.content.BacklinksItemDetails} returns this
 */
proto.search.content.BacklinksItemDetails.prototype.setDomainTrafficValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional int64 domain_traffic_keywords_number = 10;
 * @return {number}
 */
proto.search.content.BacklinksItemDetails.prototype.getDomainTrafficKeywordsNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.search.content.BacklinksItemDetails} returns this
 */
proto.search.content.BacklinksItemDetails.prototype.setDomainTrafficKeywordsNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int64 referring_domains = 11;
 * @return {number}
 */
proto.search.content.BacklinksItemDetails.prototype.getReferringDomains = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.search.content.BacklinksItemDetails} returns this
 */
proto.search.content.BacklinksItemDetails.prototype.setReferringDomains = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int64 backlinks_total = 12;
 * @return {number}
 */
proto.search.content.BacklinksItemDetails.prototype.getBacklinksTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.search.content.BacklinksItemDetails} returns this
 */
proto.search.content.BacklinksItemDetails.prototype.setBacklinksTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional int64 backlinks_nofollow = 13;
 * @return {number}
 */
proto.search.content.BacklinksItemDetails.prototype.getBacklinksNofollow = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.search.content.BacklinksItemDetails} returns this
 */
proto.search.content.BacklinksItemDetails.prototype.setBacklinksNofollow = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional int64 backlinks_dofollow = 14;
 * @return {number}
 */
proto.search.content.BacklinksItemDetails.prototype.getBacklinksDofollow = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.search.content.BacklinksItemDetails} returns this
 */
proto.search.content.BacklinksItemDetails.prototype.setBacklinksDofollow = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional int32 domain_spam_score = 15;
 * @return {number}
 */
proto.search.content.BacklinksItemDetails.prototype.getDomainSpamScore = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.search.content.BacklinksItemDetails} returns this
 */
proto.search.content.BacklinksItemDetails.prototype.setDomainSpamScore = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional int32 page_authority = 16;
 * @return {number}
 */
proto.search.content.BacklinksItemDetails.prototype.getPageAuthority = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.search.content.BacklinksItemDetails} returns this
 */
proto.search.content.BacklinksItemDetails.prototype.setPageAuthority = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional int64 backlinks_other = 17;
 * @return {number}
 */
proto.search.content.BacklinksItemDetails.prototype.getBacklinksOther = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.search.content.BacklinksItemDetails} returns this
 */
proto.search.content.BacklinksItemDetails.prototype.setBacklinksOther = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * optional bool include_paid_metrics = 18;
 * @return {boolean}
 */
proto.search.content.BacklinksItemDetails.prototype.getIncludePaidMetrics = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 18, false));
};


/**
 * @param {boolean} value
 * @return {!proto.search.content.BacklinksItemDetails} returns this
 */
proto.search.content.BacklinksItemDetails.prototype.setIncludePaidMetrics = function(value) {
  return jspb.Message.setProto3BooleanField(this, 18, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.search.content.PodcastItemDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.search.content.PodcastItemDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.search.content.PodcastItemDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.search.content.PodcastItemDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    rrsUrl: jspb.Message.getFieldWithDefault(msg, 1, ""),
    imageUrl: jspb.Message.getFieldWithDefault(msg, 2, ""),
    iTunesUrl: jspb.Message.getFieldWithDefault(msg, 3, ""),
    publishingDate: jspb.Message.getFieldWithDefault(msg, 4, 0),
    publisherName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    listenScore: jspb.Message.getFieldWithDefault(msg, 6, 0),
    globalRank: jspb.Message.getFieldWithDefault(msg, 7, ""),
    totalEpisodes: jspb.Message.getFieldWithDefault(msg, 8, 0),
    podcastUid: jspb.Message.getFieldWithDefault(msg, 9, ""),
    publisherEmail: jspb.Message.getFieldWithDefault(msg, 10, ""),
    hasGuestInterviews: jspb.Message.getBooleanFieldWithDefault(msg, 11, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.search.content.PodcastItemDetails}
 */
proto.search.content.PodcastItemDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.search.content.PodcastItemDetails;
  return proto.search.content.PodcastItemDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.search.content.PodcastItemDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.search.content.PodcastItemDetails}
 */
proto.search.content.PodcastItemDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRrsUrl(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageUrl(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setITunesUrl(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPublishingDate(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPublisherName(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setListenScore(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setGlobalRank(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalEpisodes(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setPodcastUid(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setPublisherEmail(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasGuestInterviews(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.search.content.PodcastItemDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.search.content.PodcastItemDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.search.content.PodcastItemDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.search.content.PodcastItemDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRrsUrl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getImageUrl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getITunesUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPublishingDate();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getPublisherName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getListenScore();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getGlobalRank();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getTotalEpisodes();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getPodcastUid();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getPublisherEmail();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getHasGuestInterviews();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
};


/**
 * optional string rrs_url = 1;
 * @return {string}
 */
proto.search.content.PodcastItemDetails.prototype.getRrsUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.search.content.PodcastItemDetails} returns this
 */
proto.search.content.PodcastItemDetails.prototype.setRrsUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string image_url = 2;
 * @return {string}
 */
proto.search.content.PodcastItemDetails.prototype.getImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.search.content.PodcastItemDetails} returns this
 */
proto.search.content.PodcastItemDetails.prototype.setImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string i_tunes_url = 3;
 * @return {string}
 */
proto.search.content.PodcastItemDetails.prototype.getITunesUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.search.content.PodcastItemDetails} returns this
 */
proto.search.content.PodcastItemDetails.prototype.setITunesUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 publishing_date = 4;
 * @return {number}
 */
proto.search.content.PodcastItemDetails.prototype.getPublishingDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.search.content.PodcastItemDetails} returns this
 */
proto.search.content.PodcastItemDetails.prototype.setPublishingDate = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string publisher_name = 5;
 * @return {string}
 */
proto.search.content.PodcastItemDetails.prototype.getPublisherName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.search.content.PodcastItemDetails} returns this
 */
proto.search.content.PodcastItemDetails.prototype.setPublisherName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int32 listen_score = 6;
 * @return {number}
 */
proto.search.content.PodcastItemDetails.prototype.getListenScore = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.search.content.PodcastItemDetails} returns this
 */
proto.search.content.PodcastItemDetails.prototype.setListenScore = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string global_rank = 7;
 * @return {string}
 */
proto.search.content.PodcastItemDetails.prototype.getGlobalRank = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.search.content.PodcastItemDetails} returns this
 */
proto.search.content.PodcastItemDetails.prototype.setGlobalRank = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int32 total_episodes = 8;
 * @return {number}
 */
proto.search.content.PodcastItemDetails.prototype.getTotalEpisodes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.search.content.PodcastItemDetails} returns this
 */
proto.search.content.PodcastItemDetails.prototype.setTotalEpisodes = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional string podcast_uid = 9;
 * @return {string}
 */
proto.search.content.PodcastItemDetails.prototype.getPodcastUid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.search.content.PodcastItemDetails} returns this
 */
proto.search.content.PodcastItemDetails.prototype.setPodcastUid = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string publisher_email = 10;
 * @return {string}
 */
proto.search.content.PodcastItemDetails.prototype.getPublisherEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.search.content.PodcastItemDetails} returns this
 */
proto.search.content.PodcastItemDetails.prototype.setPublisherEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional bool has_guest_interviews = 11;
 * @return {boolean}
 */
proto.search.content.PodcastItemDetails.prototype.getHasGuestInterviews = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.search.content.PodcastItemDetails} returns this
 */
proto.search.content.PodcastItemDetails.prototype.setHasGuestInterviews = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.search.content.EpisodeItemDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.search.content.EpisodeItemDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.search.content.EpisodeItemDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.search.content.EpisodeItemDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    podcastUid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    rrsUrl: jspb.Message.getFieldWithDefault(msg, 2, ""),
    audioUrl: jspb.Message.getFieldWithDefault(msg, 3, ""),
    imageUrl: jspb.Message.getFieldWithDefault(msg, 4, ""),
    iTunesUrl: jspb.Message.getFieldWithDefault(msg, 5, ""),
    publishingDate: jspb.Message.getFieldWithDefault(msg, 6, 0),
    publisherName: jspb.Message.getFieldWithDefault(msg, 7, ""),
    listenScore: jspb.Message.getFieldWithDefault(msg, 8, 0),
    globalRank: jspb.Message.getFieldWithDefault(msg, 9, ""),
    publisherEmail: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.search.content.EpisodeItemDetails}
 */
proto.search.content.EpisodeItemDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.search.content.EpisodeItemDetails;
  return proto.search.content.EpisodeItemDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.search.content.EpisodeItemDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.search.content.EpisodeItemDetails}
 */
proto.search.content.EpisodeItemDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPodcastUid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRrsUrl(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAudioUrl(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageUrl(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setITunesUrl(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPublishingDate(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPublisherName(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setListenScore(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setGlobalRank(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setPublisherEmail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.search.content.EpisodeItemDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.search.content.EpisodeItemDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.search.content.EpisodeItemDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.search.content.EpisodeItemDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPodcastUid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRrsUrl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAudioUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getImageUrl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getITunesUrl();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPublishingDate();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getPublisherName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getListenScore();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getGlobalRank();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getPublisherEmail();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * optional string podcast_uid = 1;
 * @return {string}
 */
proto.search.content.EpisodeItemDetails.prototype.getPodcastUid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.search.content.EpisodeItemDetails} returns this
 */
proto.search.content.EpisodeItemDetails.prototype.setPodcastUid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string rrs_url = 2;
 * @return {string}
 */
proto.search.content.EpisodeItemDetails.prototype.getRrsUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.search.content.EpisodeItemDetails} returns this
 */
proto.search.content.EpisodeItemDetails.prototype.setRrsUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string audio_url = 3;
 * @return {string}
 */
proto.search.content.EpisodeItemDetails.prototype.getAudioUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.search.content.EpisodeItemDetails} returns this
 */
proto.search.content.EpisodeItemDetails.prototype.setAudioUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string image_url = 4;
 * @return {string}
 */
proto.search.content.EpisodeItemDetails.prototype.getImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.search.content.EpisodeItemDetails} returns this
 */
proto.search.content.EpisodeItemDetails.prototype.setImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string i_tunes_url = 5;
 * @return {string}
 */
proto.search.content.EpisodeItemDetails.prototype.getITunesUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.search.content.EpisodeItemDetails} returns this
 */
proto.search.content.EpisodeItemDetails.prototype.setITunesUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int64 publishing_date = 6;
 * @return {number}
 */
proto.search.content.EpisodeItemDetails.prototype.getPublishingDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.search.content.EpisodeItemDetails} returns this
 */
proto.search.content.EpisodeItemDetails.prototype.setPublishingDate = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string publisher_name = 7;
 * @return {string}
 */
proto.search.content.EpisodeItemDetails.prototype.getPublisherName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.search.content.EpisodeItemDetails} returns this
 */
proto.search.content.EpisodeItemDetails.prototype.setPublisherName = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int32 listen_score = 8;
 * @return {number}
 */
proto.search.content.EpisodeItemDetails.prototype.getListenScore = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.search.content.EpisodeItemDetails} returns this
 */
proto.search.content.EpisodeItemDetails.prototype.setListenScore = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional string global_rank = 9;
 * @return {string}
 */
proto.search.content.EpisodeItemDetails.prototype.getGlobalRank = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.search.content.EpisodeItemDetails} returns this
 */
proto.search.content.EpisodeItemDetails.prototype.setGlobalRank = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string publisher_email = 10;
 * @return {string}
 */
proto.search.content.EpisodeItemDetails.prototype.getPublisherEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.search.content.EpisodeItemDetails} returns this
 */
proto.search.content.EpisodeItemDetails.prototype.setPublisherEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.search.content.TrendingContentItemDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.search.content.TrendingContentItemDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.search.content.TrendingContentItemDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.search.content.TrendingContentItemDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    pinterestSharesNumber: jspb.Message.getFieldWithDefault(msg, 1, 0),
    twitterSharesNumber: jspb.Message.getFieldWithDefault(msg, 2, 0),
    redditEngagementsNumber: jspb.Message.getFieldWithDefault(msg, 3, 0),
    facebookSharesNumber: jspb.Message.getFieldWithDefault(msg, 4, 0),
    updatedAtTimestamp: jspb.Message.getFieldWithDefault(msg, 5, 0),
    publishedAtTimestamp: jspb.Message.getFieldWithDefault(msg, 6, 0),
    totalShares: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.search.content.TrendingContentItemDetails}
 */
proto.search.content.TrendingContentItemDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.search.content.TrendingContentItemDetails;
  return proto.search.content.TrendingContentItemDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.search.content.TrendingContentItemDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.search.content.TrendingContentItemDetails}
 */
proto.search.content.TrendingContentItemDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPinterestSharesNumber(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTwitterSharesNumber(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRedditEngagementsNumber(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFacebookSharesNumber(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpdatedAtTimestamp(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPublishedAtTimestamp(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalShares(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.search.content.TrendingContentItemDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.search.content.TrendingContentItemDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.search.content.TrendingContentItemDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.search.content.TrendingContentItemDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPinterestSharesNumber();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTwitterSharesNumber();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getRedditEngagementsNumber();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getFacebookSharesNumber();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getUpdatedAtTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getPublishedAtTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getTotalShares();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
};


/**
 * optional int64 pinterest_shares_number = 1;
 * @return {number}
 */
proto.search.content.TrendingContentItemDetails.prototype.getPinterestSharesNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.search.content.TrendingContentItemDetails} returns this
 */
proto.search.content.TrendingContentItemDetails.prototype.setPinterestSharesNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 twitter_shares_number = 2;
 * @return {number}
 */
proto.search.content.TrendingContentItemDetails.prototype.getTwitterSharesNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.search.content.TrendingContentItemDetails} returns this
 */
proto.search.content.TrendingContentItemDetails.prototype.setTwitterSharesNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 reddit_engagements_number = 3;
 * @return {number}
 */
proto.search.content.TrendingContentItemDetails.prototype.getRedditEngagementsNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.search.content.TrendingContentItemDetails} returns this
 */
proto.search.content.TrendingContentItemDetails.prototype.setRedditEngagementsNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 facebook_shares_number = 4;
 * @return {number}
 */
proto.search.content.TrendingContentItemDetails.prototype.getFacebookSharesNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.search.content.TrendingContentItemDetails} returns this
 */
proto.search.content.TrendingContentItemDetails.prototype.setFacebookSharesNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 updated_at_timestamp = 5;
 * @return {number}
 */
proto.search.content.TrendingContentItemDetails.prototype.getUpdatedAtTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.search.content.TrendingContentItemDetails} returns this
 */
proto.search.content.TrendingContentItemDetails.prototype.setUpdatedAtTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 published_at_timestamp = 6;
 * @return {number}
 */
proto.search.content.TrendingContentItemDetails.prototype.getPublishedAtTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.search.content.TrendingContentItemDetails} returns this
 */
proto.search.content.TrendingContentItemDetails.prototype.setPublishedAtTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 total_shares = 7;
 * @return {number}
 */
proto.search.content.TrendingContentItemDetails.prototype.getTotalShares = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.search.content.TrendingContentItemDetails} returns this
 */
proto.search.content.TrendingContentItemDetails.prototype.setTotalShares = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.search.content.UrlItem.prototype.toObject = function(opt_includeInstance) {
  return proto.search.content.UrlItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.search.content.UrlItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.search.content.UrlItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    url: jspb.Message.getFieldWithDefault(msg, 1, ""),
    totalItems: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.search.content.UrlItem}
 */
proto.search.content.UrlItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.search.content.UrlItem;
  return proto.search.content.UrlItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.search.content.UrlItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.search.content.UrlItem}
 */
proto.search.content.UrlItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.search.content.UrlItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.search.content.UrlItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.search.content.UrlItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.search.content.UrlItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTotalItems();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string url = 1;
 * @return {string}
 */
proto.search.content.UrlItem.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.search.content.UrlItem} returns this
 */
proto.search.content.UrlItem.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 total_items = 2;
 * @return {number}
 */
proto.search.content.UrlItem.prototype.getTotalItems = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.search.content.UrlItem} returns this
 */
proto.search.content.UrlItem.prototype.setTotalItems = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.search.content.SearchItem.oneofGroups_ = [[11,12,13,14,15,16,17,18,19,20]];

/**
 * @enum {number}
 */
proto.search.content.SearchItem.DetailsCase = {
  DETAILS_NOT_SET: 0,
  GOOGLE_SEARCH: 11,
  BING_NEWS: 12,
  PODCAST: 13,
  EPISODE: 14,
  TRENDING_CONTENT: 15,
  BING_SEARCH: 16,
  AHREF_BACKLINKS: 17,
  SEMRUSH_BACKLINKS: 18,
  MOZ_BACKLINKS: 19,
  GOOGLE_NEWS: 20
};

/**
 * @return {proto.search.content.SearchItem.DetailsCase}
 */
proto.search.content.SearchItem.prototype.getDetailsCase = function() {
  return /** @type {proto.search.content.SearchItem.DetailsCase} */(jspb.Message.computeOneofCase(this, proto.search.content.SearchItem.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.search.content.SearchItem.prototype.toObject = function(opt_includeInstance) {
  return proto.search.content.SearchItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.search.content.SearchItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.search.content.SearchItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    uid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    url: jspb.Message.getFieldWithDefault(msg, 2, ""),
    title: jspb.Message.getFieldWithDefault(msg, 3, ""),
    description: jspb.Message.getFieldWithDefault(msg, 4, ""),
    type: jspb.Message.getFieldWithDefault(msg, 5, 0),
    lastVisitor: (f = msg.getLastVisitor()) && proto.search.content.UserActor.toObject(includeInstance, f),
    lastLauncher: (f = msg.getLastLauncher()) && proto.search.content.UserActor.toObject(includeInstance, f),
    totalItems: jspb.Message.getFieldWithDefault(msg, 8, 0),
    nextPageAvailable: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    selected: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    googleSearch: (f = msg.getGoogleSearch()) && proto.search.content.ArticleItemDetails.toObject(includeInstance, f),
    bingNews: (f = msg.getBingNews()) && proto.search.content.ArticleItemDetails.toObject(includeInstance, f),
    podcast: (f = msg.getPodcast()) && proto.search.content.PodcastItemDetails.toObject(includeInstance, f),
    episode: (f = msg.getEpisode()) && proto.search.content.EpisodeItemDetails.toObject(includeInstance, f),
    trendingContent: (f = msg.getTrendingContent()) && proto.search.content.TrendingContentItemDetails.toObject(includeInstance, f),
    bingSearch: (f = msg.getBingSearch()) && proto.search.content.ArticleItemDetails.toObject(includeInstance, f),
    ahrefBacklinks: (f = msg.getAhrefBacklinks()) && proto.search.content.BacklinksItemDetails.toObject(includeInstance, f),
    semrushBacklinks: (f = msg.getSemrushBacklinks()) && proto.search.content.BacklinksItemDetails.toObject(includeInstance, f),
    mozBacklinks: (f = msg.getMozBacklinks()) && proto.search.content.BacklinksItemDetails.toObject(includeInstance, f),
    googleNews: (f = msg.getGoogleNews()) && proto.search.content.ArticleItemDetails.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.search.content.SearchItem}
 */
proto.search.content.SearchItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.search.content.SearchItem;
  return proto.search.content.SearchItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.search.content.SearchItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.search.content.SearchItem}
 */
proto.search.content.SearchItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 5:
      var value = /** @type {!proto.common.ContentSearchType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 6:
      var value = new proto.search.content.UserActor;
      reader.readMessage(value,proto.search.content.UserActor.deserializeBinaryFromReader);
      msg.setLastVisitor(value);
      break;
    case 7:
      var value = new proto.search.content.UserActor;
      reader.readMessage(value,proto.search.content.UserActor.deserializeBinaryFromReader);
      msg.setLastLauncher(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalItems(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNextPageAvailable(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSelected(value);
      break;
    case 11:
      var value = new proto.search.content.ArticleItemDetails;
      reader.readMessage(value,proto.search.content.ArticleItemDetails.deserializeBinaryFromReader);
      msg.setGoogleSearch(value);
      break;
    case 12:
      var value = new proto.search.content.ArticleItemDetails;
      reader.readMessage(value,proto.search.content.ArticleItemDetails.deserializeBinaryFromReader);
      msg.setBingNews(value);
      break;
    case 13:
      var value = new proto.search.content.PodcastItemDetails;
      reader.readMessage(value,proto.search.content.PodcastItemDetails.deserializeBinaryFromReader);
      msg.setPodcast(value);
      break;
    case 14:
      var value = new proto.search.content.EpisodeItemDetails;
      reader.readMessage(value,proto.search.content.EpisodeItemDetails.deserializeBinaryFromReader);
      msg.setEpisode(value);
      break;
    case 15:
      var value = new proto.search.content.TrendingContentItemDetails;
      reader.readMessage(value,proto.search.content.TrendingContentItemDetails.deserializeBinaryFromReader);
      msg.setTrendingContent(value);
      break;
    case 16:
      var value = new proto.search.content.ArticleItemDetails;
      reader.readMessage(value,proto.search.content.ArticleItemDetails.deserializeBinaryFromReader);
      msg.setBingSearch(value);
      break;
    case 17:
      var value = new proto.search.content.BacklinksItemDetails;
      reader.readMessage(value,proto.search.content.BacklinksItemDetails.deserializeBinaryFromReader);
      msg.setAhrefBacklinks(value);
      break;
    case 18:
      var value = new proto.search.content.BacklinksItemDetails;
      reader.readMessage(value,proto.search.content.BacklinksItemDetails.deserializeBinaryFromReader);
      msg.setSemrushBacklinks(value);
      break;
    case 19:
      var value = new proto.search.content.BacklinksItemDetails;
      reader.readMessage(value,proto.search.content.BacklinksItemDetails.deserializeBinaryFromReader);
      msg.setMozBacklinks(value);
      break;
    case 20:
      var value = new proto.search.content.ArticleItemDetails;
      reader.readMessage(value,proto.search.content.ArticleItemDetails.deserializeBinaryFromReader);
      msg.setGoogleNews(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.search.content.SearchItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.search.content.SearchItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.search.content.SearchItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.search.content.SearchItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getLastVisitor();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.search.content.UserActor.serializeBinaryToWriter
    );
  }
  f = message.getLastLauncher();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.search.content.UserActor.serializeBinaryToWriter
    );
  }
  f = message.getTotalItems();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getNextPageAvailable();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getSelected();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getGoogleSearch();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.search.content.ArticleItemDetails.serializeBinaryToWriter
    );
  }
  f = message.getBingNews();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.search.content.ArticleItemDetails.serializeBinaryToWriter
    );
  }
  f = message.getPodcast();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.search.content.PodcastItemDetails.serializeBinaryToWriter
    );
  }
  f = message.getEpisode();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.search.content.EpisodeItemDetails.serializeBinaryToWriter
    );
  }
  f = message.getTrendingContent();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.search.content.TrendingContentItemDetails.serializeBinaryToWriter
    );
  }
  f = message.getBingSearch();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto.search.content.ArticleItemDetails.serializeBinaryToWriter
    );
  }
  f = message.getAhrefBacklinks();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      proto.search.content.BacklinksItemDetails.serializeBinaryToWriter
    );
  }
  f = message.getSemrushBacklinks();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      proto.search.content.BacklinksItemDetails.serializeBinaryToWriter
    );
  }
  f = message.getMozBacklinks();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      proto.search.content.BacklinksItemDetails.serializeBinaryToWriter
    );
  }
  f = message.getGoogleNews();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      proto.search.content.ArticleItemDetails.serializeBinaryToWriter
    );
  }
};


/**
 * optional string uid = 1;
 * @return {string}
 */
proto.search.content.SearchItem.prototype.getUid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.search.content.SearchItem} returns this
 */
proto.search.content.SearchItem.prototype.setUid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string url = 2;
 * @return {string}
 */
proto.search.content.SearchItem.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.search.content.SearchItem} returns this
 */
proto.search.content.SearchItem.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string title = 3;
 * @return {string}
 */
proto.search.content.SearchItem.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.search.content.SearchItem} returns this
 */
proto.search.content.SearchItem.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string description = 4;
 * @return {string}
 */
proto.search.content.SearchItem.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.search.content.SearchItem} returns this
 */
proto.search.content.SearchItem.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional common.ContentSearchType type = 5;
 * @return {!proto.common.ContentSearchType}
 */
proto.search.content.SearchItem.prototype.getType = function() {
  return /** @type {!proto.common.ContentSearchType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.common.ContentSearchType} value
 * @return {!proto.search.content.SearchItem} returns this
 */
proto.search.content.SearchItem.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional UserActor last_visitor = 6;
 * @return {?proto.search.content.UserActor}
 */
proto.search.content.SearchItem.prototype.getLastVisitor = function() {
  return /** @type{?proto.search.content.UserActor} */ (
    jspb.Message.getWrapperField(this, proto.search.content.UserActor, 6));
};


/**
 * @param {?proto.search.content.UserActor|undefined} value
 * @return {!proto.search.content.SearchItem} returns this
*/
proto.search.content.SearchItem.prototype.setLastVisitor = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.search.content.SearchItem} returns this
 */
proto.search.content.SearchItem.prototype.clearLastVisitor = function() {
  return this.setLastVisitor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.search.content.SearchItem.prototype.hasLastVisitor = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional UserActor last_launcher = 7;
 * @return {?proto.search.content.UserActor}
 */
proto.search.content.SearchItem.prototype.getLastLauncher = function() {
  return /** @type{?proto.search.content.UserActor} */ (
    jspb.Message.getWrapperField(this, proto.search.content.UserActor, 7));
};


/**
 * @param {?proto.search.content.UserActor|undefined} value
 * @return {!proto.search.content.SearchItem} returns this
*/
proto.search.content.SearchItem.prototype.setLastLauncher = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.search.content.SearchItem} returns this
 */
proto.search.content.SearchItem.prototype.clearLastLauncher = function() {
  return this.setLastLauncher(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.search.content.SearchItem.prototype.hasLastLauncher = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional int64 total_items = 8;
 * @return {number}
 */
proto.search.content.SearchItem.prototype.getTotalItems = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.search.content.SearchItem} returns this
 */
proto.search.content.SearchItem.prototype.setTotalItems = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional bool next_page_available = 9;
 * @return {boolean}
 */
proto.search.content.SearchItem.prototype.getNextPageAvailable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.search.content.SearchItem} returns this
 */
proto.search.content.SearchItem.prototype.setNextPageAvailable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional bool selected = 10;
 * @return {boolean}
 */
proto.search.content.SearchItem.prototype.getSelected = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.search.content.SearchItem} returns this
 */
proto.search.content.SearchItem.prototype.setSelected = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional ArticleItemDetails google_search = 11;
 * @return {?proto.search.content.ArticleItemDetails}
 */
proto.search.content.SearchItem.prototype.getGoogleSearch = function() {
  return /** @type{?proto.search.content.ArticleItemDetails} */ (
    jspb.Message.getWrapperField(this, proto.search.content.ArticleItemDetails, 11));
};


/**
 * @param {?proto.search.content.ArticleItemDetails|undefined} value
 * @return {!proto.search.content.SearchItem} returns this
*/
proto.search.content.SearchItem.prototype.setGoogleSearch = function(value) {
  return jspb.Message.setOneofWrapperField(this, 11, proto.search.content.SearchItem.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.search.content.SearchItem} returns this
 */
proto.search.content.SearchItem.prototype.clearGoogleSearch = function() {
  return this.setGoogleSearch(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.search.content.SearchItem.prototype.hasGoogleSearch = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional ArticleItemDetails bing_news = 12;
 * @return {?proto.search.content.ArticleItemDetails}
 */
proto.search.content.SearchItem.prototype.getBingNews = function() {
  return /** @type{?proto.search.content.ArticleItemDetails} */ (
    jspb.Message.getWrapperField(this, proto.search.content.ArticleItemDetails, 12));
};


/**
 * @param {?proto.search.content.ArticleItemDetails|undefined} value
 * @return {!proto.search.content.SearchItem} returns this
*/
proto.search.content.SearchItem.prototype.setBingNews = function(value) {
  return jspb.Message.setOneofWrapperField(this, 12, proto.search.content.SearchItem.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.search.content.SearchItem} returns this
 */
proto.search.content.SearchItem.prototype.clearBingNews = function() {
  return this.setBingNews(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.search.content.SearchItem.prototype.hasBingNews = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional PodcastItemDetails podcast = 13;
 * @return {?proto.search.content.PodcastItemDetails}
 */
proto.search.content.SearchItem.prototype.getPodcast = function() {
  return /** @type{?proto.search.content.PodcastItemDetails} */ (
    jspb.Message.getWrapperField(this, proto.search.content.PodcastItemDetails, 13));
};


/**
 * @param {?proto.search.content.PodcastItemDetails|undefined} value
 * @return {!proto.search.content.SearchItem} returns this
*/
proto.search.content.SearchItem.prototype.setPodcast = function(value) {
  return jspb.Message.setOneofWrapperField(this, 13, proto.search.content.SearchItem.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.search.content.SearchItem} returns this
 */
proto.search.content.SearchItem.prototype.clearPodcast = function() {
  return this.setPodcast(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.search.content.SearchItem.prototype.hasPodcast = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional EpisodeItemDetails episode = 14;
 * @return {?proto.search.content.EpisodeItemDetails}
 */
proto.search.content.SearchItem.prototype.getEpisode = function() {
  return /** @type{?proto.search.content.EpisodeItemDetails} */ (
    jspb.Message.getWrapperField(this, proto.search.content.EpisodeItemDetails, 14));
};


/**
 * @param {?proto.search.content.EpisodeItemDetails|undefined} value
 * @return {!proto.search.content.SearchItem} returns this
*/
proto.search.content.SearchItem.prototype.setEpisode = function(value) {
  return jspb.Message.setOneofWrapperField(this, 14, proto.search.content.SearchItem.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.search.content.SearchItem} returns this
 */
proto.search.content.SearchItem.prototype.clearEpisode = function() {
  return this.setEpisode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.search.content.SearchItem.prototype.hasEpisode = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional TrendingContentItemDetails trending_content = 15;
 * @return {?proto.search.content.TrendingContentItemDetails}
 */
proto.search.content.SearchItem.prototype.getTrendingContent = function() {
  return /** @type{?proto.search.content.TrendingContentItemDetails} */ (
    jspb.Message.getWrapperField(this, proto.search.content.TrendingContentItemDetails, 15));
};


/**
 * @param {?proto.search.content.TrendingContentItemDetails|undefined} value
 * @return {!proto.search.content.SearchItem} returns this
*/
proto.search.content.SearchItem.prototype.setTrendingContent = function(value) {
  return jspb.Message.setOneofWrapperField(this, 15, proto.search.content.SearchItem.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.search.content.SearchItem} returns this
 */
proto.search.content.SearchItem.prototype.clearTrendingContent = function() {
  return this.setTrendingContent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.search.content.SearchItem.prototype.hasTrendingContent = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional ArticleItemDetails bing_search = 16;
 * @return {?proto.search.content.ArticleItemDetails}
 */
proto.search.content.SearchItem.prototype.getBingSearch = function() {
  return /** @type{?proto.search.content.ArticleItemDetails} */ (
    jspb.Message.getWrapperField(this, proto.search.content.ArticleItemDetails, 16));
};


/**
 * @param {?proto.search.content.ArticleItemDetails|undefined} value
 * @return {!proto.search.content.SearchItem} returns this
*/
proto.search.content.SearchItem.prototype.setBingSearch = function(value) {
  return jspb.Message.setOneofWrapperField(this, 16, proto.search.content.SearchItem.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.search.content.SearchItem} returns this
 */
proto.search.content.SearchItem.prototype.clearBingSearch = function() {
  return this.setBingSearch(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.search.content.SearchItem.prototype.hasBingSearch = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional BacklinksItemDetails ahref_backlinks = 17;
 * @return {?proto.search.content.BacklinksItemDetails}
 */
proto.search.content.SearchItem.prototype.getAhrefBacklinks = function() {
  return /** @type{?proto.search.content.BacklinksItemDetails} */ (
    jspb.Message.getWrapperField(this, proto.search.content.BacklinksItemDetails, 17));
};


/**
 * @param {?proto.search.content.BacklinksItemDetails|undefined} value
 * @return {!proto.search.content.SearchItem} returns this
*/
proto.search.content.SearchItem.prototype.setAhrefBacklinks = function(value) {
  return jspb.Message.setOneofWrapperField(this, 17, proto.search.content.SearchItem.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.search.content.SearchItem} returns this
 */
proto.search.content.SearchItem.prototype.clearAhrefBacklinks = function() {
  return this.setAhrefBacklinks(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.search.content.SearchItem.prototype.hasAhrefBacklinks = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional BacklinksItemDetails semrush_backlinks = 18;
 * @return {?proto.search.content.BacklinksItemDetails}
 */
proto.search.content.SearchItem.prototype.getSemrushBacklinks = function() {
  return /** @type{?proto.search.content.BacklinksItemDetails} */ (
    jspb.Message.getWrapperField(this, proto.search.content.BacklinksItemDetails, 18));
};


/**
 * @param {?proto.search.content.BacklinksItemDetails|undefined} value
 * @return {!proto.search.content.SearchItem} returns this
*/
proto.search.content.SearchItem.prototype.setSemrushBacklinks = function(value) {
  return jspb.Message.setOneofWrapperField(this, 18, proto.search.content.SearchItem.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.search.content.SearchItem} returns this
 */
proto.search.content.SearchItem.prototype.clearSemrushBacklinks = function() {
  return this.setSemrushBacklinks(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.search.content.SearchItem.prototype.hasSemrushBacklinks = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional BacklinksItemDetails moz_backlinks = 19;
 * @return {?proto.search.content.BacklinksItemDetails}
 */
proto.search.content.SearchItem.prototype.getMozBacklinks = function() {
  return /** @type{?proto.search.content.BacklinksItemDetails} */ (
    jspb.Message.getWrapperField(this, proto.search.content.BacklinksItemDetails, 19));
};


/**
 * @param {?proto.search.content.BacklinksItemDetails|undefined} value
 * @return {!proto.search.content.SearchItem} returns this
*/
proto.search.content.SearchItem.prototype.setMozBacklinks = function(value) {
  return jspb.Message.setOneofWrapperField(this, 19, proto.search.content.SearchItem.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.search.content.SearchItem} returns this
 */
proto.search.content.SearchItem.prototype.clearMozBacklinks = function() {
  return this.setMozBacklinks(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.search.content.SearchItem.prototype.hasMozBacklinks = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional ArticleItemDetails google_news = 20;
 * @return {?proto.search.content.ArticleItemDetails}
 */
proto.search.content.SearchItem.prototype.getGoogleNews = function() {
  return /** @type{?proto.search.content.ArticleItemDetails} */ (
    jspb.Message.getWrapperField(this, proto.search.content.ArticleItemDetails, 20));
};


/**
 * @param {?proto.search.content.ArticleItemDetails|undefined} value
 * @return {!proto.search.content.SearchItem} returns this
*/
proto.search.content.SearchItem.prototype.setGoogleNews = function(value) {
  return jspb.Message.setOneofWrapperField(this, 20, proto.search.content.SearchItem.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.search.content.SearchItem} returns this
 */
proto.search.content.SearchItem.prototype.clearGoogleNews = function() {
  return this.setGoogleNews(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.search.content.SearchItem.prototype.hasGoogleNews = function() {
  return jspb.Message.getField(this, 20) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.search.content.UidRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.search.content.UidRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.search.content.UidRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.search.content.UidRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    uid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.search.content.UidRequest}
 */
proto.search.content.UidRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.search.content.UidRequest;
  return proto.search.content.UidRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.search.content.UidRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.search.content.UidRequest}
 */
proto.search.content.UidRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.search.content.UidRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.search.content.UidRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.search.content.UidRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.search.content.UidRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string uid = 1;
 * @return {string}
 */
proto.search.content.UidRequest.prototype.getUid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.search.content.UidRequest} returns this
 */
proto.search.content.UidRequest.prototype.setUid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.search.content.AudioDetailsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.search.content.AudioDetailsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.search.content.AudioDetailsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.search.content.AudioDetailsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    uid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.search.content.AudioDetailsRequest}
 */
proto.search.content.AudioDetailsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.search.content.AudioDetailsRequest;
  return proto.search.content.AudioDetailsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.search.content.AudioDetailsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.search.content.AudioDetailsRequest}
 */
proto.search.content.AudioDetailsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUid(value);
      break;
    case 2:
      var value = /** @type {!proto.common.ContentSearchType} */ (reader.readEnum());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.search.content.AudioDetailsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.search.content.AudioDetailsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.search.content.AudioDetailsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.search.content.AudioDetailsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string uid = 1;
 * @return {string}
 */
proto.search.content.AudioDetailsRequest.prototype.getUid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.search.content.AudioDetailsRequest} returns this
 */
proto.search.content.AudioDetailsRequest.prototype.setUid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional common.ContentSearchType type = 2;
 * @return {!proto.common.ContentSearchType}
 */
proto.search.content.AudioDetailsRequest.prototype.getType = function() {
  return /** @type {!proto.common.ContentSearchType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.common.ContentSearchType} value
 * @return {!proto.search.content.AudioDetailsRequest} returns this
 */
proto.search.content.AudioDetailsRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.search.content.SharedLinkSearchItem.repeatedFields_ = [1,2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.search.content.SharedLinkSearchItem.prototype.toObject = function(opt_includeInstance) {
  return proto.search.content.SharedLinkSearchItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.search.content.SharedLinkSearchItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.search.content.SharedLinkSearchItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    linksList: jspb.Message.toObjectList(msg.getLinksList(),
    proto.search.content.SharedLinkSearchItem.Link.toObject, includeInstance),
    domainsList: jspb.Message.toObjectList(msg.getDomainsList(),
    proto.search.content.SharedLinkSearchItem.Domain.toObject, includeInstance),
    topicsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.search.content.SharedLinkSearchItem}
 */
proto.search.content.SharedLinkSearchItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.search.content.SharedLinkSearchItem;
  return proto.search.content.SharedLinkSearchItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.search.content.SharedLinkSearchItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.search.content.SharedLinkSearchItem}
 */
proto.search.content.SharedLinkSearchItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.search.content.SharedLinkSearchItem.Link;
      reader.readMessage(value,proto.search.content.SharedLinkSearchItem.Link.deserializeBinaryFromReader);
      msg.addLinks(value);
      break;
    case 2:
      var value = new proto.search.content.SharedLinkSearchItem.Domain;
      reader.readMessage(value,proto.search.content.SharedLinkSearchItem.Domain.deserializeBinaryFromReader);
      msg.addDomains(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addTopics(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.search.content.SharedLinkSearchItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.search.content.SharedLinkSearchItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.search.content.SharedLinkSearchItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.search.content.SharedLinkSearchItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLinksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.search.content.SharedLinkSearchItem.Link.serializeBinaryToWriter
    );
  }
  f = message.getDomainsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.search.content.SharedLinkSearchItem.Domain.serializeBinaryToWriter
    );
  }
  f = message.getTopicsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.search.content.SharedLinkSearchItem.Link.prototype.toObject = function(opt_includeInstance) {
  return proto.search.content.SharedLinkSearchItem.Link.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.search.content.SharedLinkSearchItem.Link} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.search.content.SharedLinkSearchItem.Link.toObject = function(includeInstance, msg) {
  var f, obj = {
    title: jspb.Message.getFieldWithDefault(msg, 1, ""),
    publishedDate: jspb.Message.getFieldWithDefault(msg, 2, 0),
    url: jspb.Message.getFieldWithDefault(msg, 3, ""),
    domainName: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.search.content.SharedLinkSearchItem.Link}
 */
proto.search.content.SharedLinkSearchItem.Link.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.search.content.SharedLinkSearchItem.Link;
  return proto.search.content.SharedLinkSearchItem.Link.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.search.content.SharedLinkSearchItem.Link} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.search.content.SharedLinkSearchItem.Link}
 */
proto.search.content.SharedLinkSearchItem.Link.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPublishedDate(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDomainName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.search.content.SharedLinkSearchItem.Link.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.search.content.SharedLinkSearchItem.Link.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.search.content.SharedLinkSearchItem.Link} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.search.content.SharedLinkSearchItem.Link.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPublishedDate();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDomainName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string title = 1;
 * @return {string}
 */
proto.search.content.SharedLinkSearchItem.Link.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.search.content.SharedLinkSearchItem.Link} returns this
 */
proto.search.content.SharedLinkSearchItem.Link.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 published_date = 2;
 * @return {number}
 */
proto.search.content.SharedLinkSearchItem.Link.prototype.getPublishedDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.search.content.SharedLinkSearchItem.Link} returns this
 */
proto.search.content.SharedLinkSearchItem.Link.prototype.setPublishedDate = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string url = 3;
 * @return {string}
 */
proto.search.content.SharedLinkSearchItem.Link.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.search.content.SharedLinkSearchItem.Link} returns this
 */
proto.search.content.SharedLinkSearchItem.Link.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string domain_name = 4;
 * @return {string}
 */
proto.search.content.SharedLinkSearchItem.Link.prototype.getDomainName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.search.content.SharedLinkSearchItem.Link} returns this
 */
proto.search.content.SharedLinkSearchItem.Link.prototype.setDomainName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.search.content.SharedLinkSearchItem.Domain.prototype.toObject = function(opt_includeInstance) {
  return proto.search.content.SharedLinkSearchItem.Domain.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.search.content.SharedLinkSearchItem.Domain} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.search.content.SharedLinkSearchItem.Domain.toObject = function(includeInstance, msg) {
  var f, obj = {
    domain: jspb.Message.getFieldWithDefault(msg, 1, ""),
    percentage: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.search.content.SharedLinkSearchItem.Domain}
 */
proto.search.content.SharedLinkSearchItem.Domain.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.search.content.SharedLinkSearchItem.Domain;
  return proto.search.content.SharedLinkSearchItem.Domain.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.search.content.SharedLinkSearchItem.Domain} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.search.content.SharedLinkSearchItem.Domain}
 */
proto.search.content.SharedLinkSearchItem.Domain.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDomain(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setPercentage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.search.content.SharedLinkSearchItem.Domain.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.search.content.SharedLinkSearchItem.Domain.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.search.content.SharedLinkSearchItem.Domain} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.search.content.SharedLinkSearchItem.Domain.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDomain();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPercentage();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
};


/**
 * optional string domain = 1;
 * @return {string}
 */
proto.search.content.SharedLinkSearchItem.Domain.prototype.getDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.search.content.SharedLinkSearchItem.Domain} returns this
 */
proto.search.content.SharedLinkSearchItem.Domain.prototype.setDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional float percentage = 2;
 * @return {number}
 */
proto.search.content.SharedLinkSearchItem.Domain.prototype.getPercentage = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.search.content.SharedLinkSearchItem.Domain} returns this
 */
proto.search.content.SharedLinkSearchItem.Domain.prototype.setPercentage = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * repeated Link links = 1;
 * @return {!Array<!proto.search.content.SharedLinkSearchItem.Link>}
 */
proto.search.content.SharedLinkSearchItem.prototype.getLinksList = function() {
  return /** @type{!Array<!proto.search.content.SharedLinkSearchItem.Link>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.search.content.SharedLinkSearchItem.Link, 1));
};


/**
 * @param {!Array<!proto.search.content.SharedLinkSearchItem.Link>} value
 * @return {!proto.search.content.SharedLinkSearchItem} returns this
*/
proto.search.content.SharedLinkSearchItem.prototype.setLinksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.search.content.SharedLinkSearchItem.Link=} opt_value
 * @param {number=} opt_index
 * @return {!proto.search.content.SharedLinkSearchItem.Link}
 */
proto.search.content.SharedLinkSearchItem.prototype.addLinks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.search.content.SharedLinkSearchItem.Link, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.search.content.SharedLinkSearchItem} returns this
 */
proto.search.content.SharedLinkSearchItem.prototype.clearLinksList = function() {
  return this.setLinksList([]);
};


/**
 * repeated Domain domains = 2;
 * @return {!Array<!proto.search.content.SharedLinkSearchItem.Domain>}
 */
proto.search.content.SharedLinkSearchItem.prototype.getDomainsList = function() {
  return /** @type{!Array<!proto.search.content.SharedLinkSearchItem.Domain>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.search.content.SharedLinkSearchItem.Domain, 2));
};


/**
 * @param {!Array<!proto.search.content.SharedLinkSearchItem.Domain>} value
 * @return {!proto.search.content.SharedLinkSearchItem} returns this
*/
proto.search.content.SharedLinkSearchItem.prototype.setDomainsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.search.content.SharedLinkSearchItem.Domain=} opt_value
 * @param {number=} opt_index
 * @return {!proto.search.content.SharedLinkSearchItem.Domain}
 */
proto.search.content.SharedLinkSearchItem.prototype.addDomains = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.search.content.SharedLinkSearchItem.Domain, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.search.content.SharedLinkSearchItem} returns this
 */
proto.search.content.SharedLinkSearchItem.prototype.clearDomainsList = function() {
  return this.setDomainsList([]);
};


/**
 * repeated string topics = 3;
 * @return {!Array<string>}
 */
proto.search.content.SharedLinkSearchItem.prototype.getTopicsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.search.content.SharedLinkSearchItem} returns this
 */
proto.search.content.SharedLinkSearchItem.prototype.setTopicsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.search.content.SharedLinkSearchItem} returns this
 */
proto.search.content.SharedLinkSearchItem.prototype.addTopics = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.search.content.SharedLinkSearchItem} returns this
 */
proto.search.content.SharedLinkSearchItem.prototype.clearTopicsList = function() {
  return this.setTopicsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.search.content.AudioGenre.prototype.toObject = function(opt_includeInstance) {
  return proto.search.content.AudioGenre.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.search.content.AudioGenre} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.search.content.AudioGenre.toObject = function(includeInstance, msg) {
  var f, obj = {
    uid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.search.content.AudioGenre}
 */
proto.search.content.AudioGenre.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.search.content.AudioGenre;
  return proto.search.content.AudioGenre.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.search.content.AudioGenre} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.search.content.AudioGenre}
 */
proto.search.content.AudioGenre.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.search.content.AudioGenre.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.search.content.AudioGenre.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.search.content.AudioGenre} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.search.content.AudioGenre.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 uid = 1;
 * @return {number}
 */
proto.search.content.AudioGenre.prototype.getUid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.search.content.AudioGenre} returns this
 */
proto.search.content.AudioGenre.prototype.setUid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.search.content.AudioGenre.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.search.content.AudioGenre} returns this
 */
proto.search.content.AudioGenre.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * @enum {number}
 */
proto.search.content.FreshnessType = {
  ALL_TIMES: 0,
  LAST_24_HOURS: 1,
  LAST_7_DAYS: 2,
  LAST_30_DAYS: 3,
  LAST_6_MONTHS: 4,
  LAST_YEAR: 5,
  CUSTOM: 6
};

/**
 * @enum {number}
 */
proto.search.content.AudioSearchSource = {
  EVERYWHERE: 0,
  TITLE: 1,
  DESCRIPTION: 2,
  AUTHOR: 3,
  AUDIO: 4
};

/**
 * @enum {number}
 */
proto.search.content.UrlSearchType = {
  ALL: 0,
  EXTERNAL: 1
};

goog.object.extend(exports, proto.search.content);
