import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import cn from 'class-names';

import {
  ContactSearchSource as CONTACT_SEARCH_SOURCE,
  BacklinksSearchSource as BACKLINKS_SEARCH_SOURCE,
} from 'respona_api/generated/common_pb';

import { integrationStatusInfoMap } from '@components/Settings/Integrations/_components/IntegrationStatus/IntegrationStatus';

import './AutomationSourceOption.scss';
import { redirectUserTo } from '@utils/historyHandler';

const backlinksSourceIcons = {
  [BACKLINKS_SEARCH_SOURCE.RESPONA_BACKLINKS_SEARCH]: require('@assets/respona-logo-square.svg')
    .default, // eslint-disable-line global-require
  [BACKLINKS_SEARCH_SOURCE.AHREFS_BACKLINKS_SEARCH]: require('@assets/ahrefs-logo-square.png')
    .default, // eslint-disable-line global-require
  [BACKLINKS_SEARCH_SOURCE.SEMRUSH_BACKLINKS_SEARCH]: require('@assets/semrush-logo-square.jpeg')
    .default, // eslint-disable-line global-require
  [BACKLINKS_SEARCH_SOURCE.MOZ_BACKLINKS_SEARCH]: require('@assets/moz-logo-square.png').default, // eslint-disable-line global-require
};

const contactSourceIcons = {
  [CONTACT_SEARCH_SOURCE.RESPONA_SEARCH_SOURCE]: require('@assets/respona-logo-square.svg').default, // eslint-disable-line global-require
  [CONTACT_SEARCH_SOURCE.HUNTER_SEARCH_SOURCE]: require('@assets/hunter-logo-square.png').default, // eslint-disable-line global-require
  [CONTACT_SEARCH_SOURCE.SNOVIO_SEARCH_SOURCE]: require('@assets/snovio-logo-square.png').default, // eslint-disable-line global-require
  [CONTACT_SEARCH_SOURCE.ROCKET_REACH_SEARCH_SOURCE]: require('@assets/rocket-logo-square.png') // eslint-disable-line global-require
    .default,
};

export function AutomationSourceOptionsRow({
  style,
  children,
}: {
  style?: React.CSSProperties;
  children: React.ReactNode;
}): JSX.Element {
  return (
    <div style={style} className="source-select-row">
      {children}
    </div>
  );
}

export function AutomationSourceOptionNew({
  backlinksIntegrations,
}: {
  backlinksIntegrations?: boolean;
}): JSX.Element {
  const history = useHistory();
  const redirectionUrl = '/settings/integrations';
  return (
    <Link
      to={redirectionUrl}
      onClick={(event) => {
        redirectUserTo(history, redirectionUrl);
      }}
    >
      <div className="source-select-option-new">
        {/* <SVGIcon icon="plusIcon" color="#8D8F92" /> */}
        Connect
        <br />
        other sources
      </div>
    </Link>
  );
}

function AutomationSourceOption({
  image,
  type,
  title,
  subtitle,
  subtitleDetails,
  statusText,
  status,
  isSelected,
  onSelect,
  disabled,
  backlinksIntegration,
}: {
  image?: string | JSX.Element;
  type?: number;
  title: string;
  onSelect: () => void;
  isSelected: boolean;
  subtitle?: string;
  subtitleDetails?: string;
  statusText?: string | React.ReactNode;
  status?: number;
  disabled?: boolean;
  backlinksIntegration?: boolean;
}): JSX.Element {
  const history = useHistory();
  const redirectionUrl = '/settings/integrations';
  return (
    <>
      <div
        onClick={disabled ? undefined : onSelect}
        className={cn('source-select-option', {
          'source-select-option__selected': isSelected,
          'source-select-option__disabled': disabled,
        })}
        data-for={`source-select-option__tooltip-${title}`}
        data-tip="true"
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {image ? (
            <div className="source-select-option__image-container">{image}</div>
          ) : (
            <img
              alt={title}
              className="source-select-option__icon"
              src={(backlinksIntegration ? backlinksSourceIcons : contactSourceIcons)[type]}
            />
          )}

          <span className="source-select-option__title">
            {title}
            {/* {isBeta ? ( */}
            {/*  <span className="source-select-option__title-beta-tag">BETA</span> */}
            {/* ) : null} */}
          </span>
          <span className="source-select-option__subtitle">{subtitle}</span>
          <span className="source-select-option__subtitle-details">{subtitleDetails}</span>
        </div>

        {status || statusText ? (
          <span className="source-select-option__indicator-container">
            {status && (
              <span
                className="source-select-option__indicator"
                style={{ backgroundColor: integrationStatusInfoMap[status]?.color }}
              />
            )}
            {statusText || integrationStatusInfoMap[status]?.title}
          </span>
        ) : null}
      </div>

      {disabled ? (
        <ReactTooltip
          clickable
          id={`source-select-option__tooltip-${title}`}
          className="source-select-option__tooltip"
          place="bottom"
          effect="solid"
          isCapture
          globalEventOff="click"
          arrowColor="transparent"
          event="click"
        >
          {title} is not active at the moment.
          <br />
          <Link
            to={redirectionUrl}
            onClick={(event) => {
              redirectUserTo(history, redirectionUrl);
            }}
          >
            Manage your integrations
          </Link>
        </ReactTooltip>
      ) : null}
    </>
  );
}

export default AutomationSourceOption;
