import React from 'react';
import { DateRangePicker, Calendar } from 'react-date-range';
import { addDays, addMonths, addWeeks, addYears } from 'date-fns';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import { getLastSixMonthsRange } from '@helpers/dateTimeRangeHelpers';
import { DateRangeNumberType } from '@ts/common.types';

import './DateRangePicker.scss';

type DateRange = {
  startDate: Date;
  endDate: Date;
  key: string;
};

function DateRangePickerWrapper({
  value,
  handleSelect,
  isRangePicker = true,
  isReverseCheck = false,
  minDate = null,
  skipDayCheck = false,
}: {
  value?: DateRangeNumberType | undefined;
  handleSelect: (dateRange) => void;
  isRangePicker?: boolean;
  isReverseCheck?: boolean;
  minDate?: Date | null;
  skipDayCheck?: boolean;
}): JSX.Element {
  const checkIsDayDisabled = (day: Date): boolean => {
    if (skipDayCheck) {
      return false;
    }
    if (isReverseCheck) {
      return day.getTime() < Date.now();
    }
    return day.getTime() > Date.now();
  };

  const selection: DateRange = {
    startDate: value?.startDate ? new Date(value.startDate) : new Date(),
    endDate: value?.endDate ? new Date(value.endDate) : new Date(),
    key: 'selection',
  };

  const mutualProps: {
    color: string;
    weekdayDisplayFormat: string;
    weekStartsOn: number;
    disabledDay: (day: any) => boolean;
    onChange: (dateRange: any) => void;
    minDate?: Date;
  } = {
    color: '#3424EB',
    weekdayDisplayFormat: 'EEEEEE',
    weekStartsOn: 6,
    disabledDay: checkIsDayDisabled,
    onChange: handleSelect,
  };

  if (minDate) {
    mutualProps.minDate = minDate;
  }

  if (!isRangePicker) {
    return (
      <Calendar
        {...mutualProps}
        dayContentRenderer={(day) => (
          <div className={checkIsDayDisabled(day) ? 'disabled' : ''}>{day.getDate()}</div>
        )}
        date={selection.startDate}
      />
    );
  }

  return (
    <DateRangePicker
      {...mutualProps}
      rangeColors={['#3424EB']}
      dayContentRenderer={(day) => (
        <div className={checkIsDayDisabled(day) ? 'disabled' : ''}>{day.getDate()}</div>
      )}
      ranges={[selection]}
      staticRanges={[
        {
          label: 'Yesterday',
          hasCustomRendering: false,
          range: () => ({
            startDate: addDays(new Date(), -1),
            endDate: addDays(new Date(), -1),
          }),
          isSelected() {
            return false;
          },
        },
        {
          label: 'Last week',
          hasCustomRendering: false,
          range: () => ({
            startDate: addWeeks(new Date(), -1),
            endDate: new Date(),
          }),
          isSelected() {
            return false;
          },
        },
        {
          label: 'Last month',
          hasCustomRendering: false,
          range: () => ({
            startDate: addMonths(new Date(), -1),
            endDate: new Date(),
          }),
          isSelected() {
            return false;
          },
        },
        {
          label: 'Last 3 month',
          hasCustomRendering: false,
          range: () => ({
            startDate: addMonths(new Date(), -3),
            endDate: new Date(),
          }),
          isSelected() {
            return false;
          },
        },
        {
          label: 'Last 6 month',
          hasCustomRendering: false,
          range: getLastSixMonthsRange,
          isSelected() {
            return false;
          },
        },
        {
          label: 'Last year',
          hasCustomRendering: false,
          range: () => ({
            startDate: addYears(new Date(), -1),
            endDate: new Date(),
          }),
          isSelected() {
            return false;
          },
        },
      ]}
    />
  );
}

export default DateRangePickerWrapper;
