import React, { useState } from 'react';

import { PersonBaseType } from '@ts/people.types';
import { PeopleRelationshipStatusMap } from 'respona_api/generated/common_pb';

import SidebarCRMSectionEditableField
  from '@components/SidebarCRM/sections/SidebarCRMSection/_components/SidebarCRMSectionEditableField/SidebarCRMSectionEditableField';
import RelationshipStatus from '@components/Relationships/PeopleTable/_components/RelationshipStatus';
import { SVGIcon, SvgIconType } from '@uikit/Icon/Icon';
import { socialLinkTypeToIconMapper } from '@uikit/SocialLinks/SocialLinks';

interface Contact {
  name: string;
  status: PeopleRelationshipStatusMap[keyof PeopleRelationshipStatusMap];
  emailsList: any;
  jobPosition: string;
  socialLinksList: any;
  id: number;
  contactId: number;
}

function SectionPerson({
  contact,
  updateContact,
  saveEmail,
  removeEmail,
  removeContact,
  isRemovable,
}: {
  contact: Contact;
  updateContact?: (
    key: keyof PersonBaseType,
    value: string,
    contact: Contact
  ) => Promise<void>;
  saveEmail?: (contactId: number, initialEmail: string, email: string) => Promise<void>;
  removeEmail?: (contactId: number, email: string) => Promise<void>;
  removeContact: (contactId: number) => Promise<void>;
  isRemovable: boolean;
}) {
  const [isShownMoreEmails, setShownMoreEmails] = useState(false);

  const handleRemoveContact = (event, contactId) => {
    event.stopPropagation();

    removeContact(contactId);
  };

  const socialLinks = []
    .concat(contact.socialLinksList || [])
    .filter(Boolean)
    .filter((item, index, arr) => arr.findIndex((link) => link.url === item.url) === index);

  const emailsList = contact.emailsList || [];

  return (
    <div className="contact-sidebar-crm__content-personal-info">
      <div className="inbox-content-wrapper flex-content">
        <div className="inbox-content-wrapper__content">
          {updateContact ? (
            <SidebarCRMSectionEditableField
              enableCopy={false}
              initialValue={contact.name}
              onSave={(_, value) => updateContact('name', value, contact)}
              placeholder="Enter name:"
              extraClass="main-title"
            />
          ) : (
            <div
              className="inbox-content-wrapper__content-title"
              onClick={() => window.open(`/relationships/people?id=${contact.id}`, '_blank')}
            >
              <span>{contact.name}</span>
            </div>
          )}
          {socialLinks?.length > 0 && (
            <>
              {socialLinks.map((item) => (
                <div
                  key={item.url}
                  className="contact-sidebar-crm__content-personal-info-data-row contact-sidebar-crm__content-personal-info-data-row-social"
                  onClick={() => window.open(item.url, '_blank')}
                >
                  <SVGIcon icon={socialLinkTypeToIconMapper[item.type] as SvgIconType} />
                </div>
              ))}
            </>
          )}
          {isRemovable && (
            <span
              className="opportunity-contacts-sidebar-section-contacts__contacts-contact-title-close"
              onClick={(event) => handleRemoveContact(event, contact.contactId || contact.id)}
            >
              <SVGIcon icon="crossInGreyCircle" />
            </span>
          )}
        </div>
        {(isShownMoreEmails ? emailsList : emailsList.length >= 1 ? [emailsList[0]] : []).map(
          (item) => {
            if (saveEmail) {
              return (
                <SidebarCRMSectionEditableField
                  key={item.email}
                  initialValue={item.email}
                  onSave={(initialValue: string, email: string) =>
                    saveEmail(contact.id, initialValue, email)
                  }
                  onDelete={(email) => removeEmail(contact.id, email)}
                  placeholder="Enter email:"
                  isRenderIcon
                  renderIcon="messageIcon"
                />
              );
            }

            return (
              <div className="inbox-content-wrapper__data">
                <SVGIcon icon="email" color="#C4C6CD" />
                <div className="inbox-content-wrapper__data-email">{item.email}</div>
              </div>
            );
          }
        )}
        {isShownMoreEmails || emailsList.length <= 1 ? null : (
          <div
            onClick={() => setShownMoreEmails(true)}
            className="organizations-table__emails_more text-align-right"
          >
            +{emailsList.length - 1} other{emailsList.length - 1 > 1 ? 's' : ''}
          </div>
        )}
        {updateContact ? (
          <SidebarCRMSectionEditableField
            enableCopy={false}
            initialValue={contact.jobPosition || ''}
            onSave={(_, value) => updateContact('jobPosition', value, contact)}
            placeholder="Enter job title:"
            renderContent={(value) => (value == null || value.length === 0 ? '-' : value)}
            isRenderIcon
            renderIcon="personRounded"
          />
        ) : (
          <div className="inbox-content-wrapper__data">
            <SVGIcon icon="personRounded" size={15} color="#C4C6CD" />
            <div className="inbox-content-wrapper__data-email">{contact.jobPosition || ''}</div>
          </div>
        )}
        <div className="contact-sidebar-crm__content-personal-info-data-row">
          {contact.status >= 0 ? (
            <>
              <SVGIcon icon="flagStriped" size={16} />
              <RelationshipStatus status={contact.status} isBlocked={false} withIcon={false} />
            </>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );
}

export default SectionPerson;
