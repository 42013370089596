import React from 'react';

import ConfigurableSidebar from 'src/uikit/ConfigurableSidebar/ConfigurableSidebar';

import sidebarConfig from './sidebarConfig';

import './CampaignAnalyticsSidebar.scss';

const CampaignAnalyticsSidebar = ({ campaignId, pathname, workspaceId }) => {
  return (
    <ConfigurableSidebar
      config={sidebarConfig}
      renderProps={{ campaignId, pathname, workspaceId }}
      SidebarHeader={() => <span>Campaign analytics</span>}
    />
  );
};

export default CampaignAnalyticsSidebar;
