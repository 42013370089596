import React, { useState } from 'react';
import _ from 'lodash';
import { format } from 'date-fns';

import { getDateShort } from '@utils/date';

import Checkbox from '@uikit/Checkbox/Checkbox';

import { PersonType } from '@ts/people.types';

import ActionsMenu from '@components/ActionsMenu/ActionsMenu';

import RelationshipStatus from '@components/Relationships/PeopleTable/_components/RelationshipStatus';
import SocialLinks from '@uikit/SocialLinks/SocialLinks';
import { ColumnSettingsType } from '@ts/common.types';
import Avatar from '@uikit/Avatar/Avatar';
import EmailAccuracy from '@uikit/EmailAccuracy/EmailAccuracy';
import ReactTooltip from 'react-tooltip';

type RenderPropsType = {
  row: PersonType;
  renderProps: {
    [key: string]: any;
  };
};

declare const IS_STANDALONE_MODE: boolean;
const LIMIT_SELECTED_ITEMS = 1_000;

export default (tableSettings?: ColumnSettingsType[]) => [
  {
    title: '',
    dataKey: 'checkbox',
    RenderTitle: ({
      renderProps: { onToggleAllSelected, isAllSelected, countSelectedRows },
      onePageItems,
    }): JSX.Element => {
      const handleAllCheckedChange = ({ target: { checked } }) => onToggleAllSelected(checked);
      const isDisabled =
        !isAllSelected && countSelectedRows + onePageItems.length >= LIMIT_SELECTED_ITEMS;

      return (
        <div className="people-table__select-all-cb">
          <Checkbox onChange={handleAllCheckedChange} value={isAllSelected} disabled={isDisabled} />
        </div>
      );
    },
    RenderCell: ({ row, renderProps }: RenderPropsType): JSX.Element => {
      const handleSelectRow = (e) => {
        e.stopPropagation();
        renderProps.onToggleRowSelection(row.id);
      };
      const isChecked = Boolean(renderProps.selectedRows[row.id]);
      const isDisabled = !isChecked && renderProps.countSelectedRows >= LIMIT_SELECTED_ITEMS;

      return <Checkbox onChange={handleSelectRow} value={isChecked} disabled={isDisabled} />;
    },
    isSelectable: false,
    isFunctional: true,
  },

  ...(tableSettings
    ?.filter((item) => item.enabled)
    ?.map((item) => ({
      rawData: item,
      title: item.columnTitle,
      dataKey: item.columnMappingName,
      RenderCell: defaultColumnsRendererMap[item.columnMappingName],
    })) || []),

  {
    title: '',
    dataKey: 'actions',
    isSelectable: false,
    RenderCell: ({ row, renderProps }: RenderPropsType): JSX.Element => {
      const actionsConfig = [
        {
          icon: 'editAction',
          title: 'Edit',
          handler: () => renderProps.onEditPerson(row.id),
        },
        {
          icon: 'folder',
          title: 'Move',
          handler: () => renderProps.onMovePerson(row),
        },
      ];
      // @todo ask Ross
      // if (!row.isBlocked) {
      //   actionsConfig.push({
      //     icon: 'banWhite',
      //     title: 'Unsubscribe',
      //     handler: () => renderProps.onAddToBlackList([row.id]),
      //   });
      // }

      return (
        <ActionsMenu
          onRemove={() => renderProps.onRemovePerson(row.id)}
          id={`people-actions-${row.id}`}
          actionsConfig={actionsConfig}
          iconClassName="schedules-table__actions-icon"
          tooltipPlace="left"
          catchEvents={false}
        />
      );
    },
    isFunctional: true,
  },
];

const defaultColumnsRendererMap = {
  contact: ({ row }: RenderPropsType): JSX.Element => {
    const [isEmailsListShown, setEmailsListShown] = useState(false);

    return (
      <div className="people-table__cell">
        <div className="people-table__contact-header">
          <div
            className="people-table__name"
            style={{
              marginBottom: row.socialLinksList?.length ? '8px' : 0,
            }}
          >
            {row.name.length ? _.unescape(row.name) : '-'}
          </div>
          <div className="people-table__socials">
            <SocialLinks
              hideUknownType={false}
              socialLinks={row?.socialLinksList?.length ? row?.socialLinksList : []}
            />
          </div>
        </div>
        <div className="people-table__job-position people-table__cell--color-light-gray">
          {row.jobPosition ? _.unescape(row.jobPosition) : '-'}
        </div>
        <div className="people-table__email  people-table__cell--color-dark-gray">
          <span className="mr-5">
            {row.emailsList.length ? (
              isEmailsListShown ? (
                row.emailsList.map(({ email, accuracy }, index) => (
                  <div key={index} className="people-table__email-container">
                    {email}
                    <EmailAccuracy accuracy={accuracy} size="s" />
                  </div>
                ))
              ) : (
                <div className="people-table__email-container">
                  {_.unescape(row.emailsList[0].email)}
                  <EmailAccuracy accuracy={row.emailsList[0].accuracy} size="s" />
                </div>
              )
            ) : (
              '-'
            )}
          </span>
        </div>
        {!isEmailsListShown && row.emailsList.length > 1 && (
          <div
            // onClick={(event) => {
            //   event.stopPropagation();
            //   setEmailsListShown(true);
            // }}
            className="people-table__emails_more"
            data-for={`contact-emails-${row.id}`}
            data-tip=""
          >
            +{row.emailsList.length - 1} more
          </div>
        )}
        <ReactTooltip
          id={`contact-emails-${row.id}`}
          className="react-tooltip react-tooltip__keep-hover"
          place="bottom"
          effect="solid"
          delayHide={300}
          arrowColor="transparent"
        >
          <div className="contacts-result-emails-tooltip">
            {row.emailsList.map(({ email, accuracy }) => (
              <div
                className="contacts-result-emails-tooltip__email"
                key={email}
                // onClick={() => handleAssignContact(email, accuracy)}
              >
                <EmailAccuracy
                  accuracy={accuracy}
                  // order={selectedContactsMap[email]?.order}
                />
                {email}
              </div>
            ))}
          </div>
        </ReactTooltip>
      </div>
    );
  },
  // emails: ({ row: { id, emailsList }, renderProps }: RenderPropsType): JSX.Element => {
  //   const [firstEmail, ...otherEmail] = useMemo(() => {
  //     return emailsList.filter((email) => email.accuracy !== 1);
  //   }, [emailsList]);
  //
  //   return (
  //     <span className="people-table__email people-table__cell people-table__cell--color-light-gray">
  //       {firstEmail ? (
  //         <>
  //           <EmailAccuracy accuracy={firstEmail.accuracy} />
  //           <span className="people-table__email-cell-text" title={firstEmail.email}>
  //             {firstEmail.email}
  //
  //             <div
  //               className="people-table__email-cell-copy-btn"
  //               onClick={(e) => {
  //                 e.stopPropagation();
  //                 renderProps.copyText(firstEmail.email);
  //               }}
  //             >
  //               <SVGIcon icon="copy" size={10} color="#221CB6" />
  //             </div>
  //           </span>
  //           <Display isVisible={otherEmail.length > 0}>
  //             <span
  //               className="peoples-table-emails-chevron"
  //               data-for={`person-emails-${id}`}
  //               data-tip=""
  //             >
  //               <SVGIcon icon="chevron" />
  //             </span>
  //             <ReactTooltip
  //               id={`person-emails-${id}`}
  //               className="react-tooltip"
  //               place="bottom"
  //               effect="solid"
  //               delayHide={100}
  //               arrowColor="transparent"
  //               clickable
  //             >
  //               <div className="people-table-emails-tooltip" onClick={(e) => e.stopPropagation()}>
  //                 {otherEmail.map(({ email, accuracy }) => (
  //                   <div key={email} className="people-table-emails-tooltip__email">
  //                     <EmailAccuracy accuracy={accuracy} />
  //                     <span style={{ marginRight: '8px' }}>{email}</span>
  //                     <div
  //                       className="people-table__email-cell-copy-btn"
  //                       style={{ marginLeft: 'auto' }}
  //                       onClick={(e) => {
  //                         e.stopPropagation();
  //                         renderProps.copyText(email);
  //                       }}
  //                     >
  //                       <SVGIcon icon="copy" size={10} color="#221CB6" />
  //                     </div>
  //                   </div>
  //                 ))}
  //               </div>
  //             </ReactTooltip>
  //           </Display>
  //         </>
  //       ) : (
  //         <>Not found</>
  //       )}
  //     </span>
  //   );
  // },
  website: ({ row }: RenderPropsType): JSX.Element => (
    <div className="people-table__cell" style={{ whiteSpace: 'nowrap' }}>
      <div className="people-table__website-header">
        <Avatar
          url={row.website.logoUrl}
          fullName={row.website.title}
          className="people-table__website-square-logo"
        />
        <div className="people-table__website">
          <div
            className="people-table__website_title"
            style={{ marginBottom: row?.website?.socialLinksList?.length ? '3px' : 0 }}
          >
            {row?.website?.title.length ? _.unescape(row.website.title) : '-'}
          </div>
          <SocialLinks
            hideUknownType={false}
            socialLinks={[
              { type: 0, url: `https://${row.website.domain}` },
              ...row.website.socialLinksList,
            ]}
          />
        </div>
      </div>
    </div>
  ),
  status: ({ row: { status, isBlocked } }): JSX.Element => (
    <span
      className="people-table__cell people-table__status people-table__cell--color-light-gray"
      style={{ whiteSpace: 'nowrap' }}
    >
      <RelationshipStatus status={status} isBlocked={isBlocked} />
    </span>
  ),
  created_on: ({ row, renderProps }: RenderPropsType): JSX.Element => (
    <div
      className="people-table__cell people-table__cell--color-dark-gray no-underline"
      style={{ whiteSpace: 'nowrap' }}
    >
      {format(new Date(row.createdAt), 'MM/dd/yyyy')} at{' '}
      {format(new Date(row.createdAt), 'hh:mm aaa')}
      {IS_STANDALONE_MODE && (
        <div
          className="people-table__cell people-table__cell--color-light-gray"
          style={{ whiteSpace: 'nowrap' }}
        >
          by{' '}
          {renderProps.workspaceMembers?.find((item) => item.userId === row.ownerId)?.fullName ||
            '-'}
        </div>
      )}
    </div>
  ),
};
