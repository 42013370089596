import { ContactVariable, CustomVariableValueRelatedTo } from 'respona_api/generated/common_pb';

export default [
  {
    label: 'Url',
    value: ContactVariable.URL_VARIABLE,
    relatedTo: CustomVariableValueRelatedTo.OPPORTUNITY,
  },
  {
    label: 'Url title',
    value: ContactVariable.URL_TITLE_VARIABLE,
    relatedTo: CustomVariableValueRelatedTo.OPPORTUNITY,
  },
  {
    label: 'First name',
    value: ContactVariable.FIRST_NAME_VARIABLE,
    relatedTo: CustomVariableValueRelatedTo.PERSON,
  },
  {
    label: 'Full name',
    value: ContactVariable.FULL_NAME_VARIABLE,
    relatedTo: CustomVariableValueRelatedTo.PERSON,
  },
  {
    label: 'Last name',
    value: ContactVariable.LAST_NAME_VARIABLE,
    relatedTo: CustomVariableValueRelatedTo.PERSON,
  },
  {
    label: 'Email',
    value: ContactVariable.EMAIL_VARIABLE,
    relatedTo: CustomVariableValueRelatedTo.PERSON,
  },
  {
    label: 'Email 2',
    value: ContactVariable.EMAIL_2_VARIABLE,
    relatedTo: CustomVariableValueRelatedTo.PERSON,
  },
  {
    label: 'Email 3',
    value: ContactVariable.EMAIL_3_VARIABLE,
    relatedTo: CustomVariableValueRelatedTo.PERSON,
  },
  {
    label: 'Email 4',
    value: ContactVariable.EMAIL_4_VARIABLE,
    relatedTo: CustomVariableValueRelatedTo.PERSON,
  },
  {
    label: 'Email 5',
    value: ContactVariable.EMAIL_5_VARIABLE,
    relatedTo: CustomVariableValueRelatedTo.PERSON,
  },
  {
    label: 'Location',
    value: ContactVariable.LOCATION_VARIABLE,
    relatedTo: CustomVariableValueRelatedTo.PERSON,
  },
  {
    label: 'Job title',
    value: ContactVariable.JOB_TITLE_VARIABLE,
    relatedTo: CustomVariableValueRelatedTo.PERSON,
  },
  {
    label: 'LinkedIn url',
    value: ContactVariable.LINKEDIN_URL_VARIABLE,
    relatedTo: CustomVariableValueRelatedTo.PERSON,
  },
  {
    label: 'Tag',
    value: ContactVariable.TAG_VARIABLE,
    relatedTo: CustomVariableValueRelatedTo.PERSON,
  },
];
