import Loader from '@uikit/Loader/Loader';
import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  BacklinksSearchType,
  KeywordsSearchType,
  PodcastSearchType,
  SearchModeType,
} from '@ts/automationContent.types';

import {
  ContentAutomationSearchingMode as SEARCH_MODE,
  KeywordSearchSourceMap,
} from 'respona_api/generated/automation-content_pb';
import {
  ContactSearchSource as CONTACT_SEARCH_SOURCE,
  ContentMetricsType,
  IntegrationStatus as INTEGRATION_STATUS,
  IntegrationType as INTEGRATION_TYPE,
} from 'respona_api/generated/common_pb';

import { buildLaunchCampaignContentAutomationApiRequest } from '@api/contentAutomation.api';

import useCurrentWorkspaceId from '@hooks/useCurrentWorkspaceId';

import { integrationsSelector } from '@redux/selectors/integrations.selectors';
import { fetchBillingCredits } from '@redux/thunks/billings.thunks';
import { getActiveCampaignInfo } from '@redux/selectors/campaignSettings.selectors';
import {
  automationContentData,
  configSelector,
  searchModeSelector,
} from '@redux/selectors/automationContent.selectors';
import { setSearchMode } from '@redux/actions/automationContent.actions';

import SearchTypesTab from '@components/CampaignSettings/ContentSearch/ContentAutomationSearch/_components/SearchTypesTab/SearchTypesTab';
import KeywordsSearchSteps from '@components/CampaignSettings/ContentSearch/ContentAutomationSearch/_components/KeywordsSearchSteps/KeywordsSearchSteps';
import ContentAutomationFooter from '@components/CampaignSettings/ContentSearch/ContentAutomationSearch/_components/ContentAutomationFooter/ContentAutomationFooter';
import CASearchTypeWrapper from '@components/CampaignSettings/ContentSearch/ContentAutomationSearch/_components/CASearchTypeWrapper/CASearchTypeWrapper';
import ImportFileFlow from '@components/CampaignSettings/ContentSearch/ImportFileFlow/ImportFileFlow';
import BacklinksSearchSteps from '@components/CampaignSettings/ContentSearch/ContentAutomationSearch/_components/BacklinksSearch/BacklinksSearchSteps';
import PodcastsSearch from '@components/CampaignSettings/ContentSearch/ContentAutomationSearch/_components/PodcastsSearch/PodcastsSearch';

import './ContentAutomationSearch.scss';
import { DispatchType } from 'src/store';
import { billingDetailsSelector } from '@redux/selectors/billings.selectors';
import BaseModal from '@uikit/BaseModal/BaseModal';
import { buildLaunchContactAutomationApiRequest } from '@api/contactAutomation.api';
import {
  AUTOMATION_SEARCH,
  ContentAutomationSearchContext,
} from '@pages/OpportunitiesCampaignSettingsPage/OpportunitiesCampaignSettingsPage';
import { currentContactAutomationStateSelector } from '@redux/selectors/automationContact.selectors';
import { buildLaunchPersonalizationAutomationApiRequest } from '@api/personalizationAutomation.api';
import { personalizationAutomationSelector } from '@redux/selectors/automationPersonalization.selectors';
import { CampaignPipelineType } from 'respona_api/generated/pipeline_pb';
import { buildPipelineApiRequest, launchPipelineApi, updatePipelineApi } from '@api/pipeline.api';
import useCampaignPipelines from '@hooks/useCampaignPipelines';
import { BillingState } from 'respona_api/generated/billing_pb';

const importFileStyles = { container: { marginTop: '0' }, header: { marginBottom: '28px' } };

const renderSearchComponent = (
  searchMode: SearchModeType,
  data?: KeywordsSearchType | BacklinksSearchType | PodcastSearchType,
  setSourceRef?: (value: number) => void,
  setOpenModal?: Dispatch<SetStateAction<boolean>>
): JSX.Element => {
  switch (searchMode) {
    case 0:
      return <KeywordsSearchSteps setSourceRef={setSourceRef} data={data as KeywordsSearchType} />;
    case 1:
      return (
        <BacklinksSearchSteps setSourceRef={setSourceRef} data={data as BacklinksSearchType} />
      );
    case 2:
      return <PodcastsSearch setSourceRef={setSourceRef} data={data as PodcastSearchType} />;
    case 3:
      return <ImportFileFlow styles={importFileStyles} setOpenModal={setOpenModal} />;
    default:
      return <KeywordsSearchSteps setSourceRef={setSourceRef} data={data as KeywordsSearchType} />;
  }
};

declare const IS_STANDALONE_MODE: boolean;

function ContentAutomationSetup({
  pipelineId,
  modalOnly = false,
  isOpenModal = false,
  setOpenModal,
  currentPage,
  setPaymentSidebarOpen,
  setUpsellCreditsSidebarOpened,
  setUpsellCreditsNotEnough,
}: {
  pipelineId: number;
  modalOnly?: boolean;
  isOpenModal: boolean;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  currentPage: string;
  setPaymentSidebarOpen: (boolean) => void;
  setUpsellCreditsSidebarOpened: (boolean) => void;
  setUpsellCreditsNotEnough: (number) => void;
}): JSX.Element {
  const dispatch = useDispatch<DispatchType>();
  const isModalOpenFirstTime = useRef(true);

  const [isAutomationStarting, changeIsAutomationStarting] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [source, setSource] = useState<number>(null);
  const { info: campaignInfo } = useSelector(getActiveCampaignInfo);
  const {
    items: pipelines,
    updateItem: updatePipelineInCache,
    addItem: addPipelineToCache,
  } = useCampaignPipelines(campaignInfo.workspaceId, campaignInfo.id);
  const pipeline = pipelines.find((it) => it.id === pipelineId);

  const { settings, setSettings } = useContext(ContentAutomationSearchContext);

  const config = useSelector(configSelector);
  const searchMode = useSelector(searchModeSelector);
  const { integrations } = useSelector(integrationsSelector);
  const { data: billingDetails } = useSelector(billingDetailsSelector);

  const automationConfig = useSelector(
    automationContentData(
      searchMode,
      source as KeywordSearchSourceMap[keyof KeywordSearchSourceMap]
    )
  );

  useEffect(() => {
    if (pipeline != null) {
      setSettings({
        toggleFindContactInfo: pipeline.contactAutomationEnabled,
        stopRepeatAfterDate: pipeline.recurringEndAt,
        toggleRecurring: pipeline.recurringEnabled,
        togglePersonalization: pipeline.personalizationEnabled,
        repeatSearchEveryWeeks: Math.round(pipeline.recurringPeriodInHours / 7 / 24),
        notify: true,
      });
    }
  }, [pipeline]);

  useEffect(() => {
    if (isOpenModal === false) {
      setIsLoading(true);
    }
  }, [isOpenModal]);

  const isAhrefsConnected = integrations?.some(
    (item) =>
      item.type === INTEGRATION_TYPE.AHREFS_INTEGRATION &&
      item.status === INTEGRATION_STATUS.ACTIVE_INTEGRATION
  );

  const isSemrushConnected = integrations?.some(
    (item) =>
      item.type === INTEGRATION_TYPE.SEMRUSH_INTEGRATION &&
      item.status === INTEGRATION_STATUS.ACTIVE_INTEGRATION
  );

  const workspaceId = useCurrentWorkspaceId();

  const [activeStepIndex, changeActiveStepIndex] = useState<number>(1);

  const automationContact = useSelector(currentContactAutomationStateSelector);
  const { data: automationPersonalization } = useSelector(personalizationAutomationSelector);

  function toCampaignPipelineType() {
    switch (searchMode as SearchModeType) {
      case 0:
        return CampaignPipelineType.GOOGLE_SEARCH;
      case 1:
        return CampaignPipelineType.BACKLINK_SEARCH;
      case 2:
        return CampaignPipelineType.PODCAST_SEARCH;
      case 3:
        return CampaignPipelineType.IMPORT;
      default:
        return CampaignPipelineType.GOOGLE_SEARCH;
    }
  }

  const isStarterPlan = () => billingDetails.type === 0;

  const defaultFallbackMetricsMode = IS_STANDALONE_MODE
    ? ContentMetricsType.AHREFS_METRICS
    : ContentMetricsType.EMPTY_METRICS;

  const handleStartAutomation = () => {
    let searchModeArgs = [];

    switch (searchMode) {
      // Backlinks
      case 1:
        searchModeArgs = [
          config.metricsType || defaultFallbackMetricsMode,
          null,
          automationConfig.data,
          null,
          {
            ...config.ahrefMetrics,
            includePaidMetrics: isAhrefsConnected ? config.ahrefMetrics.includePaidMetrics : false,
          },
          null,
          {
            ...config.semrushMetrics,
            includePaidMetrics: isSemrushConnected
              ? config.semrushMetrics.includePaidMetrics
              : false,
          },
          {
            ...config.mozMetrics,
            includePaidMetrics: config.mozMetrics.includePaidMetrics,
          },
        ];
        break;
      //  Podcasts
      case 2:
        searchModeArgs = [
          ContentMetricsType.LISTENNOTES_METRICS,
          null,
          null,
          automationConfig.data,
          null,
          config.listennotesMetrics,
        ];
        break;
      //  Keywords
      case 0:
      default:
        searchModeArgs = [
          config.metricsType || defaultFallbackMetricsMode,
          automationConfig.data,
          null,
          null,
          {
            ...config.ahrefMetrics,
            includePaidMetrics: isAhrefsConnected ? config.ahrefMetrics.includePaidMetrics : false,
          },
          null,
          {
            ...config.semrushMetrics,
            includePaidMetrics: isSemrushConnected
              ? config.semrushMetrics.includePaidMetrics
              : false,
          },
          {
            ...config.mozMetrics,
            includePaidMetrics: config.mozMetrics.includePaidMetrics,
          },
        ];
    }

    let contactAutomationRequest = null;

    if (settings.toggleFindContactInfo) {
      const requestData = {
        ...automationContact,
        positionsList: automationContact.positionsList,
        senioritiesList: automationContact.senioritiesList,
      };
      contactAutomationRequest = buildLaunchContactAutomationApiRequest(
        campaignInfo.id,
        workspaceId,
        campaignInfo.lastBatch,
        requestData,
        false,
        CONTACT_SEARCH_SOURCE.RESPONA_SEARCH_SOURCE
      );
    }

    let personalizationAutomationRequest = null;

    if (settings.togglePersonalization) {
      personalizationAutomationRequest = buildLaunchPersonalizationAutomationApiRequest(
        campaignInfo.id,
        workspaceId,
        campaignInfo.lastBatch,
        campaignInfo.lastSequenceId,
        automationPersonalization
      );
    }

    changeIsAutomationStarting(true);

    const pipelineRequest = buildPipelineApiRequest(
      pipelineId,
      campaignInfo.workspaceId,
      campaignInfo.id,
      toCampaignPipelineType(),
      isStarterPlan() ? false : settings.toggleRecurring,
      settings.repeatSearchEveryWeeks * 7 * 24,
      settings.stopRepeatAfterDate,
      settings.toggleFindContactInfo,
      settings.togglePersonalization,
      settings.notify,
      contactAutomationRequest,
      buildLaunchCampaignContentAutomationApiRequest(
        settings.toggleFindContactInfo,
        campaignInfo.id,
        workspaceId,
        campaignInfo.lastBatch,
        pipelineId,
        searchMode as SearchModeType, // @ts-ignore
        ...searchModeArgs
      ),
      null,
      personalizationAutomationRequest
    );

    if (pipeline != null && pipeline.status === 3) {
      updatePipelineApi(pipelineRequest)
        .then((newState) => {
          updatePipelineInCache(newState.id, newState);
          setOpenModal(false);
        })
        .catch((e) => {
          console.error(`Update failed: ${e?.message}`, e);
        })
        .finally(() => {
          changeIsAutomationStarting(false);
        });
    } else {
      if (billingDetails.currentBonusCreditsNumber + billingDetails.currentPaidCreditsNumber < 10) {
        if (billingDetails.state === BillingState.BILLING_TRIAL) {
          setPaymentSidebarOpen(true);
        } else {
          setUpsellCreditsSidebarOpened(true);
          setUpsellCreditsNotEnough(1000);
        }
        changeIsAutomationStarting(false);
        return;
      }

      launchPipelineApi(pipelineRequest)
        .then((response) => {
          addPipelineToCache(response);
          dispatch(fetchBillingCredits());
          changeIsAutomationStarting(false);
          setOpenModal(false);
        })
        .catch((e) => {
          console.error(`Launch failed: ${e?.message}`, e);
          changeIsAutomationStarting(false);
          if (e.message.includes('credits')) {
            if (billingDetails.state === 6) {
              setPaymentSidebarOpen(true);
            } else {
              setUpsellCreditsSidebarOpened(true);
            }
          }
        });
    }
  };

  useLayoutEffect(() => {
    changeActiveStepIndex(1);
  }, [searchMode]);

  useEffect(() => {
    if (campaignInfo !== null && campaignInfo.id > 0) {
      if (!campaignInfo.advanced && currentPage === AUTOMATION_SEARCH) {
        setOpenModal(true);
      }
    }
  }, [campaignInfo, campaignInfo.id]);

  const onChangeActiveTabIndex = (index: number, openModal) => {
    dispatch(setSearchMode(index));

    if (openModal) {
      setOpenModal(true);
    }
  };

  const getHighlightedType = (): number => {
    if (campaignInfo.advanced || modalOnly) {
      return -1;
    }

    if (isModalOpenFirstTime.current) {
      return searchMode;
    }

    return -1;
  };

  const canStartAutomation = // @ts-ignore
    (searchMode === SEARCH_MODE.KEYWORDS_SEARCH && !!automationConfig?.data?.queriesList?.length) || // @ts-ignore
    (searchMode === SEARCH_MODE.BACKLINKS_SEARCH && !!automationConfig?.data?.urlsList?.length) || // @ts-ignore
    (searchMode === SEARCH_MODE.PODCAST_SEARCH && !!automationConfig?.data?.keywordsList?.length);

  return (
    <>
      {modalOnly ? null : (
        <div className="content-search-step-wrapper">
          <Loader isLoading={pipelineId == null}>
            <div className="content-automation-search">
              <div className="content-automation-search__settings-title">
                <h2>Choose the method to find opportunities</h2>
                <p>You can run multiple different searches in the same campaign</p>
              </div>
              <SearchTypesTab
                activeTabIndex={searchMode}
                onChangeActiveTabIndex={(index: number) => onChangeActiveTabIndex(index, true)}
                isBlocked={false}
              />
            </div>
          </Loader>
        </div>
      )}

      <BaseModal
        classNames={{ modal: 'content-automation-search-modal' }}
        closeOnBackgroundClick={false}
        isOpen={isOpenModal}
        onClose={() => {
          isModalOpenFirstTime.current = false;
          setOpenModal(false);
        }}
        iconColor="#BDBDBD"
      >
        <Loader isLoading={pipelineId == null}>
          <div className="content-automation-search-modal-sidebar">
            <span className="content-automation-search-modal-sidebar-title">
              Select automation type:
            </span>
            <SearchTypesTab
              activeTabIndex={searchMode}
              onChangeActiveTabIndex={(index: number) => onChangeActiveTabIndex(index, false)}
              isBlocked={false}
              isHighlighted={getHighlightedType()}
            />
          </div>
          <div className="content-automation-search-modal__body">
            <div className="content-automation-search-modal__step">
              <CASearchTypeWrapper
                pipelineId={pipelineId}
                activeStepIndex={activeStepIndex}
                changeActiveStepIndex={changeActiveStepIndex}
                canStartAutomation={canStartAutomation}
                searchMode={searchMode}
                setIsLoading={setIsLoading}
              >
                <Loader isLoading={isLoading} withTopMargin>
                  {renderSearchComponent(
                    searchMode,
                    automationConfig?.data,
                    setSource,
                    setOpenModal
                  )}
                </Loader>
              </CASearchTypeWrapper>

              {searchMode !== 3 && (
                <ContentAutomationFooter
                  isUpdate={pipeline != null && pipeline.status === 3}
                  handleStartAutomation={handleStartAutomation}
                  canStartAutomation={canStartAutomation}
                  activeStepIndex={activeStepIndex}
                  changeActiveStepIndex={changeActiveStepIndex}
                  isAutomationStarting={isAutomationStarting}
                />
              )}
            </div>
          </div>
        </Loader>
      </BaseModal>
    </>
  );
}

export default ContentAutomationSetup;
