import { createSelector } from 'reselect';

import { StateType } from '@redux/reducers';

const getBillingState = ({ billings }: StateType) => billings;

export const billingDetailsSelector = createSelector(
  [getBillingState],
  ({ billingDetails }) => billingDetails
);
export const billingCreditsSelector = createSelector(
  [getBillingState],
  ({ billingCredits }) => billingCredits
);
export const billingAddressSelector = createSelector(
  [getBillingState],
  ({ billingAddress }) => billingAddress
);