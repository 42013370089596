import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { peopleFoldersSelector } from '@redux/selectors/people.selectors';
import { companiesFoldersSelector } from '@redux/selectors/companies.selectors';

import ConfigurableSidebar from '@uikit/ConfigurableSidebar/ConfigurableSidebar';

import { ActivePagePropsType } from '@ts/internalSidebar.types';

import { createNewFolderRequest } from '@redux/thunks/people.thunks';
import { createFolderCompanyRequest } from '@redux/thunks/peopleCompany.thunks';

import './RelationshipsSidebar.scss';

import useCurrentWorkspaceId from '@hooks/useCurrentWorkspaceId';
import sidebarConfig from './sidebarConfig';

function RelationshipsSidebar({ activePage, searchParams }: ActivePagePropsType): JSX.Element {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const { items: peopleFolders } = useSelector(peopleFoldersSelector);
  const { items: companiesFolders } = useSelector(companiesFoldersSelector);
  const workspaceId = useCurrentWorkspaceId();

  const handleCreatePeopleFolder = useCallback(() => dispatch(createNewFolderRequest()), []);
  const handleCreateOrganizationFolder = useCallback(
    () => createFolderCompanyRequest(dispatch),
    []
  );

  const renderProps = {
    onCreateOrganizationFolder: handleCreateOrganizationFolder,
    onCreatePeopleFolder: handleCreatePeopleFolder,
    peopleFolders,
    companiesFolders,
    activePage,
    searchParams,
    pathname,
    workspaceId,
  };

  return (
    <div className="relationships-sidebar">
      <ConfigurableSidebar
        SidebarHeader={() => <span>Relationships</span>}
        config={sidebarConfig}
        renderProps={renderProps}
      />
    </div>
  );
}

export default RelationshipsSidebar;
