import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import cn from 'class-names';

import { PRIMARY_COLOR } from '@constants/colors';

import { SVGIcon, SvgIconType } from '@uikit/Icon/Icon';
import { RenderDefaultGroup } from '@uikit/ConfigurableSidebar/ConfigurableSidebar';

import ActionsMenu from '@components/ActionsMenu/ActionsMenu';

import '../ConfigurableSidebar.scss';
import { redirectUserTo } from '@utils/historyHandler';
import useCurrentWorkspaceId from '@hooks/useCurrentWorkspaceId';

type RenderDefaultLinksPropsType = {
  title: string | React.ReactNode;
  icon?: SvgIconType;
  url?: string;
  isActive?: boolean;
  isCropped?: boolean;
  RenderActions?: () => any;
  iconComponent?: React.ReactNode;
  onClick?: () => void;
};

export function RenderFolderLink({ id, folderId, title, activePage, onRemove, onRename, urlPath }) {
  const isFolderIdTheSameInQuery = Number(folderId) === id;
  const isActivePageUrlPathOfGroup = activePage === urlPath;
  const workspaceId = useCurrentWorkspaceId();

  // TODO: To make active also in organizations.
  return (
    <RenderConfigurableSidebarLink
      key={id}
      title={title}
      url={`/workspaces/${workspaceId}/relationships/${urlPath}?folderId=${id}`}
      isActive={isFolderIdTheSameInQuery && isActivePageUrlPathOfGroup}
      icon="folder"
      RenderActions={() => (
        <ActionsMenu
          id={`${urlPath}-folder-${id}-actions`}
          itemTitle={title}
          onRemove={onRemove}
          onRename={onRename}
        />
      )}
    />
  );
}

export function DefaultLink({ onClick = undefined, children, url = '', isActive }): JSX.Element {
  const history = useHistory();
  const cnDefaultLink = cn('configurable-sidebar__link', {
    'configurable-sidebar__link--active': isActive,
  });

  if (url) {
    return (
      <Link
        to={url}
        className={cnDefaultLink}
        onClick={(event) => {
          redirectUserTo(history, url);
        }}
      >
        {children}
      </Link>
    );
  }
  return (
    <div onClick={onClick} className={cnDefaultLink}>
      {children}
    </div>
  );
}

export function RenderConfigurableSidebarLink({
  title,
  icon,
  iconComponent,
  url = '',
  isActive = false,
  isCropped = false,
  RenderActions,
  onClick,
}: RenderDefaultLinksPropsType): JSX.Element {
  const cnDefaultLinkWrapper = cn('configurable-sidebar__link-wrapper', {
    'configurable-sidebar__link-wrapper--active': isActive,
  });

  const cnDefaultLinkText = cn('configurable-sidebar__link-text', {
    'configurable-sidebar__link-text--cropped': isCropped,
  });

  return (
    <div className={cnDefaultLinkWrapper}>
      <DefaultLink onClick={onClick} url={url} isActive={isActive}>
        <span className="configurable-sidebar__link-icon">
          {iconComponent ||
            (icon ? (
              <SVGIcon color={isActive ? PRIMARY_COLOR : '#000'} icon={icon as SvgIconType} />
            ) : null)}
        </span>
        <span className={cnDefaultLinkText}>{title}</span>
      </DefaultLink>
      {RenderActions ? (
        <span className="configurable-sidebar__link-actions">
          <RenderActions />
        </span>
      ) : null}
    </div>
  );
}

export function DefaultGroup({ title, children }) {
  return (
    <div className="configurable-sidebar__group">
      <div className="configurable-sidebar__group-title">{title}</div>
      <div className="configurable-sidebar__group-pages">{children}</div>
    </div>
  );
}

export function RenderGroup({
  title,
  RenderGroupLinks,
  pages,
  renderProps,
  groupActionClick,
}): JSX.Element {
  return (
    <div className="configurable-sidebar__group">
      <div className="configurable-sidebar__group-title">
        {title}
        {groupActionClick ? (
          <span
            onClick={() => groupActionClick(renderProps)}
            className="configurable-sidebar__group-action-icon"
            role="button"
            tabIndex={0}
          >
            <SVGIcon icon="plusCircle" />
          </span>
        ) : null}
      </div>
      <div className="configurable-sidebar__group-pages">
        {RenderGroupLinks ? (
          <RenderGroupLinks renderProps={renderProps} />
        ) : (
          <RenderDefaultGroup title={title} renderProps={renderProps} pages={pages} />
        )}
      </div>
    </div>
  );
}
