import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'class-names';

import {
  OrganizationInviteResponseType,
  OrganizationMemberResponseType,
  OrganizationMemberRoleMapType,
} from '@ts/userOrganization.types';
import { DispatchType } from 'src/store';

import { useRefCallback } from '@helpers/refHelpers';
import useIntersectionObserver from '@hooks/useIntersectionObserver';

import {
  inviteMembersToOrganizationApi,
  removeInviteFromOrganizationApi,
  removeMemberFromOrganizationApi,
  updateMemberOfOrganizationApi,
} from '@api/userOrganization.api';

import { addNotification } from '@redux/actions/notifications.actions';

import Button from '@uikit/Button/Button';
import Loader from '@uikit/Loader/Loader';
import SectionSelect from '@uikit/SectionSelect/SectionSelect';
import ConfigurableTable from '@uikit/ConfigurableTable/ConfigurableTable';

import PageHeader from '@components/PageHeader/PageHeader';
import { getMembersTableConfig } from '@components/Settings/MembersSettings/tableConfig';
import InviteMemberSidebar from '@components/Settings/InviteMemberSidebar/InviteMemberSidebar';
import RemoveMemberSidebar from '@components/Settings/RemoveMemberSidebar/RemoveMemberSidebar';
import EditMemberSidebar from '@components/Settings/EditMemberSidebar/EditMemberSidebar';

import './MembersSettings.scss';
import { userProfileSelector } from '@redux/selectors/userProfile.selector';
import NoAccessMessage from '@uikit/NoAccessMessage/NoAccessMessage';
import { SVGIcon } from '@uikit/Icon/Icon';
import HeaderSearchInput from '@components/HeaderSearchInput/HeaderSearchInput';
import useOrganizationMembers from '@hooks/useOrganizationMembers';
import { OrganizationMemberRoleMap } from 'respona_api/generated/user-organization_pb';
import useOrganizationInvitations from '@hooks/useOrganizationInvitations';

declare const IS_STANDALONE_MODE: boolean;

function AllMembersSettings(): JSX.Element {
  const dispatch = useDispatch<DispatchType>();
  const { info: userProfileInfo } = useSelector(userProfileSelector);
  const [loadingRef, setLoadingRef, ready] = useRefCallback<HTMLDivElement>();

  const [isMemberCreateOpen, changeIsMemberCreateOpen] = useState(false);
  const [removingMemberData, changeRemovingUserData] = useState(null);
  const [editMemberData, changeEditUserData] = useState<OrganizationMemberResponseType | null>(
    null
  );
  const [isAllMembersList, setIsAllMembersList] = useState<boolean>(true);
  const [searchString, setSearchString] = useState<string>('');

  if (userProfileInfo.rolesList.includes('MEMBER')) {
    return <NoAccessMessage showButton={false} />;
  }

  const {
    items: rawMembers,
    isLoading: isMembersLoadings,
    hasNextPage: hasMembersNextPage,
    fetchNextPage: fetchMembersNextPage,
    removeItem: removeMember,
    updateItem: updateMember,
  } = useOrganizationMembers(false, isAllMembersList);

  const {
    items: rawInvitations,
    isLoading: isInvitationLoading,
    refetch: invitationsRefetch,
    hasNextPage: hasInvitationNextPage,
    fetchNextPage: fetchInvitationsNextPage,
    removeItem: removeInvite,
  } = useOrganizationInvitations(false, !isAllMembersList);

  const members = useMemo(() => {
    return (
      rawMembers?.filter((it) => {
        if (!searchString) {
          return true;
        }

        const normalizedSearchString = searchString.toLowerCase();

        const propertiesToSearch = ['fullName', 'email'];

        return propertiesToSearch.some(
          (property) =>
            Object.prototype.hasOwnProperty.call(it, property) &&
            it[property].toLowerCase().includes(normalizedSearchString)
        );
      }) || []
    );
  }, [rawMembers, searchString]);

  const invitations = useMemo(() => {
    return (
      rawInvitations?.filter((it) => {
        if (!searchString) {
          return true;
        }

        const normalizedSearchString = searchString.toLowerCase();

        const propertiesToSearch = ['email'];

        return propertiesToSearch.some(
          (property) =>
            Object.prototype.hasOwnProperty.call(it, property) &&
            it[property].toLowerCase().includes(normalizedSearchString)
        );
      }) || []
    );
  }, [rawInvitations, searchString]);

  const handleCreateMemberOpen = () => changeIsMemberCreateOpen(true);
  const handleCreateMemberClose = () => changeIsMemberCreateOpen(false);
  const handleRemoveMemberSidebarOpen = (memberData) => changeRemovingUserData(memberData);
  const handleRemoveMemberSidebarClose = () => changeRemovingUserData(null);
  const handleEditMemberSidebarOpen = (memberData: OrganizationMemberResponseType) =>
    changeEditUserData(memberData);
  const handleEditMemberSidebarClose = () => changeEditUserData(null);
  const handleMembersTypeChange = (allMembers: boolean) => {
    setIsAllMembersList(allMembers);
  };

  const handleInviteMembers = (
    emails: string[],
    role: OrganizationMemberRoleMapType,
    workspaceIds: number[]
  ) =>
    inviteMembersToOrganizationApi(emails, role, workspaceIds).then(() => {
      dispatch(addNotification({ title: 'Invites were sent' }));
      handleCreateMemberClose();

      if (!isAllMembersList) {
        invitationsRefetch();
      } else {
        handleMembersTypeChange(false);
      }
    });

  const handleRemoveMember = (
    memberId: number,
    needTransferData: boolean,
    userIdForTransfer: number
  ) => {
    removeMemberFromOrganizationApi(memberId, needTransferData, userIdForTransfer).then(() => {
      removeMember(memberId);
      dispatch(addNotification({ title: 'Member has been deleted' }));
    });
    handleRemoveMemberSidebarClose();
  };

  const handleRemoveInvite = ({ id: inviteId }) => {
    removeInviteFromOrganizationApi(inviteId).then(() => {
      removeInvite(inviteId);
      dispatch(addNotification({ title: 'Invite has been deleted' }));
    });
  };

  const handleUpdateMember = (
    userId: number,
    role: OrganizationMemberRoleMap[keyof OrganizationMemberRoleMap],
    workspacesIds: number[]
  ) => {
    updateMemberOfOrganizationApi(userId, role, workspacesIds).then(() => {
      updateMember(userId, {
        ...editMemberData,
        rolesList: [role],
        workspacesNumber: workspacesIds.length,
      });
      handleEditMemberSidebarClose();
      dispatch(addNotification({ title: 'Member was updated', type: 'success' }));
    });
  };

  const renderLoader = () => {
    const loading = isAllMembersList ? isMembersLoadings : isInvitationLoading;
    if (loading) {
      return <Loader isLoading withTopMargin />;
    }

    const condition = isAllMembersList
      ? members?.length && hasMembersNextPage
      : invitations?.length && hasInvitationNextPage;

    if (condition) {
      return (
        <div ref={setLoadingRef} style={{ marginBottom: '20px' }}>
          <Loader isLoading withTopMargin />
        </div>
      );
    }

    return null;
  };

  const handleResetSearch = () => {
    setSearchString('');
  };

  useIntersectionObserver(
    loadingRef,
    () => {
      if (isAllMembersList) {
        fetchMembersNextPage();
      } else {
        fetchInvitationsNextPage();
      }
    },
    [ready]
  );

  const tableData = isAllMembersList
    ? members
    : invitations.sort(
        (a: OrganizationInviteResponseType, b: OrganizationInviteResponseType) =>
          b.sentAt - a.sentAt
      ) || [];

  return (
    <div className="settings-members">
      <PageHeader
        title="Team members"
        renderHeaderActions={() => (
          <div className="settings-members__header-items">
            <div className="settings-members__header-search">
              <HeaderSearchInput
                onUpdateSearchQuery={(data) => setSearchString(data)}
                searchQuery={searchString}
                placeholder="Search"
                debounceTime={600}
              />

              {!!searchString && (
                <div onClick={handleResetSearch} className="header-search-input__delete-btn">
                  <SVGIcon icon="crossDelete" size={16} />
                </div>
              )}
            </div>
            {IS_STANDALONE_MODE && (
              <div className="settings-members__add-user-btn">
                <Button onClick={handleCreateMemberOpen} leftIcon="addUserWhite">
                  Invite Members
                </Button>
              </div>
            )}
          </div>
        )}
      />

      <SectionSelect
        selectedOption={isAllMembersList}
        onChange={handleMembersTypeChange}
        options={[
          {
            title: 'Members',
            key: true,
          },
          {
            title: 'Invites',
            key: false,
          },
        ]}
      />

      <Loader isLoading={isMembersLoadings || isInvitationLoading} withTopMargin>
        <>
          <ConfigurableTable
            tableClassName="settings-members__table"
            rowClassName={cn('settings-members__table__row', {
              'settings-members__table__row--with-hover': isAllMembersList,
            })}
            config={getMembersTableConfig(isAllMembersList)}
            onRowClick={isAllMembersList ? handleEditMemberSidebarOpen : undefined}
            renderProps={{
              onRemove: isAllMembersList ? handleRemoveMemberSidebarOpen : handleRemoveInvite,
              onEdit: handleEditMemberSidebarOpen,
              onInvite: handleInviteMembers,
              isEditableInActionMenu: isAllMembersList,
            }}
            data={tableData}
          />
          {renderLoader()}
        </>
      </Loader>

      <InviteMemberSidebar
        isOpen={isMemberCreateOpen}
        onClose={handleCreateMemberClose}
        onInvite={handleInviteMembers}
      />

      <RemoveMemberSidebar
        isOpen={removingMemberData !== null}
        member={removingMemberData as OrganizationMemberResponseType}
        members={members as OrganizationMemberResponseType[]}
        onClose={handleRemoveMemberSidebarClose}
        onRemove={handleRemoveMember}
      />

      <EditMemberSidebar
        isOpen={editMemberData !== null}
        onUpdate={handleUpdateMember}
        onClose={handleEditMemberSidebarClose}
        member={editMemberData}
      />
    </div>
  );
}

export default AllMembersSettings;
