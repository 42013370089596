import React, { Dispatch, SetStateAction, useState } from 'react';
import { useSelector } from 'react-redux';
// eslint-disable-next-line import/no-extraneous-dependencies
import classNames from 'classnames';

import './SelectedOpportunityTableRow.scss';

import Checkbox from '@uikit/Checkbox/Checkbox';
import { SVGIcon, SvgIconType } from '@uikit/Icon/Icon';
import { socialLinkTypeToIconMapper } from '@uikit/SocialLinks/SocialLinks';

import Display from '@components/Display/Display';
import { OpportunityPageType } from '@ts/opportunity.types';
import { WorkspaceMemberShortType } from '@ts/workspace.types';
import { ContentMetricsType } from 'respona_api/generated/common_pb';
import {
  composeDomainRatingTitle,
  composeMetricsIcon,
  formatMetrics,
  getTypeInfo,
  IconsMapByType,
  isAllMetricsZero,
} from '@helpers/opportunitiesHelpers';
import useCampaignPipelines from '@hooks/useCampaignPipelines';
import { getActiveCampaignInfo } from '@redux/selectors/campaignSettings.selectors';

declare const IS_STANDALONE_MODE: boolean;

function SelectedOpportunityTableRow({
  data,
  workspaceMembers,
  isChecked,
  handleChangeCheckbox,
  setOpenOpportunitySidebar,
  setActiveOpportunity,
  isFindContactPipelineRunning,
}: {
  data: OpportunityPageType;
  workspaceMembers: WorkspaceMemberShortType[];
  isChecked: boolean;
  handleChangeCheckbox?: (id: number) => void;
  setOpenOpportunitySidebar: Dispatch<SetStateAction<boolean>>;
  setActiveOpportunity: Dispatch<SetStateAction<OpportunityPageType>>;
  isFindContactPipelineRunning: boolean;
}): JSX.Element {
  const [allContacts, setAllContacts] = useState<boolean>(false);
  const {
    info: { id: campaignId, workspaceId, lastBatch },
  } = useSelector(getActiveCampaignInfo);
  const { items: pipelines } = useCampaignPipelines(workspaceId, campaignId);
  const contactsList =
    allContacts || data.contactsList.length === 0 ? data.contactsList : [data.contactsList[0]];

  const ownerId = workspaceMembers?.find((item) => item.userId === data.ownerId)?.fullName || '-';

  const formatDate = (timestamp: number): string => {
    if (timestamp === -1) {
      return '-';
    }

    const dateObj = new Date(timestamp);
    const ampm = dateObj.getHours() >= 12 ? 'pm' : 'am';
    const hours = dateObj.getHours() % 12;
    const months = dateObj.getMonth() >= 9 ? dateObj.getMonth() + 1 : `0${dateObj.getMonth() + 1}`;
    const date = dateObj.getDate() >= 10 ? dateObj.getDate() : `0${dateObj.getDate()}`;
    const minutes = dateObj.getMinutes() >= 10 ? dateObj.getMinutes() : `0${dateObj.getMinutes()}`;

    return `${months}/${date}/${dateObj.getFullYear()} (${
      hours >= 10 ? hours : `0${hours}`
    }:${minutes} ${ampm})`;
  };

  const getType = (value: number): string => {
    switch (value) {
      case 0:
        return 'Google Search';
      case 1:
        return 'Bing Search';
      case 2:
        return 'Podcast';
      case 3:
        return 'Episode';
      case 4:
        return 'Trending Content';
      case 5:
        return 'Bing News';
      case 6:
        return 'Imported';
      case 7:
        return 'Relationships';
      case 8:
        return 'Ahrefs Backlinks';
      case 9:
        return 'Semrush Backlinks';
      case 10:
        return 'Moz Backlinks';
      case 11:
        return 'Respona Backlinks';
      case 12:
        return 'Google News';
      default:
        return 'Imported';
    }
  };

  const getIconByType = (value: number): SvgIconType => {
    switch (value) {
      case 0:
        return 'googleIcon';
      case 1:
        return 'bingIcon';
      case 2:
        return 'podcastIcon';
      case 3:
        return 'episodesIcon';
      case 4:
        return 'keywordSearchIcon';
      case 5:
        return 'bingNewsIcon';
      case 6:
        return 'uploadFileIcon';
      case 7:
        return 'relationshipsIcon';
      case 8:
        return 'keywordSearchIcon';
      case 9:
        return 'semrushIcon';
      case 10:
        return 'mozIcon';
      case 11:
        return 'semrushIcon';
      case 12:
        return 'googleIcon';
      default:
        return 'uploadFileIcon';
    }
  };

  const isPipelineLoading = (): boolean => {
    return pipelines.find((pipeline) => pipeline.id === data.pipelineId)?.status === 0;
  };

  const typeInfo = getTypeInfo(data);

  const statusClassNames = classNames('table-content_status', {
    not_verified: data.status === 0,
    verified: data.status === 1,
    flagged: data.status === 2,
  });

  const cutString = (inputString = ''): string => {
    if (inputString.length <= 100) {
      return inputString;
    }
    return inputString.slice(0, 100);
  };

  const openSidebarOpportunity = (): boolean => {
    setActiveOpportunity(data);

    setOpenOpportunitySidebar(true);

    return true;
  };

  function composeTitle() {
    if (
      data == null ||
      data.title == null ||
      data.title.length === 0 ||
      data.title.trim().length === 0
    ) {
      return '—';
    }
    return data.title;
  }

  function isValidURL() {
    try {
      // eslint-disable-next-line no-new
      new URL(data.url);
      return true;
    } catch (_) {
      return false;
    }
  }

  function composeUrl() {
    if (data == null || data.url == null || data.url.length === 0 || data.url.trim().length === 0) {
      return '—';
    }
    return data.url;
  }

  return (
    <tr onClick={openSidebarOpportunity}>
      <td>
        <div className="table-content">
          <div className="table-content_header">
            <Checkbox onChange={() => handleChangeCheckbox(data.id)} value={isChecked} />
            <h4>{composeTitle()}</h4>
          </div>
          <p className="table-content_text">{cutString(typeInfo.description)}</p>
          <a
            className="link-icon-a"
            href={data.url}
            target="_blank"
            onClick={(event) => {
              if (!isValidURL()) {
                event.preventDefault();
              }
              event.stopPropagation();
            }}
          >
            <SVGIcon icon="link" size={10} color="#221CB6" />
            <span className="link-content">{composeUrl()}</span>
          </a>
          {data.reviewed && <span className="btn-edited">Reviewed</span>}
        </div>
      </td>
      <td>
        <div className="table-content">
          <div className="table-content_source">
            <SVGIcon icon={getIconByType(data.type)} size={19} />
            <p>{getType(data.type)}</p>
          </div>
        </div>
      </td>
      <td>
        <div className="table-content">
          <div className="table-content_contacts">
            {contactsList?.length === 0 ? (
              isPipelineLoading() || isFindContactPipelineRunning ? (
                <span>Searching...</span>
              ) : (
                <span>&mdash;</span>
              )
            ) : (
              <>
                {contactsList?.map((contact) => (
                  <ul key={contact.personId} className="table-content_contacts-list">
                    <li className="name-container">
                      <p className="name">{contact.name}</p>
                      {contact.socialLinksList.map((socialLink) => (
                        <a
                          className="social-links-a"
                          key={socialLink.url}
                          href={socialLink.url}
                          target="_blank"
                        >
                          <SVGIcon
                            icon={socialLinkTypeToIconMapper[socialLink.type] as SvgIconType}
                            color="#C4C6CD"
                          />
                        </a>
                      ))}
                    </li>
                    <li>
                      <span className="status">{contact.jobPosition}</span>
                    </li>
                    <li>
                      <div className="email">{contact.contactEmail.email}</div>
                    </li>
                  </ul>
                ))}
                {!allContacts && data.contactsList.length > 1 ? (
                  <span
                    className="more"
                    onClick={(event) => {
                      event.stopPropagation();
                      setAllContacts(true);
                    }}
                  >
                    + {data.contactsList.length - 1} more
                  </span>
                ) : null}
              </>
            )}
          </div>
        </div>
      </td>
      <td>
        <div className="table-content">
          <div className="table-content_metrics">
            <ul>
              <Display isVisible={isAllMetricsZero(typeInfo)}>
                <li>
                  <span>&mdash;</span>
                </li>
              </Display>

              <Display isVisible={!isAllMetricsZero(typeInfo)}>
                <Display isVisible={typeInfo.listenScore > 0}>
                  <li>
                    <img
                      src={IconsMapByType[ContentMetricsType.LISTENNOTES_METRICS]}
                      alt="metrics-logo"
                    />
                    <p className="table-content_metrics-text">Listen score</p>
                    <span>{typeInfo.listenScore}</span>
                  </li>
                </Display>
                <Display
                  isVisible={
                    typeInfo.globalRank != null &&
                    typeInfo.globalRank.length &&
                    typeInfo.globalRank !== '0%'
                  }
                >
                  <li>
                    <img
                      src={IconsMapByType[ContentMetricsType.LISTENNOTES_METRICS]}
                      alt="metrics-logo"
                    />
                    <p className="table-content_metrics-text">Podcast rank</p>
                    <span>{typeInfo.globalRank}</span>
                  </li>
                </Display>

                <Display
                  isVisible={
                    Object.prototype.hasOwnProperty.call(typeInfo, 'hasGuestInterviews') &&
                    typeInfo.hasGuestInterviews
                  }
                >
                  <li>
                    <img
                      src={IconsMapByType[ContentMetricsType.LISTENNOTES_METRICS]}
                      alt="metrics-logo"
                    />
                    <p className="table-content_metrics-text">Guest interviews</p>
                    <span>Yes</span>
                  </li>
                </Display>

                <Display isVisible={typeInfo.domainRating > 0}>
                  <li>
                    <img src={composeMetricsIcon(data)} alt="metrics-logo" />
                    <p className="table-content_metrics-text">{composeDomainRatingTitle(data)}</p>
                    <span>{formatMetrics(typeInfo.domainRating)}</span>
                  </li>
                </Display>
                <Display isVisible={typeInfo.domainAuthority > 0}>
                  <li>
                    <img src={composeMetricsIcon(data)} alt="metrics-logo" />
                    <p className="table-content_metrics-text">Domain authority</p>
                    <span>{formatMetrics(typeInfo.domainAuthority)}</span>
                  </li>
                </Display>
                <Display isVisible={typeInfo.pageAuthority > 0}>
                  <li>
                    <img src={composeMetricsIcon(data)} alt="metrics-logo" />
                    <p className="table-content_metrics-text">Page authority</p>
                    <span>{formatMetrics(typeInfo.pageAuthority)}</span>
                  </li>
                </Display>
                <Display isVisible={typeInfo.domainTraffic > 0}>
                  <li>
                    <img src={composeMetricsIcon(data)} alt="metrics-logo" />
                    <p className="table-content_metrics-text">Domain traffic</p>
                    <span>{formatMetrics(typeInfo.domainTraffic)}</span>
                  </li>
                </Display>
                <Display isVisible={typeInfo.domainTrafficKeywordsNumber > 0}>
                  <li>
                    <img src={composeMetricsIcon(data)} alt="metrics-logo" />
                    <p className="table-content_metrics-text">Organic keywords</p>
                    <span>{formatMetrics(typeInfo.domainTrafficKeywordsNumber)}</span>
                  </li>
                </Display>
                <Display isVisible={typeInfo.domainAuthorityScore > 0}>
                  <li>
                    <img src={composeMetricsIcon(data)} alt="metrics-logo" />
                    <p className="table-content_metrics-text">Authority score</p>
                    <span>{formatMetrics(typeInfo.domainAuthorityScore)}</span>
                  </li>
                </Display>
                <Display isVisible={typeInfo.domainSpamScore > 0}>
                  <li>
                    <img src={composeMetricsIcon(data)} alt="metrics-logo" />
                    <p className="table-content_metrics-text">Domain spam score</p>
                    <span>{formatMetrics(typeInfo.domainSpamScore)}</span>
                  </li>
                </Display>
              </Display>
            </ul>
          </div>
        </div>
      </td>
      <td>
        <div className="table-content">
          <div className="table-content_date">
            <span className="date">{formatDate(data.createdAt)}</span>
            {IS_STANDALONE_MODE && <span className="person">By {ownerId}</span>}
          </div>
        </div>
      </td>
    </tr>
  );
}

export default SelectedOpportunityTableRow;
