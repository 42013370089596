import React, { useCallback, useEffect, useMemo, useState } from 'react';

import './CampaignReports.scss';
import PageHeader from '@components/PageHeader/PageHeader';
import InsightsCampaignHeader from '@components/Insights/InsightsCampaignHeader/InsightsCampaignHeader';
import { useQuery } from 'react-query';
import {
  getCampaignEmailDailyInsightsApi,
  getCampaignEmailHourlyInsightsApi,
  getCampaignPersonDailyInsightsApi,
  getCampaignWebsiteDailyInsightsApi,
} from '@api/analytics.api';
import { useDispatch, useSelector } from 'react-redux';
import {
  insightsCampaignPerformanceFiltersSelector,
  insightsDateFilterSelector,
} from '@redux/selectors/insights.selector';
import { getCurrentWorkspaceId, workspacesSelector } from '@redux/selectors/workspaces.selectors';
import Loader from '@uikit/Loader/Loader';
import {
  setInsightsCampaignPerformanceFilters,
  setInsightsDateFilter,
} from '@redux/actions/insights.actions';
import { DispatchType } from 'src/store';
import useWorkspaceMembers from '@hooks/useWorkspaceMembers';
import useMailboxAccounts from '@hooks/useMailboxAccounts';
import useCampaignShortcutsByWorkspace from '@hooks/useCampaignShortcutsByWorkspace';
import { CampaignReportsContent } from '@components/Insights/CampaignReports/CampaignReportsContent/CampaignReportsContent';
import composeFilterOptions from '@components/Insights/CampaignReports/_utils/composeFilterOptions';

export default function CampaignReports(): JSX.Element {
  const currentWorkspaceId = useSelector(getCurrentWorkspaceId);
  const dispatch = useDispatch<DispatchType>();
  const filters = useSelector(insightsCampaignPerformanceFiltersSelector);
  const dateFilter = useSelector(insightsDateFilterSelector);
  const [campaignSearchQuery, setCampaignSearchQuery] = useState<string>('');

  const { members, isFetching: isFetchingMembers } = useWorkspaceMembers();
  const { workspaces: workspacesData } = useSelector(workspacesSelector);
  const { items: mailboxAccounts } = useMailboxAccounts(currentWorkspaceId);
  const { items: campaigns } = useCampaignShortcutsByWorkspace(campaignSearchQuery);

  const campaignOptions = useMemo(
    () => campaigns?.map(({ id, title }) => ({ value: id, label: title })) || [],
    [currentWorkspaceId, campaigns]
  );

  const handleCampaignSearch = (
    inputValue: string,
    callback: (options: { value: string | number; label: string }[]) => void
  ) => {
    setCampaignSearchQuery(inputValue);
    // TODO need to find better way to upload data from last request
    setTimeout(() => callback(campaignOptions), 1500);
  };

  const queryOptions = useMemo(
    () => ({
      refetchOnWindowFocus: false,
      enabled: !!currentWorkspaceId,
      retry: 2,
    }),
    [currentWorkspaceId]
  );

  const parsedFilters = useMemo(() => Object.values(filters), [filters]);

  const fetchCampaignData = useCallback(
    (apiFunc) => {
      if (dateFilter.startDate) {
        return apiFunc(parsedFilters, dateFilter);
      }
      return apiFunc(parsedFilters);
    },
    [dateFilter, parsedFilters]
  );

  const {
    data: chartDataEmailDaily,
    isLoading: isLoadingEmailDaily,
    refetch: refetchEmailDaily,
  } = useQuery(
    ['global-insights', filters, currentWorkspaceId],
    () => fetchCampaignData(getCampaignEmailDailyInsightsApi),
    queryOptions
  );

  const {
    data: chartDataEmailHourly,
    isLoading: isLoadingEmailHourly,
    refetch: refetchEmailHourly,
  } = useQuery(
    ['hourly-insights', filters],
    () => fetchCampaignData(getCampaignEmailHourlyInsightsApi),
    queryOptions
  );

  const {
    data: chartDataPersonDaily,
    isLoading: isLoadingPersonDaily,
    refetch: refetchPersonDaily,
  } = useQuery(
    ['person-daily-insights', filters],
    () => fetchCampaignData(getCampaignPersonDailyInsightsApi),
    queryOptions
  );

  const {
    data: chartDataWebsiteDaily,
    isLoading: isLoadingWebsiteDaily,
    refetch: refetchWebsiteDaily,
  } = useQuery(
    ['website-daily-insights', filters],
    () => fetchCampaignData(getCampaignWebsiteDailyInsightsApi),
    queryOptions
  );

  useEffect(() => {
    if (
      !currentWorkspaceId ||
      !chartDataEmailDaily ||
      (chartDataEmailDaily.dateFrom === dateFilter.startDate &&
        chartDataEmailDaily.dateTo === dateFilter.endDate)
    ) {
      return;
    }

    refetchEmailDaily();
    refetchEmailHourly();
    refetchPersonDaily();
    refetchWebsiteDaily();
  }, [
    dateFilter,
    currentWorkspaceId,
    chartDataEmailDaily,
    refetchEmailDaily,
    refetchEmailHourly,
    refetchPersonDaily,
    refetchWebsiteDaily,
  ]);

  useEffect(() => {
    if (!dateFilter?.startDate && chartDataWebsiteDaily?.dateFrom) {
      dispatch(
        setInsightsDateFilter({
          startDate: +chartDataWebsiteDaily.dateFrom,
          endDate: +chartDataWebsiteDaily.dateTo,
        })
      );
    }
  }, [chartDataWebsiteDaily, dateFilter, dispatch]);

  const usersOptions = useMemo(
    () => members.map(({ userId, fullName }) => ({ value: userId, label: fullName })),
    [members]
  );

  const workspaceOptions = useMemo(
    () => workspacesData?.map(({ id, title }) => ({ value: id, label: title })) || [],
    [workspacesData]
  );

  const mailboxOptions = useMemo(
    () => mailboxAccounts?.map(({ id, email }) => ({ value: id, label: email })) || [],
    [mailboxAccounts]
  );

  const isLoading = useMemo(
    () =>
      isLoadingEmailDaily ||
      isLoadingEmailHourly ||
      isLoadingPersonDaily ||
      isLoadingWebsiteDaily ||
      isFetchingMembers ||
      !currentWorkspaceId,
    [
      isLoadingEmailDaily,
      isLoadingEmailHourly,
      isLoadingPersonDaily,
      isLoadingWebsiteDaily,
      isFetchingMembers,
      currentWorkspaceId,
    ]
  );

  return (
    <>
      <PageHeader title="Campaign reports" renderHeaderActions={() => (
        <div className="campaign-report-header">
          <InsightsCampaignHeader
            filterFields={composeFilterOptions(
              handleCampaignSearch,
              campaignOptions,
              workspaceOptions,
              usersOptions,
              mailboxOptions
            )}
            filterAction={setInsightsCampaignPerformanceFilters}
            filterClass="campaign-report-filter"
            filtersValues={filters}
            isFilterOptionsLoading={false}
          />
        </div>
      )} />

      <Loader isLoading={isLoading} withTopMargin>
        <CampaignReportsContent
          chartDataEmailDaily={chartDataEmailDaily}
          chartDataEmailHourly={chartDataEmailHourly}
          chartDataWebsiteDaily={chartDataWebsiteDaily}
          chartDataPersonDaily={chartDataPersonDaily}
        />
      </Loader>
    </>
  );
}
