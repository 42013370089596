import React, { useEffect, useState } from 'react';
import { useInfiniteQuery, useQuery, useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { QueryClient } from 'react-query/core';

import { DispatchType } from 'src/store';

import { manualSearchTypes } from '@constants/manualSearch.constants';

import {
  searchByDomainAndJobPositionApi,
  searchByDomainAndNameApi,
  searchBySocialLinkApi,
} from '@api/searchContacts.api';

import useCurrentWorkspaceId from '@hooks/useCurrentWorkspaceId';

import { fetchBillingCredits, fetchBillingDetails } from '@redux/thunks/billings.thunks';

import Loader from '@uikit/Loader/Loader';
import MultiSwitch from '@uikit/MultiSwitch/MultiSwitch';

import ContactsSearchField from '@components/CampaignSettings/ContactsSearchField/ContactsSearchField';
import ContactsSearchResultGroup from '@components/CampaignSettings/ContactsSearchResultGroup/ContactsSearchResultGroup';
import Display from '@components/Display/Display';
import PageHeader from '@components/PageHeader/PageHeader';

import './ToolboxEmailFinder.scss';
import { billingDetailsSelector } from '@redux/selectors/billings.selectors';
import UpsellCreditsSidebar from '@components/UpsellSidebars/UpsellCreditsSidebar/UpsellCreditsSidebar';
import SubscriptionActivationSidebar from '@components/SubscriptionActivationSidebars/SubscriptionActivationSidebar';
import ClickToShowMore from '@components/CampaignSettings/ManualConfirmContactSideBar/_components/Plugs/ClickToShowMore';
import NothingWasFound from '@components/CampaignSettings/ManualConfirmContactSideBar/_components/Plugs/NothingWasFound';
import { OpportunityPageType } from '@ts/opportunity.types';
import { BillingState } from 'respona_api/generated/billing_pb';
import useNonInitialEffect from '@hooks/useNonInitialEffect';

function ToolboxEmailFinder({
  setUpsellCreditsSidebarOpen,
}: {
  setUpsellCreditsSidebarOpen: (open: boolean) => void;
}): JSX.Element {
  const queryClient: QueryClient = useQueryClient();
  const dispatch: DispatchType = useDispatch();

  const [searchType, changeSearchType] = useState(manualSearchTypes.domain);

  const [upsellCreditsSidebarOpened, changeUpsellCreditsSidebarOpened] = useState(false);
  const [isPaymentSidebarOpen, setPaymentSidebarOpen] = useState<boolean>(false);
  const [isSearchMade, setIsSearchMade] = useState<boolean>(false);
  const openUpsellSidebar = () => changeUpsellCreditsSidebarOpened(true);
  const closeUpsellSidebar = () => changeUpsellCreditsSidebarOpened(false);

  const [searchFields, changeSearchFields] = useState({
    domainString: '',
    jobPosition: '',
    fullName: '',
    linkedIn: '',
    seniority: [],
    department: [],
  });

  const { data: billingDetails } = useSelector(billingDetailsSelector);
  const workspaceId = useCurrentWorkspaceId();

  const {
    data,
    isLoading,
    isFetching,
    refetch: refetchContactsSearch,
    remove,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  } = useInfiniteQuery<any[], Error>(
    ['manual-contacts-search', searchFields, searchType],
    ({ pageParam = 0 }) => {
      if (
        searchType === manualSearchTypes.domain &&
        billingDetails.currentBonusCreditsNumber + billingDetails.currentPaidCreditsNumber < 10
      ) {
        if (billingDetails.state === BillingState.BILLING_TRIAL) {
          setPaymentSidebarOpen(true);
        } else {
          openUpsellSidebar();
        }
        return [];
      }
      return getSearchQuery(pageParam)
        .then((res) => res.map((item) => ({ ...item, category: 2 })))
        .finally(() => {
          dispatch(fetchBillingCredits());
        });
    },
    {
      getNextPageParam: (lastPage: any[], allPages) => {
        if (lastPage.length < 10) return false;

        return allPages.length;
      },
      enabled: false,
      retry: 1,
      refetchOnWindowFocus: false,
    }
  );

  useNonInitialEffect(() => {
    refetchContactsSearch();
  }, [searchFields]);

  // @ts-ignore
  const flatContactsData: OpportunityPageType[] = data?.pages?.flat() || [];

  const handleAddPersonEmails = (personId, personDetails) => {
    try {
      queryClient.setQueryData(
        ['manual-contacts-search', searchFields, searchType],
        ({ pageParams, pages }) => {
          const updatedPages = pages.map((page: any[]) => {
            return page.map((contact) => {
              return contact.optionalPersonId === personId ||
                (contact.company === personDetails.company &&
                  contact.jobPosition === personDetails.jobPosition &&
                  contact.location === personDetails.location &&
                  contact.name === personDetails.name)
                ? { ...personDetails, totalItems: contact.totalItems }
                : contact;
            });
          });
          return { pageParams, pages: updatedPages };
        }
      );
    } catch (e) {
      console.error(e);
    }

    dispatch(fetchBillingCredits());
  };

  const handleContactsSearch = (fields) => {
    setIsSearchMade(true);
    changeSearchFields(fields);
    refetchContactsSearch();
  };

  const getSearchQuery = (pageParam: number) => {
    console.log('Search fields: ', searchFields);
    switch (searchType) {
      case manualSearchTypes.domain: {
        if (!searchFields.domainString.length) {
          return Promise.reject();
        }
        return searchByDomainAndJobPositionApi(
          searchFields.domainString,
          searchFields.jobPosition,
          workspaceId,
          pageParam
        );
      }
      case manualSearchTypes.name: {
        if (searchFields.fullName == null || !searchFields.fullName.length) {
          return Promise.reject();
        }
        return searchByDomainAndNameApi(
          searchFields.fullName,
          searchFields.domainString,
          workspaceId
        );
      }
      case manualSearchTypes.linkedin:
        return searchBySocialLinkApi(searchFields.linkedIn, workspaceId);
      default:
        return Promise.resolve([]);
    }
  };

  useEffect(() => {
    remove();
  }, [searchType]);

  return (
    <div className="toolbox-email-finder">
      <PageHeader title="Email Finder" />
      <div className="toolbox-email-finder__content">
        <div className="toolbox-email-finder__header">
          <div className="toolbox-email-finder__source-select">
            <span style={{ marginRight: '11px' }}>Search people by:</span>
            <MultiSwitch
              options={Object.values(manualSearchTypes).filter(
                (item) => item !== manualSearchTypes.linkedin && item !== manualSearchTypes.manually
              )}
              onChange={changeSearchType}
              value={searchType}
            />
          </div>
        </div>

        <ContactsSearchField onSearchContacts={handleContactsSearch} searchType={searchType} />

        <div className="toolbox-email-finder__bulk-notice">
          To find emails in bulk, <a href="/campaign-templates">create a campaign.</a>
        </div>

        <Loader isLoading={isLoading || isFetching} withTopMargin>
          <Display isVisible={!isSearchMade || !flatContactsData}>
            <ClickToShowMore />
          </Display>

          <Display isVisible={isSearchMade && !!flatContactsData && flatContactsData?.length === 0}>
            <NothingWasFound />
          </Display>

          <Display isVisible={flatContactsData?.length > 0}>
            <ContactsSearchResultGroup
              title="Found contacts by domain"
              contacts={flatContactsData}
              onAddEmails={handleAddPersonEmails}
              onRemovePersonContact={() => null}
              onRemoveOpportunityContact={() => null}
              searchType={searchType}
              domain={searchFields.domainString}
              searchFields={searchFields}
              onError={(e) => {
                if (e.message?.includes('enough credits')) {
                  setUpsellCreditsSidebarOpen(true);
                }
              }}
              fetchNextPage={fetchNextPage}
              isFetchingNextPage={isFetchingNextPage}
              hasNextPage={hasNextPage}
            />
          </Display>
        </Loader>
      </div>

      {isPaymentSidebarOpen && (
        <SubscriptionActivationSidebar
          title="Activate your subscription"
          onClose={() => {
            setPaymentSidebarOpen(false);
          }}
          onActionAfterPay={() => {
            setPaymentSidebarOpen(false);
            dispatch(fetchBillingDetails());
            dispatch(fetchBillingCredits());
          }}
        />
      )}

      {upsellCreditsSidebarOpened && (
        <UpsellCreditsSidebar
          title="You don&rsquo;t have enough credits for this action. How would you like to proceed?"
          onClose={closeUpsellSidebar}
          needForAutomation={5}
        />
      )}
    </div>
  );
}

export default ToolboxEmailFinder;
