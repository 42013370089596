import React, { useEffect, Suspense, lazy } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import qs from 'query-string';

import Loader from '@uikit/Loader/Loader';

import useCurrentWorkspaceId from '@hooks/useCurrentWorkspaceId';
import loadingStatuses from '@constants/loadingStatuses';

import { peopleFoldersSelector } from '@redux/selectors/people.selectors';
import { companiesFoldersSelector } from '@redux/selectors/companies.selectors';
import { fetchAllFolders } from '@redux/thunks/people.thunks';
import { getAllCompanyFolders } from '@redux/thunks/peopleCompany.thunks';

import './RelationshipsPage.scss';

// Lazily-load components
const RelationshipsSidebar = lazy(
  () => import('@components/Relationships/RelationshipsSidebar/RelationshipsSidebar')
);
const RelationshipsPeople = lazy(
  () => import('@components/Relationships/RelationshipsPeople/RelationshipsPeople')
);
const RelationshipsOrganizations = lazy(
  () => import('@components/Relationships/RelationshipsOrganizations/RelationshipsOrganizations')
);
const RelationshipsPipelines = lazy(
  () => import('@components/Relationships/RelationshipsPipelines/RelationshipsPipelines')
);
const PipelineDeal = lazy(() => import('@components/Relationships/PipelineDeal/PipelineDeal'));

function RelationshipsPage({
  match: {
    params: { relationshipsPage },
  },
  location: { search },
}): JSX.Element {
  const dispatch = useDispatch();
  const searchParams: { folderId?: string | undefined; id?: string } = qs.parse(search);
  const { loadingStatus: foldersLs } = useSelector(peopleFoldersSelector);
  const { loadingStatus: companiesFoldersLs } = useSelector(companiesFoldersSelector);
  const workspaceId = useCurrentWorkspaceId();

  useEffect(() => {
    if (foldersLs === loadingStatuses.PENDING) {
      fetchAllFolders(dispatch);
    }

    if (companiesFoldersLs === loadingStatuses.PENDING) {
      getAllCompanyFolders(dispatch);
    }
  }, []);

  const isLoading = foldersLs !== loadingStatuses.LOADED;

  return (
    <div className="relationships-page">
      <Loader isLoading={isLoading} withTopMargin>
        <Suspense fallback={<Loader isLoading withTopMargin />}>
          <RelationshipsSidebar searchParams={searchParams} activePage={relationshipsPage} />
          <div className="relationships-page__content">
            <Switch>
              <Route path="/workspaces/:workspaceId?/relationships/websites">
                <RelationshipsOrganizations searchParams={searchParams} />
              </Route>
              <Route path="/workspaces/:workspaceId?/relationships/people">
                <RelationshipsPeople searchParams={searchParams} />
              </Route>
              <Route path="/relationships/deals/:dealId">
                <PipelineDeal />
              </Route>
              <Route path="/workspaces/:workspaceId?/relationships/pipelines">
                <RelationshipsPipelines />
              </Route>
              <Route path="/workspaces/:workspaceId?/relationships*">
                <Redirect to="/relationships/websites" />
              </Route>
            </Switch>
          </div>
        </Suspense>
      </Loader>
    </div>
  );
}

export default RelationshipsPage;
