const urls = {
  // campaigns
  DASHBOARD: '/dashboard',
  CREATE_NEW_CAMPAIGN(workspaceId: number): string {
    return `/workspaces/${workspaceId}/campaign-templates`;
  },
  CAMPAIGN_BUILDER(workspaceId: number): string {
    return `/workspaces/${workspaceId}/campaign-builder/:campaignId/:step`;
  },
  ALL_CAMPAIGNS(workspaceId: number): string {
    return `/workspaces/${workspaceId}/campaigns`;
  },
  DELETED(workspaceId: number): string {
    return `/workspaces/${workspaceId}/campaigns/deleted`;
  },

  // settings
  PROFILE: '/settings/profile',
  ALL_MEMBERS: '/settings/all-members',
  ALL_EMAILS: '/settings/all-emails',
  BILLING: '/settings/billing',
  CHANGE_BILLING: '/settings/billing/change-billing',
  CHANGE_SUBSCRIPTION: '/settings/billing/change-subscription',
  INVOICE(id: number): string {
    return `/settings/billing/invoice/${id}`;
  },
  TEMPLATES: '/settings/templates',
  NEW_TEMPLATE: '/settings/new-template',

  INQUIRIES_FILTERS: '/inquiries/filter',
  INQUIRIES_FILTERS_CREATE: '/inquiries/filter/create',
  INQUIRIES_FILTERS_UPDATE: '/inquiries/filter/update',

  PERMISSION_DENIED: '/permission_denied',

  LOGIN: '/welcome',
  SIGNIN: '/login',
  REGISTER: '/register',
  SIGNUP: '/register',
  FORGOT_PASSWORD: '/forgot-password',
  RESTORE_PASSWORD: '/restore',
  BD_LOGIN: '/bd/login',
  ONBOARDING_PAYMENT: '/trial',
  ONBOARDING_CALL: 'onboarding',
  ONBOARDING_ONBOARDING: '/onboarding/onboarding',
  ONBOARDING_MEMBERS: '/onboarding/members',
  ONBOARDING_TUTORIAL: '/onboarding/tutorial',

  MAINTENANCE: '/maintenance',
};

export const pagesWithIntercom = [
  urls.LOGIN,
  urls.REGISTER,
  urls.FORGOT_PASSWORD,
  urls.RESTORE_PASSWORD,
  urls.BD_LOGIN,
  urls.ONBOARDING_PAYMENT,
  urls.ONBOARDING_ONBOARDING,
  urls.ONBOARDING_MEMBERS,
  urls.ONBOARDING_TUTORIAL,
  urls.CAMPAIGN_BUILDER,
  urls.CHANGE_SUBSCRIPTION,
];

export const intercomLeftPositionPages = [
  urls.LOGIN,
  urls.REGISTER,
  urls.ONBOARDING_CALL,
  urls.FORGOT_PASSWORD,
  urls.RESTORE_PASSWORD,
  urls.CAMPAIGN_BUILDER,
];

export const urlPrefixWithoutWorkspace = ['dashboard', 'settings', 'toolbox'];

export default urls;
