import React from 'react';
import { Link, useHistory } from 'react-router-dom';

import { getApproximateTimePeriod, getTimeDifference } from '@utils/getFormattedPastTime';

import TextWithIcon from '@uikit/TextWithIcon/TextWithIcon';
import { SVGIcon } from '@uikit/Icon/Icon';

import './TrialPeriodCard.scss';
import { redirectUserTo } from '@utils/historyHandler';

function TrialPeriodCard({
  endDate,
  paymentExpired,
}: {
  endDate: number;
  paymentExpired: boolean;
}): JSX.Element {
  const history = useHistory();
  const redirectionUrl = '/settings/billing/change-subscription';
  return (
    <div className="trial-period-card">
      <div className="trial-period-card__text-container">
        <div className="trial-period-card__warning-icon">
          <SVGIcon icon="warningTriangle" color="#ED483D" size={14} />
        </div>

        <span className="trial-period-card__text">
          {getTimeDifference(endDate, 'm') < 0 || paymentExpired
            ? 'Your trial has ended'
            : `Your trial is ending in ${getApproximateTimePeriod(endDate, true).toLowerCase()}`}
        </span>
      </div>

      <Link
        to={redirectionUrl}
        onClick={(event) => {
          redirectUserTo(history, redirectionUrl);
        }}
      >
        <TextWithIcon icon="arrowUpCircle" iconColor="#3424EB" iconSize={18}>
          Upgrade
        </TextWithIcon>
      </Link>
    </div>
  );
}

export default TrialPeriodCard;
