import React, { useEffect, useRef, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import cn from 'class-names';
import { CampaignVerificationStatus } from 'respona_api/generated/campaign_pb';

import { DispatchType } from 'src/store';

import loadingStatuses from '@constants/loadingStatuses';

import { getActiveCampaignInfo } from '@redux/selectors/campaignSettings.selectors';
import { sequenceSelector } from '@redux/selectors/sequence.selectors';
import { fetchSequenceInfo } from '@redux/thunks/sequence.thunks';

import { Button } from '@uikit/Button/Button';
import { SVGIcon } from '@uikit/Icon/Icon';

import VerifyAndLaunchCampaignButton from '@components/VerifyAndLaunchCampaignButton/VerifyAndLaunchCampaignButton';

import './StepsLinks.scss';
import Input from '@uikit/Input/Input';
import Loader from '@uikit/Loader/Loader';
import { campaignService } from 'src/services/campaignService';
import CrosedIcon from '@uikit/Icon/svgIcons/CrosedIcon';
import { checkContactsExistsByCampaignIdApi } from '@api/campaign.api';
import ReactTooltip from 'react-tooltip';
import Display from '@components/Display/Display';
import { redirectUserTo } from '@utils/historyHandler';
import useCurrentWorkspaceId from "@hooks/useCurrentWorkspaceId";

const stepsLinksConf = (campaignInfo, sequenceSteps, hasContacts?: boolean) => [
  {
    key: 'sequence',
    title: 'Sequence',
    link: `/workspaces/${campaignInfo.workspaceId}/campaign-builder/${campaignInfo.id}/sequence`,
    helpLink: 'https://help.respona.com/en/articles/6332488-how-to-create-a-sequence',
    disabled: false,
    completed:
      !!sequenceSteps &&
      sequenceSteps.length > 0 &&
      !!sequenceSteps?.[0]?.content.replace(/<[^>]+>/g, '') &&
      !!sequenceSteps?.[0]?.subject.replace(/<[^>]+>/g, ''),
  },
  {
    key: 'opportunities',
    title: 'Opportunities',
    link: `/workspaces/${campaignInfo.workspaceId}/campaign-builder/${campaignInfo.id}/opportunities`,
    helpLink: 'https://help.respona.com/en/articles/6332465-how-to-find-opportunities',
    disabled: false,
    completed: !!hasContacts,
  },
  // {
  //   key: 'confirm',
  //   title: 'Get contacts',
  //   link: `/workspaces/${campaignInfo.workspaceId}/campaign-builder/${campaignInfo.id}/confirm`,
  //   helpLink: 'https://help.respona.com/en/articles/6332496-how-to-find-contacts',
  //   disabled: !opportunitiesPreview.totalItems,
  //   completed: !!opportunitiesPreview.withContactsItems,
  // },
  {
    key: 'personalize',
    title: 'Review & launch',
    link: `/workspaces/${campaignInfo.workspaceId}/campaign-builder/${campaignInfo.id}/personalize`,
    helpLink: 'https://help.respona.com/en/articles/6332514-how-to-personalize-pitches',
    disabled: !hasContacts || sequenceSteps == null,
    completed: campaignInfo.verificationStatus === CampaignVerificationStatus.VERIFIED,
  },
];

export default function ({
  activeLink,
  campaignId,
}: {
  activeLink: string;
  campaignId: number;
}): JSX.Element {
  const ref = useRef<HTMLDivElement>();

  const dispatch = useDispatch<DispatchType>();
  const queryClient = useQueryClient();
  const history = useHistory();
  const currentWorkspaceId = useCurrentWorkspaceId();

  const { steps: sequenceSteps, loadingStatus: sequenceLoadingStatus } =
    useSelector(sequenceSelector);

  const inputRef = useRef<HTMLInputElement>();

  const [isPrefetching, setIsPrefetching] = useState<boolean>(false);
  const [title, setTitle] = useState<string>('');
  const [inputFocused, setInputFocused] = useState<boolean>(false);
  const [isUpdatingCampaign, setIsUpdatingCampaign] = useState<boolean>(false);

  const { info: campaignInfo, loadingStatus: campaignInfoLs } = useSelector(getActiveCampaignInfo);

  const { data: contactsExistsByCampaign } = useQuery(
    ['contacts-exists-by-campaign', campaignInfo.id, campaignInfo.lastBatch],
    () =>
      checkContactsExistsByCampaignIdApi(
        campaignInfo.id,
        campaignInfo.workspaceId,
        campaignInfo.lastBatch
      ),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: !!campaignInfo,
    }
  );

  useEffect(() => {
    if (!campaignInfo?.id) {
      return;
    }

    if (campaignInfoLs === loadingStatuses.LOADED && campaignInfo?.id === campaignId) {
      (async () => {
        setIsPrefetching(false);
      })();
    }
  }, [campaignInfoLs, campaignInfo?.id]);

  useEffect(() => {
    if (
      campaignInfo != null &&
      campaignInfo.lastSequenceId > 0 &&
      sequenceLoadingStatus === loadingStatuses.PENDING
    ) {
      dispatch(fetchSequenceInfo(campaignInfo.lastSequenceId));
    }
  }, [sequenceLoadingStatus, campaignInfo]);

  useEffect(() => {
    if (campaignInfo?.title) {
      setTitle(campaignInfo?.title);
    }
  }, [campaignInfo]);

  const updateCampaignName = async () => {
    setInputFocused(false);
    if (title === campaignInfo.title) {
      return;
    }

    if (title.length === 0) {
      setTitle(campaignInfo.title);
      return;
    }

    setIsUpdatingCampaign(true);

    try {
      await campaignService.update({ ...campaignInfo, title }, dispatch);
    } catch (e) {
      console.error(e);
    } finally {
      setIsUpdatingCampaign(false);
    }
  };

  const config = stepsLinksConf(campaignInfo, sequenceSteps, contactsExistsByCampaign?.value);

  const activeStepIndex = config.findIndex((item) => item.key === activeLink);

  function adjustedWidth(campaignTitle: string): number {
    let width = 0;
    // eslint-disable-next-line no-restricted-syntax
    for (const char of campaignTitle) {
      if (char === char.toUpperCase()) {
        width += 1;
      } else {
        width += 0.9;
      }
    }
    return Math.ceil(width);
  }

  return (
    <div className="steps-links" ref={ref}>
      {/* <Button */}
      {/*  onClick={() => redirectUserTo(config[activeStepIndex - 1]?.link)} */}
      {/*  disabled={activeLink === config[0].key} */}
      {/*  className="steps-links__back-button" */}
      {/* > */}
      {/*  Back */}
      {/* </Button> */}

      <Button
        onClick={() => redirectUserTo(history, `/workspaces/${currentWorkspaceId}/campaigns`)}
        className="steps-links__back-button-close"
        tabIndex={0}
        role="button"
        data-for="closeCmp"
        data-tip=""
      >
        <CrosedIcon color="#BDBDBD" />
        <Display isVisible>
          <ReactTooltip
            id="closeCmp"
            className="react-tooltip"
            place="bottom"
            effect="solid"
            offset={{ bottom: 0, left: 0 }}
            arrowColor="transparent"
          >
            <div className="steps-links__back-button-close-tooltip">Save & close</div>
          </ReactTooltip>
        </Display>
      </Button>

      <div className="campaign-creation-wrapper__campaign-name">
        {!!campaignInfo && (
          <Input
            className="campaign-creation-wrapper__settings-name"
            placeholder="Enter campaign name"
            type="text"
            value={title}
            maxLength={40}
            onBlur={() => updateCampaignName()}
            onFocus={() => setInputFocused(true)}
            onKeyDown={(e) => (e.key === 'Enter' ? inputRef.current?.blur() : null)}
            onChange={({ target: { value } }) => setTitle(value)}
            afterText={
              <Loader isLoading={isUpdatingCampaign} style={{ width: '14px', height: '14px' }} />
            }
            style={{ width: `${adjustedWidth(title.toString())}` + 'ch' }}
          />
        )}

        {title?.length > 39 && inputFocused ? (
          <div className="campaign-creation-wrapper__campaign-name-length">
            Name should not exceed 40 character
          </div>
        ) : null}
      </div>

      <div style={{ display: 'flex', alignItems: 'center' }}>
        {config.map(({ link, key, title, disabled, completed }, index, arr) => (
          <React.Fragment key={key}>
            <div
              className={cn('step-link__container', {
                'step-link__container--disabled': isPrefetching || disabled,
                'step-link__container--completed': completed,
              })}
            >
              <Link
                to={link}
                key={key}
                className={cn('step-link', {
                  'step-link--active': activeLink === key,
                })}
                onClick={(event) => {
                  redirectUserTo(history, link);
                }}
              >
                <div className="step-link__step">
                  {completed ? <SVGIcon icon="check" color="#23E771" size={10} /> : index + 1}
                </div>

                <div className="step-link__link-title">{title}</div>
              </Link>
            </div>

            {index !== arr.length - 1 ? <SVGIcon icon="chevron" size={10} color="#BBBBBB" /> : null}
          </React.Fragment>
        ))}
      </div>

      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        {activeLink === config[config.length - 1].key ? (
          <VerifyAndLaunchCampaignButton
            campaignId={campaignInfo.id}
            onVerificationComplete={async () => {
              queryClient.refetchQueries({
                queryKey: ['personalize-campaign-opportunities-preview'],
                exact: false,
              });
            }}
          />
        ) : (
          <Button
            onClick={() => redirectUserTo(history, config[activeStepIndex + 1]?.link)}
            disabled={activeLink === config[config.length - 1].key}
            className={`steps-links__next-button ${
              config[activeStepIndex].completed ? '' : 'steps-links__next-button--in-completed'
            }`}
          >
            Next step
          </Button>
        )}
      </div>
    </div>
  );
}
