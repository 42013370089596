import { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { redirectUserTo } from '@utils/historyHandler';

// accessGoOut -> function that allows you to go out
// actionToGoOut -> function that redirect you to path that you wanted before block

const useBlockHistoryChanging = (
  onAction: () => void,
  isTurnedOn = true
): [() => void, (url: string) => void] => {
  const history = useHistory();
  const actionToGoOut = useRef<() => void>(() => null);
  const canGoOutFromPage = useRef<boolean>(true);
  const unblock = useRef<any>(null);

  const forceRedirect = (url: string): void => {
    unblock.current();
    redirectUserTo(history, url);
  };

  useEffect(() => {
    unblock.current = history.block((tx, action) => {
      if (!canGoOutFromPage.current) {
        onAction();
        actionToGoOut.current = () => {
          canGoOutFromPage.current = true;
          redirectUserTo(history, tx.pathname);
        };
        return false;
      }

      return true;
    });
  }, [onAction, isTurnedOn]);

  useEffect(() => {
    if (isTurnedOn) {
      canGoOutFromPage.current = false;
    }

    if (!isTurnedOn) {
      unblock.current();
    }

    return () => unblock.current();
  }, [isTurnedOn]);

  return [actionToGoOut.current, forceRedirect];
};

export default useBlockHistoryChanging;
