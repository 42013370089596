import urls from '@constants/urls';

import { OrganizationMemberRoleMap } from 'respona_api/generated/user-organization_pb';
import * as common_pb from 'respona_api/generated/common_pb';

export const memberRoleMap = {
  0: 'Owner',
  1: 'Admin',
  2: 'Member',
};

export const memberRoleOptions: OrganizationMemberRoleMap = {
  OWNER: 0,
  ADMIN: 1,
  MEMBER: 2,
};

export const unsubscribeScopeOptions = {
  GLOBAL: 'Global',
  WORKSPACE: 'Workspace',
};

export const memberStatusMap = {
  0: 'Invite Inbox',
  1: 'Active',
  2: 'Invite Expired',
};

export const onboardingStepsUrls: {
  [key in common_pb.AuthStepMap[keyof common_pb.AuthStepMap]]: string;
} = {
  0: '/welcome',
  1: '/trial',
  2: '/onboarding/members',
  3: '/onboarding/onboarding',
  4: '/onboarding/tutorial',
  5: '/workspaces/0/campaign-templates',
  6: urls.DASHBOARD,
};
