import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button } from '@uikit/Button/Button';
import './OpportunityInspect.scss';
import {
  CampaignOpportunityFilterType,
  OpportunityDetailsType,
  OpportunityPageType,
} from '@ts/opportunity.types';
import CrosedIcon from '@uikit/Icon/svgIcons/CrosedIcon';
import Input from '@uikit/Input/Input';
import Iframe from '@components/CampaignSettings/OpportunityInspect/_components/Iframe/Iframe';
import { SVGIcon } from '@uikit/Icon/Icon';
import ReactTooltip from 'react-tooltip';
import {
  getCampaignShortcutsApi,
  markOpportunityAsReviewedApi,
  moveOpportunityToCampaignApi,
  removeOpportunityApi,
  replaceOpportunityUrlByOpportunityIdApi,
} from '@api/campaign.api';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentWorkspaceId } from '@redux/selectors/workspaces.selectors';
import { addBlackListPatternsApi } from '@api/mailbox.api';
import { useQueryClient } from 'react-query';
import { addNotification } from '@redux/actions/notifications.actions';
import { DispatchType } from 'src/store';
import useOutsideClick from '@hooks/useOutsideClick';
import { CampaignShortcutType } from '@ts/campaigns.types';
import { billingDetailsSelector } from '@redux/selectors/billings.selectors';
import OpportunityInspectIllustration from '@uikit/Icon/illustrations/OpportunityInspectIllustration';
import ContentSearchEditorV2 from '@components/CampaignSettings/ContentSearch/ContentAutomationSearch/_components/ContentSearchEditor/ContentSearchEditorV2';
import ErrorBoundary from '@uikit/ErrorBoundaries/ErrorBoundary';
import { redirectUserTo } from '@utils/historyHandler';

function OpportunityInspect({
  opportunity,
  totalItemsNumber,
  setActiveOpportunity,
  opportunityIndex,
  onCloseClick,
  goToItemIndex,
  filters,
}: {
  opportunity: OpportunityPageType;
  totalItemsNumber: number | undefined;
  setActiveOpportunity: Dispatch<SetStateAction<OpportunityPageType>>;
  opportunityIndex: number;
  onCloseClick: () => boolean;
  goToItemIndex: (index: number) => void;
  filters: CampaignOpportunityFilterType[];
}): JSX.Element {
  const confirmBtnRef = useRef<HTMLButtonElement>();
  const dispatch = useDispatch<DispatchType>();
  const currentWorkspaceId = useSelector(getCurrentWorkspaceId);
  const [currentUrl, setCurrentUrl] = useState<string>(opportunity.url);
  const [tempCurrentUrl, setTempCurrentUrl] = useState<string[]>([opportunity.url]);
  const [isVisibleRemoveApproval, setVisibleRemoveApproval] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [campaigns, setCampaigns] = useState<CampaignShortcutType[]>([]);

  const {
    data: { extendedFeaturesEnabled, enterpriseFeaturesEnabled },
  } = useSelector(billingDetailsSelector);

  const queryClient = useQueryClient();
  const { campaignId } = useParams();
  const maxIndex = totalItemsNumber || opportunity.totalItems;

  useOutsideClick(confirmBtnRef, () => setVisibleRemoveApproval(false));

  useEffect(() => {
    getCampaignShortcutsApi(currentWorkspaceId).then(setCampaigns);
  }, []);

  useEffect(() => {
    setCurrentUrl(opportunity.url);
    setTempCurrentUrl([opportunity.url]);
  }, [opportunity.url]);

  function onDeleteClick() {
    if (extendedFeaturesEnabled) {
      setIsDeleting(true);
      removeOpportunityApi(
        Number(campaignId),
        currentWorkspaceId,
        opportunity.batch,
        opportunity.uid
      )
        .then(() => onRemoveHandler())
        .finally(() => {
          setVisibleRemoveApproval(false);
          setIsDeleting(false);
        });
    } else {
      dispatch(addNotification({ title: `Upgrade the plan to use this feature`, type: 'warning' }));
    }
  }

  function onRemoveHandler() {
    try {
      queryClient.setQueryData(
        ['opportunities-with-metrics', Number(campaignId), currentWorkspaceId, filters],
        ({ pageParams, pages }) => {
          const updatedPages = pages.map((page: OpportunityPageType[]) => {
            return page
              .filter((oppo: OpportunityPageType) => oppo.id !== opportunity.id)
              .map((it) => {
                return { ...it, totalItems: it.totalItems - 1 };
              });
          });
          goToItemIndex(opportunityIndex);
          return { pageParams, pages: updatedPages };
        }
      );

      // if the last opportunity was removed then close the modal
      if (opportunity.totalItems === 1) {
        onCloseClick();
      }
    } catch (e) {
      console.log('Error updating data with metrics', e);
    }
  }

  function updateOpportunityUrl() {
    if (extendedFeaturesEnabled) {
      replaceOpportunityUrlByOpportunityIdApi(opportunity.id, currentWorkspaceId, currentUrl).then(
        (response) => {
          setActiveOpportunity({ ...opportunity, url: response.url, title: response.title });
          dispatch(addNotification({ title: 'Opportunity URL is updated.', type: 'success' }));
          try {
            queryClient.setQueryData(
              ['opportunities-with-metrics', Number(campaignId), currentWorkspaceId, filters],
              ({ pageParams, pages }) => {
                const updatedPages = pages.map((page: OpportunityPageType[]) => {
                  return page.map((oppo: OpportunityPageType) => {
                    if (oppo.id === opportunity.id) {
                      return { ...oppo, url: response.url, title: response.title };
                    }
                    return oppo;
                  });
                });
                return { pageParams, pages: updatedPages };
              }
            );
          } catch (e) {
            console.log('Error updating data with metrics', e);
          }
          try {
            queryClient.setQueryData(
              ['active-opportunity', opportunity.id],
              (cachedOpp: OpportunityDetailsType) => {
                return {
                  ...cachedOpp,
                  url: currentUrl,
                };
              }
            );
          } catch (e) {
            console.log('Error updating active opportunity', e);
          }
        }
      );
    } else {
      dispatch(addNotification({ title: `Upgrade the plan to use this feature`, type: 'warning' }));
    }
  }

  function onKeepClick() {
    if (extendedFeaturesEnabled) {
      markOpportunityAsReviewedApi(opportunity.id, currentWorkspaceId, Number(campaignId)).then(
        () => {
          setActiveOpportunity({ ...opportunity, reviewed: true });
          try {
            queryClient.setQueryData(
              ['opportunities-with-metrics', Number(campaignId), currentWorkspaceId, filters],
              ({ pageParams, pages }) => {
                const updatedPages = pages.map((page: OpportunityPageType[]) => {
                  return page.map((oppo: OpportunityPageType) => {
                    if (oppo.id === opportunity.id) {
                      return { ...oppo, reviewed: true };
                    }
                    return oppo;
                  });
                });
                return { pageParams, pages: updatedPages };
              }
            );
          } catch (e) {
            console.log('Error updating data with metrics', e);
          }
          try {
            queryClient.setQueryData(
              ['active-opportunity', opportunity.id],
              (cachedOpp: OpportunityDetailsType) => {
                return {
                  ...cachedOpp,
                  reviewed: true,
                };
              }
            );
          } catch (e) {
            console.log('Error updating active opportunity', e);
          }
          if (opportunityIndex + 1 <= maxIndex) {
            goToItemIndex(opportunityIndex + 1);
          } else {
            goToItemIndex(maxIndex);
          }
        }
      );
    } else {
      dispatch(addNotification({ title: `Upgrade the plan to use this feature`, type: 'warning' }));
    }
  }

  function onUndoClick() {
    // TODO undo last action during 5 seconds after it.
  }

  // we should show the list of campaigns in some sort of dropdown, so user will be able to select to which campaign we will transfer opportunity
  function onMoveToAnotherCampaignClick(newCampaignId: number, newWorkspaceId: number) {
    if (extendedFeaturesEnabled) {
      moveOpportunityToCampaignApi(opportunity.id, newWorkspaceId, newCampaignId).then(() =>
        onRemoveHandler()
      );
    } else {
      dispatch(addNotification({ title: `Upgrade the plan to use this feature`, type: 'warning' }));
    }
  }

  function onUnsubscribeClick(workspaceIds: number[]) {
    if (!extendedFeaturesEnabled) {
      dispatch(addNotification({ title: `Upgrade the plan to use this feature`, type: 'warning' }));
      return;
    }
    if (workspaceIds.length > 0 && !enterpriseFeaturesEnabled) {
      dispatch(
        addNotification({
          title: `Contact support to update the plan to use this feature`,
          type: 'warning',
        })
      );
      return;
    }
    const domain = new URL(opportunity.url).hostname.replace(/^www\./, '');
    addBlackListPatternsApi([domain], workspaceIds).then(() => {
      if (workspaceIds.length > 1) {
        dispatch(
          addNotification({
            title: `${domain} added to the workspace unsubscribe list.`,
            type: 'success',
          })
        );
      } else {
        dispatch(
          addNotification({
            title: `${domain} added to the global unsubscribe list.`,
            type: 'success',
          })
        );
      }
    });
  }

  function OpportunityInspectBlockedPopup({ isHidden }: { isHidden: boolean }): JSX.Element {
    if (isHidden) {
      return <div />;
    }
    const history = useHistory();

    return (
      <div className="opportunity-inspect-modal">
        <div className="opportunity-inspect-modal_background" />
        <div className="opportunity-inspect-modal_content">
          <div className="opportunity-inspect-modal_content-image">
            <OpportunityInspectIllustration />
          </div>
          <h2 className="opportunity-inspect-modal_content-title">Unlock opportunity inspect</h2>
          <p className="opportunity-inspect-modal_content-info">
            Review and approve opportunities in Respona. Available on Pro and Unlimited plans.
          </p>
          <Button
            className="opportunity-inspect-modal_content-button"
            onClick={() => redirectUserTo(history, '/settings/billing/change-subscription')}
          >
            Upgrade my plan
          </Button>
        </div>
      </div>
    );
  }

  const handlePressEnterInBrowserUrl = () => {
    if (tempCurrentUrl.length) {
      let formattedInput = tempCurrentUrl[0];
      try {
        if (!formattedInput.match(/^[a-zA-Z]+:\/\//)) {
          formattedInput = `https://${formattedInput}`;
        }
        const url = new URL(formattedInput);
        setCurrentUrl(url.href);
      } catch (error) {
        dispatch(addNotification({ title: 'Invalid URL', type: 'error' }));
      }
    }
  };

  return (
    <div className="opportunity-inspect">
      <div className="opportunity-inspect__action-bar">
        <div className="opportunity-inspect__action-bar__header">
          <Button
            onClick={onCloseClick}
            className="opportunity-inspect__back-button-close"
            tabIndex={0}
            role="button"
          >
            <CrosedIcon color="#BDBDBD" />
          </Button>
          <div className="pagination__select">
            <Button
              type="additional"
              onClick={() => {
                if (opportunityIndex - 1 >= 0) {
                  goToItemIndex(opportunityIndex - 1);
                } else {
                  goToItemIndex(0);
                }
              }}
            >
              <SVGIcon icon="arrowLeft" color="#221cb6" />
            </Button>

            <div className="pagination__select-container">
              <span>Item:</span>

              <Input
                disabled={false}
                type="number"
                min={0}
                max={maxIndex}
                value={opportunityIndex + 1}
                onChange={({ target: { value } }) => {
                  const parsedNum = parseInt(value, 10);
                  if (parsedNum + 1 > maxIndex) {
                    goToItemIndex(maxIndex - 1);
                  } else if (!!parsedNum && parsedNum < 0) {
                    goToItemIndex(0);
                  } else {
                    goToItemIndex(parsedNum - 1);
                  }
                }}
                placeholder=""
              />

              <span>
                of{' '}
                <span
                  className="pagination__select-last-page"
                  onClick={() => goToItemIndex(maxIndex - 1)}
                >
                  {maxIndex}
                </span>
              </span>
            </div>

            <Button
              type="additional"
              className="rotate"
              onClick={() => {
                if (opportunityIndex + 1 < maxIndex) {
                  goToItemIndex(opportunityIndex + 1);
                } else {
                  goToItemIndex(maxIndex - 1);
                }
              }}
            >
              <SVGIcon icon="arrowLeft" color="#221cb6" />
            </Button>
          </div>

          <div className="buttons-container">
            <Button size="s" leftIcon="trash" type="ghost" onClick={() => onUndoClick()} />
            {/* <Button type="ghost" size="m" className="revert-button" onClick={() => {}}> */}
            {/*  <SVGIcon icon="refreshOneArrowAround" color="#221CB6" /> */}
            {/* </Button> */}
            {isVisibleRemoveApproval ? (
              <span ref={confirmBtnRef}>
                <Button
                  isLoading={isDeleting}
                  leftIcon="trash"
                  type="alert"
                  onClick={() => onDeleteClick()}
                >
                  Confirm
                </Button>
              </span>
            ) : (
              <Button leftIcon="trash" type="alert" onClick={() => setVisibleRemoveApproval(true)}>
                Remove
              </Button>
            )}
            <Button
              leftIcon={opportunity.reviewed ? 'checkCircled' : 'verifyPlayIcon'}
              type="confirm"
              disabled={opportunity.reviewed}
              onClick={() => onKeepClick()}
            >
              {opportunity.reviewed ? 'Kept' : 'Keep'}
            </Button>
            <Button type="show-more" data-for="show-more-id" data-tip="" data-event="click">
              <SVGIcon icon="threeDots" />
            </Button>
            <ReactTooltip
              event="click"
              globalEventOff="click"
              effect="float"
              place="bottom"
              clickable
              id="show-more-id"
              arrowColor="transparent"
              className="dropdown-tooltip"
            >
              <ul className="dropdown-list">
                <li
                  className="dropdown-list__item"
                  data-for="show-list-campaigns"
                  data-tip=""
                  data-event="click"
                >
                  <SVGIcon icon="inbox" />
                  <span>Move to other campaigns</span>
                </li>
                <li className="dropdown-list__item" onClick={() => onUnsubscribeClick([])}>
                  <SVGIcon icon="banWhite" />
                  <span>Global unsubscribe</span>
                </li>
                <li
                  className={`dropdown-list__item ${
                    enterpriseFeaturesEnabled ? '' : 'dropdown-list__item-disabled'
                  }`}
                  onClick={() => onUnsubscribeClick([currentWorkspaceId])}
                >
                  {enterpriseFeaturesEnabled ? (
                    <SVGIcon icon="banWhite" />
                  ) : (
                    <SVGIcon icon="lock" size={16} color="#fff" />
                  )}
                  <span>Workspace unsubscribe</span>
                </li>
              </ul>
            </ReactTooltip>
            <ReactTooltip
              event="click"
              globalEventOff="click"
              effect="float"
              place="left"
              clickable
              id="show-list-campaigns"
              arrowColor="transparent"
              className="dropdown-tooltip"
            >
              <ul className="dropdown-list">
                {campaigns.map(({ title, id }) => (
                  <li
                    key={id}
                    className="dropdown-list__item"
                    onClick={() => onMoveToAnotherCampaignClick(id, currentWorkspaceId)}
                  >
                    <span>{title}</span>
                  </li>
                ))}
              </ul>
            </ReactTooltip>
          </div>
        </div>
        <div className="opportunity-inspect__action-bar__navigation">
          <div className="opportunity-inspect__action-bar__navigation-buttons">
            <Button className="home" type="ghost" onClick={() => setCurrentUrl(opportunity.url)}>
              <SVGIcon icon="house" />
            </Button>

            <Button
              type="ghost"
              disabled={opportunity.url === currentUrl}
              onClick={() => updateOpportunityUrl()}
            >
              <SVGIcon icon="pin" />
            </Button>
          </div>
          <div className="opportunity-inspect__action-bar__navigation-address">
            <span className="lock-icon">
              <SVGIcon icon="lockGrey" />
            </span>

            <ContentSearchEditorV2
              key={currentUrl}
              onChangeQueries={setTempCurrentUrl}
              onPressEnter={handlePressEnterInBrowserUrl}
              queries={tempCurrentUrl}
              withLinks
              // isUrl
              maxLines={1}
            />
          </div>
        </div>
      </div>
      <div className="opportunity-inspect__preview-section">
        <OpportunityInspectBlockedPopup isHidden={extendedFeaturesEnabled} />
        <ErrorBoundary>
          {opportunity.url ? (
            <Iframe
              initialUrl={
                extendedFeaturesEnabled
                  ? opportunity.url
                  : 'https://respona.com/blog/find-bloggers/'
              }
              currentUrl={
                extendedFeaturesEnabled ? currentUrl : 'https://respona.com/blog/find-bloggers/'
              }
              setCurrentUrl={(value) => {
                setTempCurrentUrl([value]);
                setCurrentUrl(value);
              }}
            />
          ) : (
            <div />
          )}
        </ErrorBoundary>
      </div>
    </div>
  );
}

export default OpportunityInspect;
