import React from 'react';
import { Switch, Route, Redirect, useHistory } from 'react-router-dom';

import CustomFieldsSettingsPage from '@components/Settings/CustomFieldsSettingsPage/CustomFieldsSettingsPage';
import SettingsSidebar from '@components/Settings/SettingsSidebar/SettingsSidebar';
import ProfileSettings from '@components/Settings/ProfileSettings/ProfileSettings';
import EmailsSettings from '@components/Settings/EmailsSettings/EmailsSettings';
import SchedulesSettings from '@components/Settings/SchedulesSettings/SchedulesSettings';
import AllMembersSettings from '@components/Settings/MembersSettings/AllMembersSettings';
import EarnFreeCreditsSettings from '@components/Settings/EarnFreeCreditsSettings/EarnFreeCreditsSettings';

import Workspaces from '@components/Settings/Workspaces/Workspaces';
import IntegrationsPage from '@pages/IntegrationsPage/IntegrationsPage';
import CreateWorkspacePage from '@pages/CreateWorkspacePage/CreateWorkspacePage';
import TemplatesSettingPage from '@pages/TemplatesSettingPage/TemplatesSettingPage';
import SMTPEmailSettings from '@components/Settings/SMTPEmailSettings/SMTPEmailSettings';
import UnsubscribedList from '@pages/UnsubscribedListPage/UnsubscribedListPage';
import BillingPage from '@pages/BillingPage/BillingPage';
import TemplateEditorSettingPage from '@pages/TemplateEditorSettingPage/TemplateEditorSettingPage';
import NewTemplateSettingsPage from '@pages/TemplateEditorSettingPage/NewTemplateSettingsPage';

import ErrorBoundaryNoAccess from '@uikit/ErrorBoundaries/ErrorBoundaryNoAccess';

import useCurrentWorkspaceId from '@hooks/useCurrentWorkspaceId';
import urls from '@constants/urls';

import './SettingsPage.scss';
import { MailboxAccountResponse } from 'respona_api/generated/mailbox-account_pb';
import { MailboxAccountStatisticsType } from '@ts/mailboxAccounts.types';
import useMailboxAccountStatistics from '@hooks/useMailboxAccountStatistics';
import useMailboxAccounts from '@hooks/useMailboxAccounts';
import { redirectUserTo } from '@utils/historyHandler';

declare const IS_STANDALONE_MODE: boolean;

function SettingsPage({ location: { pathname } }): JSX.Element {
  const history = useHistory();
  const currentWorkspaceId = useCurrentWorkspaceId();

  const { addItem: addMailboxToStatisticsCache, refetch: refetchMailboxStatistics } =
    useMailboxAccountStatistics();
  const { addItem: addAccountToCache, updateItem: updateAccountInCache } =
    useMailboxAccounts(currentWorkspaceId);

  const onNewMailboxCreation = (
    mailbox: MailboxAccountResponse.AsObject,
    isReAuthentication: boolean
  ) => {
    try {
      if (isReAuthentication) {
        updateAccountInCache(mailbox.id, mailbox);
        refetchMailboxStatistics();
      } else {
        addAccountToCache(mailbox);
        addMailboxToStatisticsCache({
          ...mailbox,
          openRate: 0,
          replyRate: 0,
          dailySentToday: 0,
          healthScoreRate: 0,
        } as MailboxAccountStatisticsType);
      }
    } finally {
      redirectUserTo(history, '/settings/all-emails');
    }
  };

  return (
    <div className="settings-page">
      <SettingsSidebar pathname={pathname} />
      <div className="settings-page__content">
        <Switch>
          {IS_STANDALONE_MODE && (
            <Route path="/settings/profile">
              <ProfileSettings />
            </Route>
          )}
          <Route path="/settings/all-emails">
            <EmailsSettings />
          </Route>
          <Route path="/settings/email-smtp">
            <SMTPEmailSettings
              onSuccess={(mailbox, isReAuthentication) =>
                onNewMailboxCreation(mailbox, isReAuthentication)
              }
            />
          </Route>
          {IS_STANDALONE_MODE && (
            <Route path="/settings/all-members">
              <ErrorBoundaryNoAccess>
                <AllMembersSettings />
              </ErrorBoundaryNoAccess>
            </Route>
          )}
          <Route path="/settings/schedules">
            <SchedulesSettings />
          </Route>
          <Route path="/settings/billing">
            <ErrorBoundaryNoAccess>
              <BillingPage />
            </ErrorBoundaryNoAccess>
          </Route>
          <Route path="/settings/workspaces">
            <Workspaces />
          </Route>
          <Route path="/settings/unsubscribed-list">
            <UnsubscribedList />
          </Route>
          <Route path={`${urls.NEW_TEMPLATE}`}>
            <NewTemplateSettingsPage />
          </Route>
          <Route path={`${urls.TEMPLATES}/:id`}>
            <TemplateEditorSettingPage />
          </Route>
          <Route path={`${urls.TEMPLATES}`}>
            <TemplatesSettingPage />
          </Route>
          <Route path="/settings/create/workspace">
            <CreateWorkspacePage />
          </Route>
          <Route path="/settings/integrations">
            <IntegrationsPage />
          </Route>
          {IS_STANDALONE_MODE && (
            <Route path="/settings/referral">
              <EarnFreeCreditsSettings />
            </Route>
          )}
          <Route path="/settings/custom-fields">
            <CustomFieldsSettingsPage />
          </Route>
          <Route path="/settings/*">
            {currentWorkspaceId ? <Redirect to="/settings/all-emails" /> : null}
          </Route>
        </Switch>
      </div>
    </div>
  );
}

export default SettingsPage;
