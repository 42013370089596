import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { DispatchType } from 'src/store';
import { ImportModeEnum } from '@ts/fileImport.types';
import { SelectOptionType } from '@ts/common.types';

import { customVarsToOptions } from '@helpers/customVariablesHelpers';

import { setIsEmailMapped } from '@redux/actions/fileAttributes.actions';
import { addNotification } from '@redux/actions/notifications.actions';

import useCustomVariables from '@hooks/useCustomVariables';

import Button from '@uikit/Button/Button';

import CustomFieldsSidebar from '@components/CustomFieldsSidebar/CustomFieldsSidebar';

import MapAttributeRow from './_components/MapAttributeRow/MapAttributeRow';

import './MapAttributesStep.scss';
import { CustomVariableValueRelatedTo } from 'respona_api/generated/common_pb';

function MapAttributesStep({
  headersList,
  onBack,
  onImport,
  isLoading = false,
  importMode,
}): JSX.Element {
  const dispatch = useDispatch<DispatchType>();

  const [headers, changeHeaders] = useState([]);
  const [headerIndexToChangeToNewCustomVariable, changeHeaderIndexToChangeToNewCustomVariable] =
    useState<number | null>(null);
  const [isCustomVarModalOpen, changeIsCustomVarModalOpen] = useState(false);

  const { customVariables, refetchCustomVariables } = useCustomVariables();

  useEffect(() => {
    refetchCustomVariables();
  }, []);

  useEffect(() => {
    if (headersList && headersList.length) {
      changeHeaders(headersList);
    }
  }, [headersList]);

  const handleChangeHeaderMap = (
    headerIndex,
    option: SelectOptionType & { isCustomVariable?: boolean; id?: number; [key: string]: any }
  ) => {
    return changeHeaders((prevState) => {
      const newState = prevState.map((header, index) => {
        if (index === headerIndex) {
          return {
            ...header,
            mappedVariable: option.value,
            optionalCustomVariableId: option.isCustomVariable ? option.id : -1,
          };
        }

        return header;
      });

      const isEmailMapped = !!newState.find(({ mappedVariable }) => mappedVariable === 6);

      dispatch(setIsEmailMapped(isEmailMapped));

      return newState;
    });
  };

  const handleContinue = () => {
    onImport(headers.filter((it) => it != null));
  };

  const handleOpenCustomVarModal = useCallback((headerIndex: number) => {
    changeHeaderIndexToChangeToNewCustomVariable(headerIndex);
    changeIsCustomVarModalOpen(true);
  }, []);
  const handleCloseCustomVarModal = useCallback(() => changeIsCustomVarModalOpen(false), []);

  const handleAddNewCustomVariableToHeaders = useCallback(
    (variable) => {
      handleChangeHeaderMap(
        headerIndexToChangeToNewCustomVariable,
        customVarsToOptions([variable])[0]
      );
    },
    [headerIndexToChangeToNewCustomVariable]
  );

  const selectedVariables = headers.reduce(
    (acc, item) => (item.mappedVariable ? [...acc, item.mappedVariable] : acc),
    []
  );

  const isDisabled =
    (importMode === ImportModeEnum.OPPORTUNITY &&
      !headers.some((item) => item.mappedVariable === 1)) ||
    (importMode === ImportModeEnum.PERSON && !headers.some((item) => item.mappedVariable === 6));

  return (
    <>
      <div className="map-attribute-step">
        <div className="map-attribute-step__fields">
          {headers?.map((header, index) => (
            <MapAttributeRow
              index={index}
              onChange={handleChangeHeaderMap}
              key={header.title}
              row={header}
              customVars={customVariables
                ?.filter(
                  (item) =>
                    item.relatedTo !== CustomVariableValueRelatedTo.MISC &&
                    item.relatedTo !== CustomVariableValueRelatedTo.RELATIONSHIP_OPPORTUNITY
                )
                ?.filter((item) => item.name !== 'day_of_week' && item.name !== 'website_domain')}
              onAddCustomVar={() => handleOpenCustomVarModal(index)}
              selectedVariables={selectedVariables}
            />
          ))}
        </div>
        <div className="map-attribute-step__footer">
          <Button onClick={onBack} type="additional">
            Back
          </Button>
          <Button
            onClick={handleContinue}
            disabled={isDisabled}
            onClickDisabled={() =>
              dispatch(
                addNotification({
                  title: `Please select the ${
                    importMode === ImportModeEnum.PERSON ? '"Email"' : '"URL"'
                  } field.`,
                  type: 'warning',
                })
              )
            }
            isLoading={isLoading}
            loadingText="Loading..."
          >
            Continue
          </Button>
        </div>
      </div>

      <CustomFieldsSidebar
        isOpen={isCustomVarModalOpen}
        onlyTextVariableAvailable
        isUpdate={false}
        onClose={handleCloseCustomVarModal}
        onSave={handleAddNewCustomVariableToHeaders}
        relatedTo={importMode || 0}
      />
    </>
  );
}

export default MapAttributesStep;
