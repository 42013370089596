import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';

import './LeaderBoards.scss';
import VerticalComposedChart from '@components/Insights/VerticalComposedChart/VerticalComposedChart';
import PageHeader from '@components/PageHeader/PageHeader';
import InsightsCampaignHeader from '@components/Insights/InsightsCampaignHeader/InsightsCampaignHeader';
import BlockHeader from '@components/Insights/BlockHeader/BlockHeader';
import ConfigurableTable from '@uikit/ConfigurableTable/ConfigurableTable';
import ColumnsSelectIcon from '@uikit/ColumnsSelectIcon/ColumnsSelectIcon';
import Loader from '@uikit/Loader/Loader';
import { Button } from '@uikit/Button/Button';

import { useQuery } from 'react-query';
import { getTeamMembersInsightsApi } from '@api/analytics.api';
import { getAllWorkspacesShortApi } from '@api/userOrganization.api';

import {
  insightsDateFilterSelector,
  insightsLeaderBoardsFiltersSelector,
} from '@redux/selectors/insights.selector';
import { getCurrentWorkspaceId } from '@redux/selectors/workspaces.selectors';
import { billingDetailsSelector } from '@redux/selectors/billings.selectors';
import { TeamMembersInsightsType } from '@ts/insights.types';
import {
  setInsightsDateFilter,
  setInsightsLeaderBoardsFilters,
} from '@redux/actions/insights.actions';
import { DispatchType } from 'src/store';
import loadingStatuses from '@constants/loadingStatuses';
import LeaderBoardScale from '@uikit/Icon/illustrations/LeaderBoardScale';
import useWorkspaceMembers from '@hooks/useWorkspaceMembers';
import LeaderBoardIllustaration from '@uikit/Icon/illustrations/LeaderBoardIllustaration';
import { redirectUserTo } from '@utils/historyHandler';
import useCurrentWorkspaceId from '@hooks/useCurrentWorkspaceId';
import tableConfig from './tableConfig';

type LeaderTablePropsType = {
  availableColumns: any;
  handleUpdateTableSettings: (isSelected: boolean, column: any) => void;
  setCustomFieldsSidebarOpen?: (open: boolean) => void;
  tableData: TeamMembersInsightsType;
  sortingSelectedKey: string;
};

const availableColumnsForSelection = [
  {
    columnMappingName: 'teamMember',
    columnTitle: 'test ff',
    custom: false,
    enabled: true,
    id: -1,
    order: 1,
  },
  {
    columnMappingName: 'newCampaigns',
    columnTitle: 'New Campaigns',
    custom: false,
    enabled: true,
    id: -1,
    order: 2,
  },
  {
    columnMappingName: 'inprogressCampaigns',
    columnTitle: 'In-progress Campaigns',
    custom: false,
    enabled: true,
    id: -1,
    order: 3,
  },
  {
    columnMappingName: 'completedCampaigns',
    columnTitle: 'Completed Campaigns',
    custom: false,
    enabled: true,
    id: -1,
    order: 4,
  },
  {
    columnMappingName: 'newWebsites',
    columnTitle: 'New Websites',
    custom: false,
    enabled: true,
    id: -1,
    order: 5,
  },
  {
    columnMappingName: 'contactedWebsites',
    columnTitle: 'Contacted Websites',
    custom: false,
    enabled: true,
    id: -1,
    order: 6,
  },
  {
    columnMappingName: 'repliedWebsites',
    columnTitle: 'Replied Websites',
    custom: false,
    enabled: true,
    id: -1,
    order: 7,
  },
  {
    columnMappingName: 'newContacts',
    columnTitle: 'New Contacts',
    custom: false,
    enabled: true,
    id: -1,
    order: 8,
  },
  {
    columnMappingName: 'contactedContacts',
    columnTitle: 'Contacted Contacts',
    custom: false,
    enabled: true,
    id: -1,
    order: 9,
  },
  {
    columnMappingName: 'repliedContacts',
    columnTitle: 'Replied Contacts',
    custom: false,
    enabled: true,
    id: -1,
    order: 10,
  },
];

const leaderBoardsFilterOptions = (
  workspaces: { value: string | number; label: string }[] = [],
  users: { value: string | number; label: string }[] = []
): {
  label: string;
  value: number;
  type: 'string' | 'number' | 'boolean' | 'enum' | 'enumString' | 'stringNotEmpty';
  inputOptions?: { value: string | number; label: string }[];
}[] => [
  {
    label: 'Workspace',
    value: 0,
    type: 'enumString',
    inputOptions: workspaces,
  },
  {
    label: 'User',
    value: 2,
    type: 'enumString',
    inputOptions: users,
  },
];

export function LeaderTable({
  availableColumns,
  handleUpdateTableSettings,
  setCustomFieldsSidebarOpen,
  tableData,
  sortingSelectedKey,
}: LeaderTablePropsType): JSX.Element {
  if (!tableData) {
    return null;
  }

  const [sortingValue, setSortingValue] = useState<string>('');
  const [sortingDirection, setSortingDirection] = useState<string>('none');

  useEffect(() => {
    setSortingValue(sortingSelectedKey);
    setSortingDirection('down');
  }, [sortingSelectedKey]);

  let itemsList = tableData.itemsList.map((item) => {
    const valueContacts =
      item.contactContactedNumber === 0 || item.contactRepliedNumber === 0
        ? 0
        : Number(((item.contactRepliedNumber / item.contactContactedNumber) * 100).toFixed(2));
    const valueWebsites =
      item.websiteContactedNumber === 0 || item.websiteRepliedNumber === 0
        ? 0
        : Number(((item.websiteRepliedNumber / item.websiteContactedNumber) * 100).toFixed(2));

    return {
      ...item,
      repliedWebsiteRate: valueWebsites,
      repliedContactsRate: valueContacts,
    };
  });

  if (sortingDirection !== 'none') {
    itemsList = itemsList.sort((a, b): number => {
      if (sortingDirection === 'up') {
        return a[sortingValue] - b[sortingValue];
      }

      return b[sortingValue] - a[sortingValue];
    });
  }

  const handleSortClick = (direction: string, key: string): void => {
    if (key === sortingValue && sortingDirection !== 'none') {
      setSortingDirection('none');
      setSortingValue('');
    } else {
      setSortingDirection(direction);
      setSortingValue(key);
    }
  };

  return (
    <div className="leader-report-graphics_table">
      <ConfigurableTable
        tableClassName="leader-report-table"
        rowClassName="leader-report-table__body-row"
        data={itemsList}
        config={tableConfig}
        renderProps={{ handleSortClick, selectedKey: sortingValue, direction: sortingDirection }}
      />

      <div
        className="leader-report-graphics_table__select-columns-wrapper"
        style={{ display: 'none' }}
      >
        <ColumnsSelectIcon
          elementId="leader-table-select-columns"
          availableColumns={availableColumns}
          onToggleColumnsSelection={handleUpdateTableSettings}
          setCustomFieldsSidebarOpen={setCustomFieldsSidebarOpen}
        />
      </div>
    </div>
  );
}

const handleUpdateTableSettings = async (newSelectedColumns) => {
  if (!newSelectedColumns?.length || _.isEqual(newSelectedColumns, availableColumnsForSelection)) {
  }
  // ignore it for now
};

const selectOptions = [
  // { value: 'newCampaigns', label: 'New Campaigns' },
  // { value: 'inprogressCampaigns', label: 'In-progress Campaigns' },
  // { value: 'completedCampaigns', label: 'Completed Campaigns' },
  { value: 'websiteCreatedNumber', label: 'New Websites' },
  { value: 'websiteContactedNumber', label: 'Contacted Websites' },
  { value: 'websiteRepliedNumber', label: 'Replied Websites' },
  { value: 'contactCreatedNumber', label: 'New Contacts' },
  { value: 'contactContactedNumber', label: 'Contacted Contacts' },
  { value: 'contactRepliedNumber', label: 'Replied Contacts' },
  { value: 'creditsSpentNumber', label: 'Spent Credits' },
];

export function LeaderBoardBlockedPopup({ isOpen = true }: { isOpen?: boolean }): JSX.Element {
  const history = useHistory();
  const currentWorkspaceId = useCurrentWorkspaceId();

  if (!isOpen) {
    return null;
  }

  return (
    <div className="leader-board-modal">
      <div className="leader-board-modal_background" />
      <div className="leader-board-modal_content">
        <div className="leader-board-modal_content-image">
          <LeaderBoardIllustaration />
        </div>
        <h2 className="leader-board-modal_content-title">Unlock team leaderboards</h2>
        <p className="leader-board-modal_content-info">
          Measure each team member’s productivity. Available on Pro and Unlimited plans.
        </p>
        <Button
          className="leader-board-modal_content-button"
          onClick={() =>
            redirectUserTo(
              history,
              `/workspaces/${currentWorkspaceId}/settings/billing/change-subscription`
            )
          }
        >
          Upgrade my plan
        </Button>
      </div>
    </div>
  );
}

function LeaderBoards(): JSX.Element {
  const [selectedValue, setSelectedValue] = useState(selectOptions[0]);
  const [workspaces, setWorkspaces] = useState([]);
  const [isFilterOptionsLoading, setFilterOptionsLoading] = useState(true);
  const currentWorkspaceId = useSelector(getCurrentWorkspaceId);
  const dateFilter = useSelector(insightsDateFilterSelector);
  const filtersFromRedux = useSelector(insightsLeaderBoardsFiltersSelector);
  const dispatch = useDispatch<DispatchType>();
  const parsedFilters = Object.values(filtersFromRedux);
  const { members, isFetching: isFetchingMembers } = useWorkspaceMembers();
  const options = {
    refetchOnWindowFocus: false,
    enabled: !!currentWorkspaceId,
  };

  const {
    data: chartDataTeamMembers,
    isLoading: isLoadingTeamMembers,
    refetch: refetchTeamMembers,
  } = useQuery(
    ['team-members-insights', filtersFromRedux],
    () => {
      if (dateFilter.startDate) {
        return getTeamMembersInsightsApi(parsedFilters, dateFilter);
      }

      return getTeamMembersInsightsApi(parsedFilters);
    },
    options
  );

  const isLoading = () => {
    return isLoadingTeamMembers || !chartDataTeamMembers || currentWorkspaceId === null;
  };

  useEffect(() => {
    if (!currentWorkspaceId) {
      return;
    }

    if (!!chartDataTeamMembers && dateFilter.startDate === null) {
      return;
    }

    if (
      !chartDataTeamMembers ||
      chartDataTeamMembers.dateFrom !== dateFilter.startDate ||
      chartDataTeamMembers.dateTo !== dateFilter.endDate
    ) {
      refetchTeamMembers();
    }
  }, [dateFilter, currentWorkspaceId]);

  useEffect(() => {
    Promise.all([getAllWorkspacesShortApi()]).then(([workspacesResult]) => {
      const workspaceOptions = workspacesResult.map(({ id, title }) => ({
        value: id,
        label: title,
      }));

      setWorkspaces(workspaceOptions);
      setFilterOptionsLoading(false);
    });
  }, []);

  useEffect(() => {
    if (dateFilter?.startDate === null && !!chartDataTeamMembers?.dateFrom) {
      dispatch(
        setInsightsDateFilter({
          startDate: +chartDataTeamMembers.dateFrom,
          endDate: +chartDataTeamMembers.dateTo,
        })
      );
    }
  }, [chartDataTeamMembers, dateFilter]);

  const users = members.map(({ userId, fullName }) => ({ value: userId, label: fullName }));
  const filterFields = leaderBoardsFilterOptions(workspaces, users);

  return (
    <>
      <PageHeader title="Leaderboards">
        <div className="campaign-report-header">
          <InsightsCampaignHeader
            filterFields={filterFields}
            filterAction={setInsightsLeaderBoardsFilters}
            filtersValues={filtersFromRedux}
            isFilterOptionsLoading={isFilterOptionsLoading || isFetchingMembers}
          />
        </div>
      </PageHeader>
      <div className="leader-report-graphics">
        <div className="leader-report-graphics_item">
          <div className="report-graphic-container">
            <BlockHeader
              title="Team member leaderboard"
              selectOptions={selectOptions}
              selectedValue={selectedValue}
              onSelect={setSelectedValue}
              filters={false}
              isActive={false}
            />

            <Loader isLoading={isLoading()} withTopMargin>
              <VerticalComposedChart
                itemsList={(chartDataTeamMembers?.itemsList as []) || []}
                dataKey="userName"
                selectedValue={selectedValue.value}
                selectedLabel={selectedValue.label}
              />
            </Loader>
          </div>
        </div>
      </div>
      {isLoading() ? null : (
        <LeaderTable
          availableColumns={availableColumnsForSelection}
          handleUpdateTableSettings={handleUpdateTableSettings}
          tableData={chartDataTeamMembers}
          sortingSelectedKey={selectedValue.value}
        />
      )}
    </>
  );
}

function WrapperLeaderBoards(): JSX.Element {
  const {
    loadingStatus,
    data: { extendedFeaturesEnabled },
  } = useSelector(billingDetailsSelector);

  if (loadingStatus !== loadingStatuses.LOADED) {
    return null;
  }

  if (extendedFeaturesEnabled) {
    return <LeaderBoards />;
  }

  const mockChartDataTeamMembers = {
    isEmpty: false,
    dateFrom: 1688947200000,
    dateTo: 1697068799000,
    itemsList: [
      {
        userId: 121,
        userName: 'Leslie Alexander',
        contactCreatedNumber: 500,
        contactContactedNumber: 10,
        contactRepliedNumber: 5,
        websiteCreatedNumber: 300,
        websiteContactedNumber: 20,
        websiteRepliedNumber: 15,
        creditsSpentNumber: 20,
      },
      {
        userId: 122,
        userName: 'Albert Flores',
        contactCreatedNumber: 480,
        contactContactedNumber: 5,
        contactRepliedNumber: 3,
        websiteCreatedNumber: 200,
        websiteContactedNumber: 10,
        websiteRepliedNumber: 5,
        creditsSpentNumber: 5,
      },
      {
        userId: 123,
        userName: 'Darrell Steward',
        contactCreatedNumber: 490,
        contactContactedNumber: 8,
        contactRepliedNumber: 2,
        websiteCreatedNumber: 130,
        websiteContactedNumber: 5,
        websiteRepliedNumber: 3,
        creditsSpentNumber: 55,
      },
      {
        userId: 124,
        userName: 'Kathryn Murphy',
        contactCreatedNumber: 475,
        contactContactedNumber: 6,
        contactRepliedNumber: 2,
        websiteCreatedNumber: 100,
        websiteContactedNumber: 8,
        websiteRepliedNumber: 4,
        creditsSpentNumber: 60,
      },
      {
        userId: 125,
        userName: 'Marvin McKinney',
        contactCreatedNumber: 470,
        contactContactedNumber: 2,
        contactRepliedNumber: 1,
        websiteCreatedNumber: 100,
        websiteContactedNumber: 10,
        websiteRepliedNumber: 5,
        creditsSpentNumber: 60,
      },
      {
        userId: 125,
        userName: 'Courtney Henry',
        contactCreatedNumber: 450,
        contactContactedNumber: 4,
        contactRepliedNumber: 2,
        websiteCreatedNumber: 80,
        websiteContactedNumber: 15,
        websiteRepliedNumber: 6,
        creditsSpentNumber: 60,
      },
    ],
    totalContactCreatedNumber: 2865,
    totalContactContactedNumber: 35,
    totalContactRepliedNumber: 15,
    totalWebsiteCreatedNumber: 910,
    totalWebsiteContactedNumber: 68,
    totalWebsiteRepliedNumber: 38,
    totalCreditsSpentNumber: 340,
  };

  return (
    <>
      <PageHeader title="Leaderboards" />

      <div className="leader-report-upgrade">
        <LeaderBoardScale />
        <div className="leader-report-upgrade__info">
          <h2>Upgrade to Pro for Full Access</h2>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc consequat sagittis elit
            sed hendrerit. Suspendisse
          </p>
          <Button onClick={() => redirectUserTo(history, '/settings/billing/change-subscription')}>
            Upgrade
          </Button>
        </div>
      </div>

      <div className="leader-report-graphics">
        <div className="leader-report-graphics_item">
          <div className="report-graphic-container">
            <BlockHeader
              title="Team member leaderboard"
              selectOptions={[{ value: 'websiteCreatedNumber', label: 'New Websites' }]}
              selectedValue={{ value: 'websiteCreatedNumber', label: 'New Websites' }}
              onSelect={() => {}}
              filters={false}
              isActive={false}
            />

            <VerticalComposedChart
              itemsList={mockChartDataTeamMembers.itemsList as []}
              dataKey="userName"
              selectedValue="websiteCreatedNumber"
              selectedLabel="New Websites"
            />
          </div>
        </div>
      </div>

      <LeaderTable
        availableColumns={[]}
        handleUpdateTableSettings={() => {}}
        tableData={mockChartDataTeamMembers}
        sortingSelectedKey="websiteCreatedNumber"
      />

      <LeaderBoardBlockedPopup />
    </>
  );
}

export default WrapperLeaderBoards;
