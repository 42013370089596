import * as google_protobuf_empty_pb from 'google-protobuf/google/protobuf/empty_pb';
import { contentSearchTypeMap, urlSearchTypeMap } from '@mappers/contentSearch.mappers';
import { SearchItemType, UrlItemType } from '@ts/contentSearch.types';
import { AudioGenreType } from '@ts/common.types';

import contentSearchService from '@api/services/contentSearchService';
import streamRequest from '@api/base/streamRequest';
import request from '@api/base/request';
import { ContentSearchTypeMap, TimestampRange } from 'respona_api/generated/common_pb';
import * as common_pb from 'respona_api/generated/common_pb';
import {
  ArticleSearchFilters,
  AudioDetailsRequest,
  DateFilter,
  EpisodeSearchFilters,
  PodcastSearchFilters,
  SearchRequest,
  TrendingContentSearchFilters,
  UrlRequest,
} from 'respona_api/generated/search-content_pb';

export const searchUrls = (query = '', limit = 10, type: 0 | 1): Promise<UrlItemType[]> => {
  const service = contentSearchService.getInstance();

  const urlRequest = new UrlRequest();
  urlRequest.setQuery(query);
  urlRequest.setLimit(limit);
  urlRequest.setType(type);

  return streamRequest<SearchItemType[]>(service, service.searchUrls, urlRequest);
};

export const searchLastEpisodeApi = (
  uid: string,
  type: ContentSearchTypeMap[keyof ContentSearchTypeMap]
): Promise<SearchItemType> => {
  const service = contentSearchService.getInstance();
  const contentSearchRequest = new AudioDetailsRequest();

  contentSearchRequest.setUid(uid);
  contentSearchRequest.setType(type);

  return request<SearchItemType>(service, service.searchLastEpisode, contentSearchRequest);
};

export const getAudioGenresApi = (): Promise<AudioGenreType[]> => {
  const service = contentSearchService.getInstance();
  const emptyRequest = new google_protobuf_empty_pb.Empty();

  return streamRequest<AudioGenreType[]>(service, service.getAudioGenres, emptyRequest);
};
