import { handleActions } from 'redux-actions';

import loadingStatuses from 'src/constants/loadingStatuses';
import { updateItemInListByIdHelper } from 'src/helpers/commonHelpers';
import {
  changeCurrentWorkspace,
  changeWorkspace,
  createWorkspace,
  deleteWorkspace,
  fetchWorkspaces,
  setWorkspaces,
} from '@redux/actions/workspaces.actions';
import { WorkspaceShortType } from '@ts/userOrganization.types';
import { pushIFrameUrl } from '@api/semrush/integration.api';
import urls, {urlPrefixWithoutWorkspace} from "@constants/urls";

declare const IS_STANDALONE_MODE: boolean;

export type WorkspacesStateType = {
  currentWorkspaceUid?: number;
  workspaces: WorkspaceShortType[];
  loadingStatus: string;
};

const initialState: WorkspacesStateType = {
  currentWorkspaceUid: null,
  workspaces: [],
  loadingStatus: loadingStatuses.PENDING,
};

const updateWorkspaceUrl = (payload: WorkspaceShortType[]): number => {
  const { history } = window;

  const { pathname } = window.location;
  const params = pathname.split('/');
  const hasOptionalWorkspace = params[2];
  const startOfUrl = params[1];
  const workspaceId = parseInt(hasOptionalWorkspace, 10);

  const activeWorkspace =
    payload.find((item) => item.id === workspaceId) ||
    payload.find((item) => item.favorite) ||
    payload[0];

  const isExclusion = urlPrefixWithoutWorkspace.find((item) => item === startOfUrl);

  if (!isExclusion) {
    if (isNaN(workspaceId)) {
      const url =
        pathname === '/'
          ? urls.ALL_CAMPAIGNS(activeWorkspace.id)
          : `/workspaces/${activeWorkspace.id}${pathname}`;
      history.replaceState(null, '', url);
      if (!IS_STANDALONE_MODE) {
        pushIFrameUrl(url);
      }
    }
  }

  return activeWorkspace.id;
};

export default handleActions(
  {
    [changeCurrentWorkspace]: (state: WorkspacesStateType, { payload }) => ({
      ...state,
      currentWorkspaceUid: payload,
    }),
    [fetchWorkspaces]: (state: WorkspacesStateType) => ({
      ...state,
      loadingStatus: loadingStatuses.INITED,
    }),
    [setWorkspaces]: (state: WorkspacesStateType, { payload }) => {
      return {
        ...state,
        workspaces: payload,
        currentWorkspaceUid: updateWorkspaceUrl(payload),
        loadingStatus: loadingStatuses.LOADED,
      };
    },
    [changeWorkspace]: (state: WorkspacesStateType, { payload }) => ({
      ...state,
      workspaces: updateItemInListByIdHelper(state.workspaces, payload),
    }),
    [createWorkspace]: (state: WorkspacesStateType, { payload }) => ({
      ...state,
      workspaces: [...state.workspaces, payload],
    }),
    [deleteWorkspace]: (state: WorkspacesStateType, { payload }) => ({
      ...state,
      workspaces: state.workspaces.filter(({ id }) => id !== payload),
    }),
  },
  initialState
);
