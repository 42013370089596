import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import urls from '@constants/urls';

import { getCurrentInvoiceApi } from '@api/billing.api';

import { billingDetailsSelector } from '@redux/selectors/billings.selectors';
import { appStateSelector } from '@redux/selectors/appState.selectors';

import { SVGIcon } from '@uikit/Icon/Icon';
import { redirectUserTo } from '@utils/historyHandler';

function WarningTriangle() {
  const history = useHistory();
  const { data: billingDetails } = useSelector(billingDetailsSelector);
  const { isPaymentStatusActive } = useSelector(appStateSelector);

  const handleClickToGoToCurrentInvoice = () => {
    getCurrentInvoiceApi()
      .then((invoiceContainer) => {
        redirectUserTo(history, urls.INVOICE(invoiceContainer.invoice.id));
      })
      .catch((err) => {
        redirectUserTo(history, urls.PERMISSION_DENIED);
      });
  };

  if (isPaymentStatusActive || !billingDetails.paymentExpired) {
    return null;
  }

  return (
    <span
      className="last-invoice-card__warning-triangle"
      onClick={handleClickToGoToCurrentInvoice}
      tabIndex={0}
      role="button"
    >
      <SVGIcon icon="warningTriangle" />
    </span>
  );
}

export default WarningTriangle;
