import React, { useCallback, useMemo, useState } from 'react';

import './OpportunityTableRow.scss';

import Checkbox from '@uikit/Checkbox/Checkbox';
import { SVGIcon, SvgIconType } from '@uikit/Icon/Icon';
import { socialLinkTypeToIconMapper } from '@uikit/SocialLinks/SocialLinks';

import Display from '@components/Display/Display';
import { OpportunityPageType } from '@ts/opportunity.types';
import { WorkspaceMemberShortType } from '@ts/workspace.types';
import { ContentMetricsType } from 'respona_api/generated/common_pb';
import {
  composeDomainRatingTitle,
  composeMetricsIcon,
  formatMetrics,
  getTypeInfo,
  IconsMapByType,
  isAllMetricsZero,
} from '@helpers/opportunitiesHelpers';
import { contactStatusIconMap, contactStatusMapper } from '@mappers/contact.mappers';

declare const IS_STANDALONE_MODE: boolean;

function OpportunityTableRow({
  data,
  workspaceMembers,
  isChecked,
  handleChangeCheckbox,
}: {
  data: OpportunityPageType;
  workspaceMembers: WorkspaceMemberShortType[];
  isChecked: boolean;
  handleChangeCheckbox?: (id: number) => void;
}): JSX.Element {
  const [allContacts, setAllContacts] = useState<boolean>(false);

  const contactsList = useMemo(
    () =>
      allContacts || data.contactsList.length === 0 ? data.contactsList : [data.contactsList[0]],
    [allContacts, data.contactsList]
  );

  const ownerId = useMemo(
    () => workspaceMembers?.find((item) => item.userId === data.ownerId)?.fullName || '-',
    [workspaceMembers, data.ownerId]
  );

  const formatDate = useCallback((timestamp: number): string => {
    if (timestamp === -1) return '-';
    const dateObj = new Date(timestamp);
    const ampm = dateObj.getHours() >= 12 ? 'pm' : 'am';
    const hours = dateObj.getHours() % 12 || 12;
    const months = dateObj.getMonth() >= 9 ? dateObj.getMonth() + 1 : `0${dateObj.getMonth() + 1}`;
    const date = dateObj.getDate() >= 10 ? dateObj.getDate() : `0${dateObj.getDate()}`;
    const minutes = dateObj.getMinutes() >= 10 ? dateObj.getMinutes() : `0${dateObj.getMinutes()}`;

    return `${months}/${date}/${dateObj.getFullYear()} ${hours}:${minutes} ${ampm}`;
  }, []);

  const getType = useMemo((): string => {
    const typeMap = [
      'Google Search',
      'Bing Search',
      'Podcast',
      'Episode',
      'Trending Content',
      'Bing News',
      'Imported',
      'Relationships',
      'Ahrefs Backlinks',
      'Semrush Backlinks',
      'Moz Backlinks',
      'Respona Backlinks',
      'Google News',
    ];
    return typeMap[data.type] || 'Imported';
  }, [data.type]);

  const getIconByType = useMemo((): SvgIconType => {
    const iconMap: SvgIconType[] = [
      'googleIcon',
      'bingIcon',
      'podcastIcon',
      'episodesIcon',
      'keywordSearchIcon',
      'bingNewsIcon',
      'uploadFileIcon',
      'relationshipsIcon',
      'keywordSearchIcon',
      'semrushIcon',
      'mozIcon',
      'semrushIcon',
      'googleIcon',
    ];
    return iconMap[data.type] || 'uploadFileIcon';
  }, [data.type]);

  const typeInfo = useMemo(() => getTypeInfo(data), [data]);

  const cutString = useMemo(() => {
    if (typeInfo.description == null) {
      return '-';
    }
    return typeInfo.description.length <= 100
      ? typeInfo.description
      : `${typeInfo.description.slice(0, 100)}...`;
  }, [typeInfo.description]);

  const composeTitle = useMemo(() => {
    if (
      data == null ||
      data.title == null ||
      data.title.length === 0 ||
      data.title.trim().length === 0
    ) {
      return '—';
    }
    return data.title;
  }, [data.title]);

  const isValidURL = useMemo(() => {
    try {
      // eslint-disable-next-line no-new
      new URL(data.url);
      return true;
    } catch (_) {
      return false;
    }
  }, [data.url]);

  const composeUrl = useMemo(() => {
    if (data == null || data.url == null || data.url.length === 0 || data.url.trim().length === 0) {
      return '—';
    }
    return data.url;
  }, [data.url]);

  return (
    <tr>
      <td>
        <div className="table-content">
          <div className="table-content_header">
            <Checkbox onChange={() => handleChangeCheckbox(data.id)} value={isChecked} />
            <h4>{composeTitle}</h4>
          </div>
          <p className="table-content_text">{cutString}</p>
          <a
            className="link-icon-a"
            href={data.url}
            target="_blank"
            onClick={(event) => {
              if (!isValidURL) {
                event.preventDefault();
              }
              event.stopPropagation();
            }}
          >
            <SVGIcon icon="link" size={10} color="#221CB6" />
            <span className="link-content">{composeUrl}</span>
          </a>
        </div>
      </td>
      <td>
        <div className="table-content">
          <div className="table-content_source">
            <div className="analytic-people__status-container">
              <img
                alt={contactStatusMapper[data.relationshipStatus]}
                className="analytic-people__status-icon"
                src={contactStatusIconMap[data.relationshipStatus].default}
              />
              <span className="analytic-people__status">
                {contactStatusMapper[data.relationshipStatus]}
              </span>
            </div>
          </div>
        </div>
      </td>
      <td>
        <div className="table-content">
          <div className="table-content_source">
            <SVGIcon icon={getIconByType} size={19} />
            <p>{getType}</p>
          </div>
        </div>
      </td>
      <td>
        <div className="table-content">
          <div className="table-content_contacts">
            {contactsList?.map((contact) => (
              <ul key={contact.personId} className="table-content_contacts-list">
                <li className="name-container">
                  <p className="name">{contact.name}</p>
                  {contact.socialLinksList.map((socialLink) => (
                    <a
                      className="social-links-a"
                      key={socialLink.url}
                      href={socialLink.url}
                      target="_blank"
                    >
                      <SVGIcon
                        icon={socialLinkTypeToIconMapper[socialLink.type] as SvgIconType}
                        color="#C4C6CD"
                      />
                    </a>
                  ))}
                </li>
                <li>
                  <span className="status">{contact.jobPosition}</span>
                </li>
                <li>
                  <div className="email">{contact.contactEmail.email}</div>
                </li>
              </ul>
            ))}
            {!allContacts && data.contactsList.length > 1 ? (
              <span
                className="more"
                onClick={(event) => {
                  event.stopPropagation();
                  setAllContacts(true);
                }}
              >
                + {data.contactsList.length - 1} more
              </span>
            ) : null}
          </div>
        </div>
      </td>
      <td>
        <div className="table-content">
          <div className="table-content_metrics">
            <ul>
              <Display isVisible={isAllMetricsZero(typeInfo)}>
                <li>
                  <span>&mdash;</span>
                </li>
              </Display>

              <Display isVisible={!isAllMetricsZero(typeInfo)}>
                <Display isVisible={typeInfo.listenScore > 0}>
                  <li>
                    <img
                      src={IconsMapByType[ContentMetricsType.LISTENNOTES_METRICS]}
                      alt="metrics-logo"
                    />
                    <p className="table-content_metrics-text">Listen score</p>
                    <span>{typeInfo.listenScore}</span>
                  </li>
                </Display>
                <Display
                  isVisible={
                    typeInfo.globalRank != null &&
                    typeInfo.globalRank.length &&
                    typeInfo.globalRank !== '0%'
                  }
                >
                  <li>
                    <img
                      src={IconsMapByType[ContentMetricsType.LISTENNOTES_METRICS]}
                      alt="metrics-logo"
                    />
                    <p className="table-content_metrics-text">Podcast rank</p>
                    <span>{typeInfo.globalRank}</span>
                  </li>
                </Display>
                <Display isVisible={typeInfo.domainRating > 0}>
                  <li>
                    <img src={composeMetricsIcon(data)} alt="metrics-logo" />
                    <p className="table-content_metrics-text">{composeDomainRatingTitle(data)}</p>
                    <span>{formatMetrics(typeInfo.domainRating)}</span>
                  </li>
                </Display>
                <Display isVisible={typeInfo.domainAuthority > 0}>
                  <li>
                    <img src={composeMetricsIcon(data)} alt="metrics-logo" />
                    <p className="table-content_metrics-text">Domain authority</p>
                    <span>{formatMetrics(typeInfo.domainAuthority)}</span>
                  </li>
                </Display>
                <Display isVisible={typeInfo.pageAuthority > 0}>
                  <li>
                    <img src={composeMetricsIcon(data)} alt="metrics-logo" />
                    <p className="table-content_metrics-text">Page authority</p>
                    <span>{formatMetrics(typeInfo.pageAuthority)}</span>
                  </li>
                </Display>
                <Display isVisible={typeInfo.domainTraffic > 0}>
                  <li>
                    <img src={composeMetricsIcon(data)} alt="metrics-logo" />
                    <p className="table-content_metrics-text">Domain traffic</p>
                    <span>{formatMetrics(typeInfo.domainTraffic)}</span>
                  </li>
                </Display>
                <Display isVisible={typeInfo.domainTrafficKeywordsNumber > 0}>
                  <li>
                    <img src={composeMetricsIcon(data)} alt="metrics-logo" />
                    <p className="table-content_metrics-text">Organic keywords</p>
                    <span>{formatMetrics(typeInfo.domainTrafficKeywordsNumber)}</span>
                  </li>
                </Display>
                <Display isVisible={typeInfo.domainAuthorityScore > 0}>
                  <li>
                    <img src={composeMetricsIcon(data)} alt="metrics-logo" />
                    <p className="table-content_metrics-text">Authority score</p>
                    <span>{formatMetrics(typeInfo.domainAuthorityScore)}</span>
                  </li>
                </Display>
                <Display isVisible={typeInfo.domainSpamScore > 0}>
                  <li>
                    <img src={composeMetricsIcon(data)} alt="metrics-logo" />
                    <p className="table-content_metrics-text">Domain spam score</p>
                    <span>{formatMetrics(typeInfo.domainSpamScore)}</span>
                  </li>
                </Display>
              </Display>
            </ul>
          </div>
        </div>
      </td>
      <td>
        <div className="table-content">
          <div className="table-content_date">
            <span className="date">{formatDate(data.createdAt)}</span>
            {IS_STANDALONE_MODE && <span className="person">By {ownerId}</span>}
          </div>
        </div>
      </td>
    </tr>
  );
}

export default OpportunityTableRow;
