import React from 'react';

import cn from 'class-names';

import UserLinks from '@components/UserLinks/UserLinks';
import HelpButton from '@components/HelpButton/HelpButton';

import SidebarFooterProfile from '../SidebarFooterProfile/SidebarFooterProfile';

import './SidebarFooter.scss';

declare const IS_STANDALONE_MODE: boolean;

function SidebarFooter(): JSX.Element {
  return (
    <div className="sidebar-footer">
      <UserLinks />
      {IS_STANDALONE_MODE && <HelpButton />}
      {IS_STANDALONE_MODE && <SidebarFooterProfile />}
    </div>
  );
}

export default SidebarFooter;
