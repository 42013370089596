import React from 'react';

import { RenderConfigurableSidebarLink } from '@uikit/ConfigurableSidebar/_components/ConfigurableSidebarLinks';

declare const IS_STANDALONE_MODE: boolean;

export default [
  {
    title: 'Campaign',
    pageKey: 'campaignReports',
    RenderLink: ({ pathname, workspaceId }) => (
      <RenderConfigurableSidebarLink
        title="Campaign Reports"
        icon="reportBox"
        url={`/workspaces/${workspaceId}/insights/campaign-reports`}
        isActive={new RegExp(`/workspaces/\\d+/insights/campaign-reports`).test(pathname)}
      />
    ),
  },
  ...(IS_STANDALONE_MODE
    ? [
        {
          title: 'Leader',
          pageKey: 'leaderBoards',
          RenderLink: ({ pathname, workspaceId }) => (
            <RenderConfigurableSidebarLink
              title="Leaderboards"
              icon="scaleIcon"
              url={`/workspaces/${workspaceId}/insights/leader-boards`}
              isActive={new RegExp(`/workspaces/\\d+/insights/leader-boards`).test(pathname)}
            />
          ),
        },
      ]
    : []),
  {
    title: 'Credits Usage',
    pageKey: 'creditsUsageByUser',
    RenderLink: ({ pathname, workspaceId }) => (
      <RenderConfigurableSidebarLink
        title="Credits Usage"
        icon="coins"
        url={`/workspaces/${workspaceId}/insights/credits-usage`}
        isActive={new RegExp(`/workspaces/\\d+/insights/credits-usage`).test(pathname)}
      />
    ),
  },
  // {
  //   title: 'Detailed Credits Usage',
  //   pageKey: 'detailedCreditsUsage',
  //   RenderLink: ({ pathname }) => (
  //     <RenderConfigurableSidebarLink
  //       title="Detailed Credits Usage"
  //       icon="coins"
  //       url="/insights/detailed-credits-usage"
  //       isActive={new RegExp(`/workspaces/\\d+/insights/detailed-credits-usage`).test(pathname)}
  //     />
  //   ),
  // },
  // {
  //   title: 'Reports',
  //   pageKey: 'emailReports',
  //   RenderLink: ({ pathname }) => (
  //     <RenderConfigurableSidebarLink
  //       title="Email Reports"
  //       icon="emails"
  //       url="/insights/email-reports"
  //       isActive={new RegExp(`/workspaces/\\d+/insights/email-reports`).test(pathname)}
  //     />
  //   ),
  // },
  {
    title: 'Health',
    pageKey: 'emailHealth',
    RenderLink: ({ pathname, workspaceId }) => (
      <RenderConfigurableSidebarLink
        title="Email Health"
        icon="emailHealth"
        url={`/workspaces/${workspaceId}/insights/email-health`}
        isActive={new RegExp(`/workspaces/\\d+/insights/email-health`).test(pathname)}
      />
    ),
  },
  // {
  //   title: 'Warmup',
  //   pageKey: 'emailWarmup',
  //   RenderLink: ({ pathname }) => (
  //     <RenderDefaultLink
  //       title="Email Warmup"
  //       icon="messageIcon"
  //       url="/insights/email-warmup"
  //       isActive={new RegExp(`/workspaces/\\d+/insights/email-warmup`).test(pathname)}
  //     />
  //   ),
  // },
];
