export default {
  '#1': true,
  $$$: true,
  '100% free': true,
  '100% satisfied': true,
  '4u': true,
  '50% off': true,
  'accept credit cards': true,
  acceptance: true,
  access: true,
  accordingly: true,
  'act now!': true,
  ad: true,
  'additional income': true,
  'addresses on cd': true,
  affordable: true,
  'all natural': true,
  'all new': true,
  amazing: true,
  'apply now': true,
  'apply online': true,
  'as seen on': true,
  'auto email removal': true,
  avoid: true,
  'avoid bankruptcy': true,
  bargain: true,
  'be your own boss': true,
  'being a member': true,
  beneficiary: true,
  'best price': true,
  beverage: true,
  'big bucks': true,
  'billing address': true,
  billion: true,
  'billion dollars': true,
  bonus: true,
  'brand new pager': true,
  'bulk email': true,
  buy: true,
  'buy direct': true,
  'buying judgments': true,
  'cable converter': true,
  call: true,
  'call free': true,
  'call now': true,
  'calling creditors': true,
  "can't live without": true,
  'cancel at any time': true,
  'cannot be combined with any other offer': true,
  'cards accepted': true,
  cash: true,
  'cash bonus': true,
  cashcashcash: true,
  casino: true,
  celebrity: true,
  'cents on the dollar': true,
  certified: true,
  chance: true,
  cheap: true,
  check: true,
  'check or money order': true,
  claims: true,
  clearance: true,
  click: true,
  'click below': true,
  'click here': true,
  'click to remove': true,
  collect: true,
  'collect child support': true,
  compare: true,
  'compare rates': true,
  'compete for your business': true,
  'confidentially on all orders': true,
  congratulations: true,
  'consolidate debt and credit': true,
  'consolidate your debt': true,
  'copy accurately': true,
  'copy dvds': true,
  cost: true,
  credit: true,
  'credit bureaus': true,
  'credit card offers': true,
  'cures baldness': true,
  deal: true,
  'dear [email/friend/somebody]': true,
  diagnostics: true,
  'dig up dirt on friends': true,
  'direct email': true,
  'direct marketing': true,
  discount: true,
  'do it today': true,
  "don't delete": true,
  "don't hesitate": true,
  dormant: true,
  'double your': true,
  'drastically reduced': true,
  earn: true,
  'earn $': true,
  'earn extra cash': true,
  'earn per week': true,
  'easy terms': true,
  'eliminate bad credit': true,
  'eliminate debt': true,
  'email harvest': true,
  'email marketing': true,
  'expect to earn': true,
  'explode your business': true,
  'extra income': true,
  'f r e e': true,
  'fantastic deal': true,
  'fast cash': true,
  'fast viagra delivery': true,
  'financial freedom': true,
  'financially independent': true,
  'for free': true,
  'for instant access': true,
  'for just $xxx': true,
  'for only': true,
  'for you': true,
  form: true,
  free: true,
  'free access': true,
  'free cell phone': true,
  'free consultation': true,
  'free dvd': true,
  'free gift': true,
  'free grant money': true,
  'free hosting': true,
  'free installation': true,
  'free instant': true,
  'free investment': true,
  'free leads': true,
  'free membership': true,
  'free money': true,
  'free offer': true,
  'free preview': true,
  'free priority mail': true,
  'free quote': true,
  'free sample': true,
  'free trial': true,
  'free website': true,
  freedom: true,
  friend: true,
  'full refund': true,
  get: true,
  'get it now': true,
  'get out of debt': true,
  'get paid': true,
  'get started now': true,
  'gift certificate': true,
  'give it away': true,
  'giving away': true,
  'great offer': true,
  guarantee: true,
  guaranteed: true,
  'have you been turned down?': true,
  hello: true,
  here: true,
  hidden: true,
  'hidden assets': true,
  'hidden charges': true,
  home: true,
  'home based': true,
  'home employment': true,
  'homebased business': true,
  'human growth hormone': true,
  'if only it were that easy': true,
  'important information regarding': true,
  'in accordance with laws': true,
  income: true,
  'income from home': true,
  'increase sales': true,
  'increase traffic': true,
  'increase your sales': true,
  'incredible deal': true,
  'info you requested': true,
  'information you requested': true,
  instant: true,
  insurance: true,
  'internet market': true,
  'internet marketing': true,
  investment: true,
  'investment decision': true,
  'it’s effective': true,
  'join millions': true,
  'join millions of americans': true,
  'laser printer': true,
  leave: true,
  legal: true,
  'life insurance': true,
  lifetime: true,
  'limited time': true,
  loans: true,
  'long distance phone offer': true,
  lose: true,
  'lose weight': true,
  'lose weight spam': true,
  'lower interest rate': true,
  'lower monthly payment': true,
  'lower your mortgage rate': true,
  'lowest insurance rates': true,
  'lowest price': true,
  'luxury car': true,
  'mail in order form': true,
  maintained: true,
  'make $': true,
  'make money': true,
  marketing: true,
  'marketing solutions': true,
  'mass email': true,
  medicine: true,
  medium: true,
  'meet singles': true,
  member: true,
  'message contains': true,
  million: true,
  'million dollars': true,
  miracle: true,
  money: true,
  'money back': true,
  'money making': true,
  'month trial offer': true,
  'more internet traffic': true,
  mortgage: true,
  'mortgage rates': true,
  'multi level marketing': true,
  'name brand': true,
  never: true,
  'new customers only': true,
  'new domain extensions': true,
  nigerian: true,
  'no age restrictions': true,
  'no catch': true,
  'no claim forms': true,
  'no cost': true,
  'no credit check': true,
  'no disappointment': true,
  'no experience': true,
  'no fees': true,
  'no gimmick': true,
  'no hidden costs': true,
  'no inventory': true,
  'no investment': true,
  'no medical exams': true,
  'no middleman': true,
  'no obligation': true,
  'no purchase necessary': true,
  'no questions asked': true,
  'no selling': true,
  'no strings attached': true,
  'no-obligation': true,
  'not intended': true,
  notspam: true,
  now: true,
  'now only': true,
  obligation: true,
  'off shore': true,
  offer: true,
  'offer expires': true,
  'once in lifetime': true,
  'one hundred percent free': true,
  'one hundred percent guaranteed': true,
  'one time': true,
  'one time mailing': true,
  'online biz opportunity': true,
  'online degree': true,
  'online marketing': true,
  'online pharmacy': true,
  only: true,
  'only $': true,
  opportunity: true,
  'opt in': true,
  order: true,
  'order now': true,
  'order status': true,
  'order today': true,
  'orders shipped by': true,
  'outstanding values': true,
  passwords: true,
  'pennies a day': true,
  'per day': true,
  'per week': true,
  performance: true,
  phone: true,
  'please read': true,
  'potential earnings': true,
  'pre-approved': true,
  price: true,
  'print form signature': true,
  'print out and fax': true,
  'priority mail': true,
  prize: true,
  prizes: true,
  problem: true,
  'produced and sent out': true,
  profits: true,
  'promise you': true,
  'pure profit': true,
  quote: true,
  'real thing': true,
  refinance: true,
  'refinance home': true,
  'removal instructions': true,
  remove: true,
  'removes wrinkles': true,
  'requires initial investment': true,
  'reserves the right': true,
  reverses: true,
  'reverses aging': true,
  'risk free': true,
  rolex: true,
  sale: true,
  sales: true,
  sample: true,
  satisfaction: true,
  'satisfaction guaranteed': true,
  'save $': true,
  'save big money': true,
  'save up to': true,
  'score with babes': true,
  'search engine listings': true,
  'search engines': true,
  'see for yourself': true,
  'sent in compliance': true,
  'serious cash': true,
  shopper: true,
  'shopping spree': true,
  'sign up free today': true,
  'social security number': true,
  solution: true,
  'special promotion': true,
  'stainless steel': true,
  'stock alert': true,
  'stock disclaimer statement': true,
  'stock pick': true,
  stop: true,
  'stop snoring': true,
  'stuff on sale': true,
  'subject to credit': true,
  subscribe: true,
  success: true,
  'supplies are limited': true,
  'take action now': true,
  teen: true,
  'terms and conditions': true,
  'the best rates': true,
  'the following form': true,
  'they keep your money -- no refund!': true,
  'they’re just giving it away': true,
  "this isn't junk": true,
  "this isn't spam": true,
  thousands: true,
  'time limited': true,
  trial: true,
  'undisclosed recipient': true,
  'university diplomas': true,
  unlimited: true,
  'unsecured credit': true,
  'unsecured debt': true,
  unsolicited: true,
  unsubscribe: true,
  urgent: true,
  'us dollars': true,
  vacation: true,
  'vacation offers': true,
  valium: true,
  viagra: true,
  vicodin: true,
  'visit our website': true,
  warranty: true,
  'we hate spam': true,
  'we honor all': true,
  'web traffic': true,
  'weekend getaway': true,
  'weight loss': true,
  'what are you waiting for?': true,
  'while supplies last': true,
  'while you sleep': true,
  'who really wins?': true,
  'why pay more?': true,
  wife: true,
  'will not believe your eyes': true,
  win: true,
  winner: true,
  winning: true,
  won: true,
  'work at home': true,
  'work from home': true,
  xanax: true,
  'you are a winner!': true,
  'you have been selected': true,
  'your income': true,
  'you’re a winner!': true,
};
