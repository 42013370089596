import {
  getTokenInfo,
  requestAdditionalBillingPopup,
  requestMainBillingPopup,
} from '@api/semrush/integration.api';
import React, { useEffect } from 'react';

function SemrushSubscriptionForm(): JSX.Element {
  useEffect(() => {
    getTokenInfo().then((tokenInfo) => {
      if (!tokenInfo.is_main_product_active) {
        requestMainBillingPopup();
      } else {
        requestAdditionalBillingPopup();
      }
    });
  }, [getTokenInfo, requestMainBillingPopup, requestAdditionalBillingPopup]);

  return <div />;
}

export default SemrushSubscriptionForm;
