import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useQueryClient } from 'react-query';

import { TemplateType } from '@ts/template.types';

import urls from '@constants/urls';

import { changeOwnerOfTemplateApi } from '@api/emailTemplates.api';

import { removeTemplateByQueryClient, useQueryAllTemplates } from '@queries/templates.queries';

import useWorkspaceMembers from '@hooks/useWorkspaceMembers';

import { addNotification } from '@redux/actions/notifications.actions';
import { getCurrentWorkspaceId } from '@redux/selectors/workspaces.selectors';

import Loader from '@uikit/Loader/Loader';
import ConfigurableTable from '@uikit/ConfigurableTable/ConfigurableTable';

import ChangeOwnerModal from '@components/Campaigns/ChangeOwnerModal/ChangeOwnerModal';
import queryCacheKeysConstants from '@constants/queryCacheKeys.constants';
import templatesTableConfig, {
  RenderRow,
} from '@components/Settings/TemplatesTable/templatesTableConfig';

import './TemplatesTable.scss';
import { redirectUserTo } from '@utils/historyHandler';

function TemplatesTable({
  serchQueryFilter,
  ownersIdsFilter,
}: {
  serchQueryFilter: string;
  ownersIdsFilter: number[];
}): JSX.Element {
  const history = useHistory();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [newOwnerTemplateInfo, setNewOwnerTemplateInfo] = useState<TemplateType>(null);

  const workspaceId = useSelector(getCurrentWorkspaceId);

  const queryClient = useQueryClient();
  const { members: workspaceMembers } = useWorkspaceMembers();
  const { data: templates, isFetching } = useQueryAllTemplates(
    0,
    100,
    serchQueryFilter,
    ownersIdsFilter
  );

  if (isFetching || !templates) {
    return <Loader isLoading withTopMargin />;
  }

  const handleOpenChangeOwnerModal = (template: TemplateType) => setNewOwnerTemplateInfo(template);

  const handleChangeOwner = async (ownerId: number) => {
    setIsLoading(true);
    try {
      await changeOwnerOfTemplateApi(newOwnerTemplateInfo.id, ownerId, workspaceId);
      queryClient.setQueryData(
        [queryCacheKeysConstants.templates, serchQueryFilter, ownersIdsFilter],
        (cache: any) => {
          return cache.map((item) =>
            item.id === newOwnerTemplateInfo.id
              ? {
                  ...item,
                  ownerId,
                  ownerAvatar: workspaceMembers.find(({ userId }) => userId === ownerId).logoUrl,
                }
              : item
          );
        }
      );
      setNewOwnerTemplateInfo(null);
    } catch (err) {
      dispatch(addNotification({ title: err.message, type: 'error' }));
    } finally {
      setIsLoading(false);
    }
  };

  const handleRemove = (templateId: number) => {
    removeTemplateByQueryClient(queryClient, templateId);
  };
  const handleOpen = (templateId: number) => {
    redirectUserTo(history, `${urls.TEMPLATES}/${templateId}`);
  };

  return (
    <>
      <ConfigurableTable
        tableClassName="templates-table"
        config={templatesTableConfig}
        data={templates}
        renderProps={{
          onRemove: handleRemove,
          onOpen: handleOpen,
          onChangeOwner: handleOpenChangeOwnerModal,
        }}
        RenderRow={RenderRow}
      />
      <ChangeOwnerModal
        isLoading={isLoading}
        ownedItems={newOwnerTemplateInfo ? [newOwnerTemplateInfo] : []}
        onClose={() => setNewOwnerTemplateInfo(null)}
        isOpen={!!newOwnerTemplateInfo}
        onConfirmChangeOwner={handleChangeOwner}
        workspaceMembers={workspaceMembers}
      />
    </>
  );
}

export default TemplatesTable;
