import React, { useMemo } from 'react';

import { components } from 'react-select';
import { useLocation } from 'react-router-dom';

import Loader from '@uikit/Loader/Loader';

import { ThreadPreviewResponseType, QueuePreviewResponseType } from '@ts/mailboxInbox.types';

import { GlobalInboxSortingType, GlobalInboxStatusType } from '@ts/inbox.types';
import { PRIMARY_COLOR } from '@constants/colors';
import { SVGIcon, SvgIconType } from '@uikit/Icon/Icon';

import './InboxMailsSidebar.scss';
import { ThreadStatus } from 'respona_api/generated/mailbox_pb';
import cn from 'class-names';
import ThreadsList from './_components/ThreadsList/ThreadsList';

type InboxMailsSidebarProps = {
  threadListByPages: any;
  isLoadingThreads: any;
  onLoadMore: any;
  hasNextPage: boolean;
  isFetchingNextPage: boolean;
  onChangeStatus: (value: GlobalInboxStatusType) => void;
  status: GlobalInboxStatusType;
  onChangeSorting: (value: GlobalInboxSortingType) => void;
  activeThreadId: number;
  onChangeActiveThread: any;
  handleRefresh: () => void;
};

const selectStyles = {
  control: (provided) => ({
    ...provided,
    borderColor: '#DBDFE7',
    borderRadius: '8.5px',
    height: '40px',
    cursor: 'pointer',
    '&:focus,&:hover': {
      outline: 'none',
      boxShadow: 'none',
      backgroundColor: '#EAEDF2',
      '& svg': {
        fill: '#221CB6',
      },
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    '&:focus,&:hover': {
      color: '#221CB6',
    },
  }),
};

function OptionIcon({
  icon,
  isSelected = false,
}: {
  icon: SvgIconType;
  isSelected?: boolean;
}): JSX.Element {
  return <SVGIcon icon={icon} color={isSelected ? PRIMARY_COLOR : '#fff'} />;
}

function Control({ children, ...restProps }) {
  return (
    <components.Control {...restProps}>
      &nbsp;&nbsp; <OptionIcon icon={restProps.selectProps.value.icon} isSelected /> {children}
    </components.Control>
  );
}

function Option({ children, ...restProps }) {
  return (
    <components.Option {...restProps}>
      <OptionIcon icon={restProps.data.icon} />
      <span style={{ marginLeft: '8px' }}>{children}</span>
    </components.Option>
  );
}

const tabsWithTopBar = ['/inbox/all', '/inbox/assigned', '/inbox/mentioned'];

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function InboxMailsSidebar({
  threadListByPages,
  isLoadingThreads,
  onLoadMore,
  hasNextPage,
  isFetchingNextPage,
  onChangeStatus,
  status,
  onChangeSorting,
  activeThreadId,
  onChangeActiveThread,
  handleRefresh,
}: InboxMailsSidebarProps): JSX.Element {
  const { pathname } = useLocation();

  const threadsListFlat: ThreadPreviewResponseType[] | QueuePreviewResponseType[] =
    threadListByPages?.pages?.flat().map((item) => {
      const exists = item.draftExists || item?.draftId > 0;
      return { ...item, draftExists: exists };
    });

  const handleChangeActiveThread = (threadIdTmp: string | number) => {
    // @ts-ignore
    const activeThread = threadsListFlat.find(
      (thread: ThreadPreviewResponseType | QueuePreviewResponseType) => {
        // InboxPreviewResponseType
        if ('uid' in thread) {
          return thread.uid === threadIdTmp;
        }
        // QueuePreviewResponseType
        if ('contactId' in thread) {
          return thread.contactId === threadIdTmp;
        }

        return false;
      }
    );

    if (!activeThread) {
      return;
    }

    onChangeActiveThread(activeThread);
  };

  const isLoading = isLoadingThreads || !threadListByPages;

  const hideTopSidebar = useMemo(() => {
    if (status === ThreadStatus.ALL) return true;
    const items = tabsWithTopBar.filter((item) => pathname.includes(item));
    return items.length === 0;
  }, [tabsWithTopBar, pathname, status]);

  return (
    <div className="inbox-mails-sidebar">
      {hideTopSidebar ? null : (
        <div className="inbox-mails-sidebar__status-and-sort">
          <div className="inbox-mails-sidebar__status">
            <div
              className={cn('inbox-mails-sidebar__status-item', {
                'inbox-mails-sidebar__status-item--active': status === ThreadStatus.OPEN,
              })}
              onClick={() => onChangeStatus(ThreadStatus.OPEN)}
            >
              Open
            </div>
            <div
              className={cn('inbox-mails-sidebar__status-item', {
                'inbox-mails-sidebar__status-item--active': status === ThreadStatus.ARCHIVE,
              })}
              onClick={() => onChangeStatus(ThreadStatus.ARCHIVE)}
            >
              Archived
            </div>
            <div
              className={cn('inbox-mails-sidebar__status-item', {
                'inbox-mails-sidebar__status-item--active': status === ThreadStatus.SNOOZED,
              })}
              onClick={() => onChangeStatus(ThreadStatus.SNOOZED)}
            >
              Snoozed
            </div>
            <div
              className={cn('inbox-mails-sidebar__status-item', {
                'inbox-mails-sidebar__status-item--active': status === ThreadStatus.REMOVED,
              })}
              onClick={() => {
                onChangeStatus(ThreadStatus.REMOVED);
              }}
            >
              Trash
            </div>
          </div>
          {/* <div */}
          {/*  onClick={() => (isLoadingThreads ? null : handleRefresh())} */}
          {/*  className="inbox-mails-sidebar__refresh-button" */}
          {/* > */}
          {/*  <SVGIcon icon="refresh" /> */}
          {/* </div> */}
        </div>
      )}
      {isLoading ? (
        <Loader isLoading withTopMargin />
      ) : (
        <ThreadsList
          activeIndex={activeThreadId}
          threadList={threadsListFlat}
          onChangeActiveThread={handleChangeActiveThread}
          onLoadMore={onLoadMore}
          hasNextPage={hasNextPage}
          isFetchingNextPage={isFetchingNextPage}
        />
      )}
    </div>
  );
}

export default InboxMailsSidebar;
