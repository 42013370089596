import { format } from 'date-fns';

import {
  addBlackListPatternsApi,
  addLabelApi,
  deleteThreadDraftApi,
  getAllEmailsByThreadApi,
  getAllLabelsApi,
} from '@api/mailbox.api';
import { getBasePersonByIdApi } from '@api/people.api';
import CollapsibleSidebar from '@components/CollapsibleSidebar/CollapsibleSidebar';
import { InboxWidgetInfoType } from '@components/Inbox/InboxWidget/InboxWidget';
import { PRIMARY_COLOR } from '@constants/colors';

import {
  MailboxThreadDraftType,
  QueuePreviewResponseType,
  ThreadMessageType,
  ThreadPreviewResponseType,
  ThreadTagType,
} from '@ts/mailboxInbox.types';
import { PersonBaseType } from '@ts/people.types';
import { Button } from '@uikit/Button/Button';
import Display from '@components/Display/Display';
import Input from '@uikit/Input/Input';
import { SVGIcon, SvgIconType } from '@uikit/Icon/Icon';

import Loader from '@uikit/Loader/Loader';
import ThreadEmails from '@uikit/ThreadEmails/ThreadEmails';
import React, { MutableRefObject, useCallback, useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import './InboxMailThread.scss';
import { ThreadStatus, ThreadStatusMap } from 'respona_api/generated/mailbox_pb';
import Select from '@uikit/Select/Select';
import useWorkspaceMembers from '@hooks/useWorkspaceMembers';
import ReactTooltip from 'react-tooltip';
import { nanoid } from 'nanoid';
import { QueryCollectionHookType } from '@ts/common.types';
import { billingDetailsSelector } from '@redux/selectors/billings.selectors';
import { getCurrentWorkspaceId } from '@redux/selectors/workspaces.selectors';
import { addNotification } from '@redux/actions/notifications.actions';
import { DispatchType } from 'src/store';
import DateRangePickerWrapper from '@uikit/DateRangePicker/DateRangePicker';
import TimePickerWrapper from '@uikit/TimePicker/TimePicker';
import {
  MILLISECOND_IN_DAY,
  MILLISECOND_IN_HOUR,
  MILLISECOND_IN_MINUTE,
} from '@utils/getFormattedPastTime';
import { daysTitles } from '@components/Settings/SchedulesDaysList/SchedulesDaysList';
import { MessageRequestType, MessageRequestTypeMap } from 'respona_api/generated/mailbox-sender_pb';
import cn from 'classnames';
import CustomOptionAvatar from '@components/CustomOptionAvatar/CustomOptionAvatar';
import CustomAvatar from '@components/CustomAvatar/CustonAvatar';
import useMailboxAccounts from '@hooks/useMailboxAccounts';
import { MailboxAccountType } from '@ts/mailboxAccounts.types';
import WebsitesSidebar from '@components/Relationships/WebsitesSidebar/WebsitesSidebar';
import { sidebarSize } from '@components/SidebarCRM/SidebarCRM';
import { WebsiteType } from '@ts/company.types';
import { getWebsiteByIdApi } from '@api/website.api';
import InboxCreatePersonSidebar from '@components/Inbox/InboxCreatePersonSidebar/InboxCreatePersonSidebar';

declare const IS_STANDALONE_MODE: boolean;

function InboxMailThread({
  activeThread,
  activeThreadDraft,
  refetchUnreadCounter,
  onChangeThreadStatus,
  onDeleteThread,
  onChangeViewedStatus,
  onChangeStarStatus,
  onAssignUserId,
  onAddLabelId,
  onRemoveLabelId,
  threadContainerRef,
  onDraftCreation,
  onDraftDelete,
  changeDraftType,
}: {
  activeThread?: ThreadPreviewResponseType & QueuePreviewResponseType;
  activeThreadDraft?: MailboxThreadDraftType;
  refetchUnreadCounter;
  onChangeThreadStatus: ({
    threadUid,
    status,
    changeStatus,
    actionTill,
    snoozedByUserId,
  }: {
    threadUid: string;
    status: ThreadStatusMap[keyof ThreadStatusMap];
    changeStatus: boolean;
    actionTill?: number;
    snoozedByUserId?: number;
  }) => void;
  onDeleteThread: (threadUid: string) => void;
  onChangeViewedStatus: (threadUid: string, viewed: boolean) => void;
  onChangeStarStatus: (threadUid: string) => void;
  onAssignUserId: (threadUid: string, userId: number) => void;
  onAddLabelId: (threadUid: string, labelId: number, labelTitle: string) => void;
  onRemoveLabelId: (threadUid: string, labelId: number) => void;
  threadContainerRef?: MutableRefObject<HTMLDivElement>;
  onDraftCreation: (threadUid: string) => void;
  onDraftDelete: (threadUid: string) => void;
  changeDraftType: (
    threadUid: string,
    type: MessageRequestTypeMap[keyof MessageRequestTypeMap]
  ) => void;
}): JSX.Element {
  const { inboxType } = useParams();
  const dispatch = useDispatch<DispatchType>();
  const queryClient = useQueryClient();
  const { members: workspaceMembers } = useWorkspaceMembers();
  const {
    data: { enterpriseFeaturesEnabled, extendedFeaturesEnabled },
  } = useSelector(billingDetailsSelector);
  const currentWorkspaceId = useSelector(getCurrentWorkspaceId);
  const [emailWidgetInfo, changeEmailWidgetInfo] = useState<InboxWidgetInfoType>(null);
  const [assignee, changeAssignee] = useState<{ value: number; label: string; logoUrl: any }>({
    value: -1,
    label: 'Unassigned',
    logoUrl: '',
  });
  const { items: accountMailboxes }: QueryCollectionHookType<MailboxAccountType> =
    useMailboxAccounts(currentWorkspaceId);
  const [isCreating, setCreating] = useState<boolean>(false);
  const [labelValue, setLabelValue] = useState<string>('');
  const [searchValue, setSearchValue] = useState<string>('');
  // const [isContactSidebarCRMOpen, setContactSidebarCRMOpen] = useState<boolean>(false);
  const [snoozeDate, setSnoozeDate] = useState(new Date().getTime());
  const [snoozeDateInput, setSnoozeDateInput] = useState(
    format(new Date(snoozeDate), 'MM/dd/yyyy')
  );
  const [snoozeTimeInput, setSnoozeTimeInput] = useState('08:00');
  const [openCalendar, setOpenCalendar] = useState(false);

  const assignList = () => {
    const list = workspaceMembers
      .sort((a, b) => {
        return (b.isMe ? 1 : 0) - (a.isMe ? 1 : 0);
      })
      .map((member) => {
        if (member.isMe) {
          return { value: member.userId, label: 'Assign to me', logoUrl: member.logoUrl };
        }

        return { value: member.userId, label: member.fullName, logoUrl: member.logoUrl };
      });

    list.unshift({
      value: -1,
      label: 'Unassigned',
      logoUrl: '',
    });

    return list;
  };

  useEffect(() => {
    if (activeThread != null && activeThread.assignedToUserId != null) {
      const user = assignList().find((it) => it.value === activeThread.assignedToUserId);
      if (user != null) {
        changeAssignee({
          value: activeThread.assignedToUserId,
          label:
            user.label === 'Assign to me'
              ? workspaceMembers.find(({ userId }) => userId === user.value).fullName
              : user.label,
          logoUrl: user.logoUrl,
        });
      }
    }
    if (
      activeThread != null &&
      activeThread?.draftId > 0 &&
      activeThreadDraft != null &&
      activeThreadDraft.type != null &&
      activeThreadDraft.type !== emailWidgetInfo?.type
    ) {
      changeEmailWidgetInfo({
        type: activeThreadDraft.type,
        replyAll: emailWidgetInfo?.replyAll || false,
        email: emailWidgetInfo?.email || null,
        draft: activeThreadDraft,
      });
    }
  }, [activeThread, activeThreadDraft]);

  const {
    data,
    isFetching,
    isRefetching,
    refetch,
  }: {
    data: ThreadMessageType[];
    isFetching: boolean;
    isRefetching: boolean;
    refetch: () => Promise<any>;
  } = useQuery(
    ['inbox-mail-thread', activeThread?.uid],
    () => getAllEmailsByThreadApi(activeThread?.uid, currentWorkspaceId),
    {
      enabled: !!activeThread?.uid,
      refetchOnWindowFocus: false,
      onSuccess: () => {
        if (activeThread.viewed === false) {
          refetchUnreadCounter();
        }
      },
    }
  );

  const personId = data?.[0]?.personId;
  const websiteId = data?.[0]?.websiteId;
  const id = nanoid();

  const { data: person, isFetching: isFetchingPerson } = useQuery<PersonBaseType>(
    `person-inbox-info-${personId}`,
    () => getBasePersonByIdApi(personId),
    {
      refetchOnWindowFocus: false,
      enabled: !!personId && personId > -1,
      initialData: {} as PersonBaseType,
    }
  );

  const { data: website, refetch: refetchWebsite } = useQuery<WebsiteType>(
    ['websites-sidebar-website', websiteId, currentWorkspaceId],
    () => getWebsiteByIdApi(websiteId, currentWorkspaceId),
    {
      enabled: !!websiteId && websiteId !== -1,
      refetchOnWindowFocus: false,
    }
  );

  const handleCloseEmailWidget = useCallback((deleteOnClose: boolean, threadUid?: string) => {
    if (deleteOnClose && threadUid && threadUid?.length > 0) {
      deleteThreadDraftApi(threadUid, currentWorkspaceId).then(() => {
        onDraftDelete(threadUid);
        changeEmailWidgetInfo(null);
      });
    } else {
      changeEmailWidgetInfo(null);
    }
  }, []);

  const handleReply = useCallback(
    (email: ThreadMessageType, curThreadUid: string, replyAll?: boolean) => {
      changeEmailWidgetInfo({
        type: MessageRequestType.SEND_REPLY_TO,
        replyAll,
        email,
        draft: { ...emailWidgetInfo?.draft, type: MessageRequestType.SEND_REPLY_TO }, // TODO emailWidgetInfo?.draft may be incorrect
      });
      changeDraftType(curThreadUid, MessageRequestType.SEND_REPLY_TO);
    },
    []
  );

  const handleForward = useCallback((email: ThreadMessageType, curThreadUid: string) => {
    changeEmailWidgetInfo({
      type: MessageRequestType.FORWARD_EMAIL,
      email,
      draft: { ...emailWidgetInfo?.draft, type: MessageRequestType.FORWARD_EMAIL },
    });
    changeDraftType(curThreadUid, MessageRequestType.FORWARD_EMAIL);
  }, []);

  const handleArchive = () => {
    if (activeThread?.status === ThreadStatus.ARCHIVE) {
      onChangeThreadStatus({
        threadUid: activeThread?.uid,
        status: ThreadStatus.OPEN,
        changeStatus: true,
      });
    } else {
      onChangeThreadStatus({
        threadUid: activeThread?.uid,
        status: ThreadStatus.ARCHIVE,
        changeStatus: true,
      });
    }
  };

  const handleDelete = () => {
    if (activeThread?.status === ThreadStatus.REMOVED) {
      onDeleteThread(activeThread?.uid);
    } else {
      onChangeThreadStatus({
        threadUid: activeThread?.uid,
        status: ThreadStatus.REMOVED,
        changeStatus: true,
      });
    }
  };

  const handleSnooze = (value: Date) => {
    if (value.getTime() < Date.now()) {
      dispatch(addNotification({ title: 'You can’t select a time in the past', type: 'warning' }));
    } else {
      onChangeThreadStatus({
        threadUid: activeThread?.uid,
        status: ThreadStatus.SNOOZED,
        changeStatus: activeThread?.snoozedUntil <= 0,
        actionTill: value.getTime(),
        snoozedByUserId: workspaceMembers.find(({ isMe }) => isMe).userId,
      });
    }
  };

  const handleUnsnooze = () => {
    onChangeThreadStatus({
      threadUid: activeThread?.uid,
      status: ThreadStatus.OPEN,
      changeStatus: true,
      actionTill: -1,
      snoozedByUserId: -1,
    });
  };

  const { data: labelsData } = useQuery(['mailbox-labels'], () => getAllLabelsApi(), {
    enabled: !!activeThread,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 5,
  });

  const [foundLabel, setFoundLabel] = useState<ThreadTagType[]>(labelsData);

  useEffect(() => {
    if (labelsData?.length > 0) {
      setFoundLabel(labelsData);
    }
  }, [labelsData]);

  const handleSearchLabel = ({ target: { value } }) => {
    setSearchValue(value);

    if (labelsData) {
      const filteredLabels = labelsData.filter((label) => {
        if (label.title.toLowerCase().indexOf(value.toLowerCase()) !== -1) {
          return label;
        }
      });

      if (filteredLabels.length > 0) {
        setFoundLabel(filteredLabels);
      } else {
        setFoundLabel(labelsData);
      }
    }
  };

  const onNewLabelClick = () => {
    setCreating(true);
    setLabelValue('');
  };

  const onCreateLabelClick = () => {
    addLabelApi(null, labelValue, null).then((response) => {
      setLabelValue('');
      setCreating(false);
      queryClient.setQueryData(['mailbox-labels'], (cache: ThreadTagType[]) => [
        response,
        ...cache,
      ]);
    });
  };

  const onLabelSelected = (labelId: number, labelTitle: string) => {
    if (activeThread.tagsList.filter((threadTag) => threadTag.id === labelId).length > 0) {
      onRemoveLabelId(activeThread?.uid, labelId);
    } else {
      onAddLabelId(activeThread?.uid, labelId, labelTitle);
    }
  };

  const handleStarring = () => {
    onChangeStarStatus(activeThread?.uid);
  };

  const handleViewed = () => {
    if (activeThread?.viewed) {
      onChangeViewedStatus(activeThread?.uid, false);
    } else {
      onChangeViewedStatus(activeThread?.uid, true);
    }
  };

  const getNewContactDetails = (firstEmail) => {
    const accountEmails = accountMailboxes?.map((item) => item.email);

    const useReceiverMail = accountEmails.includes(firstEmail.fromEmail);

    return {
      name: inboxType === 'sent' || useReceiverMail ? firstEmail.toName : firstEmail.fromName,
      email: inboxType === 'sent' || useReceiverMail ? firstEmail.toEmail : firstEmail.fromEmail,
    };
  };

  useEffect(() => {
    if (
      activeThread !== null &&
      activeThread.draftId > 0 &&
      activeThreadDraft !== null &&
      !!activeThreadDraft?.content?.length &&
      activeThreadDraft.content !== ''
    ) {
      changeEmailWidgetInfo({
        type: activeThreadDraft.type,
        replyAll: false,
        email: {
          toEmail: activeThreadDraft.emailTo,
          subject: activeThreadDraft.subject,
          content: null,
          messageId: activeThreadDraft.messageId,
          fromEmail: activeThread?.fromEmail,
          toName: activeThread?.toName,
          fromName: activeThread?.fromName,
          personId: activeThread?.personId,
          websiteId: activeThread?.websiteId,
          date: null,
          snoozedUntil: null,
          tagIdsList: [],
        },
        draft: activeThreadDraft,
      });
    } else {
      handleCloseEmailWidget(false);
    }
  }, [activeThread, activeThreadDraft]);

  if (!activeThread?.threadId) {
    return (
      <div className="inbox-mail-thread">
        <p className="inbox-mail-thread__empty-message">Select thread</p>
      </div>
    );
  }

  if ((!data || isFetching) && !isRefetching) {
    return (
      <div className="inbox-mail-thread">
        <Loader isFullBlockLoading withTopMargin />
      </div>
    );
  }

  function onChangeAssignee(value) {
    onAssignUserId(activeThread?.uid, value.value);
    changeAssignee({
      ...value,
      label:
        value.label === 'Assign to me'
          ? workspaceMembers.find(({ userId }) => userId === value.value).fullName
          : value.label,
    });
  }

  function onUnsubscribeClick(workspaceIds: number[]) {
    if (!extendedFeaturesEnabled) {
      dispatch(addNotification({ title: `Upgrade the plan to use this feature`, type: 'warning' }));
      return;
    }
    if (workspaceIds.length > 0 && !enterpriseFeaturesEnabled) {
      dispatch(
        addNotification({
          title: `Contact support to update the plan to use this feature`,
          type: 'warning',
        })
      );
      return;
    }
    addBlackListPatternsApi([activeThread.fromEmail], workspaceIds).then(() => {
      if (workspaceIds.length > 1) {
        dispatch(
          addNotification({
            title: `${activeThread.fromEmail} added to the workspace unsubscribe list.`,
            type: 'success',
          })
        );
      } else {
        dispatch(
          addNotification({
            title: `${activeThread.fromEmail} added to the global unsubscribe list.`,
            type: 'success',
          })
        );
      }
    });
  }

  const handleSnoozeDate = (data) => {
    setSnoozeDateInput(format(data, 'MM/dd/yyyy'));
    setSnoozeDate(data.getTime());
  };

  const handleSnoozeDateInput = (event) => {
    let countNums = 0;
    let countSlashes = 0;

    const value = event.currentTarget.value
      .trim()
      .split('')
      .filter((sign) => {
        if (!isNaN(parseInt(sign, 10))) {
          countNums++;
          return true;
        }
        if (sign === '/') {
          countSlashes++;
          return true;
        }

        return false;
      })
      .join('');

    if (value.length > snoozeDateInput.length && (countNums > 8 || countSlashes > 3)) {
      return false;
    }

    if (new Date().getTime() > new Date(value).getTime()) {
      event.preventDefault();
      return false;
    }

    setSnoozeDateInput(value);
    const time = new Date(value).getTime();
    if (time) {
      setSnoozeDate(time);
    }
  };

  const snoozeInfo = () => {
    const now = new Date().getTime();
    const diff = activeThread.snoozedUntil - now;

    if (diff < 0) {
      return '';
    }

    const ranges = [
      { text: 'day', divider: MILLISECOND_IN_DAY },
      { text: 'hour', divider: MILLISECOND_IN_HOUR },
      { text: 'minute', divider: MILLISECOND_IN_MINUTE },
    ];

    const range = ranges.find(({ divider }) => diff / divider >= 1);

    const fullItems = Math.floor(diff / range.divider);
    const infoText = `Reopens in ${fullItems} ${range.text}`;

    if (fullItems > 1) {
      return `${infoText}s`;
    }

    return infoText;
  };

  const snoozeDropdownDate = () => {
    const items = [];
    const today = new Date(); // get today's date
    today.setSeconds(0, 0);

    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);
    tomorrow.setHours(8, 0, 0, 0);

    // Use 'Later Today' only if there are at least 2 hours left in the day
    const laterToday = new Date(today);
    const remainingHoursToday = 23 - today.getHours();
    if (remainingHoursToday >= 2) {
      laterToday.setHours(today.getHours() + remainingHoursToday, 0, 0, 0);
      items.push({
        title: 'Later Today',
        day: daysTitles[laterToday.getDay()],
        time: `${laterToday.getHours() % 12 || 12}:00${laterToday.getHours() >= 12 ? 'pm' : 'am'}`,
        handleClick: () => {
          handleSnoozeDate(laterToday);
          handleSnooze(laterToday);
        },
      });
    }

    items.push({
      title: 'Tomorrow',
      day: daysTitles[tomorrow.getDay()],
      time: '8:00am',
      handleClick: () => {
        handleSnoozeDate(tomorrow);
        handleSnooze(tomorrow);
      },
    });

    if (today.getDay() <= 5) {
      const later = new Date(tomorrow);
      later.setDate(tomorrow.getDate() + 1);
      items.push({
        title: 'Later This Week',
        day: daysTitles[later.getDay()],
        time: '8:00am',
        handleClick: () => {
          handleSnoozeDate(later);
          handleSnooze(later);
        },
      });
    }

    const nextWeek = new Date(tomorrow);
    nextWeek.setDate(tomorrow.getDate() + (7 - tomorrow.getDay()) + 1);
    items.push({
      title: 'Next Week',
      day: daysTitles[nextWeek.getDay()],
      time: '8:00am',
      handleClick: () => {
        handleSnoozeDate(nextWeek);
        handleSnooze(nextWeek);
      },
    });

    const weekend = new Date(tomorrow);
    weekend.setDate(weekend.getDate() + ((5 - tomorrow.getDay()) % 7) + 1);
    items.push({
      title: today.getDay() <= 3 ? 'This Weekend' : 'Next Weekend',
      day: daysTitles[weekend.getDay()],
      time: '8:00am',
      handleClick: () => {
        handleSnoozeDate(weekend);
        handleSnooze(weekend);
      },
    });
    return items;
  };

  const removeTagFromConversation = (tagId) => {
    onRemoveLabelId(activeThread.uid, tagId);
  };

  // const onRightComponentClick = (sectionName) => {
  //   if (sectionName === 'contacts') {
  //     setContactSidebarCRMOpen(true);
  //   }
  // };
  //
  const onUpdateContactInThreads = () => {
    refetch().then(() => {
      // if this code needed then check the case when it triggers a call "getWebsiteId" with websiteId = -1
      // refetchWebsite();
    });
    // setContactSidebarCRMOpen(false);
  };

  const handleTimePicker = (value) => {
    setSnoozeTimeInput(value);

    const [hours, minutes] = value.split(':');
    setSnoozeDate(new Date(snoozeDate).setHours(parseInt(hours, 10), parseInt(minutes, 10)));
  };

  function composeSnoozedUntil() {
    try {
      return `Snoozed until ${format(new Date(activeThread.snoozedUntil), 'LLL d, hh:mm aaa')} by`;
    } catch (e) {
      return '';
    }
  }

  return (
    <>
      <div className="inbox-mail-thread">
        <div className="inbox-mail-thread__actions">
          <div className="inbox-mail-thread__actions-buttons">
            <Button
              type="ghost"
              className="inbox-mail-thread__action"
              onClick={handleArchive}
              title={`${
                activeThread?.status === ThreadStatus.ARCHIVE ? 'Unarchive' : 'Archive'
              } current thread`}
            >
              <SVGIcon
                icon="archive"
                color={activeThread?.status === ThreadStatus.ARCHIVE ? PRIMARY_COLOR : undefined}
              />
            </Button>
            <Button
              title="Snooze current thread"
              type="ghost"
              className="inbox-mail-thread__action"
              // onClick={handleSnooze}
              data-for="inbox-mail-thread__action-snoose"
              data-event="click"
              data-tip
            >
              <SVGIcon
                icon="snooze"
                color={activeThread?.status === ThreadStatus.SNOOZED ? PRIMARY_COLOR : undefined}
              />
            </Button>
            <ReactTooltip
              id="inbox-mail-thread__action-snoose"
              className="react-tooltip"
              place="bottom"
              effect="solid"
              // event="click"
              // globalEventOff="click"
              arrowColor="transparent"
              clickable
            >
              <div className="inbox-mail-thread__action-snooze-dropdown">
                {!openCalendar ? (
                  <div className="snooze-dropdown-container">
                    <div className="snooze-dropdown-container__header">Snooze until …</div>
                    {snoozeDropdownDate().map((item, index) => {
                      return (
                        <div
                          key={index}
                          className="snooze-dropdown-container__item"
                          onClick={item.handleClick}
                        >
                          <p>{item.title}</p>
                          <div className="snooze-dropdown-container__item-timing">
                            <span>{item.day},</span>
                            <span>{item.time}</span>
                          </div>
                        </div>
                      );
                    })}
                    <div className="snooze-dropdown-container__footer">
                      <Button
                        type="ghost"
                        leftIcon="calendar"
                        colorIcon="#ffffff"
                        iconSize={16}
                        onClick={() => setOpenCalendar(true)}
                      >
                        Pick date & time
                      </Button>
                    </div>
                  </div>
                ) : (
                  <div onClick={(event) => event.stopPropagation()}>
                    <div className="inbox-snooze-container">
                      <div className="inbox-snooze-container__range-picker">
                        <DateRangePickerWrapper
                          handleSelect={handleSnoozeDate}
                          value={{ startDate: snoozeDate, endDate: null }}
                          isRangePicker={false}
                          isReverseCheck
                          minDate={new Date()}
                        />
                      </div>

                      <div className="inbox-snooze-container__item">
                        <label>Date:</label>
                        <Input
                          type="text"
                          onChange={handleSnoozeDateInput}
                          value={snoozeDateInput}
                          placeholder="mm/dd/yyyy"
                          isTransparent
                        />
                      </div>
                      <div className="inbox-snooze-container__item">
                        <label>Time:</label>
                        <div className="inbox-snooze-container__item-time-picker">
                          <TimePickerWrapper onChange={handleTimePicker} value={snoozeTimeInput} />
                        </div>
                      </div>
                      {activeThread?.status === ThreadStatus.SNOOZED ? (
                        <div className="inbox-snooze-container__info">
                          <SVGIcon icon="clockThree" color="#ED483D" />
                          {snoozeInfo()}
                        </div>
                      ) : null}
                      <div className="inbox-snooze-container__footer">
                        <Button type="black" onClick={() => setOpenCalendar(false)}>
                          Cancel
                        </Button>
                        <Button
                          className="snooze-btn"
                          onClick={() => handleSnooze(new Date(snoozeDate))}
                        >
                          Snooze
                        </Button>
                        {activeThread?.status === ThreadStatus.SNOOZED ? (
                          <Button className="unsnooze-btn" type="ghost" onClick={handleUnsnooze}>
                            Unsnooze
                          </Button>
                        ) : null}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </ReactTooltip>
            <Button
              title="Delete current thread"
              type="ghost"
              onClick={handleDelete}
              className="inbox-mail-thread__action"
            >
              <SVGIcon
                icon="trash"
                color={activeThread?.status === ThreadStatus.REMOVED ? PRIMARY_COLOR : '#BDBDBD'}
              />
            </Button>
            <Button
              title="Add labels to thread"
              type="ghost"
              className="inbox-mail-thread__action"
              data-for={`inbox-mail-thread__action-${id}`}
              data-tip=""
            >
              <SVGIcon
                icon="label"
                color={activeThread?.tagsList.length ? PRIMARY_COLOR : '#BDBDBD'}
              />
            </Button>
            <ReactTooltip
              id={`inbox-mail-thread__action-${id}`}
              className="react-tooltip"
              place="bottom"
              effect="solid"
              event="click"
              globalEventOff="click"
              arrowColor="transparent"
              clickable
            >
              <div className="inbox-mail-thread__action-tooltip">
                <div className="inbox-mail-thread__action-tooltip-header">
                  <Input
                    icon="search"
                    iconColor="#C1C1C1"
                    onChange={handleSearchLabel}
                    value={searchValue}
                    placeholder="Search"
                    isTransparent
                  />
                </div>

                {!!foundLabel && (
                  <ul className="inbox-mail-thread__action-labels">
                    {foundLabel.map(({ id, title }) => {
                      return (
                        <li
                          key={id}
                          className={cn('inbox-mail-thread__action-labels-item', {
                            'inbox-mail-thread__action-labels-item--active':
                              activeThread?.tagsList.find((tag) => tag.id === id),
                          })}
                          onClick={() => onLabelSelected(id, title)}
                        >
                          <span className="inbox-mail-thread__action-labels-item-icon">
                            <SVGIcon icon={'label' as SvgIconType} color="white" />
                          </span>
                          {title}
                        </li>
                      );
                    })}
                  </ul>
                )}

                <div className="inbox-mail-thread__action-tooltip-footer">
                  <Display isVisible={isCreating}>
                    <Input
                      className="labels-input"
                      type="text"
                      placeholder="Enter label"
                      value={labelValue}
                      onChange={({ target: { value } }) => setLabelValue(value)}
                      onKeyDown={(e) => e.key === 'Enter' && onCreateLabelClick()}
                      maxLength="30"
                      isTransparent
                    />
                  </Display>
                  <Button
                    type="ghost"
                    leftIcon="plusIcon"
                    iconSize={12}
                    colorIcon="#7974F6"
                    onClick={onNewLabelClick}
                  >
                    Create new label
                  </Button>
                </div>
              </div>
            </ReactTooltip>
            <Button
              type="ghost"
              className="inbox-mail-thread__action"
              onClick={handleStarring}
              title={`${activeThread?.favorite ? 'Unmark' : 'Mark'} as starred`}
            >
              <SVGIcon icon="star" color={activeThread?.favorite ? PRIMARY_COLOR : '#BDBDBD'} />
            </Button>

            <Button
              type="ghost"
              className="inbox-mail-thread__action more-actions"
              data-for={`inbox-mail-thread__action-more-${id}`}
              data-tip=""
            >
              <SVGIcon icon="threeDots" color="#BDBDBD" />
            </Button>
            <ReactTooltip
              id={`inbox-mail-thread__action-more-${id}`}
              className="react-tooltip"
              place="bottom"
              effect="solid"
              event="click"
              globalEventOff="click"
              arrowColor="transparent"
              clickable
            >
              <div className="inbox-mail-thread__action-tooltip">
                <ul className="inbox-mail-thread__action-more">
                  <li
                    className="inbox-mail-thread__action-more-item"
                    onClick={() => handleViewed()}
                  >
                    <SVGIcon icon="messageIcon" color="white" />
                    Mark as {activeThread?.viewed ? 'unread' : 'read'}
                  </li>
                  {IS_STANDALONE_MODE && (
                    <>
                      <li
                        className="inbox-mail-thread__action-more-item"
                        onClick={() => onUnsubscribeClick([currentWorkspaceId])}
                      >
                        {enterpriseFeaturesEnabled ? (
                          <SVGIcon icon="banWhite" />
                        ) : (
                          <SVGIcon icon="lock" size={16} color="#fff" />
                        )}
                        Workspace unsubscribe
                      </li>
                      <li
                        className="inbox-mail-thread__action-more-item"
                        onClick={() => onUnsubscribeClick([])}
                      >
                        <SVGIcon icon="crossedOut" />
                        Global unsubscribe
                      </li>
                    </>
                  )}
                </ul>
              </div>
            </ReactTooltip>
          </div>
          <Select
            openMenuOnFocus
            classNamePrefix="assign"
            placeholder="Assign user"
            onChange={(value) => onChangeAssignee(value)}
            value={assignee}
            options={assignList()}
            additionalComponents={{ Control: CustomAvatar, Option: CustomOptionAvatar }}
            additionalStyles={{
              control: (provided) => ({
                ...provided,
                minHeight: '40px',
                borderRadius: '8px',
              }),
            }}
          />
        </div>
        <div className="inbox-mail-thread__header">{data[0].subject}</div>
        <div className="inbox-mail-thread__header-tags-wrapper">
          <div className="inbox-mail-thread__header-tags">
            {activeThread?.tagsList.map(({ id, title }) => (
              <span className="inbox-mail-thread__header-tags-tag" key={id}>
                {title}
                <span className="remove-tag" onClick={() => removeTagFromConversation(id)}>
                  <SVGIcon icon="crossInGreyCircle" color="#7B7B7B" />
                </span>
              </span>
            ))}
          </div>
        </div>
        {activeThread.snoozedUntil !== -1 ? (
          <div className="inbox-mail-thread__header-notes" onClick={() => handleUnsnooze()}>
            <p className="inbox-mail-thread__header-notes-text">
              {composeSnoozedUntil()}{' '}
              <span>
                {workspaceMembers?.find((it) => it.userId === activeThread.snoozedByUserId)
                  ?.fullName || 'Unknown User'}
              </span>
            </p>
            <span className="remove-note">
              <SVGIcon icon="crossInGreyCircle" color="#7B7B7B" />
            </span>
          </div>
        ) : null}
        <ThreadEmails
          emails={data}
          onReply={handleReply}
          onForward={handleForward}
          inboxWidgetInfo={emailWidgetInfo}
          handleCloseEmailWidget={handleCloseEmailWidget}
          isRefetching={isRefetching}
          refetch={refetch}
          activeThreadUid={activeThread.uid}
          onDraftCreation={onDraftCreation}
          onDraftDelete={onDraftDelete}
          activePerson={person}
        />
      </div>

      <CollapsibleSidebar title={null} targetElementRef={threadContainerRef}>
        <div className="inbox-mail-thread__person-info-sidebar">
          {websiteId === -1 ? (
            <InboxCreatePersonSidebar
              activeThread={activeThread}
              onUpdateContactInThreads={onUpdateContactInThreads}
              contactDetails={getNewContactDetails(data[0])}
            />
          ) : website ? (
            <WebsitesSidebar
              itemId={websiteId}
              isOpen
              tableQueryKey={[]}
              data={{
                logoUrl: website.logoUrl,
                title: website.title,
                domain: website.domain,
                status: website.status,
                contactsList: website.contactsList as any,
                metricsList: website.metricsList,
                createdAt: website.createdAt,
              }}
              size={sidebarSize.SMALL}
              disabledFields={{
                isDisabledDomain: true,
                isDisabledWebsiteName: true,
              }}
            />
          ) : null}
        </div>
      </CollapsibleSidebar>
    </>
  );
}

export default InboxMailThread;
