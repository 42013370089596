import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDetectAdBlock } from 'adblock-detect-react';
import { useLocation, useHistory } from 'react-router-dom';
import { useQuery } from 'react-query';
import cn from 'classnames';

import { OrganizationChecklistTask } from 'respona_api/generated/user-organization_pb';

import {
  addIntercomListener,
  show as showIntercom,
  update as updateIntercom,
} from 'src/libs/intercom';

import { DispatchType } from 'src/store';

import { completeChecklistItemApi, getOnboardingChecklistApi } from '@api/userOrganization.api';

import { addNotification } from '@redux/actions/notifications.actions';
import { userProfileSelector } from '@redux/selectors/userProfile.selector';

import getStringHashCode from '@utils/getStringHashCode';

import { SVGIcon } from '@uikit/Icon/Icon';
import { Button } from '@uikit/Button/Button';
import Loader from '@uikit/Loader/Loader';

import TutorialModal from '@components/Modals/TutorialModal/TutorialModal';
import EmailReport from '@components/Insights/EmailReport/EmailReport';
import EarnFreeCredits from '@components/Settings/EarnFreeCredits/EarnFreeCredits';
import NewIdeas from '@components/Campaigns/Dashboard/_components/Icons/NewIdeas';
import SupportTeam from '@components/Campaigns/Dashboard/_components/Icons/SupportTeam';
import WatchTutorial from '@components/Campaigns/Dashboard/_components/Icons/WatchTutorial';
import PageHeader from '@components/PageHeader/PageHeader';

import './Dashboard.scss';
import { redirectUserTo } from '@utils/historyHandler';
import useCurrentWorkspaceId from '@hooks/useCurrentWorkspaceId';

declare const IS_STANDALONE_MODE: boolean;

function Dashboard({ preview }: { preview?: boolean }): JSX.Element {
  const dispatch = useDispatch<DispatchType>();
  const location = useLocation();
  const history = useHistory();
  const currentWorkspaceId = useCurrentWorkspaceId();

  const [tutorialModalOpen, setTutorialModalOpen] = useState<boolean>(false);

  const { info: userProfile } = useSelector(userProfileSelector);

  const adBlockDetected = useDetectAdBlock();

  const { isLoading, data } = useQuery({
    queryKey: ['dashboard-query', userProfile?.email],
    queryFn: () => getOnboardingChecklistApi(),
    enabled: userProfile?.email?.length > 0 && !preview,
  });

  const scheduleOnboardingSession = () => {
    window.open('https://calendly.com/respona/onboarding', '_blank');
    completeChecklistItemApi(OrganizationChecklistTask.SCHEDULE_ONBOARDING_SESSION, true);
  };

  const handleChatWithUs = (e) => {
    e.stopPropagation();

    try {
      updateIntercom({
        hide_default_launcher: false,
        email: userProfile?.email || '',
        user_id: getStringHashCode(userProfile?.email),
        name:
          userProfile?.firstName && userProfile?.lastName
            ? `${userProfile.firstName} ${userProfile.lastName}`
            : '',
        alignment: 'left',
      });
      showIntercom();

      addIntercomListener('onHide', () => {
        setTimeout(
          () =>
            updateIntercom({
              hide_default_launcher: true,
            }),
          300
        );
      });
    } catch (e) {
      if (adBlockDetected) {
        dispatch(
          addNotification({
            title:
              'Ad blocker detected. Please make sure to add respona.com to your exceptions list.',
            type: 'error',
          })
        );
      }
    }
  };

  const startNewCampaign = async () => {
    redirectUserTo(history, `/workspaces/${currentWorkspaceId}/campaign-templates`);
  };

  useEffect(() => {
    document
      .getElementById(location.hash.replace('#', ''))
      ?.scrollIntoView?.({ behavior: 'smooth', block: 'start' });
  }, [location.hash]);

  if (isLoading && !preview) {
    return (
      <div className="dashboard">
        <Loader isLoading />
      </div>
    );
  }

  const hasLaunchedCampaign =
    data?.tasksList?.find(({ task }) => task === OrganizationChecklistTask.LAUNCH_CAMPAIGN)
      ?.complete ?? true;

  return (
    <>
      <TutorialModal isOpen={tutorialModalOpen} onClose={() => setTutorialModalOpen(false)} />

      <div className="dashboard__container">
        <div className="dashboard">
          <div style={{ height: '100%' }}>
            <PageHeader
              title="Dashboard"
              renderHeaderActions={() => (
                <Button loadingText="Starting campaign..." onClick={startNewCampaign}>
                  Start new campaign
                </Button>
              )}
            />

            <div className="dashboard__body">
              <div className="dashboard__updates">
                <div className="dashboard__updates-title">Updates</div>
                <div className="dashboard__updates-subtitle">
                  Check out Respona’s latest features and improvements and upvote upcoming updates.
                </div>
                <Button
                  size="m"
                  className="dashboard__button"
                  onClick={() => window.open('https://respona.feedbear.com/updates', '_blank')}
                >
                  View Updates
                </Button>

                <img
                  alt="illustration"
                  src={require('./_components/Images/DashboardIllustration.png').default}
                  className="dashboard__updates-illustration"
                />
              </div>

              <DashboardSection
                hash="get-started"
                title="Getting started"
                subtitle="Your outreach journey starts here."
                visible={!data?.complete && !preview}
              >
                {gettingStartedSteps({ scheduleOnboardingSession }).map((item, key) => (
                  <DashboardGettingStartedItem
                    key={key}
                    title={item.title}
                    subtitle={item.subtitle}
                    order={key + 1}
                    onClick={item.onClick}
                    href={item.href}
                    buttonText={item.buttonText}
                    buttonDisabledWhenCompleted={item.buttonDisabledWhenCompleted ?? true}
                    completed={
                      data?.tasksList?.find(({ task }) => task === item.taskIndex)?.complete ?? true
                    }
                  />
                ))}
              </DashboardSection>

              <DashboardSection
                hash="insights"
                title="Insights"
                subtitle="Check how well your campaigns are doing."
                visible={hasLaunchedCampaign && !preview}
              >
                <div className="dashboard__insights">
                  <EmailReport embedded />
                </div>
              </DashboardSection>

              <DashboardSection hash="help" title="Need help?" subtitle="We’re here for you.">
                <div className="dashboard__help-items-row">
                  <DashboardHelpItem
                    title="Watch Respona’s Tutorial video"
                    subtitle="Learn all you need to know to get started with Respona in 5 minutes."
                    icon={<WatchTutorial />}
                    iconBackgroundColor="rgba(34, 28, 182, 0.07)"
                    buttonText="Play tutorial"
                    onClick={() => setTutorialModalOpen(true)}
                  />
                  <DashboardHelpItem
                    title="Need new campaign ideas?"
                    subtitle="Browse through our step-by-step library of outreach strategies."
                    icon={<NewIdeas />}
                    iconBackgroundColor="rgba(252, 142, 14, 0.1)"
                    buttonText="Open strategy hub"
                    onClick={() =>
                      window.open(
                        'https://help.respona.com/en/collections/3195481-outreach-strategy-hub',
                        '_blank'
                      )
                    }
                  />
                  <DashboardHelpItem
                    title="Connect with support team"
                    subtitle="Any problems? Our award-winning support team is here for you."
                    icon={<SupportTeam />}
                    iconBackgroundColor="rgba(80, 171, 241, 0.09)"
                    buttonText="Chat with us"
                    onClick={handleChatWithUs}
                  />
                </div>

                {/* <div className="dashboard__help-hire-va"> */}
                {/*  <div className="dashboard__help-hire-va-title" id="service"> */}
                {/*    Managed service */}
                {/*  </div> */}
                {/*  <div className="dashboard__help-hire-va-subtitle"> */}
                {/*    Don’t have time to run outreach campaigns? Hire an affordable, trained assistant */}
                {/*    to take care of your outreach efforts, starting at $769/mo.{' '} */}
                {/*  </div> */}
                {/*  <Button */}
                {/*    className="dashboard__button dashboard__help-hire-va-button" */}
                {/*    onClick={() => window.open('https://wingassistant.com/respona/', '_blank')} */}
                {/*  > */}
                {/*    Hire a VA */}
                {/*  </Button> */}
                {/*  <img */}
                {/*    alt="illustration" */}
                {/*    src={require('./_components/Images/ResponaPlusW.png').default} */}
                {/*    className="dashboard__help-hire-va-illustration" */}
                {/*  /> */}
                {/* </div> */}
              </DashboardSection>

              {IS_STANDALONE_MODE && (
                <DashboardSection
                  hash="credits"
                  title="Earn free credits"
                  subtitle="Refer your friends and/or leave a review to earn free credits."
                >
                  <EarnFreeCredits />
                </DashboardSection>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const gettingStartedSteps = ({
  scheduleOnboardingSession,
}: {
  scheduleOnboardingSession?: (open: boolean) => void;
}) => [
  {
    taskIndex: OrganizationChecklistTask.SCHEDULE_ONBOARDING_SESSION,
    title: 'Schedule an onboarding session',
    subtitle:
      'Our success team helps you get up and running and share little-known tips and strategies.',
    onClick: () => scheduleOnboardingSession(true),
    buttonText: 'Schedule',
    buttonDisabledWhenCompleted: true,
  },
  {
    taskIndex: OrganizationChecklistTask.CONNECT_EMAIL_ACCOUNT,
    title: 'Connect your email ',
    subtitle: 'Connect your email account to automate sending campaign emails.',
    href: '/settings/emails',
    buttonText: 'Connect',
  },
  {
    taskIndex: OrganizationChecklistTask.LAUNCH_CAMPAIGN,
    title: 'Launch your first campaign',
    subtitle: 'Create and launch your first campaign to start outreach.',
    href: '/campaigns',
    buttonText: 'Campaigns',
  },
  {
    taskIndex: OrganizationChecklistTask.INVITE_TEAM_MEMBER,
    title: 'Invite your team',
    subtitle:
      'Invite unlimited team members to collaborate with you in your Respona account, at no additional cost.',
    href: '/settings/all-members',
    buttonText: 'Invite',
  },
];

function DashboardSection({
  title,
  subtitle,
  children,
  visible = true,
  hash,
}: {
  title?: string;
  subtitle?: string;
  children: React.ReactNode;
  visible?: boolean;
  hash?: string;
}) {
  return visible ? (
    <div className="dashboard__section" id={hash}>
      <div className="dashboard__section-header-title">{title}</div>
      <div className="dashboard__section-header-subtitle">{subtitle}</div>
      {children}
    </div>
  ) : null;
}

function DashboardGettingStartedItem({
  order,
  title,
  subtitle,
  onClick,
  href,
  buttonText,
  completed,
  buttonDisabledWhenCompleted,
}: {
  order: number;
  title: string;
  subtitle?: string;
  href?: string;
  onClick?: () => void;
  buttonText: string;
  completed?: boolean;
  buttonDisabledWhenCompleted?: boolean;
}) {
  return (
    <div
      className={cn('dashboard__getting-started', {
        'dashboard__getting-started--complete': completed,
      })}
    >
      {completed ? (
        <div className="dashboard__getting-started-completed-check">
          <SVGIcon icon="check" color="#ffffff" size={18} />
        </div>
      ) : (
        <div className="dashboard__getting-started-order">{order}</div>
      )}

      <div className="dashboard__getting-started-text">
        <div className="dashboard__getting-started-text-title">{title}</div>
        <div className="dashboard__getting-started-text-subtitle">{subtitle}</div>
      </div>

      <Button
        className={cn('dashboard__button', 'dashboard__getting-started-button', {
          'dashboard__getting-started-button--disabled': completed && buttonDisabledWhenCompleted,
        })}
        href={href}
        onClick={onClick}
      >
        {buttonText}
      </Button>
    </div>
  );
}

function DashboardHelpItem({
  icon,
  iconBackgroundColor,
  title,
  subtitle,
  onClick,
  href,
  buttonText,
}: {
  icon: React.ReactNode;
  iconBackgroundColor?: string;
  title: string;
  subtitle?: string;
  href?: string;
  onClick?: (e: any) => void;
  buttonText: string;
}) {
  return (
    <div className="dashboard__help-item">
      <div className="dashboard__help-item-header">
        <div
          className="dashboard__help-item-header-icon"
          style={{ backgroundColor: iconBackgroundColor }}
        >
          {icon}
        </div>
        <div className="dashboard__help-item-header-title">{title}</div>
      </div>
      <div className="dashboard__help-item-text-subtitle">{subtitle}</div>

      <Button
        className="dashboard__button dashboard__help-item-button"
        href={href}
        onClick={onClick}
      >
        {buttonText}
      </Button>
    </div>
  );
}

export default Dashboard;
