import React, { useEffect, ReactNode, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import loadingStatuses from '@constants/loadingStatuses';
import urls from '@constants/urls';

import { getActiveCampaignInfo } from '@redux/selectors/campaignSettings.selectors';
import { fetchOpenCampaign } from '@redux/thunks/campaignSettings.thunks';
import { getCurrentWorkspaceId } from '@redux/selectors/workspaces.selectors';

import Loader from '@uikit/Loader/Loader';
import NoAccessMessage from '@uikit/NoAccessMessage/NoAccessMessage';

import StepsLinks from '@components/StepsLinks/StepsLinks';
import Filters from '@components/CampaignSettings/Filters/Filters';

import { DispatchType } from 'src/store';

import './CampaignSettingsWrapper.scss';
import { redirectUserTo, redirectUserToSearchParams } from '@utils/historyHandler';

function CampaignSettingsWrapper({
  children,
  step,
  campaignId,
}: {
  children: ReactNode;
  step: string;
  campaignId;
}): JSX.Element {
  const dispatch = useDispatch<DispatchType>();

  const history = useHistory();
  const location = useLocation();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isPrefetching, setIsPrefetching] = useState<boolean>(true);

  const curWorkspaceId = useSelector(getCurrentWorkspaceId);
  const {
    info: campaignInfo,
    loadingStatus: campaignInfoLs,
    error: campaignInfoError,
  } = useSelector(getActiveCampaignInfo);

  const checkIsAutomationRunning = async () => {
    redirectUserToSearchParams(history, {
      pathname: `/workspaces/${curWorkspaceId}/campaign-builder/${campaignId}/sequence`,
      search: location.search,
    });
    setIsLoading(false);
  };

  useEffect(() => {
    if (!curWorkspaceId) {
      return;
    }

    if (
      !campaignInfo ||
      campaignInfoLs === loadingStatuses.PENDING ||
      campaignInfo.id !== campaignId
    ) {
      dispatch(fetchOpenCampaign(campaignId, curWorkspaceId)).then();
    }
  }, [campaignId, campaignInfo, curWorkspaceId]);

  useEffect(() => {
    if (!campaignInfo?.id) {
      return;
    }

    if (campaignInfoLs === loadingStatuses.LOADED && campaignInfo?.id === campaignId) {
      (async () => {
        setIsPrefetching(false);
      })();
    }
  }, [campaignInfoLs, campaignInfo?.id, campaignInfo?.workspaceId, campaignInfo?.lastBatch]);

  useEffect(() => {
    if (campaignInfoLs !== loadingStatuses.LOADED || isPrefetching) {
      return;
    }

    checkIsAutomationRunning();
  }, [campaignInfoLs, isPrefetching]);

  try {
    if (
      campaignInfoLs === loadingStatuses.ERROR &&
      !!campaignInfoError &&
      JSON.parse(campaignInfoError?.message).code === 2000
    ) {
      return <NoAccessMessage showButton />;
    }
  } catch (e) {
    if (e.message?.includes('Unexpected token')) {
      redirectUserTo(
        history,
        `${urls.MAINTENANCE}?redirect=${location.pathname}${location.search}`
      );
    }
  }

  if (isPrefetching || isLoading || campaignInfoLs !== loadingStatuses.LOADED) {
    return <Loader isLoading withTopMargin />;
  }

  return (
    <div className="campaign-creation-wrapper">
      <div className="campaign-creation-wrapper__content-wrapper">
        <StepsLinks activeLink={step} campaignId={campaignId} />

        <div style={{ flex: 1, display: 'flex', maxWidth: '100%' }}>
          <Filters activeStep={step} />

          <div className="campaign-creation-wrapper__content">{children}</div>
        </div>
      </div>
    </div>
  );
}

export default CampaignSettingsWrapper;
