import store from 'src/store';
import requestErrorParser from '@helpers/requestErrorParser';
import { ApiError } from 'src/errors/ApiError';
import { authService } from 'src/services/authService';
import { isInternalPage } from '@utils/redirections';
import fetchMetadata from '@api/base/fetchMetadata';
import { getItemFromStorage } from '@utils/localStorageHandler';

declare const IS_DEV: boolean;
declare const HIDE_NETWORK_LOGS: boolean;

const GOD_MODE = getItemFromStorage('GOD_MODE', false);

export default <T>(
  client,
  method,
  request = null,
  needHeaders = true,
  showErrorMessage = true
): Promise<T> => {
  return fetchMetadata().then((metadata) => {
    if (IS_DEV || GOD_MODE) {
      if (HIDE_NETWORK_LOGS !== true) {
        console.group(`>> ${method.name}`);
        if (needHeaders) {
          console.info(metadata);
        }
        if (request != null) {
          console.info(request.array);
        }
        console.groupEnd();
      }
    }

    return new Promise((resolve, reject) => {
      method.call(client, request, needHeaders ? metadata : {}, (err, res) => {
        if (err) {
          if (IS_DEV || GOD_MODE) {
            if (HIDE_NETWORK_LOGS !== true) {
              console.group(`<< ${method.name}`);
              console.error(err);
              console.error(err.code);
              console.groupEnd();
            }
          }

          if (showErrorMessage) {
            store.dispatch(requestErrorParser(err.message, false));
          }

          if (err.code === 16) {
            authService.logout();
          }

          return reject(new ApiError(err.message, err.code, err));
        }

        if (IS_DEV || GOD_MODE) {
          if (HIDE_NETWORK_LOGS !== true) {
            console.group(`<< ${method.name}`);
            console.info(res.toObject());
            console.groupEnd();
          }
        }

        const jsonRes = res.toObject();
        const jsonErrMessage = jsonRes.errorMessage || jsonRes.message || jsonRes.details;

        if (jsonRes.success === false && jsonErrMessage) {
          if (showErrorMessage) {
            store.dispatch(requestErrorParser(jsonErrMessage));
          }

          reject(new ApiError(jsonErrMessage, jsonRes.code, jsonRes));
        }

        return resolve(res.toObject());
      });
    });
  });
};
