import React from 'react';
import { useQueryClient } from 'react-query';

import {
  deleteMetricsValueByIdApi,
  getAllMetricsViewSettingsByCompanyIdApi,
  getPipelineOpportunitiesByWebsiteIdApi,
  getWebsiteByIdApi,
  loadMetricsValueByIdApi,
  setMetricsValueByIdApi,
  showMetricsByTypeApi,
} from '@api/website.api';
import { getPreviewEmailsApi } from '@api/mailbox.api';
import {
  createOrUpdateCustomVariableValueApi,
  getCustomVariableValuesApi,
} from '@api/customVariables.api';
import { assignStageOpportunityApi, moveStageOpportunityApi } from '@api/relationshipPipeline';

import { CustomVariableValueRelatedTo } from 'respona_api/generated/common_pb';

import ErrorBoundary from '@uikit/ErrorBoundaries/ErrorBoundary';
import SidebarCRM, {
  ContentDataType,
  GeneralDataType,
} from '@components/SidebarCRM/SidebarCRM';
import { SECTION_TYPE } from '@components/SidebarCRM/SidebarCRMContent/SidebarCRMContent';

import useCurrentWorkspaceId from '@hooks/useCurrentWorkspaceId';

import { PipelineOpportunityType } from '@ts/relationshipPipeline.types';
import { RelatedPreviewResponseType } from '@ts/mailboxInbox.types';
import { PeopleFilterType, PersonType, RelationshipStatusType } from '@ts/people.types';
import { personStatusOptions } from '@components/SidebarCRM/sections/SidebarCRMSectionContact/SidebarCRMSectionContact';
import { socialLinkTypeToIconMapper } from '@uikit/SocialLinks/SocialLinks';
import {
  addNoteApi,
  addTagApi, getAllNotesApi,
  getAllTagsApi,
  getCampaignOpportunitiesByPersonIdApi,
  getTagsByPersonIdApi, removeNoteApi,
  removeTagApi, updateNoteApi,
  updatePersonApi,
  updatePersonStatus,
  updateSocialsApi,
} from '@api/people.api';

function ContactSidebarWrapper({
  queryKey,
  isOpen,
  onClose,
  contact,
  website,
  isSidebarShadow,
}: {
  queryKey: (string | number | PeopleFilterType[])[];
  isOpen: boolean;
  onClose?: () => void;
  isSidebarShadow?: boolean;
  contact: any;
  website: any;
}): JSX.Element {
  const workspaceId = useCurrentWorkspaceId();
  const queryClient = useQueryClient();
  const QUERY_KEY_BASE = 'relationships-people';

  const itemId = contact.id;
  const headerData = {
    logoUrl: website.logoUrl,
    title: contact.name,
    url: website.domain,
  };

  const onUpdate = (data) => {
    updatePersonApi(data).then((response) => {
      queryClient.setQueryData<PersonType[]>(queryKey, (prevData: PersonType[]) => {
        return prevData.map((item) => {
          if (item.id === response.id) {
            return data;
          }
          return item;
        });
      });
    });
  }

  const mapContentData: GeneralDataType[] = [
    {
      key: 'websiteName',
      title: 'Contact name',
      value: contact.name,
      type: ContentDataType.text,
      isEditable: true,
      onChange: (name: string) => {
        onUpdate({ ...contact, name });
      },
    },
    {
      key: 'emails',
      title: 'Email(s)',
      value: contact.emailsList.map(({ email }) => ({ id: email, value: email })),
      type: ContentDataType.array,
      isEditable: true,
      onChange: (newValue, id: string) => {
        onUpdate({
          ...contact,
          emailsList: contact.emailsList.map((item) => {
            if (item.email === id) {
              return {
                ...item,
                email: newValue,
              };
            }

            return item;
          }),
        });
      },
    },
    {
      key: 'relationshipStatus',
      title: 'Relationship status',
      value: contact.status,
      type: ContentDataType.select,
      isEditable: true,
      options: personStatusOptions,
      icon: 'flagStriped',
      onChange: (status: RelationshipStatusType) => {
        updatePersonStatus(contact.id, status);
        queryClient.setQueryData<PersonType[]>(queryKey, (prevData: PersonType[]) => {
          return prevData.map((item) => {
            if (item.id === contact.id) {
              return {
                ...item,
                status,
              };
            }
            return item;
          });
        });
      },
    },
    {
      key: 'createdOn',
      title: 'Created on',
      value: contact.createdAt,
      transformCreatedAt: true,
      type: ContentDataType.text,
      isEditable: false,
    },
    {
      key: 'socialLinks',
      title: 'Social links',
      value: contact.socialLinksList.map(({ url, type }) => ({
        id: url, value: url, icon: socialLinkTypeToIconMapper[type]
      })),
      type: ContentDataType.array,
      isEditable: true,
      onChange: (newValue, id: string) => {
        let initValue = '';
        const updatedContact = {
          ...contact,
          socialLinksList: contact.socialLinksList.map((link) => {
            if (link.url === id) {
              initValue = link.url;

              return {
                ...link,
                url: newValue,
              };
            }

            return link;
          }),
        };

        queryClient.setQueryData<PersonType[]>(queryKey, (prevData: PersonType[]) => {
          return prevData.map((item) => {
            if (item.id === contact.id) {
              return {
                ...item,
                socialLinksList: updatedContact.socialLinksList,
              };
            }
            return item;
          });
        });

        updateSocialsApi(contact.id, initValue, newValue as string);
      },
    },
  ];

  const onGetEmail = (payloadId: number): Promise<RelatedPreviewResponseType[]> => {
    return getPreviewEmailsApi(workspaceId, payloadId);
  };

  const assignStageOpportunity = (userId: number, oppId: number) => {
    return assignStageOpportunityApi(oppId, workspaceId, userId);
  };

  const onMoveOpportunity = (
    stageId: number,
    oppId: number,
    pipelineId: number
  ): Promise<PipelineOpportunityType> => {
    return moveStageOpportunityApi(workspaceId, pipelineId, stageId, oppId);
  };

  const sectionsMeta = {
    [SECTION_TYPE.TAGS]: {
      queryKey: `${QUERY_KEY_BASE}-tags`,
      getAllTags: getAllTagsApi,
      getAddedTags: getTagsByPersonIdApi,
      addTag: addTagApi,
      removeTag: removeTagApi,
    },
    [SECTION_TYPE.EMAILS]: {
      queryKey: `${QUERY_KEY_BASE}-emails`,
      title: 'Conversation history',
      getEmails: onGetEmail,
    },
    [SECTION_TYPE.CAMPAIGNS]: {
      queryKey: `${QUERY_KEY_BASE}-campaigns`,
      getOpportunities: getCampaignOpportunitiesByPersonIdApi,
    },
    [SECTION_TYPE.NOTES]: {
      queryKey: `${QUERY_KEY_BASE}-notes`,
      getNote: getAllNotesApi,
      addNote: addNoteApi,
      updateNote: updateNoteApi,
      removeNote: removeNoteApi,
    },
    [SECTION_TYPE.METRICS]: {
      itemId: website.id,
      queryKey: `${QUERY_KEY_BASE}-metrics`,
      getWebsite: getWebsiteByIdApi,
      getAllMetricsViewSettings: getAllMetricsViewSettingsByCompanyIdApi,
      setMetricValue: setMetricsValueByIdApi,
      deleteMetricValue: deleteMetricsValueByIdApi,
      showMetricByType: showMetricsByTypeApi,
      loadMetricValueById: loadMetricsValueByIdApi,
    },
    [SECTION_TYPE.VARIABLES]: {
      queryKey: `${QUERY_KEY_BASE}-variables`,
      relatedTo: CustomVariableValueRelatedTo.PERSON,
      getVariables: getCustomVariableValuesApi,
      createOrUpdateVariable: createOrUpdateCustomVariableValueApi,
    },
    [SECTION_TYPE.OPPORTUNITIES]: {
      itemId: website.id,
      queryKey: `${QUERY_KEY_BASE}-opportunities`,
      title: 'Deals',
      getOpportunities: getPipelineOpportunitiesByWebsiteIdApi,
      createOrUpdateCustomVariableValue: createOrUpdateCustomVariableValueApi,
      assignUser: assignStageOpportunity,
      moveOpportunity: onMoveOpportunity,
    },
  };

  return (
    <ErrorBoundary style={{ height: '100%' }}>
      <SidebarCRM
        type="contact"
        itemId={itemId}
        isOpen={isOpen}
        onClose={onClose}
        headerData={headerData}
        contentData={mapContentData}
        sectionsMeta={sectionsMeta}
        extraClass={isSidebarShadow && 'box-shadow'}
      />
    </ErrorBoundary>
  );
}

export default ContactSidebarWrapper;
