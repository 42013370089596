import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useInfiniteQuery, useQuery, useQueryClient } from 'react-query';
import { useLocation, useParams } from 'react-router-dom';

import {
  InboxAccountsPresetType,
  MailboxThreadDraftType,
  OutboxPreviewResponseType,
  QueuePreviewResponseType,
  ThreadPreviewResponseType,
} from '@ts/mailboxInbox.types';
import { GlobalInboxSortingType } from '@ts/inbox.types';

import { getGlobalOutboxPreviewEmailsApi, getThreadPreviewsApi } from '@api/inbox.api';

import {
  inboxFiltersSelector,
  inboxSelectedMailboxesIdsSelector,
  inboxSelectedStatusesSelector,
} from '@redux/selectors/inbox.selectors';

import usePagination from '@hooks/usePagination';
import InboxMailsSidebar from '@components/Inbox/InboxMailsSidebar/InboxMailsSidebar';
import useCurrentWorkspaceId from '@hooks/useCurrentWorkspaceId';
import InboxMailThread from '@components/Inbox/InboxMailThread/InboxMailThread';

import {
  addLabelApi,
  assignToUserApi,
  changeFavoriteStateApi,
  changeThreadsStatusApi,
  changeViewStateByThreadIdApi,
  deleteThreadApi,
  getThreadDraftApi,
  getThreadPreviewByThreadIdApi,
  removeLabelApi,
} from '@api/mailbox.api';
import { addNotification } from '@redux/actions/notifications.actions';
import OutboxMailThread from '@components/Inbox/OutboxMailThread/OutboxMailThread';
import {
  MailboxFolderLabel,
  MailboxFolderLabelMap,
  ThreadStatus,
  ThreadStatusMap,
} from 'respona_api/generated/mailbox_pb';

import { setSelectedInboxStatus, setSelectedMailboxesIds } from '@redux/actions/inbox.actions';

import './InboxContent.scss';
import InboxHeader from '@components/Inbox/InboxHeader/InboxHeader';
import { MessageRequestType, MessageRequestTypeMap } from 'respona_api/generated/mailbox-sender_pb';
import { campaignsTittleFilterSelector } from '@redux/selectors/campaigns.selectors';

const getLabelFromHistory = (inboxType) => {
  type MailboxFolderLabelType = MailboxFolderLabelMap[keyof MailboxFolderLabelMap];

  switch (inboxType) {
    case 'inbox':
      return MailboxFolderLabel.INBOX as MailboxFolderLabelType;
    case 'sent':
      return MailboxFolderLabel.SENT as MailboxFolderLabelType;
    case 'needAttention':
      return MailboxFolderLabel.NEED_ATTENTION as MailboxFolderLabelType;
    case 'spam':
      return MailboxFolderLabel.SPAM as MailboxFolderLabelType;
    case 'assigned':
      return MailboxFolderLabel.ASSIGNED_TO_ME as MailboxFolderLabelType;
    case 'mentioned':
      return MailboxFolderLabel.MENTIONED_ME as MailboxFolderLabelType;
    case 'starred':
      return MailboxFolderLabel.FAVORITE as MailboxFolderLabelType;
    case 'drafts':
      return MailboxFolderLabel.DRAFT as MailboxFolderLabelType;
    default:
      return MailboxFolderLabel.INBOX as MailboxFolderLabelType;
  }
};

const pagesWithTabs = ['/inbox/all', '/inbox/assigned', '/inbox/mentioned'];

function InboxContent({
  activeThread,
  setActiveThread,
  allMailboxes,
  isFetchingMailboxes,
  onNewMessage,
  refetchUnreadCounter,
}: {
  activeThread: (ThreadPreviewResponseType & QueuePreviewResponseType) | null;
  setActiveThread: Dispatch<
    SetStateAction<(ThreadPreviewResponseType & QueuePreviewResponseType) | null>
  >;
  allMailboxes: InboxAccountsPresetType[];
  isFetchingMailboxes: boolean;
  onNewMessage: () => void;
  refetchUnreadCounter;
}): JSX.Element {
  const { pathname } = useLocation();

  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { inboxType } = useParams();
  const currentWorkspaceId = useCurrentWorkspaceId();
  const selectedMailboxesIds = useSelector(inboxSelectedMailboxesIdsSelector);
  const status = useSelector(inboxSelectedStatusesSelector);
  const filters = useSelector(inboxFiltersSelector);

  const parsedStatus = useMemo(() => {
    return pagesWithTabs.find((item) => pathname.includes(item)) !== null &&
      filters.tagIdsList.length === 0
      ? status
      : ThreadStatus.ALL;
  }, [status, filters.tagIdsList, pathname]);

  const threadContainerRef = useRef<HTMLDivElement>();
  const pageNumber = useRef(0);

  const [sortDirection, changeSortDirection] = useState<GlobalInboxSortingType>(1);
  const titleSearch = useSelector(campaignsTittleFilterSelector);

  const changeActiveThread = (payload) => {
    if (payload?.uid?.length > 0 && payload?.draftRefetch === true) {
      getThreadPreviewByThreadIdApi(payload.uid, currentWorkspaceId).then((res) => {
        changeActiveThread(res as ThreadPreviewResponseType & QueuePreviewResponseType);
      });
    } else {
      if (payload) {
        queryClient.setQueryData(
          threadsQueryKey,
          (threads: { pageParams: any[]; pages: ThreadPreviewResponseType[][] }) => {
            return {
              ...threads,
              pages:
                threads?.pages?.map((pageData) =>
                  pageData.map((tmpThreadItem) =>
                    tmpThreadItem.uid === payload.uid
                      ? {
                          ...tmpThreadItem,
                          viewed: true,
                          snoozedUntil:
                            tmpThreadItem.snoozedUntil < Date.now()
                              ? -1
                              : tmpThreadItem.snoozedUntil,
                        }
                      : tmpThreadItem
                  )
                ) || [],
            };
          }
        );
      }
      if (payload === null) {
        setActiveThread(payload);
      } else {
        setActiveThread({
          ...payload,
          viewed: true,
          snoozedUntil: payload.snoozedUntil < Date.now() ? -1 : payload.snoozedUntil,
        });
      }
    }
  };
  const { limit } = usePagination(100);
  const isOutbox = inboxType === 'outbox';

  const label = getLabelFromHistory(inboxType);
  const threadsQueryKey = [
    'inbox-threads',
    selectedMailboxesIds,
    parsedStatus,
    sortDirection,
    filters.tagIdsList.length ? getLabelFromHistory('inbox') : label,
    currentWorkspaceId,
    titleSearch,
    isOutbox,
    filters,
  ];
  const handleChangeActiveThreadId = useCallback(
    (thread) => {
      changeActiveThread(thread);
    },
    [threadsQueryKey]
  );
  const handleToggleSortDirection = useCallback((newValue: GlobalInboxSortingType) => {
    changeSortDirection(newValue);
  }, []);

  const { data, isFetching, isFetchingNextPage, fetchNextPage, hasNextPage, remove, refetch } =
    useInfiniteQuery<ThreadPreviewResponseType[] | OutboxPreviewResponseType[]>(
      threadsQueryKey,
      ({ pageParam = 0 }) => {
        const props = {
          workspaceId: currentWorkspaceId,
          mailboxIdsList: selectedMailboxesIds.length
            ? selectedMailboxesIds
            : allMailboxes.map(({ id }) => id),
          status: parsedStatus,
          filter: filters,
          label: filters.tagIdsList.length ? getLabelFromHistory('inbox') : label,
          globalSearchQuery: titleSearch,
          sortDirection,
          page: pageParam,
          limit,
        };
        return !isOutbox ? getThreadPreviewsApi(props) : getGlobalOutboxPreviewEmailsApi(props);
      },
      {
        getNextPageParam: (
          lastPage: ThreadPreviewResponseType[] | OutboxPreviewResponseType[],
          allPages
        ) => {
          if (lastPage?.length < limit) return false;

          // allPages.length -> it is if to think the next page number
          return allPages?.length || 0;
        },
        enabled: !!currentWorkspaceId && !isFetchingMailboxes && !!limit && !!allMailboxes?.length,
        retry: 1,
        refetchOnWindowFocus: false,
        refetchInterval: 30 * 60 * 1000, // 30 minutes
      }
    );

  const handleChangeThreadStar = (curThreadId: string) => {
    changeFavoriteStateApi(curThreadId, currentWorkspaceId).then(() => {
      dispatch(addNotification({ title: 'Message status updated', type: 'success' }));
      queryClient.setQueryData(
        threadsQueryKey,
        (threads: { pageParams: any[]; pages: ThreadPreviewResponseType[][] }) => {
          return {
            ...threads,
            pages: threads.pages.map((pageData) => {
              return pageData.map((pageDataItem) => {
                if (pageDataItem.uid !== curThreadId) {
                  return pageDataItem;
                }
                changeActiveThread({ ...activeThread, favorite: !pageDataItem.favorite });
                return { ...pageDataItem, favorite: !pageDataItem.favorite };
              });
            }),
          };
        }
      );
    });
  };

  const handlePinLabel = (curThreadUid: string, labelId: number, labelTitle: string) => {
    addLabelApi(curThreadUid, labelTitle, labelId).then((response) => {
      dispatch(addNotification({ title: 'Label added', type: 'success' }));
      queryClient.setQueryData(
        threadsQueryKey,
        (threads: { pageParams: any[]; pages: ThreadPreviewResponseType[][] }) => {
          return {
            ...threads,
            pages: threads.pages.map((pageData) => {
              return pageData.map((pageDataItem) => {
                if (pageDataItem.uid !== curThreadUid) {
                  return pageDataItem;
                }
                const tags = [...pageDataItem.tagsList, response];
                changeActiveThread({ ...activeThread, tagsList: tags });
                return { ...pageDataItem, tagsList: tags };
              });
            }),
          };
        }
      );
    });
  };

  const handleUnpinLabel = (curThreadUid: string, labelId: number) => {
    removeLabelApi(labelId, curThreadUid).then(() => {
      dispatch(addNotification({ title: 'Label removed', type: 'success' }));
      queryClient.setQueryData(
        threadsQueryKey,
        (threads: { pageParams: any[]; pages: ThreadPreviewResponseType[][] }) => {
          return {
            ...threads,
            pages: threads.pages.map((pageData) => {
              return pageData.map((pageDataItem) => {
                if (pageDataItem.uid !== curThreadUid) {
                  return pageDataItem;
                }
                const existingTags = pageDataItem.tagsList.filter((tags) => tags.id !== labelId);
                changeActiveThread({ ...activeThread, tagsList: existingTags });
                return { ...pageDataItem, tagsList: existingTags };
              });
            }),
          };
        }
      );
    });
  };

  const composeDraftKey = [`thread-draft-id`, activeThread?.draftId];

  const { data: activeThreadDraft, isFetching: isFetchingDraft } = useQuery<MailboxThreadDraftType>(
    composeDraftKey,
    () => getThreadDraftApi(activeThread?.draftId),
    {
      refetchOnWindowFocus: false,
      enabled: activeThread?.draftId > 0,
    }
  );

  const handleChangeDraftType = (
    threadUid: string,
    type: MessageRequestTypeMap[keyof MessageRequestTypeMap]
  ) => {
    queryClient.setQueryData(composeDraftKey, (cache: MailboxThreadDraftType) => {
      return { ...cache, type };
    });
  };

  const handleDraftUpdate = (curThreadUid: string) => {
    queryClient.setQueryData(
      threadsQueryKey,
      (threads: { pageParams: any[]; pages: ThreadPreviewResponseType[][] }) => {
        const pgs = threads?.pages || [];
        if (!pgs.length) {
          return threads;
        }
        return {
          ...threads,
          pages: pgs.map((pageData) => {
            return pageData.map((pageDataItem) => {
              if (pageDataItem.uid !== curThreadUid) {
                return pageDataItem;
              }
              return {
                ...pageDataItem,
                draftExists: true,
                draftRefetch: true,
              };
            });
          }),
        };
      }
    );
  };

  const handleDraftDelete = (curThreadUid: string) => {
    if (pathname === '/inbox/drafts') {
      queryClient.setQueryData(
        threadsQueryKey,
        (threads: { pageParams: any[]; pages: ThreadPreviewResponseType[][] }) => {
          return {
            ...threads,
            pages:
              threads?.pages?.map((pageData) =>
                pageData.filter((pageDataItem) => pageDataItem.uid !== curThreadUid)
              ) || [],
          };
        }
      );
    } else {
      const emptyDraft = {
        threadId: 0,
        threadUid: '',
        messageId: '',
        subject: '',
        content: '',
        emailTo: '',
        emailsToCcList: [],
        emailsToBccList: [],
        attachmentsList: [],
        type: MessageRequestType.SEND_NEW_EMAIL,
      } as MailboxThreadDraftType;
      queryClient.setQueryData(
        threadsQueryKey,
        (threads: { pageParams: any[]; pages: ThreadPreviewResponseType[][] }) => {
          return {
            ...threads,
            pages:
              threads?.pages?.map((pageData) => {
                return pageData.map((pageDataItem) => {
                  if (pageDataItem.uid !== curThreadUid) {
                    return pageDataItem;
                  }

                  return {
                    ...pageDataItem,
                    draft: emptyDraft,
                    draftExists: false,
                  };
                });
              }) || [],
          };
        }
      );
    }
  };

  const handleChangeAssignUser = (curThreadUid: string, userId: number) => {
    assignToUserApi(curThreadUid, userId).then(() => {
      dispatch(addNotification({ title: 'Thread assigned', type: 'success' }));
      queryClient.setQueryData(
        threadsQueryKey,
        (threads: { pageParams: any[]; pages: ThreadPreviewResponseType[][] }) => {
          return {
            ...threads,
            pages: threads.pages.map((pageData) => {
              return pageData.map((pageDataItem) => {
                if (pageDataItem.uid !== curThreadUid) {
                  return pageDataItem;
                }

                return { ...pageDataItem, assignedToUserId: userId };
              });
            }),
          };
        }
      );
    });
  };

  const handleDeleteThread = (curThreadUid: string) => {
    deleteThreadApi(curThreadUid, currentWorkspaceId).then(() => {
      dispatch(addNotification({ title: 'Thread removed', type: 'success' }));
      queryClient.setQueryData(
        threadsQueryKey,
        (threads: { pageParams: any[]; pages: ThreadPreviewResponseType[][] }) => {
          return {
            ...threads,
            pages: threads.pages.map((pageData: any) =>
              pageData.filter(({ uid: tmpThreadId }, index) => {
                if (tmpThreadId === curThreadUid) {
                  if (pageData[index + 1]) {
                    changeActiveThread(pageData[index + 1]);
                  } else if (pageData[index - 1]) {
                    changeActiveThread(pageData[index - 1]);
                  } else {
                    changeActiveThread(null);
                  }
                  return false;
                }
                return true;
              })
            ),
          };
        }
      );
    });
  };

  const handleChangeThreadStatus = ({
    threadUid: curThreadUid,
    status: newStatus,
    changeStatus,
    actionTill,
    snoozedByUserId,
  }: {
    threadUid: string;
    status: ThreadStatusMap[keyof ThreadStatusMap];
    changeStatus: boolean;
    actionTill?: number;
    snoozedByUserId?: number;
  }) => {
    changeThreadsStatusApi(currentWorkspaceId, curThreadUid, newStatus, actionTill).then(() => {
      dispatch(addNotification({ title: 'Message status updated', type: 'success' }));
      setActiveThread((prevState) => {
        const newState = { ...prevState, status: newStatus };

        if (actionTill && snoozedByUserId) {
          newState.snoozedUntil = actionTill;
          newState.snoozedByUserId = snoozedByUserId;
        }

        return newState;
      });
      queryClient.setQueryData(
        threadsQueryKey,
        (threads: { pageParams: any[]; pages: ThreadPreviewResponseType[][] }) => {
          return {
            ...threads,
            pages: threads.pages.map((pageData: any) =>
              pageData
                .filter(({ uid: tmpThreadId }, index) => {
                  if (!changeStatus || !pagesWithTabs.includes(pathname)) {
                    return true;
                  }
                  if (tmpThreadId === curThreadUid) {
                    if (pageData[index + 1]) {
                      changeActiveThread(pageData[index + 1]);
                    } else if (pageData[index - 1]) {
                      changeActiveThread(pageData[index - 1]);
                    }
                    return false;
                  }
                  return true;
                })
                .map((pageDataItem) => {
                  if (pageDataItem.uid !== curThreadUid) {
                    return pageDataItem;
                  }

                  if (
                    newStatus === ThreadStatus.SNOOZED ||
                    (newStatus === ThreadStatus.OPEN && actionTill === -1)
                  ) {
                    return { ...pageDataItem, status: newStatus, snoozedUntil: actionTill };
                  }
                  return { ...pageDataItem, status: newStatus };
                })
            ),
          };
        }
      );
    });
  };

  const setViewed = (curThreadUid: string, isViewed: boolean) => {
    queryClient.setQueryData(
      threadsQueryKey,
      (threads: { pageParams: any[]; pages: ThreadPreviewResponseType[][] }) => {
        return {
          ...threads,
          pages: threads.pages.map((pageData) =>
            pageData.map((tmpThreadItem) =>
              tmpThreadItem.uid === curThreadUid
                ? {
                    ...tmpThreadItem,
                    viewed: isViewed,
                  }
                : tmpThreadItem
            )
          ),
        };
      }
    );
  };

  const setActiveThreadsItemViewStatus = (curThreadUid: string, viewed: boolean) => {
    changeActiveThread((prevState) => ({ ...prevState, viewed }));
    changeViewStateByThreadIdApi(curThreadUid, viewed)
      .then(() => {
        setViewed(curThreadUid, viewed);
        setActiveThread((prevState) => ({ ...prevState, viewed }));
        refetchUnreadCounter();
      })
      .catch(() => {
        changeActiveThread((prevState) => ({ ...prevState, viewed: !viewed }));
      });
  };

  useEffect(() => {
    if (pathname?.length > 0) {
      const match = pathname.match(/threads\/([^/]+)/);
      const threadId = match ? match[1] : undefined;
      if (threadId?.length > 0) {
        getThreadPreviewByThreadIdApi(String(threadId), currentWorkspaceId)
          .then((res) => {
            changeActiveThread(res as ThreadPreviewResponseType & QueuePreviewResponseType);
          })
          .catch((err) => {
            console.error(err);
          });
      }
    }
  }, [pathname]);

  useEffect(() => {
    handleChangeActiveThreadId(null);
  }, [inboxType]);

  // After going out from inbox page we clear our cache
  useEffect(() => {
    return () => {
      remove();
      // When we go out from InboxPage -> throw selected mailboxes
      dispatch(setSelectedMailboxesIds([]));
    };
  }, []);

  const loadMore = () => {
    pageNumber.current++;
    fetchNextPage();
  };

  const handleRefresh = () => {
    remove();
    refetch();
  };

  return (
    <div className="inbox-content">
      <InboxHeader onNewMessage={onNewMessage} allMailboxes={allMailboxes} />
      <div className="inbox-content__wrapper">
        <div className="inbox-content__mails-sidebar">
          <InboxMailsSidebar
            threadListByPages={data}
            isLoadingThreads={isFetching && pageNumber.current === 0}
            onLoadMore={loadMore}
            hasNextPage={hasNextPage}
            isFetchingNextPage={isFetchingNextPage}
            onChangeStatus={(newStatus) => {
              pageNumber.current = 0;
              dispatch(setSelectedInboxStatus(newStatus));
            }}
            status={parsedStatus}
            onChangeSorting={handleToggleSortDirection}
            activeThreadId={activeThread?.threadId}
            onChangeActiveThread={handleChangeActiveThreadId}
            handleRefresh={handleRefresh}
          />
        </div>

        <div className="inbox-content__thread-container" ref={threadContainerRef}>
          {isOutbox ? (
            <OutboxMailThread activeQueue={activeThread} />
          ) : (
            <InboxMailThread
              activeThread={activeThread}
              activeThreadDraft={activeThreadDraft}
              refetchUnreadCounter={refetchUnreadCounter}
              onChangeThreadStatus={handleChangeThreadStatus}
              onDeleteThread={handleDeleteThread}
              onChangeViewedStatus={setActiveThreadsItemViewStatus}
              onAssignUserId={handleChangeAssignUser}
              onChangeStarStatus={handleChangeThreadStar}
              onAddLabelId={handlePinLabel}
              onRemoveLabelId={handleUnpinLabel}
              threadContainerRef={threadContainerRef}
              onDraftCreation={handleDraftUpdate}
              onDraftDelete={handleDraftDelete}
              changeDraftType={handleChangeDraftType}
            />
          )}
        </div>
      </div>
    </div>
  );
}

// @ts-ignore
export default InboxContent;
