import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CampaignLaunchType } from '@ts/campaigns.types';

import {
  changeNotifyStateForOpportunityVerificationApi,
  exportListOpportunityVerificationResultApi,
  getOpportunityVerificationProgressApi,
  resetOpportunityVerificationApi,
  runOpportunityVerificationApi,
  stopOpportunityVerificationApi,
} from '@api/campaign.api';

import useCurrentWorkspaceId from '@hooks/useCurrentWorkspaceId';

import { downloadResponse } from '@helpers/fileHelpers';

import {
  getActiveCampaignInfo,
  getActiveCampaignVerifyProgress,
  getActiveCampaignVerifyStatus,
} from '@redux/selectors/campaignSettings.selectors';
import { addNotification } from '@redux/actions/notifications.actions';
import {
  csSetActiveCampaignVerifyProgress,
  csSetActiveCampaignVerifyStatus,
} from '@redux/actions/campaignSettings.actions';

import Checkbox from '@uikit/Checkbox/Checkbox';
import NumberInput from '@uikit/Input/NumberInput';
import { SVGIcon } from '@uikit/Icon/Icon';
import ProgressBar from '@uikit/ProgressBar/ProgressBar';
import { Button } from '@uikit/Button/Button';
import Loader from '@uikit/Loader/Loader';

import Display from '@components/Display/Display';
import VerifyingButton from '@components/VerifyAndLaunchCampaignButton/_components/VerifyingButton/VerifyingButton';

import QuestionTooltip from '@pages/CampaignCreationPage/_components/QuestionTooltip/QuestionTooltip';

import './CampaignSettingsVerification.scss';
import { CampaignVerificationStatus } from 'respona_api/generated/campaign_pb';
import { DispatchType } from 'src/store';
import { getRefetchShortOpportunities } from '@redux/selectors/pipelines.selectors';
import SlideToggler from '@uikit/SlideToggler/SlideToggler';

function ReviewCard({ icon, title, number, numberColor, rightComponent = null }) {
  return (
    <div className="campaign-settings-verification__review-card">
      <SVGIcon icon={icon} />
      <span className="campaign-settings-verification__review-card__title">{title}</span>
      <span
        className="campaign-settings-verification__review-card__number"
        style={{ color: numberColor }}
      >
        {number}
      </span>
      {rightComponent}
    </div>
  );
}

function CampaignSettingsVerification({
  onLaunch,
  onVerification,
  handleSaveClick,
  emailsPresent,
}: {
  onLaunch: (type: CampaignLaunchType) => Promise<void>;
  onVerification?: () => Promise<void>;
  handleSaveClick?: () => void;
  emailsPresent?: boolean;
}): JSX.Element {
  const dispatch = useDispatch<DispatchType>();
  const campaignInfo = useSelector(getActiveCampaignInfo)?.info;
  const streamRef = useRef();
  const stoppedStatus = useRef<boolean>(false);
  const inputRef = useRef<HTMLInputElement>();

  const [shouldNotify, setShouldNotify] = useState<boolean>(false);
  const [isExporting, setIsExporting] = useState<boolean>(false);
  const [isResetting, setIsResetting] = useState<boolean>(false);
  const [isVerifyingButtonLoading, setIsVerifyingButtonLoading] = useState<boolean>(false);

  const [isContactedInThePast, changeIsContactedInThePast] = useState<boolean>(true);
  const [daysInThePast, changeDaysInThePast] = useState<number | ''>(365);
  const [withEmptyVariables, changeWithEmptyVariables] = useState<boolean>(true);
  const [withPlaceholderBrackets, changeWithPlaceholderBrackets] = useState<boolean>(true);
  const [includedInAnotherCampaign, setIncludedInAnotherCampaign] = useState<boolean>(true);
  const [notIncludedInUnsubscribeList, setNotIncludedInUnsubscribeList] = useState<boolean>(true);
  const [verifyButtonType, changeVerifyButtonType] = useState<
    'LaunchUnverified' | 'Launching' | 'LaunchVerified'
  >('LaunchUnverified');

  const currentWorkspaceId = useCurrentWorkspaceId();

  const activeCampaignId = useSelector(getActiveCampaignInfo)?.info.id;
  const verifyingStatus = useSelector(getActiveCampaignVerifyStatus);
  const verifyingProgress = useSelector(getActiveCampaignVerifyProgress);
  const refetchOpportunities = useSelector(getRefetchShortOpportunities);
  const handleRunVerification = async () => {
    if (isContactedInThePast && !daysInThePast) {
      inputRef.current.focus();
      return;
    }
    setIsVerifyingButtonLoading(true);

    if (handleSaveClick) {
      await handleSaveClick();
    }

    runOpportunityVerificationApi(
      activeCampaignId,
      currentWorkspaceId,
      isContactedInThePast,
      withEmptyVariables,
      withPlaceholderBrackets,
      includedInAnotherCampaign,
      notIncludedInUnsubscribeList,
      daysInThePast as number
    )
      .then(() => {
        if (onVerification) {
          onVerification();
        }
        dispatch(csSetActiveCampaignVerifyStatus({ statusNumber: 1 }));
      })
      // this catch is needed here to prevent a red screen when the issue is occurred
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsVerifyingButtonLoading(false);
      });
  };

  useEffect(() => {
    if (verifyingStatus === 2) {
      changeVerifyButtonType('LaunchVerified');
    }
  }, [verifyingStatus]);

  const handleLaunch = async () => {
    changeVerifyButtonType('Launching');

    setIsVerifyingButtonLoading(true);
    if (handleSaveClick) {
      await handleSaveClick();
    }

    if (verifyingStatus === 2) {
      onLaunch(2)
        .catch(() => {
          changeVerifyButtonType('LaunchVerified');
        })
        .finally(() => {
          setIsVerifyingButtonLoading(false);
        });
    } else if (verifyingStatus === 0) {
      onLaunch(0)
        .catch(() => {
          changeVerifyButtonType('LaunchUnverified');
        })
        .finally(() => {
          setIsVerifyingButtonLoading(false);
        });
    }
  };

  const processCallback = (response) => {
    const { progress, totalNumber, verifiedNumber, flaggedNumber } = response.length && response[0];

    dispatch(
      csSetActiveCampaignVerifyProgress({
        progress: progress !== Infinity && !Number.isNaN(progress) ? progress : 0,
        totalNumber,
        verifiedNumber,
        flaggedNumber,
      })
    );

    if (progress === 100 && onVerification) {
      onVerification();
    }

    if (response[1]) {
      streamRef.current = response[1];
    }
  };

  useEffect(() => {
    if (!!campaignInfo?.id && verifyingStatus === 1) {
      getOpportunityVerificationProgressApi(
        campaignInfo.id,
        campaignInfo.workspaceId,
        processCallback
      ).then((response) => {
        if (!stoppedStatus.current) {
          processCallback(response.reverse());
          refetchOpportunities();
          dispatch(csSetActiveCampaignVerifyStatus({ statusNumber: 2 }));
        }
      });
    }
  }, [campaignInfo?.id, verifyingStatus]);

  useEffect(() => {
    if (!!campaignInfo?.id && verifyingStatus === 2) {
      getOpportunityVerificationProgressApi(
        campaignInfo.id,
        campaignInfo.workspaceId,
        processCallback
      );
    }
  }, [campaignInfo?.id]);

  const handleStopVerification = () => {
    stoppedStatus.current = true;
    stopOpportunityVerificationApi(campaignInfo.id, campaignInfo.workspaceId)
      .then(() => {
        // @ts-ignore
        streamRef.current && streamRef.current.cancel();
        dispatch(csSetActiveCampaignVerifyStatus({ statusNumber: 0 }));
        if (onVerification) {
          onVerification();
        }
      })
      .finally(() => {
        stoppedStatus.current = false;
      });
  };

  const handleChangeNotifyStateForOpportunityVerification = async () => {
    try {
      const res = await changeNotifyStateForOpportunityVerificationApi(
        campaignInfo.id,
        campaignInfo.workspaceId,
        campaignInfo.lastBatch
      );

      setShouldNotify(res.value);
    } catch (e) {
      console.error(e);
    }
  };

  const handleResetVerification = () => {
    setIsResetting(true);
    resetOpportunityVerificationApi(campaignInfo.id, campaignInfo.workspaceId)
      .then(() => {
        dispatch(csSetActiveCampaignVerifyStatus({ statusNumber: 0 }));
        if (onVerification) {
          onVerification();
        }
        dispatch(
          csSetActiveCampaignVerifyProgress({
            progress: 0,
            totalNumber: 0,
            verifiedNumber: 0,
            flaggedNumber: 0,
          })
        );
      })
      .finally(() => setIsResetting(false));
  };

  const handleDownloadErrorsClick = async () => {
    setIsExporting(true);

    try {
      const res = await exportListOpportunityVerificationResultApi(
        campaignInfo.id,
        campaignInfo.workspaceId,
        campaignInfo.lastBatch
      );
      downloadResponse(res);
    } catch (e) {
      console.error(e);
    } finally {
      setIsExporting(false);
    }
  };

  const isAllFlagged =
    !!verifyingProgress?.totalNumber &&
    verifyingProgress.totalNumber === verifyingProgress.flaggedNumber;

  if (verifyingStatus === 1 || verifyingStatus === 2) {
    return (
      <>
        <div className="campaign-settings-verification__review-step">
          <div className="campaign-settings-verification__review-step__cards">
            <ReviewCard
              title="Total"
              icon="search"
              number={verifyingProgress.totalNumber}
              numberColor="#58CD95"
            />
            <ReviewCard
              title="Verified"
              icon="checkmarkInCircleIcon"
              number={Math.max(
                verifyingProgress.verifiedNumber - verifyingProgress.flaggedNumber,
                0
              )}
              numberColor="#251FB6"
            />

            <ReviewCard
              title="Flagged"
              icon="exclamationMarkInCircleIcon"
              number={verifyingProgress.flaggedNumber}
              numberColor="#ED483D"
            />
          </div>
          <div className="campaign-settings-verification__review-step__process">
            <ProgressBar
              completedText="Verification completed"
              percentage={verifyingStatus === 2 ? 100 : verifyingProgress.progress}
            />
          </div>

          <Display
            isVisible={
              verifyingStatus === CampaignVerificationStatus.VERIFIED &&
              verifyingProgress.flaggedNumber > 0
            }
          >
            <Loader isLoading={isExporting} style={{ width: '14px', height: '14px' }}>
              <div className="campaign-settings-verification__review-step__flagged-items">
                Download the list of opportunities that were flagged <SVGIcon icon="reportIcon" />{' '}
                <div
                  className="campaign-settings-verification__review-step__flagged-items__download"
                  onClick={handleDownloadErrorsClick}
                >
                  Flagged-Items.csv
                </div>
              </div>
            </Loader>
          </Display>
        </div>

        <div className="campaign-settings-verification__footer">
          {verifyingStatus === CampaignVerificationStatus.IN_PROGRESS ? (
            <div className="review-footer__notify">
              <SlideToggler
                value={shouldNotify}
                onChange={handleChangeNotifyStateForOpportunityVerification}
              />
              <div className="review-footer__notify-title">Email me when it's done</div>
            </div>
          ) : (
            <Button
              type="ghost"
              style={{ paddingLeft: '0', minWidth: 'unset' }}
              isLoading={isResetting}
              onClick={() => handleResetVerification()}
            >
              Reset
            </Button>
          )}

          {verifyingStatus === CampaignVerificationStatus.VERIFIED ? (
            <VerifyingButton
              disabled={isAllFlagged || !emailsPresent}
              onClickDisabled={() =>
                dispatch(
                  addNotification({
                    title: isAllFlagged
                      ? 'All opportunities are flagged'
                      : 'Please select sender email(s) before launching campaign',
                    type: 'warning',
                  })
                )
              }
              type={verifyButtonType}
              onClick={handleLaunch}
            />
          ) : (
            <VerifyingButton type="Stop" onClick={handleStopVerification} />
          )}
        </div>
      </>
    );
  }

  return (
    <>
      <div className="campaign-settings-verification">
        <div className="campaign-settings-verification__header">
          <p className="campaign-settings-verification__title">Verify emails to make sure:</p>
        </div>
        <div className="campaign-settings-verification__body">
          <div className="campaign-settings-verification__check-row" tabIndex={0} role="button">
            <Checkbox
              onChange={(e) => changeIsContactedInThePast(e.target.checked)}
              value={isContactedInThePast}
            />
            Not contacted in the past
            <NumberInput
              value={daysInThePast}
              maxNumber={999}
              minNumber={1}
              onChange={(value) => changeDaysInThePast(value)}
              disabledArrows
              inputRef={inputRef}
            />
            days
          </div>

          <div className="campaign-settings-verification__check-row" tabIndex={0} role="button">
            <Checkbox
              onChange={(e) => changeWithEmptyVariables(e.target.checked)}
              value={withEmptyVariables}
            />
            Don’t contain empty variables
          </div>

          <div className="campaign-settings-verification__check-row" tabIndex={0} role="button">
            <Checkbox
              onChange={(e) => changeWithPlaceholderBrackets(e.target.checked)}
              value={withPlaceholderBrackets}
            />
            Don’t include placeholder brackets
          </div>

          <div className="campaign-settings-verification__check-row" tabIndex={0} role="button">
            <Checkbox
              onChange={(e) => setIncludedInAnotherCampaign(e.target.checked)}
              value={includedInAnotherCampaign}
            />
            Not included in other active campaigns
          </div>

          <div className="campaign-settings-verification__check-row" tabIndex={0} role="button">
            <Checkbox
              onChange={(e) => setNotIncludedInUnsubscribeList(e.target.checked)}
              value={notIncludedInUnsubscribeList}
              disabled
            />
            Not included in unsubscribed list
            <div className="campaign-verification__settings-label-tooltip">
              <QuestionTooltip text="You can remove contacts from the Unsubscribed tab in the Account menu" />
            </div>
          </div>
        </div>
      </div>

      <div className="campaign-settings-verification__footer">
        {/* {!!handleSaveClick ? ( */}
        {/*  <Button type="additional" onClick={handleSaveClick}> */}
        {/*    Save */}
        {/*  </Button> */}
        {/* ) : ( */}
        {/* )} */}
        <div />

        {!isContactedInThePast &&
        !withEmptyVariables &&
        !withPlaceholderBrackets &&
        !includedInAnotherCampaign ? (
          <VerifyingButton
            type={verifyButtonType}
            disabled={!emailsPresent}
            onClickDisabled={() =>
              dispatch(
                addNotification({
                  title: 'Please select a sender email(s) and schedule before launching campaign',
                  type: 'warning',
                })
              )
            }
            onClick={handleLaunch}
          />
        ) : (
          <VerifyingButton
            disabled={!emailsPresent}
            onClickDisabled={() =>
              dispatch(
                addNotification({
                  title:
                    'Please select a sender email(s) and schedule before verifying opportunities',
                  type: 'warning',
                })
              )
            }
            type="Verify"
            onClick={handleRunVerification}
            isLoading={isVerifyingButtonLoading}
          />
        )}
      </div>
    </>
  );
}

export default CampaignSettingsVerification;
