import {
  SET_USER_PROFILE_DATA,
  SET_USER_PROFILE_DATA_LS,
  SET_USER_AUTH_STEP,
  SET_SHOW_CHECKLIST_STATUS,
} from '@redux/actions/userProfile.actions';

import { UserProfileType } from '@ts/user.types';

import loadingStatuses from '@constants/loadingStatuses';
import { string } from 'prop-types';
import { memberRoleOptions } from '@mappers/common.mappers';

export type UserProfileStateType = {
  info: UserProfileType;
  loadingStatus: string;
};

const initialState: UserProfileStateType = {
  info: {
    firstName: '',
    lastName: '',
    email: '',
    addressLine1: '',
    addressLine2: '',
    website: '',
    city: '',
    state: '',
    country: '',
    zipCode: '',
    phoneNumber: '',
    fax: '',
    avatarUrl: '',
    customFieldsList: [],
    rolesList: [],
    intercomIdentityHash: '',
  },
  loadingStatus: loadingStatuses.PENDING,
};

export default (
  state: UserProfileStateType = initialState,
  { payload, type }
): UserProfileStateType => {
  switch (type) {
    case SET_USER_PROFILE_DATA:
      return {
        ...state,
        info: payload,
      };
    case SET_USER_PROFILE_DATA_LS:
      return {
        ...state,
        loadingStatus: payload,
      };
    case SET_USER_AUTH_STEP:
      return {
        ...state,
        info: {
          ...state.info,
          authStep: payload,
        },
      };
    case SET_SHOW_CHECKLIST_STATUS:
      return {
        ...state,
        info: {
          ...state.info,
          showChecklist: payload,
        },
      };
    default:
      return state;
  }
};
