import { contentSearchTypeMap } from '@mappers/contentSearch.mappers';
import { Button } from '@uikit/Button/Button';
import ErrorBoundary from '@uikit/ErrorBoundaries/ErrorBoundary';
import React, { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
// eslint-disable-next-line import/no-extraneous-dependencies
import cn from 'classnames';

import {
  getArticleSummary,
  getAudioInfo,
  searchArticleSummary,
} from '@redux/thunks/articleSummary.thunks';

import loadingStatuses from '@constants/loadingStatuses';
import { isPodcastType } from '@helpers/contentSearchHelpers';

import Display from '@components/Display/Display';
import CopyIcon from '@uikit/CopyIcon/CopyIcon';
import Player from '@uikit/Player/Player';
import { createArticleSummarySelector } from '@redux/selectors/articleSummary.selectors';

import Loader from '@uikit/Loader/Loader';

import './PersonalizationSideBar.scss';
import { DispatchType } from 'src/store';
import { fetchBillingCredits } from '@redux/thunks/billings.thunks';
import { personalizeOneOpportunity } from '@api/personalizationAutomation.api';
import { getActiveCampaignInfo } from '@redux/selectors/campaignSettings.selectors';
import { OpportunityDetailsType } from '@ts/opportunity.types';
import { SVGIcon } from '@uikit/Icon/Icon';
import { OpenAIModelType, OpenAITone } from 'respona_api/generated/common_pb';
import { PersonalizationAutomationStrategy } from 'respona_api/generated/automation-personalization_pb';
import { getCurrentWorkspaceId } from '@redux/selectors/workspaces.selectors';
import OpportunityContactSidebarWrapper from '@components/CampaignSettings/OpportunityContactSidebar/OpportunityContactSidebarWrapper';
import OpportunityContactSidebar from '@components/CampaignSettings/OpportunityContactSidebar/OpportunityContactSidebar';

function TextSummaryItems({
  summary,
}: {
  summary: {
    url: string;
    summariesList: string[];
  };
}): JSX.Element {
  return (
    <>
      {summary.summariesList.map((summaryItem, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div className="article-summary__list-item" key={`text-summary-item-${index}`}>
          <CopyIcon text={summaryItem} icon="copyBold" />
          <span>{summaryItem}</span>
        </div>
      ))}
    </>
  );
}

declare const IS_DEV: boolean;

function AudioPlayerItems({ info, isEpisode }) {
  if (info == null) {
    return <></>;
  }
  const items = [info];
  return (
    <>
      {items.slice(0, isEpisode ? 1 : 5).map(({ uid, description, title, episode }) => (
        <div className="article-summary__list-item podcast-summary-item" key={uid}>
          <div className="podcast-summary-item__info" key={uid}>
            <div key={uid}>
              <a href={episode?.iTunesUrl} className="podcast-summary-item__title" target="_blank">
                {title}
              </a>
              {description && (
                <div
                  key={uid}
                  className="podcast-summary-item__description"
                  dangerouslySetInnerHTML={{ __html: `${description.substr(0, 100)}...` }}
                />
              )}
            </div>
          </div>
          <Player src={episode?.audioUrl} />
        </div>
      ))}
    </>
  );
}

function PersonalizationSideBar({
  opportunity,
}: {
  opportunity: OpportunityDetailsType;
}): JSX.Element {
  const {
    id: opportunityId,
    type: opportunityType,
    uid: opportunityUid,
    url: opportunityUrl,
    title: opportunityTitle,
  } = opportunity;
  const dispatch = useDispatch<DispatchType>();

  const queryClient = useQueryClient();
  const currentWorkspaceId = useSelector(getCurrentWorkspaceId);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [isSummarizing, setIsSummarizing] = useState<boolean>(false);
  const [isPersonalizing, setIsPersonalizing] = useState<boolean>(false);
  const [personalizingProgress, setPersonalizingProgress] = useState<number>(0);
  const [summarizingProgress, setSummarizingProgress] = useState<number>(0);
  const [openOpportunitySection, setOpenOpportunitySection] = useState<boolean>(true);

  const articleSummary = useSelector(createArticleSummarySelector(opportunityId));
  const { info: campaignInfo } = useSelector(getActiveCampaignInfo);

  const setRelatedOpportunityPersonalized = (hasPersonalized: boolean) => {
    queryClient.setQueryData(
      ['active-opportunity', opportunityId],
      (prevState: OpportunityDetailsType) => {
        return {
          ...prevState,
          hasPersonalized,
        };
      }
    );
  };

  useEffect(() => {
    if (
      isPodcast &&
      (!articleSummary || articleSummary.audioLoadingStatus === loadingStatuses.PENDING)
    ) {
      handleGetAudioInfo();
    }
    if (
      !isPodcast &&
      (!articleSummary || articleSummary.summaryLoadingStatus === loadingStatuses.PENDING)
    ) {
      handleGetArticleSummaryByUrl();
    }
  }, [opportunityUrl]);

  const isPodcast = isPodcastType(opportunityType);

  const handleGetArticleSummaryByUrl = async () => {
    setIsFetching(true);

    try {
      if (opportunityUrl != null && opportunityUrl.length > 0) {
        await dispatch(getArticleSummary(currentWorkspaceId, opportunityId, opportunityUrl, false));
      }
    } finally {
      setIsFetching(false);
    }
  };

  const composeDescription = () => {
    if (opportunity.type === 0) {
      return opportunity.googleSearch.description;
    }
    if (opportunity.type === 1) {
      return opportunity.bingSearch.description;
    }
    if (opportunity.type === 2) {
      return opportunity.podcast.description;
    }
    if (opportunity.type === 3) {
      return opportunity.episode.description;
    }
    if (opportunity.type === 5) {
      return opportunity.bingNews.description;
    }
    if (opportunity.type === 12) {
      return opportunity.googleNews.description;
    }
    return '';
  };

  const handleGetArticleSummaryByAudioUrl = async () => {
    setIsFetching(true);

    try {
      if (
        isPodcast &&
        articleSummary != null &&
        articleSummary?.audioInfo.episode?.audioUrl != null &&
        articleSummary.audioInfo.episode.audioUrl.length > 0
      ) {
        await dispatch(
          getArticleSummary(
            currentWorkspaceId,
            opportunityId,
            articleSummary.audioInfo.episode.audioUrl,
            true
          )
        );
      }
    } finally {
      setIsFetching(false);
    }
  };

  const handleGetAudioInfo = async () => {
    setIsFetching(true);

    try {
      await dispatch(getAudioInfo(opportunityId, opportunityUid, opportunityType));
    } finally {
      setIsFetching(false);
    }
  };

  const handlePersonalize = async () => {
    setIsPersonalizing(true);

    personalizeOneOpportunity(
      campaignInfo.id,
      campaignInfo.workspaceId,
      campaignInfo.lastSequenceId,
      opportunityId,
      opportunityUrl,
      OpenAIModelType.GPT_CHEAP,
      OpenAITone.CREATIVE,
      true,
      PersonalizationAutomationStrategy.PAGE_SUMMARIES,
      [
        'Use short, choppy sentences and paragraphs.',
        'Limit the use of commas.',
        '5th-grade reading level, use simple words and avoid buzzwords.',
      ],
      (data) => {
        const response = data[0];
        setPersonalizingProgress(response.progress);
        if (response.complete) {
          setIsPersonalizing(false);
        }
      }
    ).then(() => {
      setRelatedOpportunityPersonalized(true);
    });
  };

  const handleSearchArticleSummary = async () => {
    setIsSummarizing(true);

    try {
      if (isPodcast) {
        if (articleSummary?.audioInfo.episode?.audioUrl != null) {
          await dispatch(
            searchArticleSummary(
              currentWorkspaceId,
              opportunityId,
              articleSummary.audioInfo.episode.audioUrl,
              opportunityUid,
              isPodcast,
              ([data]) => setSummarizingProgress(data.progress)
            )
          );
        }
      }
      if (!isPodcast) {
        await dispatch(
          searchArticleSummary(
            currentWorkspaceId,
            opportunityId,
            opportunityUrl,
            opportunityUid,
            isPodcast,
            ([data]) => setSummarizingProgress(data.progress)
          )
        );
      }
    } finally {
      dispatch(fetchBillingCredits());
      setIsSummarizing(false);
      setSummarizingProgress(0);
    }
  };

  useEffect(() => {
    if (
      isPodcast &&
      articleSummary != null &&
      articleSummary.audioLoadingStatus === loadingStatuses.LOADED &&
      articleSummary?.audioInfo != null &&
      [loadingStatuses.INITED, loadingStatuses.PENDING].includes(
        articleSummary.summaryLoadingStatus
      )
    ) {
      handleGetArticleSummaryByAudioUrl();
    }
  }, [articleSummary]);

  const renderSummaryContent = () => {
    // @ts-ignore
    return (
      <>
        {articleSummary != null &&
          articleSummary.audioInfo != null &&
          isPodcast &&
          openOpportunitySection && (
            <AudioPlayerItems
              isEpisode={opportunityType === contentSearchTypeMap.episodes}
              info={articleSummary.audioInfo}
            />
          )}
        {articleSummary == null || !articleSummary.summary?.requested ? (
          <Button
            className="article-summary__button"
            size="m"
            type="ghost"
            onClick={handleSearchArticleSummary}
            isLoading={isSummarizing}
            loadingText={`${summarizingProgress}%`}
          >
            {isPodcast ? 'Summarize episode' : 'Summarize Page'}
          </Button>
        ) : (
          <div className="article-summary__list-container">
            {!articleSummary.summary?.summariesList?.length ? (
              <div className="article-summary__empty-content">
                {isSummarizing ? 'Refreshing...' : 'No summaries found'}
              </div>
            ) : (
              <TextSummaryItems summary={articleSummary.summary} />
            )}
          </div>
        )}

        {IS_DEV && (
          <Button
            className="article-summary__button"
            size="m"
            type="ghost"
            onClick={handlePersonalize}
            isLoading={isPersonalizing}
            loadingText={`${personalizingProgress}%`}
          >
            Personalize
          </Button>
        )}
      </>
    );
  };

  return (
    <div className="article-summary">
      <ErrorBoundary style={{ maxHeight: '230px' }}>
        <Loader
          style={{ marginBottom: '20px' }}
          isLoading={
            isFetching ||
            (articleSummary &&
              ((!isPodcast &&
                [loadingStatuses.PENDING].includes(articleSummary.summaryLoadingStatus)) ||
                (isPodcast &&
                  [loadingStatuses.PENDING].includes(articleSummary.audioLoadingStatus))))
          }
          withTopMargin
        >
          <Display isVisible={!!opportunityUrl}>
            <>
              <div
                className="article-summary__title dropdown"
                onClick={() => setOpenOpportunitySection(!openOpportunitySection)}
              >
                <p>{isPodcast ? 'Audio summary' : 'Opportunity'}</p>
                <span
                  className={cn('dropdown-btn', {
                    'dropdown-btn--open': openOpportunitySection,
                  })}
                >
                  <SVGIcon icon="arrowDown" color="#7B7B7BB0" />
                </span>

                {/* <span */}
                {/*  className={cn('article-summary__refresh-icon', { */}
                {/*    'article-summary__refresh-icon--animated': isSummarizing, */}
                {/*  })} */}
                {/*  onClick={() => handleSearchArticleSummary(true)} */}
                {/* > */}
                {/*  <SVGIcon icon="refreshOneArrowAround" color="#D1D1D1" size={14} /> */}
                {/* </span> */}
              </div>
              {openOpportunitySection && !isPodcast && (
                <div
                  className={cn('article-summary__text', {
                    'article-summary__text--open': openOpportunitySection,
                  })}
                >
                  <h3>{opportunityTitle}</h3>
                  <div className="article-summary__text-description">{composeDescription()}</div>
                  <div className="article-summary__text-uid">
                    <a href={opportunityUrl} target="_blank">
                      <SVGIcon icon="link" size={10} color="#221CB6" />
                      <p className="article-summary__text-uid-link">{opportunityUrl}</p>
                    </a>
                  </div>
                </div>
              )}
              <div className="article-summary__list">{renderSummaryContent()}</div>
            </>
          </Display>
        </Loader>
      </ErrorBoundary>

      <div
        style={{
          flex: 1,
          width: '100%',
          minHeight: '680px',
        }}
      >
        {/*<OpportunityContactSidebar opportunityId={opportunity.id} />*/}
         <OpportunityContactSidebarWrapper isSidebarShadow opportunityId={opportunity.id} />
      </div>
    </div>
  );
}

export default PersonalizationSideBar;
