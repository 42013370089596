import getStringHashCode from '@utils/getStringHashCode';
import { checkIsAnyNotLoaded } from '@utils/loadingChecks';
import { isInternalPage } from '@utils/redirections';
import { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { intercomLeftPositionPages, pagesWithIntercom } from '@constants/urls';

import { userProfileSelector } from '@redux/selectors/userProfile.selector';

import {
  load as loadIntercom,
  boot as bootIntercom,
  update as updateIntercom,
} from 'src/libs/intercom';

const useIntercom = (): void => {
  const [isIntercomLoaded, setIsIntercomLoaded] = useState(false);
  const [isIntercomBooted, setIsIntercomBooted] = useState(false);

  const { info: userProfile, loadingStatus } = useSelector(userProfileSelector);

  const isLoggedIn = !!localStorage.getItem('token');
  const showDefaultLauncher =
    pagesWithIntercom.find((page) => useRouteMatch(page)) ||
    !isInternalPage(window.location.pathname);

  const showLeftLauncher = intercomLeftPositionPages.find((page) => useRouteMatch(page));

  useEffect(() => {
    loadIntercom();
    setIsIntercomLoaded(true);
  }, []);

  useEffect(() => {
    if (!isIntercomLoaded) {
      return;
    }

    if (isLoggedIn && checkIsAnyNotLoaded(loadingStatus)) {
      return;
    }

    const updater = isIntercomBooted ? updateIntercom : bootIntercom;

    updater({
      hide_default_launcher: !showDefaultLauncher,
      alignment: showLeftLauncher ? 'left' : undefined,

      ...(isLoggedIn
        ? {
            email: userProfile.email,
            user_id: userProfile.email,
            name: `${userProfile.firstName} ${userProfile.lastName}`,
            user_hash: userProfile.intercomIdentityHash,
          }
        : {}),
    });

    if (!isIntercomBooted) {
      setIsIntercomBooted(true);
    }
  }, [isIntercomLoaded, isLoggedIn, loadingStatus, showDefaultLauncher]);
};

export default useIntercom;
