import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { useGoogleLogin } from '@react-oauth/google';

import RightSidebar from '@components/RightSidebar/RightSidebar';
import Loader from '@uikit/Loader/Loader';

import { getBillingShortDetailsApi } from '@api/billing.api';

import WarningTriangleCircleIcon from '@uikit/Icon/svgIcons/WarningTriangleCircleIcon';
import { redirectUserTo } from '@utils/historyHandler';
// @ts-ignore
import gmailIcon from './assets/gmailIcon.svg';
// @ts-ignore
import microsoftIcon from './assets/microsoftIcon.svg';
// @ts-ignore
import otherIcon from './assets/otherIcon.svg';

import './AddEmailSidebar.scss';

declare const IS_DEV: boolean;
declare const INTEGRATION_MODE: string;

function AddEmailSidebar({
  isOpen,
  onClose,
  onAddGmailAccount,
  onOpenUpsellSidebar,
  handleSMTP,
}: {
  isOpen: boolean;
  onClose: () => void;
  onAddGmailAccount: (code: string) => void;
  onOpenUpsellSidebar: () => void;
  handleSMTP?: () => void;
}): JSX.Element {
  const history = useHistory();
  const [googleHover, setGoogleHover] = useState<boolean>(false);
  const { isFetching, data: billingDetailsShort } = useQuery({
    queryFn: () => getBillingShortDetailsApi(),
    enabled: isOpen,
    refetchOnWindowFocus: false,
  });

  const googleAuth = useGoogleLogin({
    onSuccess: ({ code }) => {
      onAddGmailAccount(code);
      onClose();
    },
    flow: 'auth-code',
    scope:
      'profile https://www.googleapis.com/auth/gmail.readonly https://www.googleapis.com/auth/gmail.send',
  });

  useEffect(() => {
    if (isFetching) {
      return;
    }

    if (billingDetailsShort) {
      const needIncreaseEmails =
        billingDetailsShort.currentMailboxAccountsNumber >=
        billingDetailsShort.maxMailboxAccountsNumber;

      if (needIncreaseEmails) {
        onClose();
        onOpenUpsellSidebar();
      }
    }
  }, [billingDetailsShort, isFetching]);

  const handleOtherProvider = () =>
    handleSMTP ? handleSMTP() : redirectUserTo(history, '/settings/email-smtp');

  const handleMicrosoftProvider = () => {
    window.open(
      `https://${
        IS_DEV ? 'dev.respona.com' : 'app.respona.com'
      }/mailbox/microsoft/secure/aad?auth=${localStorage.getItem('token')}`,
      '_self'
    );
  };

  if (!isOpen) {
    return null;
  }

  if (isFetching) {
    return (
      <div className="add-email-sidebar">
        <RightSidebar isOpen onClose={onClose} title="Connect your Email" showFooter={false}>
          <Loader isFullBlockLoading isLoading withTopMargin />
        </RightSidebar>
      </div>
    );
  }

  return (
    <div className="add-email-sidebar">
      <RightSidebar isOpen onClose={onClose} title="Connect your Email" showFooter={false}>
        <div className="add-email-sidebar__content">
          <div className="add-email-sidebar__title">Select Email Provider</div>

          <div
            className="add-email-sidebar__provider add-email-sidebar__provider--gmail"
            // onClick={handleGoogleClick}
            onClick={() => googleAuth()}
            // onMouseEnter={() => setGoogleHover(true)}
            // onMouseLeave={() => setGoogleHover(false)}
          >
            {!googleHover ? (
              <>
                <img
                  src={gmailIcon}
                  width="80px"
                  height="80px"
                  className="add-email-sidebar__provider-icon"
                  alt="Gmail button for add email"
                />
                <div>
                  <div className="add-email-sidebar__provider-name">Google</div>
                  <div className="add-email-sidebar__provider-desc">G-suite, Gmail</div>
                </div>
              </>
            ) : (
              <>
                <div className="add-email-sidebar__provider-icon">
                  <WarningTriangleCircleIcon size="64" />
                </div>
                <div>
                  <div className="add-email-sidebar__provider-name">Temporarily unavailable</div>
                  <div className="add-email-sidebar__provider-desc">
                    Please connect your email via IMAP/SMTP
                  </div>
                </div>
              </>
            )}
          </div>

          {INTEGRATION_MODE === 'sumrush' ? null : (
            <div
              className="add-email-sidebar__provider add-email-sidebar__provider--microsoft"
              onClick={handleMicrosoftProvider}
            >
              <img
                src={microsoftIcon}
                width="80px"
                height="80px"
                className="add-email-sidebar__provider-icon"
                alt="Microsoft button for add email"
              />
              <div>
                <div className="add-email-sidebar__provider-name">Microsoft</div>
                <div className="add-email-sidebar__provider-desc">
                  Office 365, Outlook, Exchange
                </div>
              </div>
            </div>
          )}

          <div
            className="add-email-sidebar__provider add-email-sidebar__provider--other"
            onClick={handleOtherProvider}
          >
            <img
              src={otherIcon}
              width="80px"
              height="80px"
              className="add-email-sidebar__provider-icon"
              alt="SMTP button for add email"
            />
            <div>
              <div className="add-email-sidebar__provider-name">Other providers</div>
              <div className="add-email-sidebar__provider-desc">IMAP, SMTP</div>
            </div>
          </div>
        </div>
      </RightSidebar>
    </div>
  );
}

export default AddEmailSidebar;
