import React, { useState } from 'react';
import BaseModal from '@uikit/BaseModal/BaseModal';

import { useHistory } from 'react-router-dom';

import './FirstCampaignModal.scss';
import { SVGIcon, SvgIconType } from '@uikit/Icon/Icon';
import { Button } from '@uikit/Button/Button';
import cn from 'class-names';
import { redirectUserTo } from '@utils/historyHandler';
import useCurrentWorkspaceId from "@hooks/useCurrentWorkspaceId";

declare const IS_DEV: boolean;

const plates = [
  {
    id: IS_DEV ? 22 : 12,
    title: 'Build backlinks',
    info: 'Collaborate with relevant websites to build backlinks and increase your organic traffic.',
    icon: 'buildBacklinks' as SvgIconType,
  },
  {
    id: IS_DEV ? 8 : 8,
    title: 'Book podcast interviews',
    info: 'Get brand exposure by booking interviews on popular podcasts in your space.',
    icon: 'bookPodcastsInterview' as SvgIconType,
  },
  {
    id: IS_DEV ? 5 : 5,
    title: 'Recruit affiliates',
    info: 'Invite publications to join your affiliate program and drive sales.',
    icon: 'recruitAffiliates' as SvgIconType,
  },
  {
    id: IS_DEV ? 26 : 16,
    title: 'Get press mentions',
    info: 'Pitch your story to get featured on relevant media publications.',
    icon: 'getPressMentions' as SvgIconType,
  },
];

function FirstCampaignModal(): JSX.Element {
  const history = useHistory();
  const [activeId, setActiveId] = useState<number>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const currentWorkspaceId = useCurrentWorkspaceId();

  function startCampaignFromTemplate(value: number) {
    setIsLoading(true);
    redirectUserTo(history, `/workspaces/${currentWorkspaceId}/campaign-templates/${value}`);
    doClose();
  }

  const doClose = () => {
    localStorage.removeItem('firstCampaign');
    setIsOpen(false);
  };

  return (
    <BaseModal
      classNames={{ modal: 'first-campaign-modal' }}
      isOpen={isOpen}
      closeButton={false}
      iconColor="#BDBDBD"
    >
      <div className="first-campaign-modal__header">
        <div className="first-campaign-modal__header__title">
          Let’s start with your first campaign 🚀
        </div>
        <div className="first-campaign-modal__header__sub-title">
          Select your primary objective:
        </div>
      </div>
      <div className="first-campaign-modal__body">
        {plates.map(({ icon, title, info, id }) => (
          <div
            key={id}
            className={cn('first-campaign-modal__body__card', {
              'first-campaign-modal__body__card--selected': activeId === id,
            })}
            onClick={() => setActiveId(id)}
          >
            <div className="first-campaign-modal__body__card__image">
              <div className="first-campaign-modal__body__card__image__container">
                <SVGIcon icon={icon} />
              </div>
            </div>
            <div className="first-campaign-modal__body__card__info">
              <div className="first-campaign-modal__body__card__info__title">{title}</div>
              <div className="first-campaign-modal__body__card__info__sub-title">{info}</div>
            </div>
          </div>
        ))}

        <Button
          className="first-campaign-modal__submit-button"
          onClick={() => startCampaignFromTemplate(activeId)}
          isLoading={isLoading}
          disabled={activeId == null}
        >
          Continue
        </Button>
      </div>
    </BaseModal>
  );
}

export default FirstCampaignModal;
