// source: people.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var common_pb = require('./common_pb.js');
goog.object.extend(proto, common_pb);
goog.exportSymbol('proto.people.CustomVariableCreateRequest', null, global);
goog.exportSymbol('proto.people.CustomVariableCreateRequest.SettingsCase', null, global);
goog.exportSymbol('proto.people.CustomVariableRequest', null, global);
goog.exportSymbol('proto.people.CustomVariableResponse', null, global);
goog.exportSymbol('proto.people.CustomVariableResponse.SettingsCase', null, global);
goog.exportSymbol('proto.people.CustomVariableUpdateRequest', null, global);
goog.exportSymbol('proto.people.CustomVariableUpdateRequest.SettingsCase', null, global);
goog.exportSymbol('proto.people.CustomVariableValueCreateRequest', null, global);
goog.exportSymbol('proto.people.ExportPeopleRequest', null, global);
goog.exportSymbol('proto.people.ExportPeopleRequest.ExportType', null, global);
goog.exportSymbol('proto.people.OpenAICustomVariableSettings', null, global);
goog.exportSymbol('proto.people.PeopleCrudRequest', null, global);
goog.exportSymbol('proto.people.PeopleCrudResponse', null, global);
goog.exportSymbol('proto.people.PeopleDetailResponse', null, global);
goog.exportSymbol('proto.people.PeopleFilter', null, global);
goog.exportSymbol('proto.people.PeopleFilterField', null, global);
goog.exportSymbol('proto.people.PeoplePageRequest', null, global);
goog.exportSymbol('proto.people.PeopleResponse', null, global);
goog.exportSymbol('proto.people.PeopleShortResponse', null, global);
goog.exportSymbol('proto.people.PeopleTableColumns', null, global);
goog.exportSymbol('proto.people.PeopleWebsiteRelationshipStatuses', null, global);
goog.exportSymbol('proto.people.PeopleWebsiteRelationshipStatuses.Person', null, global);
goog.exportSymbol('proto.people.PeopleWebsiteShortResponse', null, global);
goog.exportSymbol('proto.people.PersonCustomVariableValueRequest', null, global);
goog.exportSymbol('proto.people.PersonInfoRequest', null, global);
goog.exportSymbol('proto.people.PersonInfoUpdateRequest', null, global);
goog.exportSymbol('proto.people.PersonRelationshipStatusRequest', null, global);
goog.exportSymbol('proto.people.SelectCustomVariableSettings', null, global);
goog.exportSymbol('proto.people.SelectCustomVariableSettings.SelectItem', null, global);
goog.exportSymbol('proto.people.TableSettingsRequest', null, global);
goog.exportSymbol('proto.people.TableSettingsResponse', null, global);
goog.exportSymbol('proto.people.TableSettingsStoreRequest', null, global);
goog.exportSymbol('proto.people.UnsubscribedPersonsList', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.people.PeopleCrudRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.people.PeopleCrudRequest.repeatedFields_, null);
};
goog.inherits(proto.people.PeopleCrudRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.people.PeopleCrudRequest.displayName = 'proto.people.PeopleCrudRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.people.PeopleCrudResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.people.PeopleCrudResponse.repeatedFields_, null);
};
goog.inherits(proto.people.PeopleCrudResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.people.PeopleCrudResponse.displayName = 'proto.people.PeopleCrudResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.people.PeopleDetailResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.people.PeopleDetailResponse.repeatedFields_, null);
};
goog.inherits(proto.people.PeopleDetailResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.people.PeopleDetailResponse.displayName = 'proto.people.PeopleDetailResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.people.PeopleShortResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.people.PeopleShortResponse.repeatedFields_, null);
};
goog.inherits(proto.people.PeopleShortResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.people.PeopleShortResponse.displayName = 'proto.people.PeopleShortResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.people.PeopleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.people.PeopleResponse.repeatedFields_, null);
};
goog.inherits(proto.people.PeopleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.people.PeopleResponse.displayName = 'proto.people.PeopleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.people.PeopleWebsiteShortResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.people.PeopleWebsiteShortResponse.repeatedFields_, null);
};
goog.inherits(proto.people.PeopleWebsiteShortResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.people.PeopleWebsiteShortResponse.displayName = 'proto.people.PeopleWebsiteShortResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.people.PeoplePageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.people.PeoplePageRequest.repeatedFields_, null);
};
goog.inherits(proto.people.PeoplePageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.people.PeoplePageRequest.displayName = 'proto.people.PeoplePageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.people.PeopleTableColumns = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.people.PeopleTableColumns, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.people.PeopleTableColumns.displayName = 'proto.people.PeopleTableColumns';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.people.PeopleFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.people.PeopleFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.people.PeopleFilter.displayName = 'proto.people.PeopleFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.people.ExportPeopleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.people.ExportPeopleRequest.repeatedFields_, null);
};
goog.inherits(proto.people.ExportPeopleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.people.ExportPeopleRequest.displayName = 'proto.people.ExportPeopleRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.people.PeopleWebsiteRelationshipStatuses = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.people.PeopleWebsiteRelationshipStatuses.repeatedFields_, null);
};
goog.inherits(proto.people.PeopleWebsiteRelationshipStatuses, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.people.PeopleWebsiteRelationshipStatuses.displayName = 'proto.people.PeopleWebsiteRelationshipStatuses';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.people.PeopleWebsiteRelationshipStatuses.Person = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.people.PeopleWebsiteRelationshipStatuses.Person, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.people.PeopleWebsiteRelationshipStatuses.Person.displayName = 'proto.people.PeopleWebsiteRelationshipStatuses.Person';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.people.CustomVariableCreateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.people.CustomVariableCreateRequest.oneofGroups_);
};
goog.inherits(proto.people.CustomVariableCreateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.people.CustomVariableCreateRequest.displayName = 'proto.people.CustomVariableCreateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.people.CustomVariableUpdateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.people.CustomVariableUpdateRequest.oneofGroups_);
};
goog.inherits(proto.people.CustomVariableUpdateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.people.CustomVariableUpdateRequest.displayName = 'proto.people.CustomVariableUpdateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.people.OpenAICustomVariableSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.people.OpenAICustomVariableSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.people.OpenAICustomVariableSettings.displayName = 'proto.people.OpenAICustomVariableSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.people.SelectCustomVariableSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.people.SelectCustomVariableSettings.repeatedFields_, null);
};
goog.inherits(proto.people.SelectCustomVariableSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.people.SelectCustomVariableSettings.displayName = 'proto.people.SelectCustomVariableSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.people.SelectCustomVariableSettings.SelectItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.people.SelectCustomVariableSettings.SelectItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.people.SelectCustomVariableSettings.SelectItem.displayName = 'proto.people.SelectCustomVariableSettings.SelectItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.people.CustomVariableResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.people.CustomVariableResponse.oneofGroups_);
};
goog.inherits(proto.people.CustomVariableResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.people.CustomVariableResponse.displayName = 'proto.people.CustomVariableResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.people.CustomVariableRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.people.CustomVariableRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.people.CustomVariableRequest.displayName = 'proto.people.CustomVariableRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.people.PersonCustomVariableValueRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.people.PersonCustomVariableValueRequest.repeatedFields_, null);
};
goog.inherits(proto.people.PersonCustomVariableValueRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.people.PersonCustomVariableValueRequest.displayName = 'proto.people.PersonCustomVariableValueRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.people.TableSettingsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.people.TableSettingsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.people.TableSettingsRequest.displayName = 'proto.people.TableSettingsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.people.TableSettingsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.people.TableSettingsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.people.TableSettingsResponse.displayName = 'proto.people.TableSettingsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.people.TableSettingsStoreRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.people.TableSettingsStoreRequest.repeatedFields_, null);
};
goog.inherits(proto.people.TableSettingsStoreRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.people.TableSettingsStoreRequest.displayName = 'proto.people.TableSettingsStoreRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.people.UnsubscribedPersonsList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.people.UnsubscribedPersonsList.repeatedFields_, null);
};
goog.inherits(proto.people.UnsubscribedPersonsList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.people.UnsubscribedPersonsList.displayName = 'proto.people.UnsubscribedPersonsList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.people.PersonInfoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.people.PersonInfoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.people.PersonInfoRequest.displayName = 'proto.people.PersonInfoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.people.PersonInfoUpdateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.people.PersonInfoUpdateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.people.PersonInfoUpdateRequest.displayName = 'proto.people.PersonInfoUpdateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.people.PersonRelationshipStatusRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.people.PersonRelationshipStatusRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.people.PersonRelationshipStatusRequest.displayName = 'proto.people.PersonRelationshipStatusRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.people.CustomVariableValueCreateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.people.CustomVariableValueCreateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.people.CustomVariableValueCreateRequest.displayName = 'proto.people.CustomVariableValueCreateRequest';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.people.PeopleCrudRequest.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.people.PeopleCrudRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.people.PeopleCrudRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.people.PeopleCrudRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.people.PeopleCrudRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    jobPosition: jspb.Message.getFieldWithDefault(msg, 3, ""),
    emailsList: jspb.Message.toObjectList(msg.getEmailsList(),
    common_pb.Email.toObject, includeInstance),
    websiteName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    websiteDomain: jspb.Message.getFieldWithDefault(msg, 6, ""),
    websiteLogo: jspb.Message.getFieldWithDefault(msg, 7, ""),
    status: jspb.Message.getFieldWithDefault(msg, 8, 0),
    optFolderId: jspb.Message.getFieldWithDefault(msg, 9, 0),
    workspaceId: jspb.Message.getFieldWithDefault(msg, 10, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.people.PeopleCrudRequest}
 */
proto.people.PeopleCrudRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.people.PeopleCrudRequest;
  return proto.people.PeopleCrudRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.people.PeopleCrudRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.people.PeopleCrudRequest}
 */
proto.people.PeopleCrudRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setJobPosition(value);
      break;
    case 4:
      var value = new common_pb.Email;
      reader.readMessage(value,common_pb.Email.deserializeBinaryFromReader);
      msg.addEmails(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setWebsiteName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setWebsiteDomain(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setWebsiteLogo(value);
      break;
    case 8:
      var value = /** @type {!proto.common.PeopleRelationshipStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOptFolderId(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWorkspaceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.people.PeopleCrudRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.people.PeopleCrudRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.people.PeopleCrudRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.people.PeopleCrudRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getJobPosition();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEmailsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      common_pb.Email.serializeBinaryToWriter
    );
  }
  f = message.getWebsiteName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getWebsiteDomain();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getWebsiteLogo();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getOptFolderId();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getWorkspaceId();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.people.PeopleCrudRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.people.PeopleCrudRequest} returns this
 */
proto.people.PeopleCrudRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.people.PeopleCrudRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.people.PeopleCrudRequest} returns this
 */
proto.people.PeopleCrudRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string job_position = 3;
 * @return {string}
 */
proto.people.PeopleCrudRequest.prototype.getJobPosition = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.people.PeopleCrudRequest} returns this
 */
proto.people.PeopleCrudRequest.prototype.setJobPosition = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated common.Email emails = 4;
 * @return {!Array<!proto.common.Email>}
 */
proto.people.PeopleCrudRequest.prototype.getEmailsList = function() {
  return /** @type{!Array<!proto.common.Email>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_pb.Email, 4));
};


/**
 * @param {!Array<!proto.common.Email>} value
 * @return {!proto.people.PeopleCrudRequest} returns this
*/
proto.people.PeopleCrudRequest.prototype.setEmailsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.common.Email=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.Email}
 */
proto.people.PeopleCrudRequest.prototype.addEmails = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.common.Email, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.people.PeopleCrudRequest} returns this
 */
proto.people.PeopleCrudRequest.prototype.clearEmailsList = function() {
  return this.setEmailsList([]);
};


/**
 * optional string website_name = 5;
 * @return {string}
 */
proto.people.PeopleCrudRequest.prototype.getWebsiteName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.people.PeopleCrudRequest} returns this
 */
proto.people.PeopleCrudRequest.prototype.setWebsiteName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string website_domain = 6;
 * @return {string}
 */
proto.people.PeopleCrudRequest.prototype.getWebsiteDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.people.PeopleCrudRequest} returns this
 */
proto.people.PeopleCrudRequest.prototype.setWebsiteDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string website_logo = 7;
 * @return {string}
 */
proto.people.PeopleCrudRequest.prototype.getWebsiteLogo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.people.PeopleCrudRequest} returns this
 */
proto.people.PeopleCrudRequest.prototype.setWebsiteLogo = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional common.PeopleRelationshipStatus status = 8;
 * @return {!proto.common.PeopleRelationshipStatus}
 */
proto.people.PeopleCrudRequest.prototype.getStatus = function() {
  return /** @type {!proto.common.PeopleRelationshipStatus} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.common.PeopleRelationshipStatus} value
 * @return {!proto.people.PeopleCrudRequest} returns this
 */
proto.people.PeopleCrudRequest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional int64 opt_folder_id = 9;
 * @return {number}
 */
proto.people.PeopleCrudRequest.prototype.getOptFolderId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.people.PeopleCrudRequest} returns this
 */
proto.people.PeopleCrudRequest.prototype.setOptFolderId = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int64 workspace_id = 10;
 * @return {number}
 */
proto.people.PeopleCrudRequest.prototype.getWorkspaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.people.PeopleCrudRequest} returns this
 */
proto.people.PeopleCrudRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.people.PeopleCrudResponse.repeatedFields_ = [5,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.people.PeopleCrudResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.people.PeopleCrudResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.people.PeopleCrudResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.people.PeopleCrudResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    jobPosition: jspb.Message.getFieldWithDefault(msg, 3, ""),
    avatarUrl: jspb.Message.getFieldWithDefault(msg, 4, ""),
    socialLinksList: jspb.Message.toObjectList(msg.getSocialLinksList(),
    common_pb.SocialLink.toObject, includeInstance),
    emailsList: jspb.Message.toObjectList(msg.getEmailsList(),
    common_pb.Email.toObject, includeInstance),
    website: (f = msg.getWebsite()) && proto.people.PeopleWebsiteShortResponse.toObject(includeInstance, f),
    status: jspb.Message.getFieldWithDefault(msg, 8, 0),
    createdAt: jspb.Message.getFieldWithDefault(msg, 9, 0),
    folderId: jspb.Message.getFieldWithDefault(msg, 10, 0),
    workspaceId: jspb.Message.getFieldWithDefault(msg, 11, 0),
    ownerId: jspb.Message.getFieldWithDefault(msg, 12, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.people.PeopleCrudResponse}
 */
proto.people.PeopleCrudResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.people.PeopleCrudResponse;
  return proto.people.PeopleCrudResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.people.PeopleCrudResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.people.PeopleCrudResponse}
 */
proto.people.PeopleCrudResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setJobPosition(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAvatarUrl(value);
      break;
    case 5:
      var value = new common_pb.SocialLink;
      reader.readMessage(value,common_pb.SocialLink.deserializeBinaryFromReader);
      msg.addSocialLinks(value);
      break;
    case 6:
      var value = new common_pb.Email;
      reader.readMessage(value,common_pb.Email.deserializeBinaryFromReader);
      msg.addEmails(value);
      break;
    case 7:
      var value = new proto.people.PeopleWebsiteShortResponse;
      reader.readMessage(value,proto.people.PeopleWebsiteShortResponse.deserializeBinaryFromReader);
      msg.setWebsite(value);
      break;
    case 8:
      var value = /** @type {!proto.common.PeopleRelationshipStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFolderId(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWorkspaceId(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOwnerId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.people.PeopleCrudResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.people.PeopleCrudResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.people.PeopleCrudResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.people.PeopleCrudResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getJobPosition();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAvatarUrl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSocialLinksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      common_pb.SocialLink.serializeBinaryToWriter
    );
  }
  f = message.getEmailsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      common_pb.Email.serializeBinaryToWriter
    );
  }
  f = message.getWebsite();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.people.PeopleWebsiteShortResponse.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getFolderId();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getWorkspaceId();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getOwnerId();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.people.PeopleCrudResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.people.PeopleCrudResponse} returns this
 */
proto.people.PeopleCrudResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.people.PeopleCrudResponse.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.people.PeopleCrudResponse} returns this
 */
proto.people.PeopleCrudResponse.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string job_position = 3;
 * @return {string}
 */
proto.people.PeopleCrudResponse.prototype.getJobPosition = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.people.PeopleCrudResponse} returns this
 */
proto.people.PeopleCrudResponse.prototype.setJobPosition = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string avatar_url = 4;
 * @return {string}
 */
proto.people.PeopleCrudResponse.prototype.getAvatarUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.people.PeopleCrudResponse} returns this
 */
proto.people.PeopleCrudResponse.prototype.setAvatarUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated common.SocialLink social_links = 5;
 * @return {!Array<!proto.common.SocialLink>}
 */
proto.people.PeopleCrudResponse.prototype.getSocialLinksList = function() {
  return /** @type{!Array<!proto.common.SocialLink>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_pb.SocialLink, 5));
};


/**
 * @param {!Array<!proto.common.SocialLink>} value
 * @return {!proto.people.PeopleCrudResponse} returns this
*/
proto.people.PeopleCrudResponse.prototype.setSocialLinksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.common.SocialLink=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.SocialLink}
 */
proto.people.PeopleCrudResponse.prototype.addSocialLinks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.common.SocialLink, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.people.PeopleCrudResponse} returns this
 */
proto.people.PeopleCrudResponse.prototype.clearSocialLinksList = function() {
  return this.setSocialLinksList([]);
};


/**
 * repeated common.Email emails = 6;
 * @return {!Array<!proto.common.Email>}
 */
proto.people.PeopleCrudResponse.prototype.getEmailsList = function() {
  return /** @type{!Array<!proto.common.Email>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_pb.Email, 6));
};


/**
 * @param {!Array<!proto.common.Email>} value
 * @return {!proto.people.PeopleCrudResponse} returns this
*/
proto.people.PeopleCrudResponse.prototype.setEmailsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.common.Email=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.Email}
 */
proto.people.PeopleCrudResponse.prototype.addEmails = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.common.Email, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.people.PeopleCrudResponse} returns this
 */
proto.people.PeopleCrudResponse.prototype.clearEmailsList = function() {
  return this.setEmailsList([]);
};


/**
 * optional PeopleWebsiteShortResponse website = 7;
 * @return {?proto.people.PeopleWebsiteShortResponse}
 */
proto.people.PeopleCrudResponse.prototype.getWebsite = function() {
  return /** @type{?proto.people.PeopleWebsiteShortResponse} */ (
    jspb.Message.getWrapperField(this, proto.people.PeopleWebsiteShortResponse, 7));
};


/**
 * @param {?proto.people.PeopleWebsiteShortResponse|undefined} value
 * @return {!proto.people.PeopleCrudResponse} returns this
*/
proto.people.PeopleCrudResponse.prototype.setWebsite = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.people.PeopleCrudResponse} returns this
 */
proto.people.PeopleCrudResponse.prototype.clearWebsite = function() {
  return this.setWebsite(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.people.PeopleCrudResponse.prototype.hasWebsite = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional common.PeopleRelationshipStatus status = 8;
 * @return {!proto.common.PeopleRelationshipStatus}
 */
proto.people.PeopleCrudResponse.prototype.getStatus = function() {
  return /** @type {!proto.common.PeopleRelationshipStatus} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.common.PeopleRelationshipStatus} value
 * @return {!proto.people.PeopleCrudResponse} returns this
 */
proto.people.PeopleCrudResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional int64 created_at = 9;
 * @return {number}
 */
proto.people.PeopleCrudResponse.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.people.PeopleCrudResponse} returns this
 */
proto.people.PeopleCrudResponse.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int64 folder_id = 10;
 * @return {number}
 */
proto.people.PeopleCrudResponse.prototype.getFolderId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.people.PeopleCrudResponse} returns this
 */
proto.people.PeopleCrudResponse.prototype.setFolderId = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int64 workspace_id = 11;
 * @return {number}
 */
proto.people.PeopleCrudResponse.prototype.getWorkspaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.people.PeopleCrudResponse} returns this
 */
proto.people.PeopleCrudResponse.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int64 owner_id = 12;
 * @return {number}
 */
proto.people.PeopleCrudResponse.prototype.getOwnerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.people.PeopleCrudResponse} returns this
 */
proto.people.PeopleCrudResponse.prototype.setOwnerId = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.people.PeopleDetailResponse.repeatedFields_ = [5,6,7,8,9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.people.PeopleDetailResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.people.PeopleDetailResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.people.PeopleDetailResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.people.PeopleDetailResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    jobPosition: jspb.Message.getFieldWithDefault(msg, 3, ""),
    avatarUrl: jspb.Message.getFieldWithDefault(msg, 4, ""),
    socialLinksList: jspb.Message.toObjectList(msg.getSocialLinksList(),
    common_pb.SocialLink.toObject, includeInstance),
    emailsList: jspb.Message.toObjectList(msg.getEmailsList(),
    common_pb.Email.toObject, includeInstance),
    notesList: jspb.Message.toObjectList(msg.getNotesList(),
    common_pb.Note.toObject, includeInstance),
    tagsList: jspb.Message.toObjectList(msg.getTagsList(),
    common_pb.Tag.toObject, includeInstance),
    customVariablesList: jspb.Message.toObjectList(msg.getCustomVariablesList(),
    common_pb.CustomVariableValue.toObject, includeInstance),
    status: jspb.Message.getFieldWithDefault(msg, 10, 0),
    website: (f = msg.getWebsite()) && proto.people.PeopleWebsiteShortResponse.toObject(includeInstance, f),
    createdAt: jspb.Message.getFieldWithDefault(msg, 12, 0),
    folderId: jspb.Message.getFieldWithDefault(msg, 13, 0),
    workspaceId: jspb.Message.getFieldWithDefault(msg, 14, 0),
    ownerId: jspb.Message.getFieldWithDefault(msg, 15, 0),
    location: jspb.Message.getFieldWithDefault(msg, 16, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.people.PeopleDetailResponse}
 */
proto.people.PeopleDetailResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.people.PeopleDetailResponse;
  return proto.people.PeopleDetailResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.people.PeopleDetailResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.people.PeopleDetailResponse}
 */
proto.people.PeopleDetailResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setJobPosition(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAvatarUrl(value);
      break;
    case 5:
      var value = new common_pb.SocialLink;
      reader.readMessage(value,common_pb.SocialLink.deserializeBinaryFromReader);
      msg.addSocialLinks(value);
      break;
    case 6:
      var value = new common_pb.Email;
      reader.readMessage(value,common_pb.Email.deserializeBinaryFromReader);
      msg.addEmails(value);
      break;
    case 7:
      var value = new common_pb.Note;
      reader.readMessage(value,common_pb.Note.deserializeBinaryFromReader);
      msg.addNotes(value);
      break;
    case 8:
      var value = new common_pb.Tag;
      reader.readMessage(value,common_pb.Tag.deserializeBinaryFromReader);
      msg.addTags(value);
      break;
    case 9:
      var value = new common_pb.CustomVariableValue;
      reader.readMessage(value,common_pb.CustomVariableValue.deserializeBinaryFromReader);
      msg.addCustomVariables(value);
      break;
    case 10:
      var value = /** @type {!proto.common.PeopleRelationshipStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 11:
      var value = new proto.people.PeopleWebsiteShortResponse;
      reader.readMessage(value,proto.people.PeopleWebsiteShortResponse.deserializeBinaryFromReader);
      msg.setWebsite(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFolderId(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWorkspaceId(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOwnerId(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.people.PeopleDetailResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.people.PeopleDetailResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.people.PeopleDetailResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.people.PeopleDetailResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getJobPosition();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAvatarUrl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSocialLinksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      common_pb.SocialLink.serializeBinaryToWriter
    );
  }
  f = message.getEmailsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      common_pb.Email.serializeBinaryToWriter
    );
  }
  f = message.getNotesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      common_pb.Note.serializeBinaryToWriter
    );
  }
  f = message.getTagsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      common_pb.Tag.serializeBinaryToWriter
    );
  }
  f = message.getCustomVariablesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      common_pb.CustomVariableValue.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
  f = message.getWebsite();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.people.PeopleWebsiteShortResponse.serializeBinaryToWriter
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
  f = message.getFolderId();
  if (f !== 0) {
    writer.writeInt64(
      13,
      f
    );
  }
  f = message.getWorkspaceId();
  if (f !== 0) {
    writer.writeInt64(
      14,
      f
    );
  }
  f = message.getOwnerId();
  if (f !== 0) {
    writer.writeInt64(
      15,
      f
    );
  }
  f = message.getLocation();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.people.PeopleDetailResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.people.PeopleDetailResponse} returns this
 */
proto.people.PeopleDetailResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.people.PeopleDetailResponse.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.people.PeopleDetailResponse} returns this
 */
proto.people.PeopleDetailResponse.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string job_position = 3;
 * @return {string}
 */
proto.people.PeopleDetailResponse.prototype.getJobPosition = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.people.PeopleDetailResponse} returns this
 */
proto.people.PeopleDetailResponse.prototype.setJobPosition = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string avatar_url = 4;
 * @return {string}
 */
proto.people.PeopleDetailResponse.prototype.getAvatarUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.people.PeopleDetailResponse} returns this
 */
proto.people.PeopleDetailResponse.prototype.setAvatarUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated common.SocialLink social_links = 5;
 * @return {!Array<!proto.common.SocialLink>}
 */
proto.people.PeopleDetailResponse.prototype.getSocialLinksList = function() {
  return /** @type{!Array<!proto.common.SocialLink>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_pb.SocialLink, 5));
};


/**
 * @param {!Array<!proto.common.SocialLink>} value
 * @return {!proto.people.PeopleDetailResponse} returns this
*/
proto.people.PeopleDetailResponse.prototype.setSocialLinksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.common.SocialLink=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.SocialLink}
 */
proto.people.PeopleDetailResponse.prototype.addSocialLinks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.common.SocialLink, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.people.PeopleDetailResponse} returns this
 */
proto.people.PeopleDetailResponse.prototype.clearSocialLinksList = function() {
  return this.setSocialLinksList([]);
};


/**
 * repeated common.Email emails = 6;
 * @return {!Array<!proto.common.Email>}
 */
proto.people.PeopleDetailResponse.prototype.getEmailsList = function() {
  return /** @type{!Array<!proto.common.Email>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_pb.Email, 6));
};


/**
 * @param {!Array<!proto.common.Email>} value
 * @return {!proto.people.PeopleDetailResponse} returns this
*/
proto.people.PeopleDetailResponse.prototype.setEmailsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.common.Email=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.Email}
 */
proto.people.PeopleDetailResponse.prototype.addEmails = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.common.Email, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.people.PeopleDetailResponse} returns this
 */
proto.people.PeopleDetailResponse.prototype.clearEmailsList = function() {
  return this.setEmailsList([]);
};


/**
 * repeated common.Note notes = 7;
 * @return {!Array<!proto.common.Note>}
 */
proto.people.PeopleDetailResponse.prototype.getNotesList = function() {
  return /** @type{!Array<!proto.common.Note>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_pb.Note, 7));
};


/**
 * @param {!Array<!proto.common.Note>} value
 * @return {!proto.people.PeopleDetailResponse} returns this
*/
proto.people.PeopleDetailResponse.prototype.setNotesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.common.Note=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.Note}
 */
proto.people.PeopleDetailResponse.prototype.addNotes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.common.Note, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.people.PeopleDetailResponse} returns this
 */
proto.people.PeopleDetailResponse.prototype.clearNotesList = function() {
  return this.setNotesList([]);
};


/**
 * repeated common.Tag tags = 8;
 * @return {!Array<!proto.common.Tag>}
 */
proto.people.PeopleDetailResponse.prototype.getTagsList = function() {
  return /** @type{!Array<!proto.common.Tag>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_pb.Tag, 8));
};


/**
 * @param {!Array<!proto.common.Tag>} value
 * @return {!proto.people.PeopleDetailResponse} returns this
*/
proto.people.PeopleDetailResponse.prototype.setTagsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.common.Tag=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.Tag}
 */
proto.people.PeopleDetailResponse.prototype.addTags = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.common.Tag, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.people.PeopleDetailResponse} returns this
 */
proto.people.PeopleDetailResponse.prototype.clearTagsList = function() {
  return this.setTagsList([]);
};


/**
 * repeated common.CustomVariableValue custom_variables = 9;
 * @return {!Array<!proto.common.CustomVariableValue>}
 */
proto.people.PeopleDetailResponse.prototype.getCustomVariablesList = function() {
  return /** @type{!Array<!proto.common.CustomVariableValue>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_pb.CustomVariableValue, 9));
};


/**
 * @param {!Array<!proto.common.CustomVariableValue>} value
 * @return {!proto.people.PeopleDetailResponse} returns this
*/
proto.people.PeopleDetailResponse.prototype.setCustomVariablesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.common.CustomVariableValue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.CustomVariableValue}
 */
proto.people.PeopleDetailResponse.prototype.addCustomVariables = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.common.CustomVariableValue, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.people.PeopleDetailResponse} returns this
 */
proto.people.PeopleDetailResponse.prototype.clearCustomVariablesList = function() {
  return this.setCustomVariablesList([]);
};


/**
 * optional common.PeopleRelationshipStatus status = 10;
 * @return {!proto.common.PeopleRelationshipStatus}
 */
proto.people.PeopleDetailResponse.prototype.getStatus = function() {
  return /** @type {!proto.common.PeopleRelationshipStatus} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.common.PeopleRelationshipStatus} value
 * @return {!proto.people.PeopleDetailResponse} returns this
 */
proto.people.PeopleDetailResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};


/**
 * optional PeopleWebsiteShortResponse website = 11;
 * @return {?proto.people.PeopleWebsiteShortResponse}
 */
proto.people.PeopleDetailResponse.prototype.getWebsite = function() {
  return /** @type{?proto.people.PeopleWebsiteShortResponse} */ (
    jspb.Message.getWrapperField(this, proto.people.PeopleWebsiteShortResponse, 11));
};


/**
 * @param {?proto.people.PeopleWebsiteShortResponse|undefined} value
 * @return {!proto.people.PeopleDetailResponse} returns this
*/
proto.people.PeopleDetailResponse.prototype.setWebsite = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.people.PeopleDetailResponse} returns this
 */
proto.people.PeopleDetailResponse.prototype.clearWebsite = function() {
  return this.setWebsite(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.people.PeopleDetailResponse.prototype.hasWebsite = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional int64 created_at = 12;
 * @return {number}
 */
proto.people.PeopleDetailResponse.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.people.PeopleDetailResponse} returns this
 */
proto.people.PeopleDetailResponse.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional int64 folder_id = 13;
 * @return {number}
 */
proto.people.PeopleDetailResponse.prototype.getFolderId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.people.PeopleDetailResponse} returns this
 */
proto.people.PeopleDetailResponse.prototype.setFolderId = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional int64 workspace_id = 14;
 * @return {number}
 */
proto.people.PeopleDetailResponse.prototype.getWorkspaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.people.PeopleDetailResponse} returns this
 */
proto.people.PeopleDetailResponse.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional int64 owner_id = 15;
 * @return {number}
 */
proto.people.PeopleDetailResponse.prototype.getOwnerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.people.PeopleDetailResponse} returns this
 */
proto.people.PeopleDetailResponse.prototype.setOwnerId = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional string location = 16;
 * @return {string}
 */
proto.people.PeopleDetailResponse.prototype.getLocation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.people.PeopleDetailResponse} returns this
 */
proto.people.PeopleDetailResponse.prototype.setLocation = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.people.PeopleShortResponse.repeatedFields_ = [5,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.people.PeopleShortResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.people.PeopleShortResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.people.PeopleShortResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.people.PeopleShortResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    jobPosition: jspb.Message.getFieldWithDefault(msg, 3, ""),
    avatarUrl: jspb.Message.getFieldWithDefault(msg, 4, ""),
    emailsList: jspb.Message.toObjectList(msg.getEmailsList(),
    common_pb.Email.toObject, includeInstance),
    socialLinksList: jspb.Message.toObjectList(msg.getSocialLinksList(),
    common_pb.SocialLink.toObject, includeInstance),
    status: jspb.Message.getFieldWithDefault(msg, 7, 0),
    ownerId: jspb.Message.getFieldWithDefault(msg, 8, 0),
    totalItems: jspb.Message.getFieldWithDefault(msg, 9, 0),
    workspaceId: jspb.Message.getFieldWithDefault(msg, 10, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.people.PeopleShortResponse}
 */
proto.people.PeopleShortResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.people.PeopleShortResponse;
  return proto.people.PeopleShortResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.people.PeopleShortResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.people.PeopleShortResponse}
 */
proto.people.PeopleShortResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setJobPosition(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAvatarUrl(value);
      break;
    case 5:
      var value = new common_pb.Email;
      reader.readMessage(value,common_pb.Email.deserializeBinaryFromReader);
      msg.addEmails(value);
      break;
    case 6:
      var value = new common_pb.SocialLink;
      reader.readMessage(value,common_pb.SocialLink.deserializeBinaryFromReader);
      msg.addSocialLinks(value);
      break;
    case 7:
      var value = /** @type {!proto.common.PeopleRelationshipStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOwnerId(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalItems(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWorkspaceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.people.PeopleShortResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.people.PeopleShortResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.people.PeopleShortResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.people.PeopleShortResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getJobPosition();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAvatarUrl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getEmailsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      common_pb.Email.serializeBinaryToWriter
    );
  }
  f = message.getSocialLinksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      common_pb.SocialLink.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getOwnerId();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getTotalItems();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getWorkspaceId();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.people.PeopleShortResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.people.PeopleShortResponse} returns this
 */
proto.people.PeopleShortResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.people.PeopleShortResponse.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.people.PeopleShortResponse} returns this
 */
proto.people.PeopleShortResponse.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string job_position = 3;
 * @return {string}
 */
proto.people.PeopleShortResponse.prototype.getJobPosition = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.people.PeopleShortResponse} returns this
 */
proto.people.PeopleShortResponse.prototype.setJobPosition = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string avatar_url = 4;
 * @return {string}
 */
proto.people.PeopleShortResponse.prototype.getAvatarUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.people.PeopleShortResponse} returns this
 */
proto.people.PeopleShortResponse.prototype.setAvatarUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated common.Email emails = 5;
 * @return {!Array<!proto.common.Email>}
 */
proto.people.PeopleShortResponse.prototype.getEmailsList = function() {
  return /** @type{!Array<!proto.common.Email>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_pb.Email, 5));
};


/**
 * @param {!Array<!proto.common.Email>} value
 * @return {!proto.people.PeopleShortResponse} returns this
*/
proto.people.PeopleShortResponse.prototype.setEmailsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.common.Email=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.Email}
 */
proto.people.PeopleShortResponse.prototype.addEmails = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.common.Email, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.people.PeopleShortResponse} returns this
 */
proto.people.PeopleShortResponse.prototype.clearEmailsList = function() {
  return this.setEmailsList([]);
};


/**
 * repeated common.SocialLink social_links = 6;
 * @return {!Array<!proto.common.SocialLink>}
 */
proto.people.PeopleShortResponse.prototype.getSocialLinksList = function() {
  return /** @type{!Array<!proto.common.SocialLink>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_pb.SocialLink, 6));
};


/**
 * @param {!Array<!proto.common.SocialLink>} value
 * @return {!proto.people.PeopleShortResponse} returns this
*/
proto.people.PeopleShortResponse.prototype.setSocialLinksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.common.SocialLink=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.SocialLink}
 */
proto.people.PeopleShortResponse.prototype.addSocialLinks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.common.SocialLink, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.people.PeopleShortResponse} returns this
 */
proto.people.PeopleShortResponse.prototype.clearSocialLinksList = function() {
  return this.setSocialLinksList([]);
};


/**
 * optional common.PeopleRelationshipStatus status = 7;
 * @return {!proto.common.PeopleRelationshipStatus}
 */
proto.people.PeopleShortResponse.prototype.getStatus = function() {
  return /** @type {!proto.common.PeopleRelationshipStatus} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.common.PeopleRelationshipStatus} value
 * @return {!proto.people.PeopleShortResponse} returns this
 */
proto.people.PeopleShortResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional int64 owner_id = 8;
 * @return {number}
 */
proto.people.PeopleShortResponse.prototype.getOwnerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.people.PeopleShortResponse} returns this
 */
proto.people.PeopleShortResponse.prototype.setOwnerId = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 total_items = 9;
 * @return {number}
 */
proto.people.PeopleShortResponse.prototype.getTotalItems = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.people.PeopleShortResponse} returns this
 */
proto.people.PeopleShortResponse.prototype.setTotalItems = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int64 workspace_id = 10;
 * @return {number}
 */
proto.people.PeopleShortResponse.prototype.getWorkspaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.people.PeopleShortResponse} returns this
 */
proto.people.PeopleShortResponse.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.people.PeopleResponse.repeatedFields_ = [8,9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.people.PeopleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.people.PeopleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.people.PeopleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.people.PeopleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    jobPosition: jspb.Message.getFieldWithDefault(msg, 3, ""),
    avatarUrl: jspb.Message.getFieldWithDefault(msg, 4, ""),
    status: jspb.Message.getFieldWithDefault(msg, 5, 0),
    ownerId: jspb.Message.getFieldWithDefault(msg, 6, 0),
    createdAt: jspb.Message.getFieldWithDefault(msg, 7, 0),
    emailsList: jspb.Message.toObjectList(msg.getEmailsList(),
    common_pb.Email.toObject, includeInstance),
    socialLinksList: jspb.Message.toObjectList(msg.getSocialLinksList(),
    common_pb.SocialLink.toObject, includeInstance),
    website: (f = msg.getWebsite()) && proto.people.PeopleWebsiteShortResponse.toObject(includeInstance, f),
    workspaceId: jspb.Message.getFieldWithDefault(msg, 11, 0),
    totalItems: jspb.Message.getFieldWithDefault(msg, 12, 0),
    location: jspb.Message.getFieldWithDefault(msg, 13, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.people.PeopleResponse}
 */
proto.people.PeopleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.people.PeopleResponse;
  return proto.people.PeopleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.people.PeopleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.people.PeopleResponse}
 */
proto.people.PeopleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setJobPosition(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAvatarUrl(value);
      break;
    case 5:
      var value = /** @type {!proto.common.PeopleRelationshipStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOwnerId(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 8:
      var value = new common_pb.Email;
      reader.readMessage(value,common_pb.Email.deserializeBinaryFromReader);
      msg.addEmails(value);
      break;
    case 9:
      var value = new common_pb.SocialLink;
      reader.readMessage(value,common_pb.SocialLink.deserializeBinaryFromReader);
      msg.addSocialLinks(value);
      break;
    case 10:
      var value = new proto.people.PeopleWebsiteShortResponse;
      reader.readMessage(value,proto.people.PeopleWebsiteShortResponse.deserializeBinaryFromReader);
      msg.setWebsite(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWorkspaceId(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalItems(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.people.PeopleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.people.PeopleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.people.PeopleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.people.PeopleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getJobPosition();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAvatarUrl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getOwnerId();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getEmailsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      common_pb.Email.serializeBinaryToWriter
    );
  }
  f = message.getSocialLinksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      common_pb.SocialLink.serializeBinaryToWriter
    );
  }
  f = message.getWebsite();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.people.PeopleWebsiteShortResponse.serializeBinaryToWriter
    );
  }
  f = message.getWorkspaceId();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getTotalItems();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
  f = message.getLocation();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.people.PeopleResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.people.PeopleResponse} returns this
 */
proto.people.PeopleResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.people.PeopleResponse.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.people.PeopleResponse} returns this
 */
proto.people.PeopleResponse.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string job_position = 3;
 * @return {string}
 */
proto.people.PeopleResponse.prototype.getJobPosition = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.people.PeopleResponse} returns this
 */
proto.people.PeopleResponse.prototype.setJobPosition = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string avatar_url = 4;
 * @return {string}
 */
proto.people.PeopleResponse.prototype.getAvatarUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.people.PeopleResponse} returns this
 */
proto.people.PeopleResponse.prototype.setAvatarUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional common.PeopleRelationshipStatus status = 5;
 * @return {!proto.common.PeopleRelationshipStatus}
 */
proto.people.PeopleResponse.prototype.getStatus = function() {
  return /** @type {!proto.common.PeopleRelationshipStatus} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.common.PeopleRelationshipStatus} value
 * @return {!proto.people.PeopleResponse} returns this
 */
proto.people.PeopleResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional int64 owner_id = 6;
 * @return {number}
 */
proto.people.PeopleResponse.prototype.getOwnerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.people.PeopleResponse} returns this
 */
proto.people.PeopleResponse.prototype.setOwnerId = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 created_at = 7;
 * @return {number}
 */
proto.people.PeopleResponse.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.people.PeopleResponse} returns this
 */
proto.people.PeopleResponse.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * repeated common.Email emails = 8;
 * @return {!Array<!proto.common.Email>}
 */
proto.people.PeopleResponse.prototype.getEmailsList = function() {
  return /** @type{!Array<!proto.common.Email>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_pb.Email, 8));
};


/**
 * @param {!Array<!proto.common.Email>} value
 * @return {!proto.people.PeopleResponse} returns this
*/
proto.people.PeopleResponse.prototype.setEmailsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.common.Email=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.Email}
 */
proto.people.PeopleResponse.prototype.addEmails = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.common.Email, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.people.PeopleResponse} returns this
 */
proto.people.PeopleResponse.prototype.clearEmailsList = function() {
  return this.setEmailsList([]);
};


/**
 * repeated common.SocialLink social_links = 9;
 * @return {!Array<!proto.common.SocialLink>}
 */
proto.people.PeopleResponse.prototype.getSocialLinksList = function() {
  return /** @type{!Array<!proto.common.SocialLink>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_pb.SocialLink, 9));
};


/**
 * @param {!Array<!proto.common.SocialLink>} value
 * @return {!proto.people.PeopleResponse} returns this
*/
proto.people.PeopleResponse.prototype.setSocialLinksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.common.SocialLink=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.SocialLink}
 */
proto.people.PeopleResponse.prototype.addSocialLinks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.common.SocialLink, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.people.PeopleResponse} returns this
 */
proto.people.PeopleResponse.prototype.clearSocialLinksList = function() {
  return this.setSocialLinksList([]);
};


/**
 * optional PeopleWebsiteShortResponse website = 10;
 * @return {?proto.people.PeopleWebsiteShortResponse}
 */
proto.people.PeopleResponse.prototype.getWebsite = function() {
  return /** @type{?proto.people.PeopleWebsiteShortResponse} */ (
    jspb.Message.getWrapperField(this, proto.people.PeopleWebsiteShortResponse, 10));
};


/**
 * @param {?proto.people.PeopleWebsiteShortResponse|undefined} value
 * @return {!proto.people.PeopleResponse} returns this
*/
proto.people.PeopleResponse.prototype.setWebsite = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.people.PeopleResponse} returns this
 */
proto.people.PeopleResponse.prototype.clearWebsite = function() {
  return this.setWebsite(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.people.PeopleResponse.prototype.hasWebsite = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional int64 workspace_id = 11;
 * @return {number}
 */
proto.people.PeopleResponse.prototype.getWorkspaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.people.PeopleResponse} returns this
 */
proto.people.PeopleResponse.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int32 total_items = 12;
 * @return {number}
 */
proto.people.PeopleResponse.prototype.getTotalItems = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.people.PeopleResponse} returns this
 */
proto.people.PeopleResponse.prototype.setTotalItems = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional string location = 13;
 * @return {string}
 */
proto.people.PeopleResponse.prototype.getLocation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.people.PeopleResponse} returns this
 */
proto.people.PeopleResponse.prototype.setLocation = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.people.PeopleWebsiteShortResponse.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.people.PeopleWebsiteShortResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.people.PeopleWebsiteShortResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.people.PeopleWebsiteShortResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.people.PeopleWebsiteShortResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    logoUrl: jspb.Message.getFieldWithDefault(msg, 3, ""),
    domain: jspb.Message.getFieldWithDefault(msg, 4, ""),
    socialLinksList: jspb.Message.toObjectList(msg.getSocialLinksList(),
    common_pb.SocialLink.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.people.PeopleWebsiteShortResponse}
 */
proto.people.PeopleWebsiteShortResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.people.PeopleWebsiteShortResponse;
  return proto.people.PeopleWebsiteShortResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.people.PeopleWebsiteShortResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.people.PeopleWebsiteShortResponse}
 */
proto.people.PeopleWebsiteShortResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogoUrl(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDomain(value);
      break;
    case 5:
      var value = new common_pb.SocialLink;
      reader.readMessage(value,common_pb.SocialLink.deserializeBinaryFromReader);
      msg.addSocialLinks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.people.PeopleWebsiteShortResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.people.PeopleWebsiteShortResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.people.PeopleWebsiteShortResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.people.PeopleWebsiteShortResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLogoUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDomain();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSocialLinksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      common_pb.SocialLink.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.people.PeopleWebsiteShortResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.people.PeopleWebsiteShortResponse} returns this
 */
proto.people.PeopleWebsiteShortResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.people.PeopleWebsiteShortResponse.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.people.PeopleWebsiteShortResponse} returns this
 */
proto.people.PeopleWebsiteShortResponse.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string logo_url = 3;
 * @return {string}
 */
proto.people.PeopleWebsiteShortResponse.prototype.getLogoUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.people.PeopleWebsiteShortResponse} returns this
 */
proto.people.PeopleWebsiteShortResponse.prototype.setLogoUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string domain = 4;
 * @return {string}
 */
proto.people.PeopleWebsiteShortResponse.prototype.getDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.people.PeopleWebsiteShortResponse} returns this
 */
proto.people.PeopleWebsiteShortResponse.prototype.setDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated common.SocialLink social_links = 5;
 * @return {!Array<!proto.common.SocialLink>}
 */
proto.people.PeopleWebsiteShortResponse.prototype.getSocialLinksList = function() {
  return /** @type{!Array<!proto.common.SocialLink>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_pb.SocialLink, 5));
};


/**
 * @param {!Array<!proto.common.SocialLink>} value
 * @return {!proto.people.PeopleWebsiteShortResponse} returns this
*/
proto.people.PeopleWebsiteShortResponse.prototype.setSocialLinksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.common.SocialLink=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.SocialLink}
 */
proto.people.PeopleWebsiteShortResponse.prototype.addSocialLinks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.common.SocialLink, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.people.PeopleWebsiteShortResponse} returns this
 */
proto.people.PeopleWebsiteShortResponse.prototype.clearSocialLinksList = function() {
  return this.setSocialLinksList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.people.PeoplePageRequest.repeatedFields_ = [4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.people.PeoplePageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.people.PeoplePageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.people.PeoplePageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.people.PeoplePageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    page: jspb.Message.getFieldWithDefault(msg, 1, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 2, 0),
    optionalFolderId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    optionalFiltersList: jspb.Message.toObjectList(msg.getOptionalFiltersList(),
    proto.people.PeopleFilter.toObject, includeInstance),
    additionalColumnsList: jspb.Message.toObjectList(msg.getAdditionalColumnsList(),
    proto.people.PeopleTableColumns.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.people.PeoplePageRequest}
 */
proto.people.PeoplePageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.people.PeoplePageRequest;
  return proto.people.PeoplePageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.people.PeoplePageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.people.PeoplePageRequest}
 */
proto.people.PeoplePageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLimit(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOptionalFolderId(value);
      break;
    case 4:
      var value = new proto.people.PeopleFilter;
      reader.readMessage(value,proto.people.PeopleFilter.deserializeBinaryFromReader);
      msg.addOptionalFilters(value);
      break;
    case 5:
      var value = new proto.people.PeopleTableColumns;
      reader.readMessage(value,proto.people.PeopleTableColumns.deserializeBinaryFromReader);
      msg.addAdditionalColumns(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.people.PeoplePageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.people.PeoplePageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.people.PeoplePageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.people.PeoplePageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getOptionalFolderId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getOptionalFiltersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.people.PeopleFilter.serializeBinaryToWriter
    );
  }
  f = message.getAdditionalColumnsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.people.PeopleTableColumns.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 page = 1;
 * @return {number}
 */
proto.people.PeoplePageRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.people.PeoplePageRequest} returns this
 */
proto.people.PeoplePageRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 limit = 2;
 * @return {number}
 */
proto.people.PeoplePageRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.people.PeoplePageRequest} returns this
 */
proto.people.PeoplePageRequest.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 optional_folder_id = 3;
 * @return {number}
 */
proto.people.PeoplePageRequest.prototype.getOptionalFolderId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.people.PeoplePageRequest} returns this
 */
proto.people.PeoplePageRequest.prototype.setOptionalFolderId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated PeopleFilter optional_filters = 4;
 * @return {!Array<!proto.people.PeopleFilter>}
 */
proto.people.PeoplePageRequest.prototype.getOptionalFiltersList = function() {
  return /** @type{!Array<!proto.people.PeopleFilter>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.people.PeopleFilter, 4));
};


/**
 * @param {!Array<!proto.people.PeopleFilter>} value
 * @return {!proto.people.PeoplePageRequest} returns this
*/
proto.people.PeoplePageRequest.prototype.setOptionalFiltersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.people.PeopleFilter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.people.PeopleFilter}
 */
proto.people.PeoplePageRequest.prototype.addOptionalFilters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.people.PeopleFilter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.people.PeoplePageRequest} returns this
 */
proto.people.PeoplePageRequest.prototype.clearOptionalFiltersList = function() {
  return this.setOptionalFiltersList([]);
};


/**
 * repeated PeopleTableColumns additional_columns = 5;
 * @return {!Array<!proto.people.PeopleTableColumns>}
 */
proto.people.PeoplePageRequest.prototype.getAdditionalColumnsList = function() {
  return /** @type{!Array<!proto.people.PeopleTableColumns>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.people.PeopleTableColumns, 5));
};


/**
 * @param {!Array<!proto.people.PeopleTableColumns>} value
 * @return {!proto.people.PeoplePageRequest} returns this
*/
proto.people.PeoplePageRequest.prototype.setAdditionalColumnsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.people.PeopleTableColumns=} opt_value
 * @param {number=} opt_index
 * @return {!proto.people.PeopleTableColumns}
 */
proto.people.PeoplePageRequest.prototype.addAdditionalColumns = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.people.PeopleTableColumns, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.people.PeoplePageRequest} returns this
 */
proto.people.PeoplePageRequest.prototype.clearAdditionalColumnsList = function() {
  return this.setAdditionalColumnsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.people.PeopleTableColumns.prototype.toObject = function(opt_includeInstance) {
  return proto.people.PeopleTableColumns.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.people.PeopleTableColumns} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.people.PeopleTableColumns.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.people.PeopleTableColumns}
 */
proto.people.PeopleTableColumns.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.people.PeopleTableColumns;
  return proto.people.PeopleTableColumns.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.people.PeopleTableColumns} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.people.PeopleTableColumns}
 */
proto.people.PeopleTableColumns.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.people.PeopleTableColumns.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.people.PeopleTableColumns.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.people.PeopleTableColumns} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.people.PeopleTableColumns.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.people.PeopleTableColumns.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.people.PeopleTableColumns} returns this
 */
proto.people.PeopleTableColumns.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.people.PeopleTableColumns.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.people.PeopleTableColumns} returns this
 */
proto.people.PeopleTableColumns.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.people.PeopleFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.people.PeopleFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.people.PeopleFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.people.PeopleFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    order: jspb.Message.getFieldWithDefault(msg, 1, 0),
    field: jspb.Message.getFieldWithDefault(msg, 2, 0),
    value: jspb.Message.getFieldWithDefault(msg, 3, ""),
    operation: jspb.Message.getFieldWithDefault(msg, 4, 0),
    join: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.people.PeopleFilter}
 */
proto.people.PeopleFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.people.PeopleFilter;
  return proto.people.PeopleFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.people.PeopleFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.people.PeopleFilter}
 */
proto.people.PeopleFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrder(value);
      break;
    case 2:
      var value = /** @type {!proto.people.PeopleFilterField} */ (reader.readEnum());
      msg.setField(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 4:
      var value = /** @type {!proto.common.FilterOperation} */ (reader.readEnum());
      msg.setOperation(value);
      break;
    case 5:
      var value = /** @type {!proto.common.FilterJoinType} */ (reader.readEnum());
      msg.setJoin(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.people.PeopleFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.people.PeopleFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.people.PeopleFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.people.PeopleFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrder();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getField();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOperation();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getJoin();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * optional int32 order = 1;
 * @return {number}
 */
proto.people.PeopleFilter.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.people.PeopleFilter} returns this
 */
proto.people.PeopleFilter.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional PeopleFilterField field = 2;
 * @return {!proto.people.PeopleFilterField}
 */
proto.people.PeopleFilter.prototype.getField = function() {
  return /** @type {!proto.people.PeopleFilterField} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.people.PeopleFilterField} value
 * @return {!proto.people.PeopleFilter} returns this
 */
proto.people.PeopleFilter.prototype.setField = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string value = 3;
 * @return {string}
 */
proto.people.PeopleFilter.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.people.PeopleFilter} returns this
 */
proto.people.PeopleFilter.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional common.FilterOperation operation = 4;
 * @return {!proto.common.FilterOperation}
 */
proto.people.PeopleFilter.prototype.getOperation = function() {
  return /** @type {!proto.common.FilterOperation} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.common.FilterOperation} value
 * @return {!proto.people.PeopleFilter} returns this
 */
proto.people.PeopleFilter.prototype.setOperation = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional common.FilterJoinType join = 5;
 * @return {!proto.common.FilterJoinType}
 */
proto.people.PeopleFilter.prototype.getJoin = function() {
  return /** @type {!proto.common.FilterJoinType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.common.FilterJoinType} value
 * @return {!proto.people.PeopleFilter} returns this
 */
proto.people.PeopleFilter.prototype.setJoin = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.people.ExportPeopleRequest.repeatedFields_ = [4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.people.ExportPeopleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.people.ExportPeopleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.people.ExportPeopleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.people.ExportPeopleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    optionalId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    optionalSearchQuery: jspb.Message.getFieldWithDefault(msg, 3, ""),
    optionalFiltersList: jspb.Message.toObjectList(msg.getOptionalFiltersList(),
    proto.people.PeopleFilter.toObject, includeInstance),
    optionalSelectedList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    invalidEmails: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    workEmails: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    personalEmails: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    genericEmails: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    catchAllEmails: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    optionalNumberTopResults: jspb.Message.getFieldWithDefault(msg, 11, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.people.ExportPeopleRequest}
 */
proto.people.ExportPeopleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.people.ExportPeopleRequest;
  return proto.people.ExportPeopleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.people.ExportPeopleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.people.ExportPeopleRequest}
 */
proto.people.ExportPeopleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.people.ExportPeopleRequest.ExportType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOptionalId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOptionalSearchQuery(value);
      break;
    case 4:
      var value = new proto.people.PeopleFilter;
      reader.readMessage(value,proto.people.PeopleFilter.deserializeBinaryFromReader);
      msg.addOptionalFilters(value);
      break;
    case 5:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt64());
      msg.setOptionalSelectedList(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInvalidEmails(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setWorkEmails(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPersonalEmails(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setGenericEmails(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCatchAllEmails(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOptionalNumberTopResults(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.people.ExportPeopleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.people.ExportPeopleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.people.ExportPeopleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.people.ExportPeopleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getOptionalId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getOptionalSearchQuery();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOptionalFiltersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.people.PeopleFilter.serializeBinaryToWriter
    );
  }
  f = message.getOptionalSelectedList();
  if (f.length > 0) {
    writer.writePackedInt64(
      5,
      f
    );
  }
  f = message.getInvalidEmails();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getWorkEmails();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getPersonalEmails();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getGenericEmails();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getCatchAllEmails();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getOptionalNumberTopResults();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.people.ExportPeopleRequest.ExportType = {
  ALL: 0,
  FILTERED: 1,
  SELECTED: 2,
  FOLDER: 3,
  CAMPAIGN: 4,
  TOP_RESULTS: 5
};

/**
 * optional ExportType type = 1;
 * @return {!proto.people.ExportPeopleRequest.ExportType}
 */
proto.people.ExportPeopleRequest.prototype.getType = function() {
  return /** @type {!proto.people.ExportPeopleRequest.ExportType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.people.ExportPeopleRequest.ExportType} value
 * @return {!proto.people.ExportPeopleRequest} returns this
 */
proto.people.ExportPeopleRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional int64 optional_id = 2;
 * @return {number}
 */
proto.people.ExportPeopleRequest.prototype.getOptionalId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.people.ExportPeopleRequest} returns this
 */
proto.people.ExportPeopleRequest.prototype.setOptionalId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string optional_search_query = 3;
 * @return {string}
 */
proto.people.ExportPeopleRequest.prototype.getOptionalSearchQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.people.ExportPeopleRequest} returns this
 */
proto.people.ExportPeopleRequest.prototype.setOptionalSearchQuery = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated PeopleFilter optional_filters = 4;
 * @return {!Array<!proto.people.PeopleFilter>}
 */
proto.people.ExportPeopleRequest.prototype.getOptionalFiltersList = function() {
  return /** @type{!Array<!proto.people.PeopleFilter>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.people.PeopleFilter, 4));
};


/**
 * @param {!Array<!proto.people.PeopleFilter>} value
 * @return {!proto.people.ExportPeopleRequest} returns this
*/
proto.people.ExportPeopleRequest.prototype.setOptionalFiltersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.people.PeopleFilter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.people.PeopleFilter}
 */
proto.people.ExportPeopleRequest.prototype.addOptionalFilters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.people.PeopleFilter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.people.ExportPeopleRequest} returns this
 */
proto.people.ExportPeopleRequest.prototype.clearOptionalFiltersList = function() {
  return this.setOptionalFiltersList([]);
};


/**
 * repeated int64 optional_selected = 5;
 * @return {!Array<number>}
 */
proto.people.ExportPeopleRequest.prototype.getOptionalSelectedList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.people.ExportPeopleRequest} returns this
 */
proto.people.ExportPeopleRequest.prototype.setOptionalSelectedList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.people.ExportPeopleRequest} returns this
 */
proto.people.ExportPeopleRequest.prototype.addOptionalSelected = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.people.ExportPeopleRequest} returns this
 */
proto.people.ExportPeopleRequest.prototype.clearOptionalSelectedList = function() {
  return this.setOptionalSelectedList([]);
};


/**
 * optional bool invalid_emails = 6;
 * @return {boolean}
 */
proto.people.ExportPeopleRequest.prototype.getInvalidEmails = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.people.ExportPeopleRequest} returns this
 */
proto.people.ExportPeopleRequest.prototype.setInvalidEmails = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool work_emails = 7;
 * @return {boolean}
 */
proto.people.ExportPeopleRequest.prototype.getWorkEmails = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.people.ExportPeopleRequest} returns this
 */
proto.people.ExportPeopleRequest.prototype.setWorkEmails = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool personal_emails = 8;
 * @return {boolean}
 */
proto.people.ExportPeopleRequest.prototype.getPersonalEmails = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.people.ExportPeopleRequest} returns this
 */
proto.people.ExportPeopleRequest.prototype.setPersonalEmails = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional bool generic_emails = 9;
 * @return {boolean}
 */
proto.people.ExportPeopleRequest.prototype.getGenericEmails = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.people.ExportPeopleRequest} returns this
 */
proto.people.ExportPeopleRequest.prototype.setGenericEmails = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional bool catch_all_emails = 10;
 * @return {boolean}
 */
proto.people.ExportPeopleRequest.prototype.getCatchAllEmails = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.people.ExportPeopleRequest} returns this
 */
proto.people.ExportPeopleRequest.prototype.setCatchAllEmails = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional int32 optional_number_top_results = 11;
 * @return {number}
 */
proto.people.ExportPeopleRequest.prototype.getOptionalNumberTopResults = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.people.ExportPeopleRequest} returns this
 */
proto.people.ExportPeopleRequest.prototype.setOptionalNumberTopResults = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.people.PeopleWebsiteRelationshipStatuses.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.people.PeopleWebsiteRelationshipStatuses.prototype.toObject = function(opt_includeInstance) {
  return proto.people.PeopleWebsiteRelationshipStatuses.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.people.PeopleWebsiteRelationshipStatuses} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.people.PeopleWebsiteRelationshipStatuses.toObject = function(includeInstance, msg) {
  var f, obj = {
    websiteId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    statusesList: jspb.Message.toObjectList(msg.getStatusesList(),
    proto.people.PeopleWebsiteRelationshipStatuses.Person.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.people.PeopleWebsiteRelationshipStatuses}
 */
proto.people.PeopleWebsiteRelationshipStatuses.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.people.PeopleWebsiteRelationshipStatuses;
  return proto.people.PeopleWebsiteRelationshipStatuses.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.people.PeopleWebsiteRelationshipStatuses} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.people.PeopleWebsiteRelationshipStatuses}
 */
proto.people.PeopleWebsiteRelationshipStatuses.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWebsiteId(value);
      break;
    case 2:
      var value = new proto.people.PeopleWebsiteRelationshipStatuses.Person;
      reader.readMessage(value,proto.people.PeopleWebsiteRelationshipStatuses.Person.deserializeBinaryFromReader);
      msg.addStatuses(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.people.PeopleWebsiteRelationshipStatuses.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.people.PeopleWebsiteRelationshipStatuses.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.people.PeopleWebsiteRelationshipStatuses} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.people.PeopleWebsiteRelationshipStatuses.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWebsiteId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getStatusesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.people.PeopleWebsiteRelationshipStatuses.Person.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.people.PeopleWebsiteRelationshipStatuses.Person.prototype.toObject = function(opt_includeInstance) {
  return proto.people.PeopleWebsiteRelationshipStatuses.Person.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.people.PeopleWebsiteRelationshipStatuses.Person} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.people.PeopleWebsiteRelationshipStatuses.Person.toObject = function(includeInstance, msg) {
  var f, obj = {
    personId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    status: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.people.PeopleWebsiteRelationshipStatuses.Person}
 */
proto.people.PeopleWebsiteRelationshipStatuses.Person.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.people.PeopleWebsiteRelationshipStatuses.Person;
  return proto.people.PeopleWebsiteRelationshipStatuses.Person.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.people.PeopleWebsiteRelationshipStatuses.Person} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.people.PeopleWebsiteRelationshipStatuses.Person}
 */
proto.people.PeopleWebsiteRelationshipStatuses.Person.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPersonId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {!proto.common.PeopleRelationshipStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.people.PeopleWebsiteRelationshipStatuses.Person.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.people.PeopleWebsiteRelationshipStatuses.Person.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.people.PeopleWebsiteRelationshipStatuses.Person} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.people.PeopleWebsiteRelationshipStatuses.Person.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPersonId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional int64 person_id = 1;
 * @return {number}
 */
proto.people.PeopleWebsiteRelationshipStatuses.Person.prototype.getPersonId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.people.PeopleWebsiteRelationshipStatuses.Person} returns this
 */
proto.people.PeopleWebsiteRelationshipStatuses.Person.prototype.setPersonId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.people.PeopleWebsiteRelationshipStatuses.Person.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.people.PeopleWebsiteRelationshipStatuses.Person} returns this
 */
proto.people.PeopleWebsiteRelationshipStatuses.Person.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional common.PeopleRelationshipStatus status = 3;
 * @return {!proto.common.PeopleRelationshipStatus}
 */
proto.people.PeopleWebsiteRelationshipStatuses.Person.prototype.getStatus = function() {
  return /** @type {!proto.common.PeopleRelationshipStatus} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.common.PeopleRelationshipStatus} value
 * @return {!proto.people.PeopleWebsiteRelationshipStatuses.Person} returns this
 */
proto.people.PeopleWebsiteRelationshipStatuses.Person.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional int64 website_id = 1;
 * @return {number}
 */
proto.people.PeopleWebsiteRelationshipStatuses.prototype.getWebsiteId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.people.PeopleWebsiteRelationshipStatuses} returns this
 */
proto.people.PeopleWebsiteRelationshipStatuses.prototype.setWebsiteId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated Person statuses = 2;
 * @return {!Array<!proto.people.PeopleWebsiteRelationshipStatuses.Person>}
 */
proto.people.PeopleWebsiteRelationshipStatuses.prototype.getStatusesList = function() {
  return /** @type{!Array<!proto.people.PeopleWebsiteRelationshipStatuses.Person>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.people.PeopleWebsiteRelationshipStatuses.Person, 2));
};


/**
 * @param {!Array<!proto.people.PeopleWebsiteRelationshipStatuses.Person>} value
 * @return {!proto.people.PeopleWebsiteRelationshipStatuses} returns this
*/
proto.people.PeopleWebsiteRelationshipStatuses.prototype.setStatusesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.people.PeopleWebsiteRelationshipStatuses.Person=} opt_value
 * @param {number=} opt_index
 * @return {!proto.people.PeopleWebsiteRelationshipStatuses.Person}
 */
proto.people.PeopleWebsiteRelationshipStatuses.prototype.addStatuses = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.people.PeopleWebsiteRelationshipStatuses.Person, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.people.PeopleWebsiteRelationshipStatuses} returns this
 */
proto.people.PeopleWebsiteRelationshipStatuses.prototype.clearStatusesList = function() {
  return this.setStatusesList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.people.CustomVariableCreateRequest.oneofGroups_ = [[6,7]];

/**
 * @enum {number}
 */
proto.people.CustomVariableCreateRequest.SettingsCase = {
  SETTINGS_NOT_SET: 0,
  OPEN_AI: 6,
  SELECT: 7
};

/**
 * @return {proto.people.CustomVariableCreateRequest.SettingsCase}
 */
proto.people.CustomVariableCreateRequest.prototype.getSettingsCase = function() {
  return /** @type {proto.people.CustomVariableCreateRequest.SettingsCase} */(jspb.Message.computeOneofCase(this, proto.people.CustomVariableCreateRequest.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.people.CustomVariableCreateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.people.CustomVariableCreateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.people.CustomVariableCreateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.people.CustomVariableCreateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    title: jspb.Message.getFieldWithDefault(msg, 1, ""),
    description: jspb.Message.getFieldWithDefault(msg, 2, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, 0),
    relatedTo: jspb.Message.getFieldWithDefault(msg, 4, 0),
    workspaceId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    openAi: (f = msg.getOpenAi()) && proto.people.OpenAICustomVariableSettings.toObject(includeInstance, f),
    select: (f = msg.getSelect()) && proto.people.SelectCustomVariableSettings.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.people.CustomVariableCreateRequest}
 */
proto.people.CustomVariableCreateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.people.CustomVariableCreateRequest;
  return proto.people.CustomVariableCreateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.people.CustomVariableCreateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.people.CustomVariableCreateRequest}
 */
proto.people.CustomVariableCreateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 3:
      var value = /** @type {!proto.common.CustomVariableType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 4:
      var value = /** @type {!proto.common.CustomVariableValueRelatedTo} */ (reader.readEnum());
      msg.setRelatedTo(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWorkspaceId(value);
      break;
    case 6:
      var value = new proto.people.OpenAICustomVariableSettings;
      reader.readMessage(value,proto.people.OpenAICustomVariableSettings.deserializeBinaryFromReader);
      msg.setOpenAi(value);
      break;
    case 7:
      var value = new proto.people.SelectCustomVariableSettings;
      reader.readMessage(value,proto.people.SelectCustomVariableSettings.deserializeBinaryFromReader);
      msg.setSelect(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.people.CustomVariableCreateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.people.CustomVariableCreateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.people.CustomVariableCreateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.people.CustomVariableCreateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getRelatedTo();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getWorkspaceId();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getOpenAi();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.people.OpenAICustomVariableSettings.serializeBinaryToWriter
    );
  }
  f = message.getSelect();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.people.SelectCustomVariableSettings.serializeBinaryToWriter
    );
  }
};


/**
 * optional string title = 1;
 * @return {string}
 */
proto.people.CustomVariableCreateRequest.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.people.CustomVariableCreateRequest} returns this
 */
proto.people.CustomVariableCreateRequest.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.people.CustomVariableCreateRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.people.CustomVariableCreateRequest} returns this
 */
proto.people.CustomVariableCreateRequest.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional common.CustomVariableType type = 3;
 * @return {!proto.common.CustomVariableType}
 */
proto.people.CustomVariableCreateRequest.prototype.getType = function() {
  return /** @type {!proto.common.CustomVariableType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.common.CustomVariableType} value
 * @return {!proto.people.CustomVariableCreateRequest} returns this
 */
proto.people.CustomVariableCreateRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional common.CustomVariableValueRelatedTo related_to = 4;
 * @return {!proto.common.CustomVariableValueRelatedTo}
 */
proto.people.CustomVariableCreateRequest.prototype.getRelatedTo = function() {
  return /** @type {!proto.common.CustomVariableValueRelatedTo} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.common.CustomVariableValueRelatedTo} value
 * @return {!proto.people.CustomVariableCreateRequest} returns this
 */
proto.people.CustomVariableCreateRequest.prototype.setRelatedTo = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional int64 workspace_id = 5;
 * @return {number}
 */
proto.people.CustomVariableCreateRequest.prototype.getWorkspaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.people.CustomVariableCreateRequest} returns this
 */
proto.people.CustomVariableCreateRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional OpenAICustomVariableSettings open_ai = 6;
 * @return {?proto.people.OpenAICustomVariableSettings}
 */
proto.people.CustomVariableCreateRequest.prototype.getOpenAi = function() {
  return /** @type{?proto.people.OpenAICustomVariableSettings} */ (
    jspb.Message.getWrapperField(this, proto.people.OpenAICustomVariableSettings, 6));
};


/**
 * @param {?proto.people.OpenAICustomVariableSettings|undefined} value
 * @return {!proto.people.CustomVariableCreateRequest} returns this
*/
proto.people.CustomVariableCreateRequest.prototype.setOpenAi = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.people.CustomVariableCreateRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.people.CustomVariableCreateRequest} returns this
 */
proto.people.CustomVariableCreateRequest.prototype.clearOpenAi = function() {
  return this.setOpenAi(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.people.CustomVariableCreateRequest.prototype.hasOpenAi = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional SelectCustomVariableSettings select = 7;
 * @return {?proto.people.SelectCustomVariableSettings}
 */
proto.people.CustomVariableCreateRequest.prototype.getSelect = function() {
  return /** @type{?proto.people.SelectCustomVariableSettings} */ (
    jspb.Message.getWrapperField(this, proto.people.SelectCustomVariableSettings, 7));
};


/**
 * @param {?proto.people.SelectCustomVariableSettings|undefined} value
 * @return {!proto.people.CustomVariableCreateRequest} returns this
*/
proto.people.CustomVariableCreateRequest.prototype.setSelect = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.people.CustomVariableCreateRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.people.CustomVariableCreateRequest} returns this
 */
proto.people.CustomVariableCreateRequest.prototype.clearSelect = function() {
  return this.setSelect(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.people.CustomVariableCreateRequest.prototype.hasSelect = function() {
  return jspb.Message.getField(this, 7) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.people.CustomVariableUpdateRequest.oneofGroups_ = [[6,7]];

/**
 * @enum {number}
 */
proto.people.CustomVariableUpdateRequest.SettingsCase = {
  SETTINGS_NOT_SET: 0,
  OPEN_AI: 6,
  SELECT: 7
};

/**
 * @return {proto.people.CustomVariableUpdateRequest.SettingsCase}
 */
proto.people.CustomVariableUpdateRequest.prototype.getSettingsCase = function() {
  return /** @type {proto.people.CustomVariableUpdateRequest.SettingsCase} */(jspb.Message.computeOneofCase(this, proto.people.CustomVariableUpdateRequest.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.people.CustomVariableUpdateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.people.CustomVariableUpdateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.people.CustomVariableUpdateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.people.CustomVariableUpdateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    type: jspb.Message.getFieldWithDefault(msg, 4, 0),
    relatedTo: jspb.Message.getFieldWithDefault(msg, 5, 0),
    openAi: (f = msg.getOpenAi()) && proto.people.OpenAICustomVariableSettings.toObject(includeInstance, f),
    select: (f = msg.getSelect()) && proto.people.SelectCustomVariableSettings.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.people.CustomVariableUpdateRequest}
 */
proto.people.CustomVariableUpdateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.people.CustomVariableUpdateRequest;
  return proto.people.CustomVariableUpdateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.people.CustomVariableUpdateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.people.CustomVariableUpdateRequest}
 */
proto.people.CustomVariableUpdateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {!proto.common.CustomVariableType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 5:
      var value = /** @type {!proto.common.CustomVariableValueRelatedTo} */ (reader.readEnum());
      msg.setRelatedTo(value);
      break;
    case 6:
      var value = new proto.people.OpenAICustomVariableSettings;
      reader.readMessage(value,proto.people.OpenAICustomVariableSettings.deserializeBinaryFromReader);
      msg.setOpenAi(value);
      break;
    case 7:
      var value = new proto.people.SelectCustomVariableSettings;
      reader.readMessage(value,proto.people.SelectCustomVariableSettings.deserializeBinaryFromReader);
      msg.setSelect(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.people.CustomVariableUpdateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.people.CustomVariableUpdateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.people.CustomVariableUpdateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.people.CustomVariableUpdateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getRelatedTo();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getOpenAi();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.people.OpenAICustomVariableSettings.serializeBinaryToWriter
    );
  }
  f = message.getSelect();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.people.SelectCustomVariableSettings.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.people.CustomVariableUpdateRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.people.CustomVariableUpdateRequest} returns this
 */
proto.people.CustomVariableUpdateRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.people.CustomVariableUpdateRequest.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.people.CustomVariableUpdateRequest} returns this
 */
proto.people.CustomVariableUpdateRequest.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.people.CustomVariableUpdateRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.people.CustomVariableUpdateRequest} returns this
 */
proto.people.CustomVariableUpdateRequest.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional common.CustomVariableType type = 4;
 * @return {!proto.common.CustomVariableType}
 */
proto.people.CustomVariableUpdateRequest.prototype.getType = function() {
  return /** @type {!proto.common.CustomVariableType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.common.CustomVariableType} value
 * @return {!proto.people.CustomVariableUpdateRequest} returns this
 */
proto.people.CustomVariableUpdateRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional common.CustomVariableValueRelatedTo related_to = 5;
 * @return {!proto.common.CustomVariableValueRelatedTo}
 */
proto.people.CustomVariableUpdateRequest.prototype.getRelatedTo = function() {
  return /** @type {!proto.common.CustomVariableValueRelatedTo} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.common.CustomVariableValueRelatedTo} value
 * @return {!proto.people.CustomVariableUpdateRequest} returns this
 */
proto.people.CustomVariableUpdateRequest.prototype.setRelatedTo = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional OpenAICustomVariableSettings open_ai = 6;
 * @return {?proto.people.OpenAICustomVariableSettings}
 */
proto.people.CustomVariableUpdateRequest.prototype.getOpenAi = function() {
  return /** @type{?proto.people.OpenAICustomVariableSettings} */ (
    jspb.Message.getWrapperField(this, proto.people.OpenAICustomVariableSettings, 6));
};


/**
 * @param {?proto.people.OpenAICustomVariableSettings|undefined} value
 * @return {!proto.people.CustomVariableUpdateRequest} returns this
*/
proto.people.CustomVariableUpdateRequest.prototype.setOpenAi = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.people.CustomVariableUpdateRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.people.CustomVariableUpdateRequest} returns this
 */
proto.people.CustomVariableUpdateRequest.prototype.clearOpenAi = function() {
  return this.setOpenAi(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.people.CustomVariableUpdateRequest.prototype.hasOpenAi = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional SelectCustomVariableSettings select = 7;
 * @return {?proto.people.SelectCustomVariableSettings}
 */
proto.people.CustomVariableUpdateRequest.prototype.getSelect = function() {
  return /** @type{?proto.people.SelectCustomVariableSettings} */ (
    jspb.Message.getWrapperField(this, proto.people.SelectCustomVariableSettings, 7));
};


/**
 * @param {?proto.people.SelectCustomVariableSettings|undefined} value
 * @return {!proto.people.CustomVariableUpdateRequest} returns this
*/
proto.people.CustomVariableUpdateRequest.prototype.setSelect = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.people.CustomVariableUpdateRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.people.CustomVariableUpdateRequest} returns this
 */
proto.people.CustomVariableUpdateRequest.prototype.clearSelect = function() {
  return this.setSelect(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.people.CustomVariableUpdateRequest.prototype.hasSelect = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.people.OpenAICustomVariableSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.people.OpenAICustomVariableSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.people.OpenAICustomVariableSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.people.OpenAICustomVariableSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    prompt: jspb.Message.getFieldWithDefault(msg, 1, ""),
    outputLengthFrom: jspb.Message.getFieldWithDefault(msg, 2, 0),
    outputLengthTo: jspb.Message.getFieldWithDefault(msg, 3, 0),
    tone: jspb.Message.getFieldWithDefault(msg, 4, ""),
    examples: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.people.OpenAICustomVariableSettings}
 */
proto.people.OpenAICustomVariableSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.people.OpenAICustomVariableSettings;
  return proto.people.OpenAICustomVariableSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.people.OpenAICustomVariableSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.people.OpenAICustomVariableSettings}
 */
proto.people.OpenAICustomVariableSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrompt(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOutputLengthFrom(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOutputLengthTo(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTone(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setExamples(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.people.OpenAICustomVariableSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.people.OpenAICustomVariableSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.people.OpenAICustomVariableSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.people.OpenAICustomVariableSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPrompt();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOutputLengthFrom();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getOutputLengthTo();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getTone();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getExamples();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string prompt = 1;
 * @return {string}
 */
proto.people.OpenAICustomVariableSettings.prototype.getPrompt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.people.OpenAICustomVariableSettings} returns this
 */
proto.people.OpenAICustomVariableSettings.prototype.setPrompt = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 output_length_from = 2;
 * @return {number}
 */
proto.people.OpenAICustomVariableSettings.prototype.getOutputLengthFrom = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.people.OpenAICustomVariableSettings} returns this
 */
proto.people.OpenAICustomVariableSettings.prototype.setOutputLengthFrom = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 output_length_to = 3;
 * @return {number}
 */
proto.people.OpenAICustomVariableSettings.prototype.getOutputLengthTo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.people.OpenAICustomVariableSettings} returns this
 */
proto.people.OpenAICustomVariableSettings.prototype.setOutputLengthTo = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string tone = 4;
 * @return {string}
 */
proto.people.OpenAICustomVariableSettings.prototype.getTone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.people.OpenAICustomVariableSettings} returns this
 */
proto.people.OpenAICustomVariableSettings.prototype.setTone = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string examples = 5;
 * @return {string}
 */
proto.people.OpenAICustomVariableSettings.prototype.getExamples = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.people.OpenAICustomVariableSettings} returns this
 */
proto.people.OpenAICustomVariableSettings.prototype.setExamples = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.people.SelectCustomVariableSettings.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.people.SelectCustomVariableSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.people.SelectCustomVariableSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.people.SelectCustomVariableSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.people.SelectCustomVariableSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    multiSelect: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.people.SelectCustomVariableSettings.SelectItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.people.SelectCustomVariableSettings}
 */
proto.people.SelectCustomVariableSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.people.SelectCustomVariableSettings;
  return proto.people.SelectCustomVariableSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.people.SelectCustomVariableSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.people.SelectCustomVariableSettings}
 */
proto.people.SelectCustomVariableSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMultiSelect(value);
      break;
    case 2:
      var value = new proto.people.SelectCustomVariableSettings.SelectItem;
      reader.readMessage(value,proto.people.SelectCustomVariableSettings.SelectItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.people.SelectCustomVariableSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.people.SelectCustomVariableSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.people.SelectCustomVariableSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.people.SelectCustomVariableSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMultiSelect();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.people.SelectCustomVariableSettings.SelectItem.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.people.SelectCustomVariableSettings.SelectItem.prototype.toObject = function(opt_includeInstance) {
  return proto.people.SelectCustomVariableSettings.SelectItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.people.SelectCustomVariableSettings.SelectItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.people.SelectCustomVariableSettings.SelectItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    order: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    color: jspb.Message.getFieldWithDefault(msg, 3, ""),
    uid: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.people.SelectCustomVariableSettings.SelectItem}
 */
proto.people.SelectCustomVariableSettings.SelectItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.people.SelectCustomVariableSettings.SelectItem;
  return proto.people.SelectCustomVariableSettings.SelectItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.people.SelectCustomVariableSettings.SelectItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.people.SelectCustomVariableSettings.SelectItem}
 */
proto.people.SelectCustomVariableSettings.SelectItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrder(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setColor(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.people.SelectCustomVariableSettings.SelectItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.people.SelectCustomVariableSettings.SelectItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.people.SelectCustomVariableSettings.SelectItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.people.SelectCustomVariableSettings.SelectItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrder();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getColor();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional int32 order = 1;
 * @return {number}
 */
proto.people.SelectCustomVariableSettings.SelectItem.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.people.SelectCustomVariableSettings.SelectItem} returns this
 */
proto.people.SelectCustomVariableSettings.SelectItem.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.people.SelectCustomVariableSettings.SelectItem.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.people.SelectCustomVariableSettings.SelectItem} returns this
 */
proto.people.SelectCustomVariableSettings.SelectItem.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string color = 3;
 * @return {string}
 */
proto.people.SelectCustomVariableSettings.SelectItem.prototype.getColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.people.SelectCustomVariableSettings.SelectItem} returns this
 */
proto.people.SelectCustomVariableSettings.SelectItem.prototype.setColor = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string uid = 4;
 * @return {string}
 */
proto.people.SelectCustomVariableSettings.SelectItem.prototype.getUid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.people.SelectCustomVariableSettings.SelectItem} returns this
 */
proto.people.SelectCustomVariableSettings.SelectItem.prototype.setUid = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool multi_select = 1;
 * @return {boolean}
 */
proto.people.SelectCustomVariableSettings.prototype.getMultiSelect = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.people.SelectCustomVariableSettings} returns this
 */
proto.people.SelectCustomVariableSettings.prototype.setMultiSelect = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * repeated SelectItem items = 2;
 * @return {!Array<!proto.people.SelectCustomVariableSettings.SelectItem>}
 */
proto.people.SelectCustomVariableSettings.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.people.SelectCustomVariableSettings.SelectItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.people.SelectCustomVariableSettings.SelectItem, 2));
};


/**
 * @param {!Array<!proto.people.SelectCustomVariableSettings.SelectItem>} value
 * @return {!proto.people.SelectCustomVariableSettings} returns this
*/
proto.people.SelectCustomVariableSettings.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.people.SelectCustomVariableSettings.SelectItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.people.SelectCustomVariableSettings.SelectItem}
 */
proto.people.SelectCustomVariableSettings.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.people.SelectCustomVariableSettings.SelectItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.people.SelectCustomVariableSettings} returns this
 */
proto.people.SelectCustomVariableSettings.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.people.CustomVariableResponse.oneofGroups_ = [[10,11]];

/**
 * @enum {number}
 */
proto.people.CustomVariableResponse.SettingsCase = {
  SETTINGS_NOT_SET: 0,
  OPEN_AI: 10,
  SELECT: 11
};

/**
 * @return {proto.people.CustomVariableResponse.SettingsCase}
 */
proto.people.CustomVariableResponse.prototype.getSettingsCase = function() {
  return /** @type {proto.people.CustomVariableResponse.SettingsCase} */(jspb.Message.computeOneofCase(this, proto.people.CustomVariableResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.people.CustomVariableResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.people.CustomVariableResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.people.CustomVariableResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.people.CustomVariableResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    description: jspb.Message.getFieldWithDefault(msg, 4, ""),
    type: jspb.Message.getFieldWithDefault(msg, 5, 0),
    relatedTo: jspb.Message.getFieldWithDefault(msg, 6, 0),
    workspaceId: jspb.Message.getFieldWithDefault(msg, 7, 0),
    createdAt: jspb.Message.getFieldWithDefault(msg, 8, 0),
    isDefault: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    openAi: (f = msg.getOpenAi()) && proto.people.OpenAICustomVariableSettings.toObject(includeInstance, f),
    select: (f = msg.getSelect()) && proto.people.SelectCustomVariableSettings.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.people.CustomVariableResponse}
 */
proto.people.CustomVariableResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.people.CustomVariableResponse;
  return proto.people.CustomVariableResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.people.CustomVariableResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.people.CustomVariableResponse}
 */
proto.people.CustomVariableResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 5:
      var value = /** @type {!proto.common.CustomVariableType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 6:
      var value = /** @type {!proto.common.CustomVariableValueRelatedTo} */ (reader.readEnum());
      msg.setRelatedTo(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWorkspaceId(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDefault(value);
      break;
    case 10:
      var value = new proto.people.OpenAICustomVariableSettings;
      reader.readMessage(value,proto.people.OpenAICustomVariableSettings.deserializeBinaryFromReader);
      msg.setOpenAi(value);
      break;
    case 11:
      var value = new proto.people.SelectCustomVariableSettings;
      reader.readMessage(value,proto.people.SelectCustomVariableSettings.deserializeBinaryFromReader);
      msg.setSelect(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.people.CustomVariableResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.people.CustomVariableResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.people.CustomVariableResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.people.CustomVariableResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getRelatedTo();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getWorkspaceId();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getIsDefault();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getOpenAi();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.people.OpenAICustomVariableSettings.serializeBinaryToWriter
    );
  }
  f = message.getSelect();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.people.SelectCustomVariableSettings.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.people.CustomVariableResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.people.CustomVariableResponse} returns this
 */
proto.people.CustomVariableResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.people.CustomVariableResponse.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.people.CustomVariableResponse} returns this
 */
proto.people.CustomVariableResponse.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.people.CustomVariableResponse.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.people.CustomVariableResponse} returns this
 */
proto.people.CustomVariableResponse.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string description = 4;
 * @return {string}
 */
proto.people.CustomVariableResponse.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.people.CustomVariableResponse} returns this
 */
proto.people.CustomVariableResponse.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional common.CustomVariableType type = 5;
 * @return {!proto.common.CustomVariableType}
 */
proto.people.CustomVariableResponse.prototype.getType = function() {
  return /** @type {!proto.common.CustomVariableType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.common.CustomVariableType} value
 * @return {!proto.people.CustomVariableResponse} returns this
 */
proto.people.CustomVariableResponse.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional common.CustomVariableValueRelatedTo related_to = 6;
 * @return {!proto.common.CustomVariableValueRelatedTo}
 */
proto.people.CustomVariableResponse.prototype.getRelatedTo = function() {
  return /** @type {!proto.common.CustomVariableValueRelatedTo} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.common.CustomVariableValueRelatedTo} value
 * @return {!proto.people.CustomVariableResponse} returns this
 */
proto.people.CustomVariableResponse.prototype.setRelatedTo = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional int64 workspace_id = 7;
 * @return {number}
 */
proto.people.CustomVariableResponse.prototype.getWorkspaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.people.CustomVariableResponse} returns this
 */
proto.people.CustomVariableResponse.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int64 created_at = 8;
 * @return {number}
 */
proto.people.CustomVariableResponse.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.people.CustomVariableResponse} returns this
 */
proto.people.CustomVariableResponse.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional bool is_default = 9;
 * @return {boolean}
 */
proto.people.CustomVariableResponse.prototype.getIsDefault = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.people.CustomVariableResponse} returns this
 */
proto.people.CustomVariableResponse.prototype.setIsDefault = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional OpenAICustomVariableSettings open_ai = 10;
 * @return {?proto.people.OpenAICustomVariableSettings}
 */
proto.people.CustomVariableResponse.prototype.getOpenAi = function() {
  return /** @type{?proto.people.OpenAICustomVariableSettings} */ (
    jspb.Message.getWrapperField(this, proto.people.OpenAICustomVariableSettings, 10));
};


/**
 * @param {?proto.people.OpenAICustomVariableSettings|undefined} value
 * @return {!proto.people.CustomVariableResponse} returns this
*/
proto.people.CustomVariableResponse.prototype.setOpenAi = function(value) {
  return jspb.Message.setOneofWrapperField(this, 10, proto.people.CustomVariableResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.people.CustomVariableResponse} returns this
 */
proto.people.CustomVariableResponse.prototype.clearOpenAi = function() {
  return this.setOpenAi(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.people.CustomVariableResponse.prototype.hasOpenAi = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional SelectCustomVariableSettings select = 11;
 * @return {?proto.people.SelectCustomVariableSettings}
 */
proto.people.CustomVariableResponse.prototype.getSelect = function() {
  return /** @type{?proto.people.SelectCustomVariableSettings} */ (
    jspb.Message.getWrapperField(this, proto.people.SelectCustomVariableSettings, 11));
};


/**
 * @param {?proto.people.SelectCustomVariableSettings|undefined} value
 * @return {!proto.people.CustomVariableResponse} returns this
*/
proto.people.CustomVariableResponse.prototype.setSelect = function(value) {
  return jspb.Message.setOneofWrapperField(this, 11, proto.people.CustomVariableResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.people.CustomVariableResponse} returns this
 */
proto.people.CustomVariableResponse.prototype.clearSelect = function() {
  return this.setSelect(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.people.CustomVariableResponse.prototype.hasSelect = function() {
  return jspb.Message.getField(this, 11) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.people.CustomVariableRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.people.CustomVariableRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.people.CustomVariableRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.people.CustomVariableRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    relatedTo: jspb.Message.getFieldWithDefault(msg, 1, 0),
    workspaceId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.people.CustomVariableRequest}
 */
proto.people.CustomVariableRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.people.CustomVariableRequest;
  return proto.people.CustomVariableRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.people.CustomVariableRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.people.CustomVariableRequest}
 */
proto.people.CustomVariableRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.common.CustomVariableValueRelatedTo} */ (reader.readEnum());
      msg.setRelatedTo(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWorkspaceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.people.CustomVariableRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.people.CustomVariableRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.people.CustomVariableRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.people.CustomVariableRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRelatedTo();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getWorkspaceId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional common.CustomVariableValueRelatedTo related_to = 1;
 * @return {!proto.common.CustomVariableValueRelatedTo}
 */
proto.people.CustomVariableRequest.prototype.getRelatedTo = function() {
  return /** @type {!proto.common.CustomVariableValueRelatedTo} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.common.CustomVariableValueRelatedTo} value
 * @return {!proto.people.CustomVariableRequest} returns this
 */
proto.people.CustomVariableRequest.prototype.setRelatedTo = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional int64 workspace_id = 2;
 * @return {number}
 */
proto.people.CustomVariableRequest.prototype.getWorkspaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.people.CustomVariableRequest} returns this
 */
proto.people.CustomVariableRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.people.PersonCustomVariableValueRequest.repeatedFields_ = [1,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.people.PersonCustomVariableValueRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.people.PersonCustomVariableValueRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.people.PersonCustomVariableValueRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.people.PersonCustomVariableValueRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    variableNamesList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    relatedIdsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.people.PersonCustomVariableValueRequest}
 */
proto.people.PersonCustomVariableValueRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.people.PersonCustomVariableValueRequest;
  return proto.people.PersonCustomVariableValueRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.people.PersonCustomVariableValueRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.people.PersonCustomVariableValueRequest}
 */
proto.people.PersonCustomVariableValueRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addVariableNames(value);
      break;
    case 3:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt64());
      msg.setRelatedIdsList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.people.PersonCustomVariableValueRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.people.PersonCustomVariableValueRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.people.PersonCustomVariableValueRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.people.PersonCustomVariableValueRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVariableNamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getRelatedIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      3,
      f
    );
  }
};


/**
 * repeated string variable_names = 1;
 * @return {!Array<string>}
 */
proto.people.PersonCustomVariableValueRequest.prototype.getVariableNamesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.people.PersonCustomVariableValueRequest} returns this
 */
proto.people.PersonCustomVariableValueRequest.prototype.setVariableNamesList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.people.PersonCustomVariableValueRequest} returns this
 */
proto.people.PersonCustomVariableValueRequest.prototype.addVariableNames = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.people.PersonCustomVariableValueRequest} returns this
 */
proto.people.PersonCustomVariableValueRequest.prototype.clearVariableNamesList = function() {
  return this.setVariableNamesList([]);
};


/**
 * repeated int64 related_ids = 3;
 * @return {!Array<number>}
 */
proto.people.PersonCustomVariableValueRequest.prototype.getRelatedIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.people.PersonCustomVariableValueRequest} returns this
 */
proto.people.PersonCustomVariableValueRequest.prototype.setRelatedIdsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.people.PersonCustomVariableValueRequest} returns this
 */
proto.people.PersonCustomVariableValueRequest.prototype.addRelatedIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.people.PersonCustomVariableValueRequest} returns this
 */
proto.people.PersonCustomVariableValueRequest.prototype.clearRelatedIdsList = function() {
  return this.setRelatedIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.people.TableSettingsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.people.TableSettingsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.people.TableSettingsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.people.TableSettingsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    workspaceId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    relatedTo: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.people.TableSettingsRequest}
 */
proto.people.TableSettingsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.people.TableSettingsRequest;
  return proto.people.TableSettingsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.people.TableSettingsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.people.TableSettingsRequest}
 */
proto.people.TableSettingsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWorkspaceId(value);
      break;
    case 2:
      var value = /** @type {!proto.common.CustomVariableValueRelatedTo} */ (reader.readEnum());
      msg.setRelatedTo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.people.TableSettingsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.people.TableSettingsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.people.TableSettingsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.people.TableSettingsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWorkspaceId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getRelatedTo();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional int64 workspace_id = 1;
 * @return {number}
 */
proto.people.TableSettingsRequest.prototype.getWorkspaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.people.TableSettingsRequest} returns this
 */
proto.people.TableSettingsRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional common.CustomVariableValueRelatedTo related_to = 2;
 * @return {!proto.common.CustomVariableValueRelatedTo}
 */
proto.people.TableSettingsRequest.prototype.getRelatedTo = function() {
  return /** @type {!proto.common.CustomVariableValueRelatedTo} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.common.CustomVariableValueRelatedTo} value
 * @return {!proto.people.TableSettingsRequest} returns this
 */
proto.people.TableSettingsRequest.prototype.setRelatedTo = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.people.TableSettingsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.people.TableSettingsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.people.TableSettingsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.people.TableSettingsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    columnTitle: jspb.Message.getFieldWithDefault(msg, 2, ""),
    columnMappingName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    order: jspb.Message.getFieldWithDefault(msg, 4, 0),
    enabled: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    custom: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.people.TableSettingsResponse}
 */
proto.people.TableSettingsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.people.TableSettingsResponse;
  return proto.people.TableSettingsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.people.TableSettingsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.people.TableSettingsResponse}
 */
proto.people.TableSettingsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setColumnTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setColumnMappingName(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrder(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnabled(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCustom(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.people.TableSettingsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.people.TableSettingsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.people.TableSettingsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.people.TableSettingsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getColumnTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getColumnMappingName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOrder();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getEnabled();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getCustom();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.people.TableSettingsResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.people.TableSettingsResponse} returns this
 */
proto.people.TableSettingsResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string column_title = 2;
 * @return {string}
 */
proto.people.TableSettingsResponse.prototype.getColumnTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.people.TableSettingsResponse} returns this
 */
proto.people.TableSettingsResponse.prototype.setColumnTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string column_mapping_name = 3;
 * @return {string}
 */
proto.people.TableSettingsResponse.prototype.getColumnMappingName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.people.TableSettingsResponse} returns this
 */
proto.people.TableSettingsResponse.prototype.setColumnMappingName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 order = 4;
 * @return {number}
 */
proto.people.TableSettingsResponse.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.people.TableSettingsResponse} returns this
 */
proto.people.TableSettingsResponse.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional bool enabled = 5;
 * @return {boolean}
 */
proto.people.TableSettingsResponse.prototype.getEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.people.TableSettingsResponse} returns this
 */
proto.people.TableSettingsResponse.prototype.setEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool custom = 6;
 * @return {boolean}
 */
proto.people.TableSettingsResponse.prototype.getCustom = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.people.TableSettingsResponse} returns this
 */
proto.people.TableSettingsResponse.prototype.setCustom = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.people.TableSettingsStoreRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.people.TableSettingsStoreRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.people.TableSettingsStoreRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.people.TableSettingsStoreRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.people.TableSettingsStoreRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    relatedTo: jspb.Message.getFieldWithDefault(msg, 1, 0),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.people.TableSettingsResponse.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.people.TableSettingsStoreRequest}
 */
proto.people.TableSettingsStoreRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.people.TableSettingsStoreRequest;
  return proto.people.TableSettingsStoreRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.people.TableSettingsStoreRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.people.TableSettingsStoreRequest}
 */
proto.people.TableSettingsStoreRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.common.CustomVariableValueRelatedTo} */ (reader.readEnum());
      msg.setRelatedTo(value);
      break;
    case 2:
      var value = new proto.people.TableSettingsResponse;
      reader.readMessage(value,proto.people.TableSettingsResponse.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.people.TableSettingsStoreRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.people.TableSettingsStoreRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.people.TableSettingsStoreRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.people.TableSettingsStoreRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRelatedTo();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.people.TableSettingsResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional common.CustomVariableValueRelatedTo related_to = 1;
 * @return {!proto.common.CustomVariableValueRelatedTo}
 */
proto.people.TableSettingsStoreRequest.prototype.getRelatedTo = function() {
  return /** @type {!proto.common.CustomVariableValueRelatedTo} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.common.CustomVariableValueRelatedTo} value
 * @return {!proto.people.TableSettingsStoreRequest} returns this
 */
proto.people.TableSettingsStoreRequest.prototype.setRelatedTo = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * repeated TableSettingsResponse items = 2;
 * @return {!Array<!proto.people.TableSettingsResponse>}
 */
proto.people.TableSettingsStoreRequest.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.people.TableSettingsResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.people.TableSettingsResponse, 2));
};


/**
 * @param {!Array<!proto.people.TableSettingsResponse>} value
 * @return {!proto.people.TableSettingsStoreRequest} returns this
*/
proto.people.TableSettingsStoreRequest.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.people.TableSettingsResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.people.TableSettingsResponse}
 */
proto.people.TableSettingsStoreRequest.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.people.TableSettingsResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.people.TableSettingsStoreRequest} returns this
 */
proto.people.TableSettingsStoreRequest.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.people.UnsubscribedPersonsList.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.people.UnsubscribedPersonsList.prototype.toObject = function(opt_includeInstance) {
  return proto.people.UnsubscribedPersonsList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.people.UnsubscribedPersonsList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.people.UnsubscribedPersonsList.toObject = function(includeInstance, msg) {
  var f, obj = {
    unsubscribedPersonsIdList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    validPersonsIdList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.people.UnsubscribedPersonsList}
 */
proto.people.UnsubscribedPersonsList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.people.UnsubscribedPersonsList;
  return proto.people.UnsubscribedPersonsList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.people.UnsubscribedPersonsList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.people.UnsubscribedPersonsList}
 */
proto.people.UnsubscribedPersonsList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt64());
      msg.setUnsubscribedPersonsIdList(value);
      break;
    case 2:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt64());
      msg.setValidPersonsIdList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.people.UnsubscribedPersonsList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.people.UnsubscribedPersonsList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.people.UnsubscribedPersonsList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.people.UnsubscribedPersonsList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUnsubscribedPersonsIdList();
  if (f.length > 0) {
    writer.writePackedInt64(
      1,
      f
    );
  }
  f = message.getValidPersonsIdList();
  if (f.length > 0) {
    writer.writePackedInt64(
      2,
      f
    );
  }
};


/**
 * repeated int64 unsubscribed_persons_id = 1;
 * @return {!Array<number>}
 */
proto.people.UnsubscribedPersonsList.prototype.getUnsubscribedPersonsIdList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.people.UnsubscribedPersonsList} returns this
 */
proto.people.UnsubscribedPersonsList.prototype.setUnsubscribedPersonsIdList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.people.UnsubscribedPersonsList} returns this
 */
proto.people.UnsubscribedPersonsList.prototype.addUnsubscribedPersonsId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.people.UnsubscribedPersonsList} returns this
 */
proto.people.UnsubscribedPersonsList.prototype.clearUnsubscribedPersonsIdList = function() {
  return this.setUnsubscribedPersonsIdList([]);
};


/**
 * repeated int64 valid_persons_id = 2;
 * @return {!Array<number>}
 */
proto.people.UnsubscribedPersonsList.prototype.getValidPersonsIdList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.people.UnsubscribedPersonsList} returns this
 */
proto.people.UnsubscribedPersonsList.prototype.setValidPersonsIdList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.people.UnsubscribedPersonsList} returns this
 */
proto.people.UnsubscribedPersonsList.prototype.addValidPersonsId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.people.UnsubscribedPersonsList} returns this
 */
proto.people.UnsubscribedPersonsList.prototype.clearValidPersonsIdList = function() {
  return this.setValidPersonsIdList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.people.PersonInfoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.people.PersonInfoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.people.PersonInfoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.people.PersonInfoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    personId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.people.PersonInfoRequest}
 */
proto.people.PersonInfoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.people.PersonInfoRequest;
  return proto.people.PersonInfoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.people.PersonInfoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.people.PersonInfoRequest}
 */
proto.people.PersonInfoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPersonId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.people.PersonInfoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.people.PersonInfoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.people.PersonInfoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.people.PersonInfoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPersonId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int64 person_id = 1;
 * @return {number}
 */
proto.people.PersonInfoRequest.prototype.getPersonId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.people.PersonInfoRequest} returns this
 */
proto.people.PersonInfoRequest.prototype.setPersonId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.people.PersonInfoRequest.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.people.PersonInfoRequest} returns this
 */
proto.people.PersonInfoRequest.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.people.PersonInfoUpdateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.people.PersonInfoUpdateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.people.PersonInfoUpdateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.people.PersonInfoUpdateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    personId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    valueFrom: jspb.Message.getFieldWithDefault(msg, 2, ""),
    valueTo: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.people.PersonInfoUpdateRequest}
 */
proto.people.PersonInfoUpdateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.people.PersonInfoUpdateRequest;
  return proto.people.PersonInfoUpdateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.people.PersonInfoUpdateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.people.PersonInfoUpdateRequest}
 */
proto.people.PersonInfoUpdateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPersonId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValueFrom(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setValueTo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.people.PersonInfoUpdateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.people.PersonInfoUpdateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.people.PersonInfoUpdateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.people.PersonInfoUpdateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPersonId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getValueFrom();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getValueTo();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int64 person_id = 1;
 * @return {number}
 */
proto.people.PersonInfoUpdateRequest.prototype.getPersonId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.people.PersonInfoUpdateRequest} returns this
 */
proto.people.PersonInfoUpdateRequest.prototype.setPersonId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string value_from = 2;
 * @return {string}
 */
proto.people.PersonInfoUpdateRequest.prototype.getValueFrom = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.people.PersonInfoUpdateRequest} returns this
 */
proto.people.PersonInfoUpdateRequest.prototype.setValueFrom = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string value_to = 3;
 * @return {string}
 */
proto.people.PersonInfoUpdateRequest.prototype.getValueTo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.people.PersonInfoUpdateRequest} returns this
 */
proto.people.PersonInfoUpdateRequest.prototype.setValueTo = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.people.PersonRelationshipStatusRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.people.PersonRelationshipStatusRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.people.PersonRelationshipStatusRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.people.PersonRelationshipStatusRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    personId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    status: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.people.PersonRelationshipStatusRequest}
 */
proto.people.PersonRelationshipStatusRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.people.PersonRelationshipStatusRequest;
  return proto.people.PersonRelationshipStatusRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.people.PersonRelationshipStatusRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.people.PersonRelationshipStatusRequest}
 */
proto.people.PersonRelationshipStatusRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPersonId(value);
      break;
    case 2:
      var value = /** @type {!proto.common.PeopleRelationshipStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.people.PersonRelationshipStatusRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.people.PersonRelationshipStatusRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.people.PersonRelationshipStatusRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.people.PersonRelationshipStatusRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPersonId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional int64 person_id = 1;
 * @return {number}
 */
proto.people.PersonRelationshipStatusRequest.prototype.getPersonId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.people.PersonRelationshipStatusRequest} returns this
 */
proto.people.PersonRelationshipStatusRequest.prototype.setPersonId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional common.PeopleRelationshipStatus status = 2;
 * @return {!proto.common.PeopleRelationshipStatus}
 */
proto.people.PersonRelationshipStatusRequest.prototype.getStatus = function() {
  return /** @type {!proto.common.PeopleRelationshipStatus} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.common.PeopleRelationshipStatus} value
 * @return {!proto.people.PersonRelationshipStatusRequest} returns this
 */
proto.people.PersonRelationshipStatusRequest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.people.CustomVariableValueCreateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.people.CustomVariableValueCreateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.people.CustomVariableValueCreateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.people.CustomVariableValueCreateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    relatedid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    relatedto: jspb.Message.getFieldWithDefault(msg, 2, 0),
    item: (f = msg.getItem()) && common_pb.CustomVariableValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.people.CustomVariableValueCreateRequest}
 */
proto.people.CustomVariableValueCreateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.people.CustomVariableValueCreateRequest;
  return proto.people.CustomVariableValueCreateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.people.CustomVariableValueCreateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.people.CustomVariableValueCreateRequest}
 */
proto.people.CustomVariableValueCreateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRelatedid(value);
      break;
    case 2:
      var value = /** @type {!proto.common.CustomVariableValueRelatedTo} */ (reader.readEnum());
      msg.setRelatedto(value);
      break;
    case 3:
      var value = new common_pb.CustomVariableValue;
      reader.readMessage(value,common_pb.CustomVariableValue.deserializeBinaryFromReader);
      msg.setItem(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.people.CustomVariableValueCreateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.people.CustomVariableValueCreateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.people.CustomVariableValueCreateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.people.CustomVariableValueCreateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRelatedid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getRelatedto();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getItem();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      common_pb.CustomVariableValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 relatedId = 1;
 * @return {number}
 */
proto.people.CustomVariableValueCreateRequest.prototype.getRelatedid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.people.CustomVariableValueCreateRequest} returns this
 */
proto.people.CustomVariableValueCreateRequest.prototype.setRelatedid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional common.CustomVariableValueRelatedTo relatedTo = 2;
 * @return {!proto.common.CustomVariableValueRelatedTo}
 */
proto.people.CustomVariableValueCreateRequest.prototype.getRelatedto = function() {
  return /** @type {!proto.common.CustomVariableValueRelatedTo} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.common.CustomVariableValueRelatedTo} value
 * @return {!proto.people.CustomVariableValueCreateRequest} returns this
 */
proto.people.CustomVariableValueCreateRequest.prototype.setRelatedto = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional common.CustomVariableValue item = 3;
 * @return {?proto.common.CustomVariableValue}
 */
proto.people.CustomVariableValueCreateRequest.prototype.getItem = function() {
  return /** @type{?proto.common.CustomVariableValue} */ (
    jspb.Message.getWrapperField(this, common_pb.CustomVariableValue, 3));
};


/**
 * @param {?proto.common.CustomVariableValue|undefined} value
 * @return {!proto.people.CustomVariableValueCreateRequest} returns this
*/
proto.people.CustomVariableValueCreateRequest.prototype.setItem = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.people.CustomVariableValueCreateRequest} returns this
 */
proto.people.CustomVariableValueCreateRequest.prototype.clearItem = function() {
  return this.setItem(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.people.CustomVariableValueCreateRequest.prototype.hasItem = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * @enum {number}
 */
proto.people.PeopleFilterField = {
  STRING_NAME: 0,
  STRING_EMAIL: 1,
  STRING_JOB_TITLE: 2,
  DROPDOWN_RELATIONSHIP_STATUS: 3,
  DROPDOWN_PEOPLE_OWNER_ID: 4,
  DATE_PEOPLE_CREATED_AT: 5,
  DROPDOWN_PEOPLE_TAG: 6,
  DROPDOWN_CAMPAIGN_ID: 7,
  DROPDOWN_WORKSPACE_ID: 8
};

goog.object.extend(exports, proto.people);
