import { decodeJwt } from 'jose';
import { SemrushJwtToken } from '@ts/integrations/semrush.types';

const handleError = (message: string) => (error: any) => {
  console.error(message, error);
  throw error;
};

let tokenCache: { token: string; expiry: number } | null = null;
let jwtPayloadCache: SemrushJwtToken | null = null;
let smInitialized = false;

const isTokenValid = (expiry: number): boolean => {
  return expiry > Date.now();
};

const ensureInitialized = async () => {
  if (!smInitialized) {
    await initClient();
  }
};

const getJwtPayload = async (): Promise<SemrushJwtToken> => {
  await ensureInitialized();
  if (jwtPayloadCache && tokenCache && isTokenValid(tokenCache.expiry)) {
    return jwtPayloadCache;
  }
  const jwtToken = await getAccessToken();
  const decodedToken = decodeJwt(jwtToken) as SemrushJwtToken;

  jwtPayloadCache = decodedToken;

  return decodedToken;
};

export const initClient = async (): Promise<void> => {
  try {
    await window.SM.init();
    smInitialized = true;
    console.warn('SM initialized successfully');
  } catch (error) {
    handleError('Error initializing SM:')(error);
  }
};

export const pushIFrameUrl = async (url: string): Promise<void> => {
  await ensureInitialized();
  try {
    window.SM.client('pushUrl', url);
  } catch (error) {
    handleError('Error pushing iFrame URL:')(error);
  }
};

export const requestMainBillingPopup = async (): Promise<void> => {
  await ensureInitialized();
  try {
    window.SM.client('requestMainProductPurchase');
  } catch (error) {
    handleError('Error requesting billing popup:')(error);
  }
};

export const requestAdditionalBillingPopup = async (): Promise<void> => {
  await ensureInitialized();
  try {
    window.SM.client('requestInAppPurchase');
  } catch (error) {
    handleError('Error requesting billing popup:')(error);
  }
};

export const getAccessToken = async (): Promise<string> => {
  await ensureInitialized();
  try {
    if (tokenCache && isTokenValid(tokenCache.expiry)) {
      return tokenCache.token;
    }

    const token = await window.SM.client('getAccessToken');
    const decodedToken = decodeJwt(token) as SemrushJwtToken;

    const expiry = decodedToken.exp * 1000;

    tokenCache = { token, expiry };
    jwtPayloadCache = decodedToken; // Update cache

    return token;
  } catch (error) {
    handleError('Error fetching access token:')(error);
  }
};

export const getViewedId = async (): Promise<string> => {
  await ensureInitialized();
  try {
    const jwtPayload = await getJwtPayload();
    return jwtPayload.viewer_id;
  } catch (error) {
    handleError('Error fetching viewed ID:')(error);
  }
};

export const getTokenInfo = async (): Promise<SemrushJwtToken> => {
  await ensureInitialized();
  try {
    return getJwtPayload();
  } catch (error) {
    handleError('Error retrieving token:')(error);
  }
};
