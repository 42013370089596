import React, { useEffect, useMemo, useState } from 'react';
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';

import {
  ConnectIntegrationMutationArgs,
  DeleteIntegrationMutationArgs,
  IntegrationType,
  UpdateIntegrationMutationArgs,
} from '@ts/userOrganization.types';

import { AppIntegrationResponse } from 'respona_api/generated/user-organization_pb';

import { integrationsSelector } from '@redux/selectors/integrations.selectors';
import {
  connectIntegrationThunk,
  deleteIntegrationThunk,
  fetchAllIntegrations,
  updateIntegrationThunk,
} from '@redux/thunks/integrations.thunks';

import { checkIsAnyNotLoaded } from '@utils/loadingChecks';

import { getCurrentWorkspaceId, workspacesSelector } from '@redux/selectors/workspaces.selectors';

import ConfigurableTable from '@uikit/ConfigurableTable/ConfigurableTable';
import Loader from '@uikit/Loader/Loader';

import integrationsTableConfig, {
  RenderRow,
} from '@components/Settings/Integrations/integrationsTableConfig';
import IntegrationEditingSidebar from '@components/Settings/Integrations/_components/IntegrationEditingSidebar/IntegrationEditingSidebar';

import { integrationsService } from 'src/services/integrationsService';

import './Integrations.scss';
import loadingStatuses from '@constants/loadingStatuses';
import * as common_pb from 'respona_api/generated/common_pb';

declare const INTEGRATION_MODE: string;

function Integrations({ searchQueryFilter }: { searchQueryFilter?: string }): JSX.Element {
  const location = useLocation();
  const history = useHistory();

  const dispatch = useDispatch();
  const workspaceId = useSelector(getCurrentWorkspaceId);
  const { integrations, loadingStatus: integrationsLs } = useSelector(integrationsSelector);

  const allowedPerModeIntegrations = useMemo(() => {
    if (INTEGRATION_MODE === 'semrush') {
      return integrations.filter(
        (item) => item.type === common_pb.IntegrationType.SEMRUSH_INTEGRATION
      );
    }
    return integrations;
  }, [integrationsLs]);

  useEffect(() => {
    if (integrationsLs === loadingStatuses.PENDING && workspaceId > 0) {
      fetchAllIntegrations(workspaceId)(dispatch);
    }
  }, [integrationsLs, integrations, workspaceId]);

  const { workspaces, loadingStatus: workspacesLs } = useSelector(workspacesSelector);

  const [selectedIntegration, setSelectedIntegration] = useState<IntegrationType>(null);

  const connectIntegrationMutation = useMutation<
    AppIntegrationResponse.AsObject,
    unknown,
    ConnectIntegrationMutationArgs
  >(
    ({ type, workspaceIdsList, settings }: ConnectIntegrationMutationArgs) =>
      dispatch(
        connectIntegrationThunk(type, workspaceIdsList, settings, workspaceId)
      ) as unknown as Promise<AppIntegrationResponse.AsObject>,
    {
      onSuccess: () => {
        setSelectedIntegration(null);
        fetchAllIntegrations(workspaceId)(dispatch);
      },
    }
  );

  const updateIntegrationMutation = useMutation<
    AppIntegrationResponse.AsObject,
    unknown,
    UpdateIntegrationMutationArgs
  >(
    ({ id, type, data }: UpdateIntegrationMutationArgs) =>
      dispatch(
        updateIntegrationThunk(id, workspaceId, type, data)
      ) as unknown as Promise<AppIntegrationResponse.AsObject>,
    {
      onSuccess: () => setSelectedIntegration(null),
    }
  );

  const deleteIntegrationMutation = useMutation<
    AppIntegrationResponse.AsObject,
    unknown,
    DeleteIntegrationMutationArgs
  >(
    ({ id }: DeleteIntegrationMutationArgs) =>
      dispatch(
        deleteIntegrationThunk(id, workspaceId)
      ) as unknown as Promise<AppIntegrationResponse.AsObject>,
    {
      onSuccess: () => setSelectedIntegration(null),
    }
  );

  const filterByString = (string: string, search: string) => {
    return string.toUpperCase().includes(search.toUpperCase());
  };

  useEffect(() => {
    if (!workspaceId) {
      return;
    }

    integrationsService.performOauthIntegrations(
      history,
      location,
      connectIntegrationMutation,
      workspaces
    );
  }, [location.pathname, workspaceId]);

  if (!integrations?.length || checkIsAnyNotLoaded(workspacesLs) || !workspaceId) {
    return <Loader isLoading withTopMargin />;
  }

  return (
    <>
      <IntegrationEditingSidebar
        onSave={connectIntegrationMutation}
        onUpdate={updateIntegrationMutation}
        onRemove={deleteIntegrationMutation}
        onClose={() => setSelectedIntegration(null)}
        integration={selectedIntegration}
      />
      <ConfigurableTable
        tableClassName="integrations-table"
        config={integrationsTableConfig}
        data={allowedPerModeIntegrations
          .slice()
          .filter((item) => filterByString(item.title, searchQueryFilter))}
        renderProps={{
          onOpen: setSelectedIntegration,
          onUpdate: updateIntegrationMutation,
          onRemove: deleteIntegrationMutation,
        }}
        RenderRow={RenderRow}
      />
    </>
  );
}

export default Integrations;
