import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import RightSidebar from '@components/RightSidebar/RightSidebar';
import Input from '@uikit/Input/Input';
import Select from '@uikit/Select/Select';
import Loader from '@uikit/Loader/Loader';

import './EditWorkspaceSidebar.scss';
import { WorkspaceType } from '@ts/userOrganization.types';
import AutomationPositionTooltipContent from '@components/CampaignSettings/ContactAutomationSetup/_components/AutomationPositionTooltipContent/AutomationPositionTooltipContent';
import { billingDetailsSelector } from '@redux/selectors/billings.selectors';
import useMailboxAccounts from '@hooks/useMailboxAccounts';
import { QueryCollectionHookType } from '@ts/common.types';
import { MailboxAccountType } from '@ts/mailboxAccounts.types';
import useCurrentWorkspaceId from '@hooks/useCurrentWorkspaceId';
import useOrganizationMembers from '@hooks/useOrganizationMembers';

declare const IS_STANDALONE_MODE: boolean;

function EditWorkspaceSidebar({
  workspace,
  isOpen,
  onClose,
  onUpdate,
}: {
  workspace: WorkspaceType;
  isOpen: boolean;
  onClose: () => void;
  onUpdate: (
    workspaceId: number,
    title: string,
    logo: string,
    members: number[],
    mailboxes: number[]
  ) => void;
}): JSX.Element {
  if (!isOpen || !workspace) {
    return <div />;
  }

  const {
    data: { enterpriseFeaturesEnabled, extendedFeaturesEnabled },
  } = useSelector(billingDetailsSelector);

  const {
    items: baseAccounts,
    isLoading: isMailboxLoading,
  }: QueryCollectionHookType<MailboxAccountType> = useMailboxAccounts(useCurrentWorkspaceId());

  const [members, changeMembers] = useState<{ label: string; value: number }[]>(
    workspace.membersList.map((item) => {
      return { label: item.fullName, value: item.userId };
    })
  );
  const [mailboxes, changeMailboxes] = useState<{ label: string; value: number }[]>(
    workspace.accountsList.map((item) => {
      return { label: item.email, value: item.id };
    })
  );
  const [workspaceTitle, changeWorkspaceTitle] = useState(workspace.title);

  useEffect(() => {
    changeWorkspaceTitle(workspace.title);
    changeMailboxes(
      workspace.accountsList.map((item) => {
        return { label: item.email, value: item.id };
      })
    );
    changeMembers(
      workspace.membersList.map((item) => {
        return { label: item.fullName, value: item.userId };
      })
    );
  }, [workspace.id]);

  const { items: organizationMembers, isLoading: isMembersLoading } = useOrganizationMembers(true);

  const handleChangeWorkspaceTitle = ({ target: { value } }) => changeWorkspaceTitle(value);
  const handleSave = () =>
    onUpdate(
      workspace.id,
      workspaceTitle,
      workspace.logoUrl,
      members.map((item) => item.value),
      mailboxes.map((item) => item.value)
    );

  function composeSidebarTitle() {
    return `Edit workspace \n${workspace.title}`;
  }

  return (
    <RightSidebar
      isOpen={isOpen}
      title={composeSidebarTitle()}
      onClose={onClose}
      onSave={handleSave}
      width="350px"
    >
      <div className="edit-workspace-sidebar">
        <div className="edit-workspace-sidebar__row">
          <div className="edit-workspace-sidebar__field-label">Workspace name</div>
          <div className="edit-workspace-sidebar__field">
            <Input isFullWidth value={workspaceTitle} onChange={handleChangeWorkspaceTitle} />
          </div>
        </div>

        {IS_STANDALONE_MODE && (
          <Loader isLoading={organizationMembers == null || isMembersLoading}>
            <div className="edit-workspace-sidebar__row">
              <div className="edit-workspace-sidebar__field-label">Team members</div>
              <div className="edit-workspace-sidebar__field">
                <Select
                  isMulti
                  renderMultipleValuesTooltipContent={AutomationPositionTooltipContent}
                  fieldToRender="label"
                  value={members}
                  options={
                    organizationMembers?.map((item) => {
                      return { label: item.fullName, value: item.userId };
                    }) || []
                  }
                  onChange={changeMembers}
                />
              </div>
            </div>
          </Loader>
        )}

        <Loader isLoading={isMailboxLoading}>
          <div className="edit-workspace-sidebar__row">
            <div className="edit-workspace-sidebar__field-label">Email accounts</div>
            <div className="edit-workspace-sidebar__field">
              <Select
                isDisabled={
                  enterpriseFeaturesEnabled === false && extendedFeaturesEnabled === false
                }
                isMulti
                renderMultipleValuesTooltipContent={AutomationPositionTooltipContent}
                fieldToRender="label"
                value={mailboxes}
                options={baseAccounts?.map((item) => {
                  return { label: item.email, value: item.id };
                })}
                onChange={changeMailboxes}
              />
            </div>
          </div>
        </Loader>
      </div>
    </RightSidebar>
  );
}

export default EditWorkspaceSidebar;
