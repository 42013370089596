import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useLocation, useHistory } from 'react-router-dom';
import qs from 'query-string';
import cn from 'class-names';

import { NotificationSettingsContainer } from 'respona_api/generated/user-shared_pb';
import { UserNotificationSettingResponse } from 'respona_api/generated/user_pb';

import {
  getNotificationSettingsSharedApi,
  updateNotificationSettingStateSharedApi,
} from '@api/userShared.api';

import { updateItemInListByIdHelper } from '@helpers/commonHelpers';

import { addNotification } from '@redux/actions/notifications.actions';

import Loader from '@uikit/Loader/Loader';
import SlideToggler from '@uikit/SlideToggler/SlideToggler';
import {
  ExternalUnsubscribePageGradientLeft,
  ExternalUnsubscribePageGradientRight,
} from '@uikit/ExternalUnsubscribePageGradients/ExternalUnsubscribePageGradients';

import Display from '@components/Display/Display';
import { subscriptionsConfig } from '@components/Settings/Profile/subscriptionsConfig';

import 'src/assets/fonts/OakesGrotesk.css';
import './ExternalUnsubscribePage.scss';

// @ts-ignore
import responaLogo from 'src/assets/ResponaLogoLarge.svg';
import { redirectUserTo } from '@utils/historyHandler';

function ExternalUnsubscribePage(): JSX.Element {
  const history = useHistory();
  const { search } = useLocation();

  const dispatch = useDispatch();

  const queryClient = useQueryClient();
  const { token } = qs.parse(search);

  const [isUnsubscribingAll, setIsUnsubscribingAll] = useState(false);

  const { data, error, isLoading } = useQuery<NotificationSettingsContainer.AsObject>(
    ['notificationSettings', token],
    () => getNotificationSettingsSharedApi(token as string),
    {
      enabled: !!token,
      retry: false,
    }
  );

  const updateSettingsMutation = useMutation(
    ({ id, isDisabled, type }: UserNotificationSettingResponse.AsObject): Promise<void> =>
      updateNotificationSettingStateSharedApi(id, isDisabled, token as string),
    {
      onSuccess: (_, newData) => {
        dispatch(
          addNotification({
            title: `${subscriptionsConfig[newData.type].title} notification preference updated`,
            type: 'success',
          })
        );
        queryClient.setQueryData<NotificationSettingsContainer.AsObject>(
          ['notificationSettings', token],
          (oldData) => ({
            ...oldData,
            settingsList: updateItemInListByIdHelper(oldData.settingsList, newData),
          })
        );
      },
    }
  );

  const unsubscribeAll = async () => {
    setIsUnsubscribingAll(true);
    try {
      await Promise.all(
        data?.settingsList
          .slice()
          .filter((item) => !item.isDisabled)
          .map((item) =>
            updateSettingsMutation.mutateAsync({
              id: item.id,
              type: item.type,
              isDisabled: true,
            })
          )
      );
    } catch (e) {
      console.error(e);
    } finally {
      setIsUnsubscribingAll(false);
    }
  };

  if (!token || error) {
    redirectUserTo(history, '/');
    return null;
  }

  if (isLoading) {
    return <Loader isFullScreenLoading />;
  }

  return (
    <div className="external-unsubscribe-page">
      <a href="https://respona.com/" className="external-unsubscribe-page__logo">
        <img alt="Respona" src={responaLogo} className="external-unsubscribe-page__logo" />
      </a>

      <ExternalUnsubscribePageGradientLeft />
      <ExternalUnsubscribePageGradientRight />

      <div className="external-unsubscribe-page__content">
        <div className="external-unsubscribe-page__message">
          <h1 className="external-unsubscribe-page__title">Subscription preferences</h1>
          <p className="external-unsubscribe-page__sub-title">
            Select which lists{' '}
            <span className="external-unsubscribe-page__sub-title-email">{data?.userEmail}</span>{' '}
            would like to receive communication from:
          </p>
        </div>

        <div className="external-unsubscribe-page__list">
          {data?.settingsList
            ?.slice()
            .sort((a, b) => (a.id > b.id ? 1 : 0))
            .map(({ id, type, isDisabled }) => (
              <div className="external-unsubscribe-page__list-item" key={id}>
                <div className="external-unsubscribe-page__list-item-text-wrapper">
                  <span className="external-unsubscribe-page__list-item-title">
                    {subscriptionsConfig[type].title}
                  </span>
                  <span className="external-unsubscribe-page__list-item-subtitle">
                    {subscriptionsConfig[type].description}
                  </span>
                </div>
                <div className="external-unsubscribe-page__list-item-toggle">
                  <SlideToggler
                    value={!isDisabled}
                    isDisabled={
                      updateSettingsMutation.isLoading && updateSettingsMutation.variables.id === id
                    }
                    onChange={() =>
                      updateSettingsMutation.mutate({
                        id,
                        type,
                        isDisabled: !isDisabled,
                      })
                    }
                    classNames={{
                      container: 'external-unsubscribe-page__slider-container',
                      containerActive: 'external-unsubscribe-page__slider-container--active',
                      slider: 'external-unsubscribe-page__slider',
                      sliderActive: 'external-unsubscribe-page__slider--active',
                    }}
                  />
                </div>
              </div>
            ))}
        </div>

        <Display isVisible={data?.settingsList?.some((item) => !item.isDisabled)}>
          <span
            onClick={unsubscribeAll}
            className={cn('external-unsubscribe-page__button-unsubscribe-all', {
              'external-unsubscribe-page__button-unsubscribe-all--loading': isUnsubscribingAll,
            })}
          >
            {isUnsubscribingAll ? <Loader isLoading /> : 'Unsubscribe from all communication'}
          </span>
        </Display>
      </div>
    </div>
  );
}

export default ExternalUnsubscribePage;
