import React from 'react';

import { PodcastSearchType } from '@ts/automationContent.types';

import { listenNotesRegions } from '@constants/countries.contstants';
import { podcastLanguages } from '@constants/languages.contstants';

import NumberInput from '@uikit/Input/NumberInput';
import Select from '@uikit/Select/Select';
import Checkbox from '@uikit/Checkbox/Checkbox';

import AdvancedSettingsWrapper from '@components/CampaignSettings/ContentSearch/ContentAutomationSearch/_components/AdvancedSettingsWrapper/AdvancedSettingsWrapper';

import './PodcastSearchAdvancedSettings.scss';
import { PodcastSearchSource } from 'respona_api/generated/automation-content_pb';

const SelectStyles = {
  control: () => ({
    backgroundColor: 'transparent',
    border: '1px solid #DBDFE7',
    borderRadius: '8px',
    display: 'flex',
    height: '30px',
    width: '174px',
  }),
};

// TODO -> make advanced settings more DRY (it is practically the same in all three sections)

function PodcastSearchAdvancedSettings({
  settings,
  onChangeSettings,
}: {
  settings: PodcastSearchType;
  onChangeSettings: (settings: Partial<PodcastSearchType>) => void;
}): JSX.Element {
  const createFieldChangeHandler = (fieldName: string) => (value: unknown) => {
    onChangeSettings({
      [fieldName]: value,
    });
  };

  return (
    <AdvancedSettingsWrapper>
      <div
        className={`p-s-advanced-settings ${
          settings.source === PodcastSearchSource.SHOWS_PODCAST_SEARCH
            ? 'p-s-advanced-settings__five-rows'
            : ''
        }`}
      >
        <div className="p-s-advanced-settings__item p-s-advanced-settings__top-search-filter">
          Analyze top
          <NumberInput
            value={settings.analyzeResultsNumber}
            maxNumber={999}
            minNumber={0}
            onChange={createFieldChangeHandler('analyzeResultsNumber')}
            disabledArrows
          />
          search results
        </div>

        <div className="p-s-advanced-settings__item p-s-advanced-settings__item-grid">
          Location
          <div className="p-s-advanced-settings__location-select">
            <Select
              value={listenNotesRegions.find((item) => item.value === settings.location)}
              options={listenNotesRegions}
              onChange={(option) => {
                createFieldChangeHandler('location')(option.value);
              }}
              additionalStyles={SelectStyles}
            />
          </div>
        </div>
        <div className="p-s-advanced-settings__item p-s-advanced-settings__item-grid">
          Language
          <div className="p-s-advanced-settings__language-select">
            <Select
              value={podcastLanguages.find((item) => item.value === settings.language)}
              options={podcastLanguages}
              onChange={(option) => {
                createFieldChangeHandler('language')(option.value);
              }}
              additionalStyles={SelectStyles}
            />
          </div>
        </div>
        <div />
        {settings.source === PodcastSearchSource.SHOWS_PODCAST_SEARCH && <div />}

        <div className="p-s-advanced-settings__item">
          <Checkbox
            onChange={() => {
              createFieldChangeHandler('oneResultPerDomain')(!settings.oneResultPerDomain);
            }}
            value={settings.oneResultPerDomain}
          />
          No duplicate podcasts
        </div>
        <div className="p-s-advanced-settings__item max-label-width">
          <Checkbox
            onChange={() => {
              createFieldChangeHandler('ignoreAlreadyContacted')(!settings.ignoreAlreadyContacted);
            }}
            value={settings.ignoreAlreadyContacted}
          />
          Ignore podcasts contacted within
          <NumberInput
            value={settings.optionalIgnoreAlreadyContactedDays}
            maxNumber={999}
            minNumber={1}
            onChange={createFieldChangeHandler('optionalIgnoreAlreadyContactedDays')}
            disabledArrows
          />
          days
        </div>
        <div className="p-s-advanced-settings__item">
          <Checkbox
            onChange={() => {
              createFieldChangeHandler('ignoreUnsubscribed')(!settings.ignoreUnsubscribed);
            }}
            value={settings.ignoreUnsubscribed}
          />
          Ignore podcasts included in the Unsubscribed list
        </div>
        <div className="p-s-advanced-settings__item">
          <Checkbox
            onChange={() => {
              createFieldChangeHandler('ignoreActive')(!settings.ignoreActive);
            }}
            value={settings.ignoreActive}
          />
          Ignore podcasts included in other active/paused campaigns
        </div>
        {settings.source === PodcastSearchSource.SHOWS_PODCAST_SEARCH && (
          <div className="p-s-advanced-settings__item">
            <Checkbox
              onChange={() => {
                createFieldChangeHandler('hasGuestInterviews')(!settings.hasGuestInterviews);
              }}
              value={settings.hasGuestInterviews}
            />
            Only podcasts with guest interviews
          </div>
        )}
      </div>
    </AdvancedSettingsWrapper>
  );
}

export default PodcastSearchAdvancedSettings;
