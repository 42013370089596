// @ts-nocheck
import LoginPageBackground from '@pages/LoginPage/_components/LoginPageBackground';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import * as Sentry from '@sentry/react';

import { BillingType } from '@ts/billing.types';
import { BillingState, BillingSubscriptionType } from 'respona_api/generated/billing_pb';

import Loader from '@uikit/Loader/Loader';

import { userProfileSelector } from '@redux/selectors/userProfile.selector';
import { fetchAllWorkspaces } from '@redux/thunks/workspacesRequests';
import { setUserProfileData, setUserProfileLs } from '@redux/actions/userProfile.actions';
import { fetchBillingDetails } from '@redux/thunks/billings.thunks';

import useNonInitialEffect from '@hooks/useNonInitialEffect';
import loadingStatuses from '@constants/loadingStatuses';
import { isInternalPage } from '@utils/redirections';
import { onboardingStepsUrls } from '@mappers/common.mappers';
import { getCurrentInvoiceApi } from '@api/billing.api';
import urls from '@constants/urls';
import { fetchUserProfile } from '@redux/thunks/userProfileRequests';
import {
  setInsightsCampaignPerformanceFilters,
  setInsightsCreditsUsageByUserFilters,
  setInsightsLeaderBoardsFilters,
} from '@redux/actions/insights.actions';
import { setFiltersForPeople } from '@redux/actions/people.actions';
import { setCompaniesFilters } from '@redux/actions/companies.actions';
import { getCurrentWorkspaceId, workspacesSelector } from '@redux/selectors/workspaces.selectors';
import {
  insightsCampaignPerformanceFiltersSelector,
  insightsCreditsUsageByUserFiltersSelector,
  insightsLeaderBoardsFiltersSelector,
} from '@redux/selectors/insights.selector';
import { peopleFiltersSelector } from '@redux/selectors/people.selectors';
import { companiesFiltersSelector } from '@redux/selectors/companies.selectors';
import { redirectUserTo } from '@utils/historyHandler';
import { getItemFromStorage } from '@utils/localStorageHandler';

const useSentryUserInfo = () => {
  const { info } = useSelector(userProfileSelector);
  const email = info?.email;

  useEffect(() => {
    if (!email) {
      Sentry.setUser(null);
      return;
    }

    Sentry.setUser({ email });
  }, [email]);
};

declare const IS_STANDALONE_MODE: boolean;

export default function ({ children }: { children: JSX.Element }): JSX.Element {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [isReady, changeIsReady] = useState(false);
  const curWorkspaceId = useSelector(getCurrentWorkspaceId);
  const { workspaces } = useSelector(workspacesSelector);
  const leaderBoardsFilters = useSelector(insightsLeaderBoardsFiltersSelector);
  const insightsCreditsUsageByUserFilters = useSelector(insightsCreditsUsageByUserFiltersSelector);
  const campaignPerformanceFilters = useSelector(insightsCampaignPerformanceFiltersSelector);
  const peopleFilters = useSelector(peopleFiltersSelector);
  const websitesFilters = useSelector(companiesFiltersSelector);

  const isRefetchRequired = isInternalPage(location.pathname);

  const {
    data: user,
    isSuccess: isUserSuccess,
    isError: isUserError,
    refetch,
  } = useQuery('userProfile', () => fetchUserProfile(dispatch), {
    enabled: false,
    retry: false,
    staleTime: 2 * (60 * 1000),
  });

  if (IS_STANDALONE_MODE) {
    useSentryUserInfo();
  }

  useEffect(() => {
    if (workspaces != null && curWorkspaceId > 0) {
      updateWorkspaceInFilter(curWorkspaceId, leaderBoardsFilters, setInsightsLeaderBoardsFilters);
      updateWorkspaceInFilter(
        curWorkspaceId,
        insightsCreditsUsageByUserFilters,
        setInsightsCreditsUsageByUserFilters
      );
      updateWorkspaceInFilter(
        curWorkspaceId,
        campaignPerformanceFilters,
        setInsightsCampaignPerformanceFilters
      );
      updateWorkspaceInFilter(curWorkspaceId, peopleFilters, setFiltersForPeople, 8);
      updateWorkspaceInFilter(curWorkspaceId, websitesFilters, setCompaniesFilters, 7);
    }
  }, [curWorkspaceId, workspaces]);

  const updateWorkspaceInFilter = (workspaceId, filters, action, fieldNumber = 0) => {
    let isFiltersUpdated = false;
    const updatedFilters = filters.map((option) => {
      // if the chosen field is not "Workspace" or workspace is same
      if (option.field !== fieldNumber || (option.value as any).value === workspaceId) {
        return option;
      }

      isFiltersUpdated = true;
      const changedWorkspace = workspaces.find(({ id }) => id === workspaceId);

      return {
        ...option,
        value: {
          value: changedWorkspace.id,
          label: changedWorkspace.title,
        },
      };
    });

    if (isFiltersUpdated) {
      dispatch(action(updatedFilters));
    }
  };

  useNonInitialEffect(() => {
    dispatch(setUserProfileLs(loadingStatuses.INITED));
    if (isUserSuccess) {
      dispatch(setUserProfileLs(loadingStatuses.LOADED));
      dispatch(setUserProfileData(user));
    }
  }, [user, isUserSuccess, isUserError]);

  useEffect(() => {
    if (isRefetchRequired) {
      refetch({ throwOnError: true })
        .then(async ({ data: userProfile }) => {
          const step = userProfile?.authStep;
          // If user should be on-boarding - we redirect them there
          if (step < 2) {
            changeIsReady(true);
            redirectUserTo(history, onboardingStepsUrls[step]);
            return;
          }

          try {
            dispatch(fetchBillingDetails(true)).then(
              ({ state, paymentExpired, type }: BillingType) => {
                if (type === BillingSubscriptionType.MAINTENANCE) {
                  changeIsReady(true);
                  redirectUserTo(history, urls.CHANGE_SUBSCRIPTION);
                  return;
                }

                fetchAllWorkspaces(dispatch);

                if (
                  [BillingState.BILLING_UN_PAID_NORMAL, BillingState.BILLING_CANCELED].includes(
                    state
                  ) ||
                  paymentExpired
                ) {
                  getCurrentInvoiceApi()
                    .then((invoiceContainer) => {
                      const invoiceId = invoiceContainer.invoice.id;
                      changeIsReady(true);
                      if (
                        type === BillingSubscriptionType.ENTERPRISE ||
                        invoiceContainer.invoice.custom === true ||
                        invoiceContainer.invoice.type === BillingSubscriptionType.ENTERPRISE
                      ) {
                        redirectUserTo(history, urls.INVOICE(invoiceId));
                      } else if (type !== BillingSubscriptionType.INTERNAL) {
                        redirectUserTo(history, urls.CHANGE_SUBSCRIPTION);
                      }
                    })
                    .catch((reason) => {
                      // go to billing and there we should see permission denied
                      changeIsReady(true);
                      redirectUserTo(history, urls.BILLING);
                      console.log('Redirecting to billing from base request component');
                    });
                  return;
                }

                // if (step === 6) {
                //   getOnboardingChecklistApi().then((checklistData) => {
                //     if (!checklistData?.complete) {
                //       changeIsReady(true);
                //       redirectUserTo(onboardingStepsUrls[step]);
                //     }
                //   });
                // }

                changeIsReady(true);
              }
            );
          } catch (e) {
            throw new Error(e);
          }
        })
        .catch((e) => {
          changeIsReady(true);

          if (e.message?.includes('Response closed without grpc-status')) {
            redirectUserTo(history, urls.MAINTENANCE);
          }

          if (IS_STANDALONE_MODE) {
            const token = getItemFromStorage('token');
            if (!token) {
              redirectUserTo(history, urls.LOGIN);
            }
          }
        });
    } else {
      changeIsReady(true);
    }
  }, [isRefetchRequired]);

  return !isReady ? <BasePreloaderScreen /> : children;
}

export function BasePreloaderScreen({ location }) {
  return (
    <div style={style}>
      <LoginPageBackground style={{ filter: 'blur(5px)' }} location={location} />
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '150px',
          height: '150px',
          backgroundColor: '#ffffff',
          borderRadius: '8px',
        }}
      >
        <Loader isLoading />
      </div>
    </div>
  );
}

const style = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100vw',
  height: '100vh',
  overflow: 'hidden',
  position: 'fixed',
  transition: 'none!important',
};
