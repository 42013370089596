import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import { useHistory, Link } from 'react-router-dom';
import cn from 'class-names';

import Urls from '@constants/urls';
import { subscriptionLimits } from '@helpers/subscriptionHelpers';
import debounce from '@utils/debounce';
import gtm from 'src/libs/gtm';

import {
  BillingSubscriptionCycle,
  BillingSubscriptionCycleMap,
  BillingSubscriptionType,
  BillingSubscriptionTypeMap,
} from 'respona_api/generated/billing_pb';

import PoorNumberInput from '@uikit/PoorNumberInput/PoorNumberInput';
import GradientBackgroundLoader from '@uikit/GradientBackgroundLoader/GradientBackgroundLoader';
import Button from '@uikit/Button/Button';
import { SVGIcon } from '@uikit/Icon/Icon';
import Loader from '@uikit/Loader/Loader';

import { checkIsAllLoaded, checkIsAnyPending } from '@utils/loadingChecks';
import {
  calcSubscriptionPriceApi,
  getSubscriptionPresetApi,
  changeSubscriptionApi,
} from '@api/billing.api';

import { addNotification } from '@redux/actions/notifications.actions';
import { billingDetailsSelector } from '@redux/selectors/billings.selectors';
import { fetchBillingCredits, fetchBillingDetails } from '@redux/thunks/billings.thunks';

import SubscriptionTypeBar from '@components/Settings/SubscriptionForm/_components/SubscriptionTypeBar/SubscriptionTypeBar';
import PageHeader from '@components/PageHeader/PageHeader';
import UseCardToPay from '@components/UseCardToPay/UseCardToPay';
import BillingTypeCheckboxes from '@components/Settings/SubscriptionForm/_components/BillingTypeCheckboxes/BillingTypeCheckboxes';

import './SubscriptionForm.scss';
import Display from '@components/Display/Display';
import { BillingSubscriptionTypeMapValue, PaymentResponseType } from '@ts/billing.types';
import { userProfileSelector } from '@redux/selectors/userProfile.selector';
import { redirectUserTo } from '@utils/historyHandler';

function SubscriptionForm(): JSX.Element {
  const history = useHistory();
  const dispatch = useDispatch();

  const submitBtnRef = useRef<HTMLButtonElement>();
  const valuesRef = useRef<any>();
  const [values, setValues] = useState<{
    type: BillingSubscriptionTypeMap[keyof BillingSubscriptionTypeMap];
    emailAccountsCount: number;
    emailAccountsCountPro: number;
    creditsCount: number;
    creditsCountPro: number;
    creditsCountUnlimited: number;
    billingInterval: BillingSubscriptionCycleMap[keyof BillingSubscriptionCycleMap];
    contacts: number;
  }>({
    type: BillingSubscriptionType.STARTER,
    emailAccountsCount: null,
    emailAccountsCountPro: null,
    creditsCount: null,
    creditsCountPro: null,
    creditsCountUnlimited: null,
    billingInterval: BillingSubscriptionCycle.MONTHLY,
    contacts: null,
  });
  const [contacts, setContacts] = useState<number>(0);
  const { info: profileFromRedux } = useSelector(userProfileSelector);

  const onChangeValues = (key: keyof typeof values, value: unknown) => {
    if (values[key] === value) {
      return;
    }
    if (key === 'type' && value === BillingSubscriptionType.UNLIMITED) {
      const newContactsValue = Math.max(
        subscriptionLimits[BillingSubscriptionType.UNLIMITED][values.billingInterval].minContacts,
        billingDetails.totalContactsNumber
      );
      setValues((prevState) => {
        const valuesObj = {
          ...prevState,
          contacts: newContactsValue,
          billingInterval: BillingSubscriptionCycle.YEARLY,
          [key]: value,
        };
        valuesRef.current = valuesObj;
        return valuesObj;
      });
      setContacts(newContactsValue);
      return;
    }
    if (
      key === 'type' &&
      (value === BillingSubscriptionType.PRO || value === BillingSubscriptionType.STARTER)
    ) {
      if (values.billingInterval === BillingSubscriptionCycle.YEARLY) {
        if (values.creditsCount % 12000 !== 0) {
          onChangeValues(
            'creditsCount',
            Math.max(
              values.creditsCount * 12,
              subscriptionLimits[BillingSubscriptionType.STARTER][values.billingInterval]
                .minCredits,
              billingDetails.maxPaidCreditsNumber
            )
          );
        }
        if (values.creditsCountPro % 12000 !== 0) {
          onChangeValues(
            'creditsCountPro',
            Math.max(
              values.creditsCountPro * 12,
              subscriptionLimits[BillingSubscriptionType.PRO][values.billingInterval].minCredits,
              billingDetails.maxPaidCreditsNumber
            )
          );
        }
      }
    }
    if (key === 'type' && value === BillingSubscriptionType.PRO) {
      const newContactsValue = Math.max(
        subscriptionLimits[BillingSubscriptionType.PRO][values.billingInterval].minContacts,
        billingDetails.totalContactsNumber
      );
      setValues((prevState) => {
        const valuesObj = {
          ...prevState,
          contacts: newContactsValue,
          emailAccountsCountPro: Math.max(
            billingDetails.totalMailboxAccountsNumber,
            subscriptionLimits[BillingSubscriptionType.PRO][values.billingInterval].minEmails
          ),
          [key]: value,
        };
        valuesRef.current = valuesObj;
        return valuesObj;
      });
      setContacts(newContactsValue);
      return;
    }
    if (key === 'type' && value === BillingSubscriptionType.STARTER) {
      if (values.billingInterval === BillingSubscriptionCycle.YEARLY) {
        const maxEmails = Math.max(
          presetData.emailAccounts,
          subscriptionLimits[BillingSubscriptionType.STARTER][BillingSubscriptionCycle.YEARLY]
            .minEmails,
          billingDetails.totalMailboxAccountsNumber
        );
        if (values.emailAccountsCount > maxEmails) {
          onChangeValues('emailAccountsCount', maxEmails);
        }
      }
      const newContactsValue = Math.max(
        subscriptionLimits[BillingSubscriptionType.STARTER][values.billingInterval].minContacts,
        billingDetails.totalContactsNumber
      );
      setContacts(newContactsValue);
      onChangeValues('contacts', newContactsValue);
    }

    // if (key === 'billingInterval' && value === BillingSubscriptionCycle.MONTHLY) {
    //   onChangeValues(
    //     'emailAccountsCount',
    //     Math.max(
    //       billingDetails.totalMailboxAccountsNumber,
    //       subscriptionLimits[BillingSubscriptionType.STARTER][BillingSubscriptionCycle.MONTHLY]
    //         .minEmails
    //     )
    //   );
    // }

    setValues((prevState) => {
      valuesRef.current = { ...prevState, [key]: value };
      return { ...prevState, [key]: value };
    });
  };

  const [isTCAccepted, setTCAccepted] = useState(true);
  const [isCurrentCard, changeIsCurrentCard] = useState(true);
  const [isChangingBilling, changeIsChangingBilling] = useState(false);

  const { data: billingDetails, loadingStatus: billingDetailsLs } =
    useSelector(billingDetailsSelector);

  const {
    data: calcResultData,
    refetch,
    isLoading,
    isIdle,
    isFetching,
  } = useQuery(
    'calc-subscriptions-price',
    () => {
      return calcSubscriptionPriceApi(
        values.type === BillingSubscriptionType.PRO
          ? values.emailAccountsCountPro
          : values.emailAccountsCount,
        values.type === BillingSubscriptionType.STARTER
          ? values.creditsCount
          : values.type === BillingSubscriptionType.PRO
            ? values.creditsCountPro
            : values.creditsCountUnlimited,
        values.type,
        values.billingInterval,
        values.contacts
      );
    },
    {
      enabled: false,
      retry: false,
    }
  );

  const { data: presetData, isLoading: isPresetLoading } = useQuery(
    'subscription-preset',
    () => getSubscriptionPresetApi(),
    {
      refetchOnWindowFocus: false,
      retry: false,
    }
  );

  function pickIntervalFromPreset(): BillingSubscriptionCycleMap[keyof BillingSubscriptionCycleMap] {
    if (presetData.currentType === BillingSubscriptionType.TRIAL) {
      return BillingSubscriptionCycle.MONTHLY;
    }
    return presetData.cycle;
  }

  function pickTypeFromPreset(): BillingSubscriptionTypeMap[keyof BillingSubscriptionTypeMap] {
    if (presetData.currentType === BillingSubscriptionType.TRIAL) {
      return BillingSubscriptionType.STARTER;
    }
    return presetData.currentType;
  }

  useEffect(() => {
    if (checkIsAnyPending(billingDetailsLs)) {
      dispatch(fetchBillingDetails());
    }

    if (checkIsAllLoaded(billingDetailsLs) && presetData) {
      const type = pickTypeFromPreset();
      const interval = pickIntervalFromPreset();

      const contactsNumber = Math.max(
        billingDetails.totalContactsNumber,
        subscriptionLimits[type][interval].minContacts
      );

      const dataValues = {
        type,
        billingInterval: interval,
        contacts: contactsNumber,
        emailAccountsCount: Math.max(
          billingDetails.totalMailboxAccountsNumber,
          subscriptionLimits[BillingSubscriptionType.STARTER][interval].minEmails
        ),
        emailAccountsCountPro: Math.max(
          billingDetails.totalMailboxAccountsNumber,
          subscriptionLimits[BillingSubscriptionType.PRO][interval].minEmails
        ),
        creditsCount: Math.max(
          billingDetails.maxPaidCreditsNumber,
          subscriptionLimits[BillingSubscriptionType.STARTER][interval].minCredits
        ),
        creditsCountUnlimited: Math.max(
          billingDetails.maxPaidCreditsNumber,
          subscriptionLimits[BillingSubscriptionType.UNLIMITED][BillingSubscriptionCycle.YEARLY]
            .minCredits
        ),
        creditsCountPro: Math.max(
          billingDetails.maxPaidCreditsNumber,
          subscriptionLimits[BillingSubscriptionType.PRO][interval].minCredits
        ),
      };
      setContacts(contactsNumber);
      setValues(dataValues);
      valuesRef.current = dataValues;

      changeIsCurrentCard(
        billingDetails.card != null &&
          billingDetails.card.creditCardNumber != null &&
          billingDetails.card.creditCardNumber.length > 0
      );
    }
  }, [billingDetails, presetData]);

  const toggleCard = () => {
    changeIsCurrentCard((prevState) => !prevState);
  };

  const roundContacts = (value) => {
    const minContactsNumber = subscriptionLimits[values.type][values.billingInterval].minContacts;
    if (value < minContactsNumber) {
      setContacts(minContactsNumber);
      onChangeValues('contacts', minContactsNumber);
      return;
    }
    const roundValue = Math.floor(value / 10_000) * 10_000;

    if (value !== roundValue) {
      setContacts(roundValue);
    }
    onChangeValues('contacts', roundValue);
  };
  const debCallback = useRef(debounce((value) => roundContacts(value), 2000));

  useEffect(() => {
    if (
      values.creditsCount &&
      [
        BillingSubscriptionType.STARTER,
        BillingSubscriptionType.UNLIMITED,
        BillingSubscriptionType.PRO,
      ].includes(
        // @ts-ignore
        values.type
      )
    ) {
      refetch();
    }
  }, [values]);

  const isMonthInterval = values.billingInterval === BillingSubscriptionCycle.MONTHLY;
  const getIntervalValue = () => {
    if (calcResultData == null) {
      return 0;
    }
    return isMonthInterval ? calcResultData?.value : Math.floor(calcResultData?.value / 12);
  };

  const handleChangeBillingInterval = (newBillingInterval) => {
    if (newBillingInterval === values.billingInterval) {
      return;
    }
    if (newBillingInterval === BillingSubscriptionCycle.MONTHLY) {
      onChangeValues('creditsCountPro', values.creditsCountPro / 12);
      onChangeValues('creditsCount', values.creditsCount / 12);
      if (
        values.emailAccountsCountPro <
        subscriptionLimits[BillingSubscriptionType.PRO][newBillingInterval].minEmails
      ) {
        onChangeValues(
          'emailAccountsCountPro',
          subscriptionLimits[BillingSubscriptionType.PRO][newBillingInterval].minEmails
        );
      }
      if (
        values.emailAccountsCount <
        subscriptionLimits[BillingSubscriptionType.STARTER][newBillingInterval].minEmails
      ) {
        onChangeValues(
          'emailAccountsCount',
          subscriptionLimits[BillingSubscriptionType.STARTER][newBillingInterval].minEmails
        );
      }
    } else {
      onChangeValues('creditsCountPro', values.creditsCountPro * 12);
      onChangeValues('creditsCount', values.creditsCount * 12);
    }

    onChangeValues('billingInterval', newBillingInterval);
  };

  const cnSubmitWithCard = cn('subscription-form__apply-btn', {
    'is-current-card': isCurrentCard,
    'is-new-card': !isCurrentCard,
  });

  const isButtonDisabled =
    (!isTCAccepted && values.type === BillingSubscriptionType.UNLIMITED) ||
    ![
      BillingSubscriptionType.STARTER,
      BillingSubscriptionType.UNLIMITED,
      BillingSubscriptionType.PRO,
    ].includes(
      // @ts-ignore
      values.type
    );

  function makePayment(nonce: string, deviceData: string) {
    changeIsChangingBilling(true);
    const currentType = valuesRef.current.type;
    const currentInterval = valuesRef.current.billingInterval;
    changeSubscriptionApi(
      currentType === BillingSubscriptionType.PRO
        ? valuesRef.current.emailAccountsCountPro
        : valuesRef.current.emailAccountsCount,
      currentType === BillingSubscriptionType.STARTER
        ? valuesRef.current.creditsCount
        : currentType === BillingSubscriptionType.UNLIMITED
          ? valuesRef.current.creditsCountUnlimited
          : valuesRef.current.creditsCountPro,
      currentType,
      nonce,
      deviceData,
      currentInterval,
      valuesRef.current.contacts
    )
      .then(async (response: PaymentResponseType) => {
        dispatch(addNotification({ title: 'Subscription updated', type: 'success' }));
        await dispatch(fetchBillingCredits());
        await dispatch(fetchBillingDetails());
        gtm.makePayment(
          profileFromRedux.email,
          response.optionalTransactionCost,
          response.optionalTransactionId,
          convertBillingType(currentType),
          response.optionalSubscriptionPrice,
          currentInterval
        );
        redirectUserTo(history, '/settings/billing/');
      })
      .finally(() => {
        changeIsChangingBilling(false);
      });
  }

  function convertBillingType(value: BillingSubscriptionTypeMapValue): string {
    switch (value) {
      case BillingSubscriptionType.STARTER:
        return 'Starter';
      case BillingSubscriptionType.PRO:
        return 'Pro';
      case BillingSubscriptionType.TRIAL:
        return 'Trial';
      case BillingSubscriptionType.MAINTENANCE:
        return 'Maintenance';
      case BillingSubscriptionType.INTERNAL:
        return 'Internal';
      case BillingSubscriptionType.UNLIMITED:
        return 'Unlimited';
      case BillingSubscriptionType.ENTERPRISE:
        return 'Enterprise';
      default:
        return 'Unknown';
    }
  }

  function validateAndProceedWithPayment(nonce: string, deviceData: string) {
    if (isCurrentCard && nonce == null && deviceData == null) {
      makePayment(nonce, deviceData);
    }
    if (!isCurrentCard && nonce != null && deviceData != null) {
      makePayment(nonce, deviceData);
    }
  }

  return (
    <>
      <PageHeader
        title="Change Subscription"
        renderHeaderActions={() => (
          <Link
            className="subscription-form__close"
            to={Urls.BILLING}
            onClick={(event) => {
              redirectUserTo(history, Urls.BILLING);
            }}
          >
            <SVGIcon icon="crossBlack" color="#000000" size={12} />
          </Link>
        )}
      />
      <Loader lsDeps={[billingDetailsLs]} isLoading={isPresetLoading} withTopMargin>
        <div className="subscription-form">
          <div className="subscription-form__content">
            <div className="subscription-form__billing-cycle-title">Select plan</div>
            <SubscriptionTypeBar
              values={values}
              onChangeValues={onChangeValues}
              presetData={presetData}
              billingDetails={billingDetails}
            />
            {values.type !== BillingSubscriptionType.ENTERPRISE && (
              <>
                <div className="subscription-form__params-row">
                  <div className="subscription-form__param">
                    <div className="subscription-form__billing-cycle-title subscription-form__param-title subscription-form__param-title-with-tooltip">
                      Active contacts
                      {/* <SVGIcon icon="questionInCircleFilled" color="#C6C6C6" size={12} /> */}
                    </div>
                  </div>
                  <div className="subscription-form__param">
                    <div className="subscription-form__param-field">
                      <PoorNumberInput
                        value={contacts}
                        onChange={(value) => {
                          setContacts(value);
                          debCallback.current(value);
                        }}
                        onBlur={(value) => {
                          setContacts(value);
                          clearTimeout(debCallback.current(value));
                          roundContacts(value);
                        }}
                        min={Math.max(
                          Math.ceil(billingDetails.currentContactsNumber / 10_000) * 10_000,
                          subscriptionLimits[values.type][values.billingInterval].minContacts
                        )}
                        max={subscriptionLimits[values.type][values.billingInterval].maxContacts}
                        step={10000}
                      />
                    </div>
                    <div className="subscription-form__param-label" style={{ marginTop: '8px' }}>
                      $
                      {values.billingInterval === BillingSubscriptionCycle.YEARLY
                        ? '96/year'
                        : '10/mo'}{' '}
                      per 10K extra contacts
                    </div>
                  </div>
                </div>

                <div className="subscription-form__billing-cycle-title">Billing Cycle</div>
                <BillingTypeCheckboxes
                  type={values.type}
                  value={values.billingInterval}
                  onChange={handleChangeBillingInterval}
                />

                <div className="subscription-form__price">
                  New Subscription
                  <GradientBackgroundLoader
                    isLoading={isLoading || isFetching}
                    width={100}
                    height={28}
                    borderRadius={8.5}
                  >
                    <div className="subscription-form__price-value">
                      ${getIntervalValue()}/
                      <span className="subscription-form__price-interval">mo</span>
                    </div>
                  </GradientBackgroundLoader>
                </div>

                <div className="subscription-form__due-today-price">
                  Due Today
                  <GradientBackgroundLoader
                    isLoading={isLoading || isFetching}
                    width={100}
                    height={28}
                    borderRadius={8.5}
                  >
                    <div className="subscription-form__due-today-price-value">
                      ${calcResultData?.value}
                    </div>
                  </GradientBackgroundLoader>
                </div>

                <div className="subscription-form__payment-details-title">Payment details</div>

                <div className="subscription-form__card">
                  <UseCardToPay
                    onToogle={toggleCard}
                    disableButtonHandler={(value) => console.log(value)}
                    isCurrentCard={isCurrentCard}
                    submitBtnRef={submitBtnRef}
                    gradientLoaderHeight={78}
                    gradientLoaderWidth={700}
                    buttonClickCallback={(nonce: string, deviceData: string) =>
                      validateAndProceedWithPayment(nonce, deviceData)
                    }
                  />
                </div>
                <Display isVisible={isCurrentCard}>
                  <Button
                    onClick={() => validateAndProceedWithPayment(null, null)}
                    disabled={isButtonDisabled}
                    className={cnSubmitWithCard}
                    isLoading={isChangingBilling}
                  >
                    Pay and update subscription
                  </Button>
                </Display>
                <Display isVisible={!isCurrentCard}>
                  <Button
                    disabled={isButtonDisabled}
                    innerRef={submitBtnRef}
                    className={cnSubmitWithCard}
                    isLoading={isChangingBilling}
                  >
                    Pay and update subscription
                  </Button>
                </Display>

                <div className="subscription-form__footer">
                  <span className="subscription-form__footer-icon">
                    <SVGIcon icon="wallet" color="#8F9199" />
                  </span>
                  Plan credits will reset upon the start of the new subscription.
                </div>
              </>
            )}

            {values.type === BillingSubscriptionType.ENTERPRISE && (
              <Button
                onClick={() => {
                  window.open('https://respona.com/demo/', '_blank', 'noreferrer');
                }}
                className="subscription-form__contact_us-btn"
              >
                Contact us
              </Button>
            )}
            {/* {values.type === BillingSubscriptionType.UNLIMITED && ( */}
            {/*  <> */}
            {/*    <div className="subscription-form__param"> */}
            {/*      <Checkbox */}
            {/*        className="subscription-form__checkbox" */}
            {/*        onChange={() => setTCAccepted((value) => !value)} */}
            {/*        value={isTCAccepted} */}
            {/*      > */}
            {/*        I've read and agree with Respona’s fair use policy: */}
            {/*      </Checkbox> */}
            {/*    </div> */}

            {/*    <ul className="subscription-form__terms"> */}
            {/*      <li> */}
            {/*        To prevent misuse, accounts with 100 emails accounts are subject to a manual */}
            {/*        review before */}
            {/*        <br /> */}
            {/*        adding additional emails. */}
            {/*      </li> */}
            {/*      <li> */}
            {/*        Account sharing with external organizations or entities is prohibited without */}
            {/*        prior consent */}
            {/*        <br /> */}
            {/*        from Respona. */}
            {/*      </li> */}
            {/*    </ul> */}
            {/*  </> */}
            {/* )} */}
          </div>
        </div>
      </Loader>
    </>
  );
}

export default SubscriptionForm;
