import React, { useCallback, useEffect, useState } from 'react';
import { useQueryClient, useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { EntityRelationRequest } from 'respona_api/generated/common_pb';

import { PeopleFilterObjectType, PersonBaseType, PersonType } from '@ts/people.types';

import useWorkspaceMembers from '@hooks/useWorkspaceMembers';

import Pagination from '@uikit/Pagination/Pagination';
import Loader from '@uikit/Loader/Loader';

import { CampaignToAddPeopleType } from '@components/Relationships/PeopleHeaderActions/MoreActionsButton/MoreActionsButton';
import PeopleMoveToFolderModal from '@components/Relationships/MoveToFolderModals/PeopleMoveToFolderModal';
import SimpleActionSidebar from '@components/Relationships/SimpleActionSidebar/SimpleActionSidebar';
import PersonSidebar from '@components/PersonSidebar/PersonSidebar';
import PageHeader from '@components/PageHeader/PageHeader';
import PeopleTable from '@components/Relationships/PeopleTable/PeopleTable';
import PeopleHeaderActions from '@components/Relationships/PeopleHeaderActions/PeopleHeaderActions';

import {
  peopleFoldersSelector,
  peopleTagsSelector,
  peopleFiltersSelector,
} from '@redux/selectors/people.selectors';
import {
  fetchAllFolders,
  fetchAllTags,
  removePeopleBatchRequest,
  movePersonsToNewFolder,
  movePersonsToFolder,
  createNewFolderRequest,
  renameFolder,
  removeFolder,
} from '@redux/thunks/people.thunks';
import { getCurrentWorkspaceId } from '@redux/selectors/workspaces.selectors';
import { setFiltersForPeople } from '@redux/actions/people.actions';
import { addNotification } from '@redux/actions/notifications.actions';
import {
  addToBlackListApi,
  createPersonApi,
  getAllPeopleApi,
  getBasePersonByIdApi,
} from '@api/people.api';

import loadingStatuses from '@constants/loadingStatuses';
import useNonInitialEffect from '@hooks/useNonInitialEffect';

import usePagination from '@hooks/usePagination';

import ExportPeopleSidebar from '@components/ExportCsvSidebar/ExportCsvSidebar';
import AddPeopleToCampaignSidebar from '@components/Relationships/AddPeopleToCampaignSidebar/AddPeopleToCampaignSidebar';

import './RelationshipsPeople.scss';

import { DispatchType } from 'src/store';
import Folders from '@uikit/Folders/Folders';
import { FolderType } from '@ts/common.types';
import { SvgIconType } from '@uikit/Icon/Icon';
import useCampaignShortcutsByWorkspace from '@hooks/useCampaignShortcutsByWorkspace';
import { redirectUserTo } from '@utils/historyHandler';
import {ExportPeopleRequest} from "respona_api/generated/people_pb";
import ContactSidebarWrapper from '@components/ContactSidebarCRM/ContactSidebarWrapper';

const ROW_HEIGHT = 97;

function RelationshipsPeople({
  searchParams,
}: {
  searchParams: { folderId?: string | undefined; id?: string };
}): JSX.Element {
  const folderId = searchParams.folderId ? Number(searchParams.folderId) : undefined;
  const dispatch = useDispatch<DispatchType>();
  const history = useHistory();
  const queryClient = useQueryClient();
  const { members } = useWorkspaceMembers();
  const { items: campaigns } = useCampaignShortcutsByWorkspace();

  const [isOpenAddPeopleToCampaignSidebar, changeIsOpenAddPeopleToCampaignSidebar] =
    useState<null | CampaignToAddPeopleType>(null);
  const [isAddPersonOpen, changeIsAddPersonOpen] = useState(false);
  const [isExportPeopleOpen, changeIsExportPeopleOpen] = useState(false);
  const [isRemovingPeopleOpen, changeIsRemovingPeopleOpen] = useState(false);
  const [isRemoveButtonLoading, changeIsRemoveButtonLoading] = useState<boolean>(false);
  const [personForEdit, changePersonForEdit] = useState(null);
  const [searchString, changeSearchString] = useState('');
  const [campaignsList, setCampaignsList] = useState(null);

  const { page, changePage, limit, changeLimit } = usePagination(ROW_HEIGHT, 240 + ROW_HEIGHT);

  const [selectedIds, changeSelectedIds] = useState({});
  const [selectedForModal, changeSelectedForModal] = useState(null);

  const { items: folders, loadingStatus: foldersLs } = useSelector(peopleFoldersSelector);
  const { items: tags, loadingStatus: tagsLs } = useSelector(peopleTagsSelector);
  const currentWorkspaceId = useSelector(getCurrentWorkspaceId);

  const filters = useSelector(peopleFiltersSelector);

  const handleOpenAddPerson = useCallback(() => changeIsAddPersonOpen(true), []);
  const handleCloseAddPerson = useCallback(() => changeIsAddPersonOpen(false), []);
  const handleOpenExportPeople = useCallback(() => changeIsExportPeopleOpen(true), []);
  const handleCloseExportPeople = useCallback(() => changeIsExportPeopleOpen(false), []);
  const handleOpenRemovingPeople = useCallback(() => changeIsRemovingPeopleOpen(true), []);
  const handleCloseRemovingPeople = useCallback(() => changeIsRemovingPeopleOpen(false), []);

  const handleOpenAddPeopleToCampaignSidebar = useCallback(
    (campaignName: string, campaignId: number) =>
      changeIsOpenAddPeopleToCampaignSidebar({ campaignName, campaignId }),
    []
  );
  const handleCloseAddPeopleToCampaignSidebar = useCallback(
    () => changeIsOpenAddPeopleToCampaignSidebar(null),
    []
  );
  const handleCreatePeopleFolder = useCallback(
    (title?: string) => dispatch(createNewFolderRequest(title)),
    []
  );

  const handleMovePersonByModal = (person) => changeSelectedForModal(person);
  const handleCloseModal = () => changeSelectedForModal(null);

  const queryKey = ['relationships-people', page, limit, folderId, searchString, filters];

  const {
    data: people,
    isLoading,
    isFetching,
    isRefetching,
    isError,
    isIdle,
    refetch,
  } = useQuery<PersonType[]>(
    queryKey,
    () => {
      return getAllPeopleApi(
        Math.max(page - 1, 0),
        limit,
        Number(searchParams.folderId),
        Object.values(filters),
        searchString
      ).then((peopleRes: PersonType[]) => {
        return peopleRes.map(({ emailsList, ...rest }) => ({
          ...rest,
          emailsList: emailsList.sort(
            ({ accuracy: accuracyA }, { accuracy: accuracyB }) => accuracyB - accuracyA
          ),
        }));
      });
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: true,
    }
  );

  const isAllSelected = () => {
    return people ? people.every(({ id }) => selectedIds[id]) : false;
  };
  const allSelected = isAllSelected();
  const getSelectedIds = useCallback(() => Object.keys(selectedIds).map(Number), [selectedIds]);

  const handleCreatePerson = useCallback(
    (personFields) => {
      return createPersonApi(personFields)
        .then(() => {
          refetch();
        })
        .finally(() => {
          dispatch(addNotification({ title: 'Person created', type: 'success' }));
        });
    },
    [queryKey]
  );

  const handleEditPerson = (personId) =>
    changePersonForEdit(people.find(({ id }) => id === personId));

  const handleTogglePersonSelection = (personId) => {
    changeSelectedIds((prevState) => {
      if (prevState[personId] === undefined) {
        return {
          ...prevState,
          [personId]: true,
        };
      }

      // @ts-ignore
      const { [personId]: omitted, ...newState } = prevState;
      return newState;
    });
  };

  const handleToggleAllSelected = (isSelected) => {
    const newSelectedIds = { ...selectedIds };

    people.forEach((item) => {
      if (isSelected) {
        newSelectedIds[item.id] = true;
      } else {
        delete newSelectedIds[item.id];
      }
    });

    changeSelectedIds(newSelectedIds);
  };

  const handleRemovePeoples = () => {
    changeIsRemoveButtonLoading(true);
    removePeopleBatchRequest(getSelectedIds())
      .then(() => {
        if (allSelected) {
          changePage(1);
        }
        if (people.every(({ id }) => selectedIds[id] !== undefined)) {
          if (page !== 1 && getSelectedIds().length === limit) {
            changePage(page - 1);
          } else {
            refetch();
          }
        } else {
          refetch();
        }
        changeSelectedIds({});
      })
      .finally(() => {
        changeIsRemoveButtonLoading(false);
        changeIsRemovingPeopleOpen(false);
        dispatch(addNotification({ title: 'People removed', type: 'success' }));
      });
  };

  const handleRemovePerson = (personId?: number) => {
    removePeopleBatchRequest([personId]).then(() => {
      changeSelectedForModal(null);
      refetch();
    });
  };

  const onPersonUpdate = (person?: PersonBaseType) => {
    if (queryKey) {
      queryClient.setQueryData<PersonType[]>(queryKey, (prevData: PersonType[]) => {
        return prevData.map((item) => {
          if (item.id === person.id) {
            return {
              ...item,
              emailsList: person.emailsList,
              name: person.name,
              socialLinksList: person.socialLinksList,
              jobPosition: person.jobPosition,
              status: person.status,
            };
          }
          return item;
        });
      });
    }
  };

  const handleCreateNewFolder = () =>
    dispatch(
      movePersonsToNewFolder(
        getSelectedIds().map(Number),
        allSelected
          ? EntityRelationRequest.SelectionType.ALL
          : EntityRelationRequest.SelectionType.SELECTED
      )
    ).then(() => {
      dispatch(addNotification({ title: 'New folder created', type: 'success' }));
    });

  const handleMoveToFolder = ({ value }) => {
    movePersonsToFolder(
      value,
      getSelectedIds().map(Number),
      allSelected
        ? EntityRelationRequest.SelectionType.ALL
        : EntityRelationRequest.SelectionType.SELECTED
    ).then(() => {
      dispatch(addNotification({ title: 'People added to folder', type: 'success' }));
      changeSelectedIds({});
    });
  };

  const handleAddToBlackList = (peopleIds?: number[]) => {
    const ids = peopleIds || getSelectedIds().map(Number);

    addToBlackListApi(ids, currentWorkspaceId).then(() => {
      queryClient.setQueryData(queryKey, (cachedPeople: PersonType[]) =>
        cachedPeople.map((tmpPerson) =>
          ids.includes(tmpPerson.id)
            ? {
                ...tmpPerson,
                isBlocked: true,
                status: 5,
              }
            : tmpPerson
        )
      );
      dispatch(addNotification({ title: 'Unsubscribed', type: 'success' }));
    });
  };

  const handleRemovePersonFromList = (personId) => {
    queryClient.setQueryData(queryKey, (cachedPeople: PersonType[]) =>
      cachedPeople.filter(({ id }) => id !== personId)
    );
  };

  const handleApplyPeopleFilters = useCallback(
    (newFiltersObject: PeopleFilterObjectType) => dispatch(setFiltersForPeople(newFiltersObject)),
    []
  );

  useNonInitialEffect(() => {
    changePage(1);
    changeSelectedIds({});
  }, [folderId]);

  useEffect(() => {
    if (foldersLs === loadingStatuses.PENDING) {
      fetchAllFolders(dispatch);
    }

    if (tagsLs === loadingStatuses.PENDING) {
      fetchAllTags(dispatch);
    }
  }, []);

  useEffect(() => {
    if (campaigns?.length > 0) {
      setCampaignsList(campaignsList);
    }
  }, [campaigns]);

  useEffect(() => {
    if (!searchParams.id) {
      return;
    }
    getBasePersonByIdApi(Number(searchParams.id)).then(changePersonForEdit);
  }, [searchParams.id]);

  const cleanFilters = () => {
    return filters.filter(({ value }) => value);
  };

  function toBasePerson(candidate: PersonType): PersonBaseType {
    return {
      id: candidate.id,
      name: candidate.name,
      jobPosition: candidate.jobPosition,
      avatarUrl: candidate.avatarUrl,
      socialLinksList: candidate.socialLinksList,
      emailsList: candidate.emailsList,
      website: candidate.website,
      status: candidate.status,
      createdAt: candidate.createdAt,
      folderId: -1,
      workspaceId: candidate.workspaceId,
      ownerId: candidate.ownerId,
    };
  }

  function findPersonIfPossible() {
    if (personForEdit == null) {
      return null;
    }
    const candidate = people?.find(({ id }) => personForEdit.id === id);
    if (candidate == null) {
      return null;
    }
    return toBasePerson(candidate);
  }

  function renderSidebar() {
    const sidebarData= findPersonIfPossible();

    if (!sidebarData) {
      return null;
    }

    const { website, ...contact } = sidebarData;

    if (searchParams?.id?.length) {
      return (
        <ContactSidebarWrapper
          queryKey={queryKey}
          isOpen={!!personForEdit}
          onClose={() => {
            redirectUserTo(history, `/workspaces/${currentWorkspaceId}/relationships/people`);
            changePersonForEdit(null);
          }}
          website={website}
          contact={contact}
        />
      );
    }

    return (
      <ContactSidebarWrapper
        queryKey={queryKey}
        isOpen={!!personForEdit}
        onClose={() => changePersonForEdit(null)}
        website={website}
        contact={contact}
      />
    );

    // return (
    //   <ContactSidebarCRM
    //     isOpen={!!personForEdit}
    //     onClose={() => changePersonForEdit(null)}
    //     person={findPersonIfPossible()}
    //     queryKey={queryKey}
    //     isWebsite={false}
    //     onUpdate={onPersonUpdate}
    //   />
    // );
  }

  const mapToFolders = (items: FolderType[]): (FolderType & { icon: SvgIconType })[] => {
    return items.map((item) => ({ ...item, icon: 'folder' }));
  };

  const handleRename = (id: number, title: string) => {
    dispatch(renameFolder(id, title));
    return Promise.resolve();
  };

  const handleRemove = (id: number) => dispatch(removeFolder(id));

  return (
    <>
      <div className="relationships-people">
        <PageHeader
          title="Contacts"
          howToLink="https://help.respona.com/en/articles/6332429-how-to-use-relationships"
          renderHeaderActions={() => (
            <PeopleHeaderActions
              onCreatePerson={handleOpenAddPerson}
              selectedIds={getSelectedIds().map(Number)}
              allSelected={allSelected}
              onOpenRemovePeople={handleOpenRemovingPeople}
              onCreateNewFolder={handleCreateNewFolder}
              onOpenExportPeople={handleOpenExportPeople}
              onMoveToFolder={handleMoveToFolder}
              // onAddTag={handleAddTag}
              onAddToBlackList={handleAddToBlackList}
              onAddToCampaign={handleOpenAddPeopleToCampaignSidebar}
              folders={folders}
              tags={Object.values(tags)}
              searchString={searchString}
              onChangeSearchString={changeSearchString}
              onApplyFilters={handleApplyPeopleFilters}
              members={members}
              campaignsList={campaignsList}
            />
          )}
        />

        <Folders
          path={`/workspaces/${currentWorkspaceId}/relationships/people`}
          items={mapToFolders(folders)}
          handleCreate={handleCreatePeopleFolder}
          entityName="Folder"
          paramName="folderId"
          allIcon="person"
          allText="All people"
          onRemoveClick={(id) => handleRemove(id)}
          onRenameClick={(id, title) => handleRename(id, title)}
        />

        <div className="relationships-people__table-wrapper">
          <Loader isLoading={(isLoading || isFetching || isIdle) && !isRefetching} withTopMargin>
            <PeopleTable
              people={people}
              isError={isError}
              onCreatePerson={handleOpenAddPerson}
              selectedRows={selectedIds}
              allSelected={allSelected}
              onToggleRowSelection={handleTogglePersonSelection}
              onEditPerson={handleEditPerson}
              onMovePerson={handleMovePersonByModal}
              onRemovePerson={handleRemovePerson}
              onToggleAllSelected={handleToggleAllSelected}
              onAddToBlackList={handleAddToBlackList}
            />
          </Loader>
        </div>
        <Pagination
          curPage={page}
          onChangePage={changePage}
          pageLimit={limit}
          onChangePageLimit={changeLimit}
          totalItems={people?.[0]?.totalItems}
        />
        <PersonSidebar
          title="Add person"
          isOpen={isAddPersonOpen}
          onClose={handleCloseAddPerson}
          onCreate={handleCreatePerson}
        />
        <ExportPeopleSidebar
          isOpen={isExportPeopleOpen}
          itemsNumber={getSelectedIds().length || 0}
          onClose={handleCloseExportPeople}
          selectedIds={getSelectedIds()}
          filters={cleanFilters()}
          searchQuery={searchString}
          type="people"
          sidebarExportType={ExportPeopleRequest.ExportType.SELECTED}
        />
        <AddPeopleToCampaignSidebar
          isOpen={!!isOpenAddPeopleToCampaignSidebar}
          onClose={handleCloseAddPeopleToCampaignSidebar}
          selectedIds={getSelectedIds()}
          itemsNumber={getSelectedIds().length}
          campaignInfo={isOpenAddPeopleToCampaignSidebar}
        />
        <SimpleActionSidebar
          title="Remove"
          subtitle="Removing contacts is not reversible"
          itemsNumber={getSelectedIds().length || (people && people[0] && people[0].totalItems)}
          itemsTitle="people"
          isOpen={isRemovingPeopleOpen}
          onClose={handleCloseRemovingPeople}
          onAction={handleRemovePeoples}
          isActionButtonLoading={isRemoveButtonLoading}
          actionButtonLoadingText="Removing..."
        />
        {selectedForModal ? (
          <PeopleMoveToFolderModal
            itemToMove={selectedForModal}
            onClose={handleCloseModal}
            onRemovePersonFromList={handleRemovePersonFromList}
          />
        ) : null}
      </div>

      {/* <CustomFieldsSidebar */}
      {/*  onSave={() => refetchTableSettings()} */}
      {/*  isUpdate={false} */}
      {/*  isOpen={isCustomFieldsSidebarOpen} */}
      {/*  onClose={() => setCustomFieldsSidebarOpen(false)} */}
      {/*  relatedTo={CustomVariableValueRelatedTo.PERSON} */}
      {/* /> */}

      {renderSidebar()}
    </>
  );
}

export default RelationshipsPeople;
