import React, { Dispatch, SetStateAction, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import './OpportunitiesCampaignSettingsPage.scss';

import ContentAutomationSetup from '@components/CampaignSettings/ContentSearch/ContentAutomationSearch/ContentAutomationSetup';
import Loader from '@uikit/Loader/Loader';

import ContentSearchActivityLogs from '@components/CampaignSettings/ContentSearchActivityLogs/ContentSearchActivityLogs';
import ContentSearchActivityOpportunities from '@components/CampaignSettings/ContentSearchActivityOpportunities/ContentSearchActivityOpportunities';
import { getCurrentWorkspaceId } from '@redux/selectors/workspaces.selectors';
import { CampaignStepSettingsType } from '@ts/campaigns.types';
import { DispatchType } from 'src/store';
import { getActiveCampaignInfo } from '@redux/selectors/campaignSettings.selectors';
import UpsellCreditsSidebar from '@components/UpsellSidebars/UpsellCreditsSidebar/UpsellCreditsSidebar';
import SubscriptionActivationSidebar from '@components/SubscriptionActivationSidebars/SubscriptionActivationSidebar';
import { fetchBillingCredits, fetchBillingDetails } from '@redux/thunks/billings.thunks';
import { CampaignOpportunityFilterType, OpportunityPageType } from '@ts/opportunity.types';
import OpportunityContactSidebarWrapper from '@components/CampaignSettings/OpportunityContactSidebar/OpportunityContactSidebarWrapper';
import ContactPipelineSetupModal from '@components/CampaignSettings/ContactPipelineSetupModal/ContactPipelineSetupModal';
import PersonalizationPipelineSetupModal from '@components/CampaignSettings/PersonalizationPipelineSetupModal/PersonalizationPipelineSetupModal';
import OpportunityInspect from '@components/CampaignSettings/OpportunityInspect/OpportunityInspect';
import { getCampaignOpportunitiesApi } from '@api/campaign.api';
import debounce from '@utils/debounce';
import { billingCreditsSelector } from '@redux/selectors/billings.selectors';
import useCampaignPipelines from '@hooks/useCampaignPipelines';

export const AUTOMATION_SEARCH = 'automation-search';
export const AUTOMATION_RESULT = 'automation-result';

declare const IS_STANDALONE_MODE: boolean;

type ContentAutomationSearchContextType = {
  settings: CampaignStepSettingsType;
  setSettings: Dispatch<SetStateAction<CampaignStepSettingsType>>;
};

export const ContentAutomationSearchContext = React.createContext(
  {} as ContentAutomationSearchContextType
);

function OpportunitiesCampaignSettingsPage(): JSX.Element {
  const now = new Date();
  const dispatch: DispatchType = useDispatch();
  const [page, setPage] = useState<string>();
  const [isLoading, setLoading] = useState<boolean>(true);
  const [activeOpportunity, setActiveOpportunity] = useState<OpportunityPageType>(null);
  const [filters, setFilters] = useState<CampaignOpportunityFilterType[]>([]);
  const [opportunityIndex, setOpportunityIndex] = useState<number>(0);
  const [totalItemsNumber, setTotalItemsNumber] = useState<number>(null);
  const [isOpenAutomationSearchModal, setOpenAutomationSearchModal] = useState<boolean>(false);
  const [isOpenFindContactsModal, setOpenFindContactsModal] = useState<boolean>(false);
  const [isOpenPersonalizerModal, setOpenPersonalizerModal] = useState<boolean>(false);
  const [isOpenOpportunitySidebar, setOpenOpportunitySidebar] = useState<boolean>(false);
  const { info: campaignInfo } = useSelector(getActiveCampaignInfo);
  const { data: billingDetails } = useSelector(billingCreditsSelector);
  const [settings, setSettings] = useState<CampaignStepSettingsType>({
    toggleFindContactInfo: true,
    toggleRecurring: false,
    togglePersonalization: !!billingDetails.extendedFeaturesEnabled,
    repeatSearchEveryWeeks: 2,
    stopRepeatAfterDate: new Date(now.setMonth(now.getMonth() + 12)).getTime(),
    notify: true,
  });

  const currentWorkspaceId = useSelector(getCurrentWorkspaceId);
  const { campaignId } = useParams();
  const [activePipelineId, setActivePipelineId] = useState<number>(-1);
  const [upsellCreditsSidebarOpened, setUpsellCreditsSidebarOpened] = useState<boolean>(false);
  const [upsellCreditsNotEnough, setUpsellCreditsNotEnough] = useState<number>(0);
  const [isPaymentSidebarOpen, setPaymentSidebarOpen] = useState<boolean>(false);
  const campaignIdValue = useMemo(() => Number(campaignId), [campaignId]);
  const { items: pipelinesData, isLoading: isLoadingPipelines } = useCampaignPipelines(
    currentWorkspaceId,
    campaignIdValue
  );

  const handleChangeIndexOpportunity = useRef(
    debounce(
      (
        index: number,
        activeOpport: OpportunityPageType,
        activeFilters: CampaignOpportunityFilterType[]
      ) => {
        getCampaignOpportunitiesApi(
          campaignIdValue,
          currentWorkspaceId,
          index,
          1,
          activeOpport.batch,
          activeFilters
        ).then((response) => {
          if (response && response.length) {
            setActiveOpportunity(response[0]);
            setTotalItemsNumber(response[0].totalItems);
          }
        });
      },
      400
    )
  ).current;

  useEffect(() => {
    if (pipelinesData != null) {
      if (showPipelines(pipelinesData) || campaignInfo.opportunitiesNumber > 0) {
        setPage(AUTOMATION_RESULT);
      } else {
        setPage(AUTOMATION_SEARCH);
      }
      setLoading(false);
    }
  }, [pipelinesData]);

  const showPipelines = (data): boolean => {
    return data.length > 0;
  };

  if (isLoading || isLoadingPipelines) {
    return <Loader isLoading={isLoading || isLoadingPipelines} withTopMargin />;
  }

  const isTooltipOpen = () => {
    return document.querySelector('.__react_component_tooltip.show') !== null;
  };

  const closeSideBar = (): boolean => {
    if (isTooltipOpen()) {
      return false;
    }

    setActiveOpportunity(null);
    setOpenOpportunitySidebar(false);

    return true;
  };

  const onSideBarClick = (event): boolean => {
    if (isTooltipOpen()) {
      return false;
    }

    event.stopPropagation();

    return true;
  };

  const changeActiveOpportunityByIndex = (index: number) => {
    setOpportunityIndex(index);

    if (!Number.isNaN(index)) {
      handleChangeIndexOpportunity(index, activeOpportunity, filters);
    }
  };

  return (
    <ContentAutomationSearchContext.Provider value={{ settings, setSettings }}>
      {page === AUTOMATION_SEARCH ? (
        <ContentAutomationSetup
          pipelineId={activePipelineId}
          isOpenModal={isOpenAutomationSearchModal}
          setOpenModal={setOpenAutomationSearchModal}
          currentPage={page}
          setPaymentSidebarOpen={setPaymentSidebarOpen}
          setUpsellCreditsSidebarOpened={setUpsellCreditsSidebarOpened}
          setUpsellCreditsNotEnough={setUpsellCreditsNotEnough}
        />
      ) : (
        <div>
          <ContentAutomationSetup
            pipelineId={activePipelineId}
            modalOnly
            isOpenModal={isOpenAutomationSearchModal}
            setOpenModal={setOpenAutomationSearchModal}
            currentPage={page}
            setPaymentSidebarOpen={setPaymentSidebarOpen}
            setUpsellCreditsSidebarOpened={setUpsellCreditsSidebarOpened}
            setUpsellCreditsNotEnough={setUpsellCreditsNotEnough}
          />
          <ContentSearchActivityLogs
            campaignId={campaignIdValue}
            setOpenModal={setOpenAutomationSearchModal}
            setOpenFindContactsModal={setOpenFindContactsModal}
            setOpenPersonalizerModal={setOpenPersonalizerModal}
            setActivePipelineId={setActivePipelineId}
          />
          <ContentSearchActivityOpportunities
            setOpenAutomationSearchModal={setOpenAutomationSearchModal}
            setOpenFindContactsModal={setOpenFindContactsModal}
            setOpenPersonalizerModal={setOpenPersonalizerModal}
            setOpenOpportunitySidebar={setOpenOpportunitySidebar}
            setActiveOpportunity={setActiveOpportunity}
            setOpportunityIndex={setOpportunityIndex}
            setPaymentSidebarOpen={setPaymentSidebarOpen}
            setActivePipelineId={setActivePipelineId}
            totalItemsNumber={totalItemsNumber}
            filters={filters}
            setFilters={setFilters}
          />
          <ContactPipelineSetupModal
            pipelineId={activePipelineId}
            isOpenModal={isOpenFindContactsModal}
            setOpenModal={setOpenFindContactsModal}
            setPaymentSidebarOpen={setPaymentSidebarOpen}
            setUpsellCreditsSidebarOpened={setUpsellCreditsSidebarOpened}
            setUpsellCreditsNotEnough={setUpsellCreditsNotEnough}
          />
          <PersonalizationPipelineSetupModal
            pipelineId={activePipelineId}
            isOpenModal={isOpenPersonalizerModal}
            setOpenModal={setOpenPersonalizerModal}
            setPaymentSidebarOpen={setPaymentSidebarOpen}
            setUpsellCreditsSidebarOpened={setUpsellCreditsSidebarOpened}
            setUpsellCreditsNotEnough={setUpsellCreditsNotEnough}
          />
        </div>
      )}

      {isPaymentSidebarOpen && (
        <SubscriptionActivationSidebar
          title="Activate your subscription"
          onClose={() => {
            setPaymentSidebarOpen(false);
          }}
          onActionAfterPay={() => {
            setPaymentSidebarOpen(false);
            dispatch(fetchBillingDetails());
            dispatch(fetchBillingCredits());
          }}
        />
      )}

      {upsellCreditsSidebarOpened && (
        <UpsellCreditsSidebar
          title="You don&rsquo;t have enough credits for this action. How would you like to proceed?"
          onClose={() => setUpsellCreditsSidebarOpened(false)}
          needForAutomation={upsellCreditsNotEnough}
        />
      )}

      {isOpenOpportunitySidebar && activeOpportunity && (
        <div className={`opportunity-sibebar ${IS_STANDALONE_MODE ? '' : 'fit-width'}`}>
          {IS_STANDALONE_MODE && (
            <div className="opportunity-sibebar-inspect">
              <OpportunityInspect
                opportunity={activeOpportunity}
                totalItemsNumber={totalItemsNumber}
                setActiveOpportunity={setActiveOpportunity}
                opportunityIndex={opportunityIndex}
                goToItemIndex={(index) => changeActiveOpportunityByIndex(index)}
                onCloseClick={() => closeSideBar()}
                filters={filters}
              />
            </div>
          )}
          <div className="opportunity-sibebar-content" onClick={onSideBarClick}>
            {/* <OpportunityContactSidebar
              isClose={IS_STANDALONE_MODE}
              onCloseClick={() => closeSideBar()}
              opportunityId={activeOpportunity.id}
            /> */}
            <OpportunityContactSidebarWrapper
              opportunityId={activeOpportunity.id}
              // isClose={IS_STANDALONE_MODE}
              // onCloseClick={() => closeSideBar()}
            />
          </div>
        </div>
      )}
    </ContentAutomationSearchContext.Provider>
  );
}

export default OpportunitiesCampaignSettingsPage;
