import { getViewedId } from '@api/semrush/integration.api';
import { MetadataType } from '@ts/common.types';

declare const BUILD_VERSION: string;
declare const INTEGRATION_MODE: string;
declare const IS_STANDALONE_MODE: boolean;

const fetchMetadata = (): Promise<MetadataType> => {
  const metadata: MetadataType = {};

  if (IS_STANDALONE_MODE) {
    metadata.Authorization = localStorage.getItem('token');
    return Promise.resolve(metadata);
  }
  if (INTEGRATION_MODE === 'semrush') {
    metadata['X-REALM'] = 'semrush';

    return getViewedId().then((authToken) => {
      metadata.Authorization = authToken;
      return metadata;
    });
  }
  return Promise.reject(Error('Unknown integration mode'));
};

export default fetchMetadata;
