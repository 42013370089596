import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Input from '@uikit/Input/Input';
import debounce from '@utils/debounce';
import Button from '@uikit/Button/Button';
import { SVGIcon } from '@uikit/Icon/Icon';

import './HeaderSearchInput.scss';
import { redirectUserTo } from '@utils/historyHandler';

export default function ({
  onUpdateSearchQuery,
  searchQuery,
  isFiltersPage = false,
  placeholder = 'Search',
  debounceTime = 1000,
}: {
  onUpdateSearchQuery: (string) => void;
  searchQuery: string;
  isFiltersPage?: boolean;
  placeholder?: string;
  debounceTime?: number;
}): JSX.Element {
  const history = useHistory();
  const [searchString, changeSearchString] = useState(searchQuery);

  const handleUpdateSearchQueryDebounced = useRef(
    debounce((query) => {
      onUpdateSearchQuery(query);
    }, debounceTime || 1000)
  ).current;

  const handleChangeSearch = ({ target: { value } }) => {
    changeSearchString(value);
    handleUpdateSearchQueryDebounced(value);
  };

  const handleCancelClick = () => redirectUserTo(history, '/inquiries/all-inquiries');

  const handleResetSearch = () => {
    changeSearchString('');
    onUpdateSearchQuery('');
  };

  if (isFiltersPage) {
    return (
      <Button type="alert" onClick={handleCancelClick}>
        Cancel
      </Button>
    );
  }

  return (
    <div className="header-search-input__container">
      <Input
        className="header-search-input"
        icon="search"
        iconColor="#C1C1C1"
        onChange={handleChangeSearch}
        value={searchString}
        placeholder={placeholder}
      />

      {!!searchString && (
        <div onClick={handleResetSearch} className="header-search-input__delete-btn">
          <SVGIcon icon="crossDelete" size={16} />
        </div>
      )}
    </div>
  );
}
