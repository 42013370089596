declare const IS_DEV: boolean;
declare const IS_STANDALONE_MODE: boolean;

export const getItemFromStorage = (
  key: string,
  defaultValue?: string | boolean | number
): string | boolean | number => {
  if (IS_STANDALONE_MODE) {
    return localStorage.getItem(key) || defaultValue;
  }

  return defaultValue;
};

export const removeItemFromStorage = (key: string): void => {
  if (IS_STANDALONE_MODE || IS_DEV) {
    localStorage.removeItem(key);
  }
};
