import React, { useState } from 'react';

import { useHistory } from 'react-router-dom';

import useCurrentWorkspaceId from '@hooks/useCurrentWorkspaceId';

import Button from '@uikit/Button/Button';
import PageHeader from '@components/PageHeader/PageHeader';
import HeaderSearchInput from '@components/HeaderSearchInput/HeaderSearchInput';
import TemplatesTable from '@components/Settings/TemplatesTable/TemplatesTable';
import HeaderFiltersButtonWithTooltip from '@components/PageHeader/HeaderFiltersButtonWithTooltip/HeaderFiltersButtonWithTooltip';
import TeamFilter from '@components/Filters/TeamFilter/TeamFilter';
import urls from '@constants/urls';

import './TemplatesSettingPage.scss';
import { redirectUserTo } from '@utils/historyHandler';

function TemplatesSettingPage(): JSX.Element {
  const [searchQuery, changeSearchQuery] = useState<string>('');
  const [chosenOwnersIds, changeChosenOwnersIds] = useState<number[]>([]);
  const currentWorkspaceId = useCurrentWorkspaceId();

  const history = useHistory();

  const handleUpdateSearchQuery = (query: string) => changeSearchQuery(query);

  const handleCreateTemplate = () => {
    redirectUserTo(history, urls.NEW_TEMPLATE);
  };

  return (
    <div className="template-settings-page">
      <PageHeader title="Templates">
        <div className="template-settings-page__header-actions">
          <HeaderSearchInput
            searchQuery={searchQuery}
            onUpdateSearchQuery={handleUpdateSearchQuery}
          />
          <HeaderFiltersButtonWithTooltip
            areFiltersEnabled={!!chosenOwnersIds.length}
            onClearAllFilters={() => changeChosenOwnersIds([])}
            tooltipOffset={70}
          >
            <TeamFilter
              chosenPeopleIds={chosenOwnersIds}
              gatherChosenPeopleIds={(ids: number[]) => changeChosenOwnersIds(ids)}
              isSearchable
              chooseAllPeople={() => changeChosenOwnersIds([])}
            />
          </HeaderFiltersButtonWithTooltip>
          <Button
            className="template-settings-page__header-actions-btn"
            onClick={handleCreateTemplate}
          >
            Create new template
          </Button>
        </div>
      </PageHeader>

      <TemplatesTable serchQueryFilter={searchQuery} ownersIdsFilter={chosenOwnersIds} />
    </div>
  );
}

export default TemplatesSettingPage;
