import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { MailboxAccountResponse } from 'respona_api/generated/mailbox-account_pb';
import { DispatchType } from 'src/store';

import { getCurrentWorkspaceId } from '@redux/selectors/workspaces.selectors';

import { Button } from '@uikit/Button/Button';
import { SVGIcon } from '@uikit/Icon/Icon';
import Loader from '@uikit/Loader/Loader';
import Select from '@uikit/Select/Select';

import {
  createEmailSelectMenu,
  EmailSelectOption,
} from '@uikit/EmailSelect/_components/EmailSelectDropdownComponents';

import AddEmailSidebar from '@components/Settings/AddEmailSidebar/AddEmailSidebar';
import { SMTPEmailSettingsSidebar } from '@components/Settings/SMTPEmailSettings/SMTPEmailSettings';
import AutomationPositionTooltipContent from '@components/CampaignSettings/ContactAutomationSetup/_components/AutomationPositionTooltipContent/AutomationPositionTooltipContent';
import UpsellEmailSidebar from '@components/UpsellSidebars/UpsellEmailSidebar/UpsellEmailSidebar';
import Display from '@components/Display/Display';

import './EmailSelect.scss';
import SubscriptionActivationSidebar from '@components/SubscriptionActivationSidebars/SubscriptionActivationSidebar';
import { fetchBillingCredits, fetchBillingDetails } from '@redux/thunks/billings.thunks';
import useMailboxAccounts from '@hooks/useMailboxAccounts';
import { creatGmailAccountApi } from '@api/mailboxAccounts.api';
import useMailboxAccountStatistics from '@hooks/useMailboxAccountStatistics';
import { MailboxAccountStatisticsType } from '@ts/mailboxAccounts.types';
import { redirectUserTo } from '@utils/historyHandler';

const optionsTransformDefault = (emails) =>
  emails?.map(({ email, id }) => ({
    label: email,
    value: id,
  }));

function EmailSelect({
  isMulti = false,
  allowCustomInput = false,
  withAddLink = true,
  optionsTransform = null,
  onChange,
  value,
  onAddNewEmail = null,
  isDisabled = false,
  setLoading = (isLoading: boolean) => {},
}): JSX.Element {
  const dispatch = useDispatch<DispatchType>();
  const history = useHistory();

  const selectRef = useRef(null);
  const selectWrapperRef = useRef(null);

  const [isOpen, setIsOpen] = useState<boolean>(undefined);
  const [isCreationSidebarOpen, setCreationSidebarOpen] = useState<boolean>(false);
  const [isSMTPSidebarOpen, setSMTPSidebarOpen] = useState<boolean>(false);
  const [isUpsellEmailSidebarOpen, setIsUpsellEmailSidebarOpen] = useState<boolean>(false);
  const [isPaymentSidebarOpen, setPaymentSidebarOpen] = useState<boolean>(false);

  const emailTransform = optionsTransform || optionsTransformDefault;
  const currentWorkspaceId = useSelector(getCurrentWorkspaceId);
  const {
    items: data,
    isLoading,
    addItem: addAccountToCache,
    updateItem: updateAccountInCache,
  } = useMailboxAccounts(currentWorkspaceId);

  const { addItem: addMailboxToStatisticsCache, refetch: refetchMailboxStatistics } =
    useMailboxAccountStatistics();

  const emailOptions = emailTransform(data);

  const handleAddEmail = () => {
    if (emailOptions?.length >= 1) {
      redirectUserTo(history, '/settings/all-emails');
    } else {
      setCreationSidebarOpen(true);
    }
  };

  useEffect(() => {
    if (data?.length === 1 && !value?.length) {
      const emailToInsert = emailOptions[0];
      if (emailToInsert) {
        onChange(isMulti ? [emailToInsert] : emailToInsert);
      }
    }
  }, [data]);

  const values = Array.isArray(value) ? value : [value];

  return (
    <Loader isLoading={isLoading}>
      {!data?.length ? (
        <Button style={{ width: '100%' }} type="additional" onClick={handleAddEmail}>
          +&nbsp;Connect a new email
        </Button>
      ) : (
        <div className="select-wrapper">
          <Display isVisible={!!value && !values.length}>
            <div className="select-wrapper__svg">
              <SVGIcon icon="warningRoundedSignIcon" color="red" />
            </div>
          </Display>
          <div className="email-select__select-wrapper" ref={selectWrapperRef}>
            <Select
              innerRef={selectRef}
              additionalStyles={emailSelectStyles}
              value={value}
              isCreatableSelect={!isMulti}
              placeholder="Select email"
              onChange={onChange}
              isMulti={isMulti}
              options={emailOptions}
              additionalComponents={{
                ...(isMulti ? { Option: EmailSelectOption } : {}),
                ...(withAddLink
                  ? {
                      Menu: createEmailSelectMenu(
                        handleAddEmail,
                        setIsOpen,
                        selectRef,
                        selectWrapperRef
                      ),
                    }
                  : {}),
              }}
              allowCustomInput={!isMulti}
              isSearchable={isMulti}
              closeMenuOnSelect={!isMulti}
              visibleMultiCount={1}
              hideSelectedOptions={!isMulti}
              allowSelectAll={isMulti}
              menuIsOpen={isOpen}
              renderMultipleValuesTooltipContent={(data) =>
                AutomationPositionTooltipContent({ ...data, fieldToRender: 'label' })
              }
              backspaceRemoves={false}
              isDisabled={isDisabled}
            />
          </div>
        </div>
      )}

      {isCreationSidebarOpen && (
        <AddEmailSidebar
          isOpen
          onClose={() => setCreationSidebarOpen(false)}
          onOpenUpsellSidebar={() => null}
          handleSMTP={() => {
            setCreationSidebarOpen(false);
            setSMTPSidebarOpen(true);
          }}
          onAddGmailAccount={async (code: string, autoBuy = false) => {
            setLoading(true);
            creatGmailAccountApi(code, autoBuy)
              .then((createdAccount) => {
                addAccountToCache(createdAccount);
                addMailboxToStatisticsCache({
                  ...createdAccount,
                  dailySentToday: 0,
                } as MailboxAccountStatisticsType);
                const newOption = { value: createdAccount?.id, label: createdAccount?.email };
                onChange(isMulti ? [...values.filter((it) => it != null), newOption] : newOption);
              })
              .finally(() => setLoading(false));
            onAddNewEmail && onAddNewEmail();
            setCreationSidebarOpen(false);
          }}
        />
      )}

      {isSMTPSidebarOpen && (
        <SMTPEmailSettingsSidebar
          onClose={() => setSMTPSidebarOpen(false)}
          onSuccess={async (
            createdAccount: MailboxAccountResponse.AsObject,
            isReAuthentication: boolean
          ) => {
            if (isReAuthentication) {
              updateAccountInCache(createdAccount.id, createdAccount);
              refetchMailboxStatistics();
            } else {
              addAccountToCache(createdAccount);
              addMailboxToStatisticsCache({
                ...createdAccount,
                dailySentToday: 0,
                openRate: 0,
                replyRate: 0,
                healthScoreRate: 0,
              } as MailboxAccountStatisticsType);
            }
            if (createdAccount?.email) {
              onChange([...values, { value: createdAccount?.id, label: createdAccount?.email }]);
            }
            onAddNewEmail && onAddNewEmail();
            setSMTPSidebarOpen(false);
            setCreationSidebarOpen(false);
          }}
        />
      )}

      {isPaymentSidebarOpen && (
        <SubscriptionActivationSidebar
          title="Activate your subscription"
          onClose={() => {
            setPaymentSidebarOpen(false);
          }}
          onActionAfterPay={() => {
            setPaymentSidebarOpen(false);
            dispatch(fetchBillingDetails());
            dispatch(fetchBillingCredits());
          }}
        />
      )}

      {isUpsellEmailSidebarOpen && (
        <UpsellEmailSidebar
          title="You have exceeded your email account limit. Would you like to increase your limit?"
          onClose={() => setIsUpsellEmailSidebarOpen(false)}
          onActionAfterPay={() => {
            setCreationSidebarOpen(true);
          }}
        />
      )}
    </Loader>
  );
}

export default EmailSelect;

const emailSelectStyles = {
  control: (provided) => ({
    ...provided,
    borderColor: 'hsl(0, 0%, 80%)',
    boxShadow: 'none!important',
    borderRadius: '8px',
  }),
  container: (provided) => ({
    ...provided,
    maxWidth: '290px',
  }),
  valueContainer: (provided) => ({
    ...provided,
    maxWidth: '70%',
    height: '28px',
    flexWrap: 'nowrap',
  }),
  input: (provided) => ({
    ...provided,
    width: '0!important',
    overflow: 'hidden',
  }),
};
