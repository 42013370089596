import React from 'react';

import ColoredLabel from '@uikit/ColoredLabel/ColoredLabel';
import SidebarTrialCounter from '../Sidebar/components/SidebarTrialCounter/SidebarTrialCounter';

export default [
  {
    key: 'account',
    icon: 'gear',
    color: 'orange',
    positionElements: 'column',
    Children: () => <SidebarTrialCounter />,
  },
  {
    key: 'billing',
    icon: 'wallet',
    positionElements: 'column',
    Children: ({
      renderProps: {
        credits: { currentPaidCreditsNumber, currentBonusCreditsNumber },
      },
    }) => {
      function composeCredits(): string {
        const sum = currentPaidCreditsNumber + currentBonusCreditsNumber;
        if (sum >= 1_000_000) {
          return `${(sum / 1_000_000).toFixed(1)}m`;
        }
        if (sum >= 1_000) {
          return `${(sum / 1000).toFixed(1)}k`;
        }
        return `${sum}`;
      }

      return (
        <>
          <ColoredLabel color="blue" fontSize="small" position="moved-to-top">
            {composeCredits()}
          </ColoredLabel>
        </>
      );
    },
    Tooltip({
      renderProps: {
        credits: { currentPaidCreditsNumber, currentBonusCreditsNumber },
      },
    }): JSX.Element {
      return <div>{currentPaidCreditsNumber + currentBonusCreditsNumber} credits</div>;
    },
  },
  // {
  //   key: 'notifications',
  //   icon: 'notificationsIcon',
  //   color: 'blue',
  //   positionElements: 'column',
  //   Children: (): JSX.Element => {
  //     const { items: baseAccounts }: MailboxPartStateType = useSelector(getBaseAccountMailboxes);
  //
  //     const notifications = baseAccounts.filter(
  //       ({ status }) => MailboxAccountStatus.MAILBOX_AUTH_FAILED === status
  //     );
  //
  //     return (
  //       <ColoredLabel color="blue" fontSize="small" position="moved-to-top">
  //         <span>{notifications.length}</span>
  //       </ColoredLabel>
  //     );
  //   },
  //   Tooltip(): JSX.Element {
  //     return <div>Notifications</div>;
  //   },
  // },
];
