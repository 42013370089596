import { handleActions } from 'redux-actions';

import loadingStatuses from '@constants/loadingStatuses';
import { BillingType, BillingAddressType, BillingDetailsShortType } from '@ts/billing.types';

import {
  setBillingCreditsData,
  setBillingCreditsLs,
  setBillingData,
  setBillingLs,
  setBillingAddressData,
  setBillingAddressLs,
} from '@redux/actions/billing.actions';

export type BillingsStateType = {
  billingDetails: {
    loadingStatus: string;
    data: BillingType;
  };
  billingCredits: {
    loadingStatus: string;
    data: BillingDetailsShortType;
  };
  billingAddress: {
    loadingStatus: string;
    data: BillingAddressType;
  };
};

const initialState: BillingsStateType = {
  billingDetails: {
    loadingStatus: loadingStatuses.PENDING,
    data: {
      id: 0,
      type: 0,
      cycle: 0,
      currentPaidCreditsNumber: 0,
      maxPaidCreditsNumber: 0,
      currentContactsNumber: 0,
      totalContactsNumber: 0,
      currentBonusCreditsNumber: 0,
      currentMailboxAccountsNumber: 0,
      totalMailboxAccountsNumber: 0,
      amount: 0,
      state: undefined,
      autoRenewal: false,
      nextChargeTime: 0,
      invoiceNumber: '',
      invoiceAmount: 0,
      invoiceChargeTime: 0,
      deactivatedAt: -1,
      maintenanceStartedAt: -1,
      paymentExpired: false,
      card: {
        creditCardNumber: '',
        expirationMonth: '',
        expirationYear: '',
        country: '',
        postalCode: '',
      },
      contactName: '',
      contactEmail: '',
      active: false,
      extendedFeaturesEnabled: false,
      enterpriseFeaturesEnabled: false,
      optSemrushActiveProductsList: [],
    },
  },
  billingCredits: {
    loadingStatus: loadingStatuses.PENDING,
    data: {
      currentContactsNumber: 0,
      totalContactsNumber: 0,
      currentPaidCreditsNumber: 0,
      currentBonusCreditsNumber: 0,
      maxPaidCreditsNumber: 0,
      state: undefined,
      currentMailboxAccountsNumber: 0,
      maxMailboxAccountsNumber: 0,
      deactivatedAt: -1,
      maintenanceStartedAt: -1,
      active: false,
      paymentExpired: false,
      extendedFeaturesEnabled: false,
      enterpriseFeaturesEnabled: false,
    },
  },
  billingAddress: {
    loadingStatus: loadingStatuses.PENDING,
    data: {
      addressId: 0,
      companyName: '',
      firstLine: '',
      secondLine: '',
      city: '',
      zipCode: '',
      state: '',
      country: '',
      contactEmail: '',
      contactName: '',
      tax: '',
    },
  },
};

export default handleActions(
  {
    [setBillingLs]: (state, { payload }) => ({
      ...state,
      billingDetails: {
        ...state.billingDetails,
        loadingStatus: payload,
      },
    }),
    [setBillingData]: (state, { payload }) => ({
      ...state,
      billingDetails: {
        ...state.billingDetails,
        data: payload,
      },
    }),
    [setBillingCreditsLs]: (state, { payload }) => ({
      ...state,
      billingCredits: {
        ...state.billingCredits,
        loadingStatus: payload,
      },
    }),
    [setBillingCreditsData]: (state, { payload }) => ({
      ...state,
      billingCredits: {
        ...state.billingCredits,
        data: payload,
      },
    }),
    [setBillingAddressLs]: (state, { payload }) => ({
      ...state,
      billingAddress: {
        ...state.billingAddress,
        loadingStatus: payload,
      },
    }),
    [setBillingAddressData]: (state, { payload }) => ({
      ...state,
      billingAddress: {
        ...state.billingAddress,
        data: payload,
      },
    }),
  },
  initialState
);
