import React, { useRef, useState } from 'react';

import ReactDOM from 'react-dom';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { SVGIcon } from '@uikit/Icon/Icon';
import { toggleNotificationsSidebar } from '@redux/actions/appState.actions';
import { appStateSelector } from '@redux/selectors/appState.selectors';
import useOutsideClick from '@hooks/useOutsideClick';
import useHotkeys from '@hooks/useHotkeys';

import { redirectUserTo } from '@utils/historyHandler';
import { GNCConfigItemType } from './gncConfig';

import './NotificationsSidebar.scss';

function NotificationsSidebar(): JSX.Element {
  const dispatch = useDispatch();
  const history = useHistory();
  const notificationsSidebarRef = useRef<HTMLDivElement>();
  const { isNotificationsSidebarOpen } = useSelector(appStateSelector);

  const [gncNotifications, changeGncNotifications] = useState<GNCConfigItemType[]>([]);

  const handleCloseSidebar = () => {
    dispatch(toggleNotificationsSidebar(false));
  };

  useOutsideClick(notificationsSidebarRef, handleCloseSidebar);
  useHotkeys({
    Escape: handleCloseSidebar,
  });

  if (!isNotificationsSidebarOpen) {
    return null;
  }

  return ReactDOM.createPortal(
    <div className="notifications-sidebar" ref={notificationsSidebarRef}>
      <div className="notifications-sidebar__header">
        Notifications
        <div
          className="notifications-sidebar__close-icon"
          onClick={handleCloseSidebar}
          role="button"
          tabIndex={0}
        >
          <SVGIcon icon="crossBlack" />
        </div>
      </div>
      <div className="notifications-sidebar__body">
        {gncNotifications.length ? (
          gncNotifications.map(({ title, link, buttonText }) => {
            const handleClick = () => {
              handleCloseSidebar();
              redirectUserTo(history, link);
            };

            return (
              <div className="notifications-sidebar__notification" key={link}>
                <h4 className="notifications-sidebar__notification-title">{title}</h4>
                <div
                  className="notifications-sidebar__notification-text"
                  onClick={handleClick}
                  role="button"
                  tabIndex={0}
                >
                  {buttonText}
                </div>
              </div>
            );
          })
        ) : (
          <p>No notifications</p>
        )}
      </div>
    </div>,
    document.body
  );
}

export default NotificationsSidebar;
